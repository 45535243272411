import React from "react";
import { Row, Col } from "react-bootstrap";
import { Form, Text } from "react-form";
import Footer from "../structure/Footer";

const AccountSetup = ({ title, history }) => (
	<div id="accountSetup" className="onboarding">
		<div className="container">
			<Row className="text-center" id="logoRow">
				<img src="/tuzagTCSLogo.svg" alt="tuzag TCS logo" />
			</Row>
			<Row>
				<p className="pageName">1. Account Setup</p>
			</Row>
			<Row>
				<Col md={10} mdOffset={1}>
					<p>
						Brief instructions go here. Seriously, no more than two lines of
						friendly encouragement go here. Be sure to define account
						administrator here.
					</p>
				</Col>
			</Row>
			<Form
				onSubmit={values => {
					history.push("/paymentSetup");
				}}
				// validate={({ companyName, adminUsername }) => {
				// 	return {
				// 		name: !name ? 'A name is required' : undefined
				// 	}
				// }}
			>
				{({ submitForm }) => {
					return (
						<form onSubmit={submitForm}>
							<Row>
								<Col md={6}>
									<p>company name:</p>
									<Text field="companyName" />
								</Col>
								<Col md={6}>
									<p>account administrator&mdash;username:</p>
									<Text field="username" />
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<p>account administrator&mdash;first name:</p>
									<Text field="first" />
								</Col>
								<Col md={6}>
									<p>account administrator&mdash;last name:</p>
									<Text field="last" />
								</Col>
							</Row>
							<Row>
								<Col md={6}>
									<p>account administrator&mdash;email address:</p>
									<Text field="email" />
								</Col>
								<Col md={6}>
									<p>account administrator&mdash;phone:</p>
									<Text field="phone" />
								</Col>
							</Row>
							<button className="orangeButton" type="submit">
								Save and continue...
							</button>
						</form>
					);
				}}
			</Form>
		</div>
		<Footer color="purple" />
	</div>
);

export default AccountSetup;
