import React, { Component } from "react";
import browser from "detect-browser";

let interval;

export default class Loading extends Component {
	componentWillUnmount() {
		clearInterval(interval);
	}

	componentDidMount() {
		if (browser.name !== "chrome") {
			//don't animate if a service worker is detected (this is chrome only ATM)
			startAnimation();
		}

		function startAnimation() {
			const logoObject = document.getElementById("logoAnimation");
			logoObject.addEventListener("load", function() {
				let fillOpacity = "1";
				interval = setInterval(() => {
					const svgDoc = logoObject.contentDocument;
					let circleArray = [];

					for (let i = 0; i < 34; i++) {
						circleArray.push("circle" + i);
					}
					shuffle(circleArray);
					timeout(0, svgDoc, circleArray, fillOpacity);

					fillOpacity = fillOpacity === "1" ? "0" : "1";
				}, 1500);
			});
		}

		function timeout(i, svgDoc, circleArray, fillOpacity) {
			if (i < 34) {
				setTimeout(function() {
					const svgItem = svgDoc.getElementById(circleArray[i]);
					svgItem.setAttribute("fill-opacity", fillOpacity);
					i++;
					timeout(i, svgDoc, circleArray);
				}, 30);
			}
		}

		function shuffle(array) {
			let currentIndex = array.length,
				temporaryValue,
				randomIndex;

			// While there remain elements to shuffle...
			while (0 !== currentIndex) {
				// Pick a remaining element...
				randomIndex = Math.floor(Math.random() * currentIndex);
				currentIndex -= 1;

				// And swap it with the current element.
				temporaryValue = array[currentIndex];
				array[currentIndex] = array[randomIndex];
				array[randomIndex] = temporaryValue;
			}

			return array;
		}
	}

	render() {
		return (
			<div className="loading">
				{browser.name === "chrome" ? ( //chrome has an issue in service worker with loading my svg in the object tag
					<img
						id="logoFailsafe"
						src="/bubble.svg"
						alt="tuzag message bubble logo"
					/>
				) : (
					<object
						id="logoAnimation"
						data="/bubbleForAnimation.svg"
						type="image/svg+xml"
					>
						<img
							id="logoFailsafe"
							src="/bubble.svg"
							alt="tuzag message bubble logo"
						/>
					</object>
				)}
				<h3>Loading...</h3>
			</div>
		);
	}
}
