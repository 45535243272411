import React, { Component } from "react";
import autoBind from "react-autobind";
import { Row, Col, Container } from "react-bootstrap";
import API from "../../../API";
import Snackbar from "../../structure/Snackbar";
import globalStore from "../../../GlobalStore";
import { observer } from "mobx-react";
import Loading from "../../structure/Loading";
import Select from "react-select";
import Mousetrap from "mousetrap";
import shortid from "shortid";

export default observer(
	class Video extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				snackbarMessage: "",
				activeElementID: null,
				elementData: null
			};
		}

		componentDidMount() {
			Mousetrap.bind(["ctrl+s", "command+s"], event => {
				event.preventDefault();
				this.save();
			});
		}

		componentWillUnmount() {
			this.save(false, () => {}, true);
			Mousetrap.unbind(["ctrl+s", "command+s"]);
		}

		save(
			hideSnackbar = false,
			callback = () => {},
			componentUnmounting = false
		) {
			if (!this.state.activeElementID) {
				callback();
			} else {
				API(
					`/message/${this.props.match.params.messageID}/technical/video/${this.state.activeElementID}`,
					"POST",
					{ elementData: this.state.elementData },
					data => {
						if (componentUnmounting) {
							//do nothing
						} else if (hideSnackbar) {
							this.setState(
								{
									elementData: data.videoElement
								},
								() => {
									callback();
								}
							);
						} else {
							this.setState(
								{
									elementData: data.videoElement,
									snackbarMessage: "Saved!",
									snackbarID: shortid.generate()
								},
								() => {
									callback();
								}
							);
						}
					}
				);
			}
		}

		getFormFields() {
			let toReturn = [
				<Row key="elements">
					<Col xs={4}>
						<label>Display on element: </label>
					</Col>
					<Col xs={8}>
						<Select
							options={["Both", "Question", "Response"].map(option => {
								return { label: option, value: option };
							})}
							value={
								this.state.elementData.elements
									? {
											label: this.state.elementData.elements,
											value: this.state.elementData.elements
									  }
									: {
											label: "Both",
											value: "Both"
									  }
							}
							onChange={value => {
								this.setState({
									elementData: {
										...this.state.elementData,
										elements: value.value
									}
								});
							}}
						/>
					</Col>
				</Row>,
				<Row key="layerNum">
					<Col xs={4}>
						<label>Layer Num: </label>
					</Col>
					<Col xs={8}>
						<input
							type="number"
							className="form-control mousetrap"
							value={
								this.state.elementData.layerNum
									? this.state.elementData.layerNum
									: 1
							}
							onChange={evt => {
								this.setState({
									elementData: {
										...this.state.elementData,
										layerNum: evt.target.value
									}
								});
							}}
						/>
					</Col>
				</Row>,
				<Row key="transitionIn">
					<Col xs={4}>
						<label>Transition In: </label>
					</Col>
					<Col xs={8}>
						<Select
							options={["None", "Fade"].map(option => {
								return { label: option, value: option };
							})}
							value={
								this.state.elementData.transitionIn
									? {
											value: this.state.elementData.transitionIn,
											label: this.state.elementData.transitionIn
									  }
									: {
											value: "None",
											label: "None"
									  }
							}
							onChange={value => {
								this.setState({
									elementData: {
										...this.state.elementData,
										transitionIn: value.value
									}
								});
							}}
						/>
					</Col>
				</Row>,
				<Row key="transitionInLength">
					<Col xs={4}>
						<label>Transition In Seconds: </label>
					</Col>
					<Col xs={8}>
						<input
							type="number"
							className="form-control mousetrap"
							value={
								this.state.elementData.transitionInLength
									? this.state.elementData.transitionInLength
									: 0
							}
							onChange={evt => {
								this.setState({
									elementData: {
										...this.state.elementData,
										transitionInLength: evt.target.value
									}
								});
							}}
						/>
					</Col>
				</Row>,
				<Row key="transitionOut">
					<Col xs={4}>
						<label>Transition Out: </label>
					</Col>
					<Col xs={8}>
						<Select
							options={["None", "Fade"].map(option => {
								return { label: option, value: option };
							})}
							value={
								this.state.elementData.transitionOut
									? {
											value: this.state.elementData.transitionOut,
											label: this.state.elementData.transitionOut
									  }
									: {
											value: "None",
											label: "None"
									  }
							}
							onChange={value => {
								this.setState({
									elementData: {
										...this.state.elementData,
										transitionOut: value.value
									}
								});
							}}
						/>
					</Col>
				</Row>,
				<Row key="transitionOutLength">
					<Col xs={4}>
						<label>Transition Out Seconds: </label>
					</Col>
					<Col xs={8}>
						<input
							type="number"
							className="form-control mousetrap"
							value={
								this.state.elementData.transitionOutLength
									? this.state.elementData.transitionOutLength
									: 0
							}
							onChange={evt => {
								this.setState({
									elementData: {
										...this.state.elementData,
										transitionOutLength: evt.target.value
									}
								});
							}}
						/>
					</Col>
				</Row>,
				<Row key="x">
					<Col xs={4}>
						<label>X: </label>
					</Col>
					<Col xs={8}>
						<input
							type="number"
							className="form-control mousetrap"
							value={
								this.state.elementData.x || this.state.elementData.x === 0
									? this.state.elementData.x
									: ""
							}
							onChange={evt => {
								this.setState({
									elementData: {
										...this.state.elementData,
										x: evt.target.value
									}
								});
							}}
						/>
					</Col>
				</Row>,
				<Row key="y">
					<Col xs={4}>
						<label>Y: </label>
					</Col>
					<Col xs={8}>
						<input
							type="number"
							className="form-control mousetrap"
							value={
								this.state.elementData.y || this.state.elementData.y === 0
									? this.state.elementData.y
									: ""
							}
							onChange={evt => {
								this.setState({
									elementData: {
										...this.state.elementData,
										y: evt.target.value
									}
								});
							}}
						/>
					</Col>
				</Row>
			];

			if (
				this.state.elementData.type === "Image" ||
				this.state.elementData.type === "Background" ||
				this.state.elementData.type === "Video"
			)
				toReturn.push(
					<Row key="height">
						<Col xs={4}>
							<label>Height: </label>
						</Col>
						<Col xs={8}>
							<input
								type="number"
								className="form-control mousetrap"
								value={
									this.state.elementData.height
										? this.state.elementData.height
										: ""
								}
								onChange={evt => {
									this.setState({
										elementData: {
											...this.state.elementData,
											height: evt.target.value
										}
									});
								}}
								onBlur={evt => {
									if (this.state.elementData.type === "Image") {
										const setHeight = evt.target.value;
										this.setState({
											elementData: {
												...this.state.elementData,
												width: Math.round(
													(setHeight * this.state.imgSourceWidth) /
														this.state.imgSourceHeight
												)
											}
										});
									}
								}}
							/>
						</Col>
					</Row>,
					<Row key="width">
						<Col xs={4}>
							<label>Width: </label>
						</Col>
						<Col xs={8}>
							<input
								type="number"
								className="form-control mousetrap"
								value={
									this.state.elementData.width
										? this.state.elementData.width
										: ""
								}
								onChange={evt => {
									this.setState({
										elementData: {
											...this.state.elementData,
											width: evt.target.value
										}
									});
								}}
							/>
						</Col>
					</Row>
				);

			if (this.state.elementData.type === "Text") {
				toReturn.push(
					<Row key="width">
						<Col xs={4}>
							<label>Width: </label>
						</Col>
						<Col xs={8}>
							<input
								type="number"
								className="form-control mousetrap"
								value={
									this.state.elementData.width
										? this.state.elementData.width
										: ""
								}
								onChange={evt => {
									this.setState({
										elementData: {
											...this.state.elementData,
											width: evt.target.value
										}
									});
								}}
							/>
						</Col>
					</Row>,
					<Row key="Alignment">
						<Col xs={4}>
							<label>Alignment: </label>
						</Col>
						<Col xs={8}>
							<Select
								options={[
									{ label: "Left", value: "Left" },
									{
										label: "Center",
										value: "Center"
									},
									{ label: "Right", value: "Right" }
								]}
								value={
									this.state.elementData.alignment
										? {
												label: this.state.elementData.alignment,
												value: this.state.elementData.alignment
										  }
										: {
												label: "Left",
												value: "Left"
										  }
								}
								clearable={false}
								onChange={value => {
									this.setState({
										elementData: {
											...this.state.elementData,
											alignment: value.value
										}
									});
								}}
							/>
						</Col>
					</Row>,
					<Row key="maxNumLines">
						<Col xs={4}>
							<label>Max Num Lines: </label>
						</Col>
						<Col xs={8}>
							<input
								type="number"
								className="form-control mousetrap"
								value={
									this.state.elementData.maxNumLines
										? this.state.elementData.maxNumLines
										: 2
								}
								onChange={evt => {
									this.setState({
										elementData: {
											...this.state.elementData,
											maxNumLines: evt.target.value
										}
									});
								}}
							/>
						</Col>
					</Row>,
					<Row key="font">
						<Col xs={4}>
							<label>Font: </label>
						</Col>
						<Col xs={8}>
							<Select
								styles={{
									menu: provided => ({ ...provided, zIndex: 9999 })
								}}
								options={[
									{ label: "Default", value: "Default" },
									{
										label: "SF Pro Display",
										value: "SF Pro Display"
									},
									{ label: "Courgette", value: "Courgette" }
								]}
								value={
									this.state.elementData.fontFamily
										? {
												label: this.state.elementData.fontFamily,
												value: this.state.elementData.fontFamily
										  }
										: {
												label: "Default",
												value: "Default"
										  }
								}
								onChange={value => {
									this.setState({
										elementData: {
											...this.state.elementData,
											fontFamily: value.value
										}
									});
								}}
							/>
						</Col>
					</Row>,
					<Row key="fontStyle">
						<Col xs={4}>
							<label>Font Style: </label>
						</Col>
						<Col xs={8}>
							<Select
								styles={{
									menu: provided => ({ ...provided, zIndex: 9999 }),
									option: (styles, { data }) => {
										let additionalStyling = {};

										if (
											this.state.elementData.fontFamily === "SF Pro Display" ||
											this.state.elementData.fontFamily === "Courgette"
										) {
											additionalStyling.fontFamily = this.state.elementData.fontFamily;
										}

										if (data.value === "Regular") {
											additionalStyling.fontWeight = 400;
										} else if (data.value === "Italic") {
											additionalStyling.fontStyle = "italic";
										} else if (data.value === "Ultralight") {
											additionalStyling.fontWeight = 100;
										} else if (data.value === "Ultralight Italic") {
											additionalStyling.fontWeight = 100;
											additionalStyling.fontStyle = "italic";
										} else if (data.value === "Thin") {
											additionalStyling.fontWeight = 200;
										} else if (data.value === "Thin Italic") {
											additionalStyling.fontWeight = 200;
											additionalStyling.fontStyle = "italic";
										} else if (data.value === "Light") {
											additionalStyling.fontWeight = 300;
										} else if (data.value === "Light Italic") {
											additionalStyling.fontWeight = 300;
											additionalStyling.fontStyle = "italic";
										} else if (data.value === "Medium") {
											additionalStyling.fontWeight = 500;
										} else if (data.value === "Medium Italic") {
											additionalStyling.fontWeight = 500;
											additionalStyling.fontStyle = "italic";
										} else if (data.value === "Semibold") {
											additionalStyling.fontWeight = 600;
										} else if (data.value === "Semibold Italic") {
											additionalStyling.fontWeight = 600;
											additionalStyling.fontStyle = "italic";
										} else if (data.value === "Bold") {
											additionalStyling.fontWeight = 700;
										} else if (data.value === "Bold Italic") {
											additionalStyling.fontWeight = 700;
											additionalStyling.fontStyle = "italic";
										} else if (data.value === "Heavy") {
											additionalStyling.fontWeight = 800;
										} else if (data.value === "Heavy Italic") {
											additionalStyling.fontWeight = 800;
											additionalStyling.fontStyle = "italic";
										} else if (data.value === "Black") {
											additionalStyling.fontWeight = 900;
										} else if (data.value === "Black Italic") {
											additionalStyling.fontWeight = 900;
											additionalStyling.fontStyle = "italic";
										}

										return { ...styles, ...additionalStyling };
									}
								}}
								options={
									this.state.elementData.fontFamily === "Courgette"
										? [{ label: "Regular", value: "Regular" }]
										: [
												"Regular",
												"Italic",
												"Ultralight",
												"Ultralight Italic",
												"Thin",
												"Thin Italic",
												"Light",
												"Light Italic",
												"Medium",
												"Medium Italic",
												"Semibold",
												"Semibold Italic",
												"Bold",
												"Bold Italic",
												"Heavy",
												"Heavy Italic",
												"Black",
												"Black Italic"
										  ].map(option => {
												return { label: option, value: option };
										  })
								}
								value={
									this.state.elementData.fontStyle
										? {
												label: this.state.elementData.fontStyle,
												value: this.state.elementData.fontStyle
										  }
										: {
												label: "Regular",
												value: "Regular"
										  }
								}
								onChange={value => {
									this.setState({
										elementData: {
											...this.state.elementData,
											fontStyle: value.value
										}
									});
								}}
							/>
						</Col>
					</Row>,
					<Row key="fontSize">
						<Col xs={4}>
							<label>Font Size: </label>
						</Col>
						<Col xs={8}>
							<input
								type="number"
								className="form-control mousetrap"
								value={
									this.state.elementData.size ? this.state.elementData.size : 0
								}
								onChange={evt => {
									this.setState({
										elementData: {
											...this.state.elementData,
											size: evt.target.value
										}
									});
								}}
							/>
						</Col>
					</Row>,
					<Row key="fontColor">
						<Col xs={4}>
							<label>Font Color: </label>
						</Col>
						<Col xs={8}>
							<input
								type="text"
								className="form-control mousetrap"
								value={
									this.state.elementData.fontColor
										? this.state.elementData.fontColor
										: ""
								}
								onChange={evt => {
									this.setState({
										elementData: {
											...this.state.elementData,
											fontColor: evt.target.value
										}
									});
								}}
							/>
						</Col>
					</Row>
				);
			}

			if (
				this.state.elementData.type &&
				this.state.elementData.type !== "Not a Video Element"
			) {
				return toReturn;
			}
		}

		setImageDimensions = () => {
			API(
				`/message/${this.props.match.params.messageID}`,
				"POST",
				{
					bypassMapping: true,
					elementID: this.state.activeElementID
				},
				tailorData => {
					let img = new Image();
					img.src = tailorData[this.state.activeElementID];
					img.onload = () => {
						this.setState({
							imgSourceHeight: img.height,
							imgSourceWidth: img.width
						});
						if (
							(!this.state.elementData.height &&
								!this.state.elementData.width) ||
							(this.state.elementData.height === 0 &&
								this.state.elementData.width === 0)
						) {
							this.setState({
								elementData: {
									...this.state.elementData,
									height: img.height,
									width: img.width
								}
							});
						}

						return true;
					};
				},
				false,
				true
			);
		};

		render() {
			return (
				<Container fluid id="technicalVideo">
					<Row>
						<Col xs={4}>
							{globalStore.elements
								.filter(element => {
									return ![
										"question",
										"response",
										"next question",
										"end",
										"help"
									].includes(element.name.toLowerCase());
								})
								.map(element => {
									return (
										<div
											key={element.id}
											onClick={() => {
												this.save(true, () => {
													this.setState(
														{
															activeElementID: element.id,
															elementData: null
														},
														() => {
															API(
																`/message/${this.props.match.params.messageID}/technical/video/${element.id}`,
																"GET",
																{},
																data => {
																	this.setState(
																		{
																			elementData: data.videoElement
																		},
																		() => {
																			this.setImageDimensions();
																		}
																	);
																}
															);
														}
													);
												});
											}}
											className={
												(element.id === this.state.activeElementID
													? "activeElement "
													: "") + "element"
											}
										>
											{element.name}
										</div>
									);
								})}
						</Col>
						{this.state.activeElementID ? (
							<Col>
								{this.state.elementData ? (
									<div>
										<Row>
											<Col xs={4}>
												<label>Type: </label>
											</Col>
											<Col xs={8}>
												<Select
													options={[
														{
															label: "Not a Video Element",
															value: "Not a Video Element"
														},
														{ label: "Image", value: "Image" },
														{ label: "Text", value: "Text" },
														{ label: "Video", value: "Video" },
														{ label: "Background", value: "Background" }
													]}
													value={
														this.state.elementData.type
															? {
																	label: this.state.elementData.type,
																	value: this.state.elementData.type
															  }
															: {
																	label: "Not a Video Element",
																	value: "Not a Video Element"
															  }
													}
													onChange={value => {
														this.setState(
															{
																elementData: {
																	...this.state.elementData,
																	type: value.value
																}
															},
															() => {
																if (value.value === "Image") {
																	this.setImageDimensions();
																}
															}
														);
													}}
												/>
											</Col>
										</Row>
										{this.getFormFields()}
										<Row>
											<Col className="text-right">
												<a
													onClick={() => {
														this.save();
													}}
													className="saveButton"
													type="submit"
												>
													Save
												</a>
											</Col>
										</Row>
									</div>
								) : (
									<Loading />
								)}
							</Col>
						) : (
							<Col></Col>
						)}
					</Row>
					<Snackbar
						message={this.state.snackbarMessage}
						lastSaveID={this.state.snackbarID}
					/>
				</Container>
			);
		}
	}
);
