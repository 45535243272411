import React, { Component } from "react";
import autoBind from "react-autobind";
import { Row, Col } from "react-bootstrap";
import ReactQuill from "react-quill";
import { observer } from "mobx-react";
import globalStore from "../../../GlobalStore";

export default observer(
	class Notes extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				panelOpen: true
			};

			this.modules = {
				keyboard: {
					bindings: {
						custom: {
							key: "S",
							shortKey: true,
							handler: function() {
								props.save();
							}
						},
						cmdUp: {
							key: "UP",
							shortKey: true,
							handler: function() {
								const currentElementIndex = globalStore.elements.findIndex(
									element => {
										return this.props.pageStore.element.id === element.id;
									}
								);
								const nextElement =
									globalStore.elements[currentElementIndex - 1];
								if (nextElement) {
									const nextElementID = nextElement.id;
									globalStore.history.push(
										`/message/${
											nextElement.messageID
										}/elements/${nextElementID}`
									);
								}
							}.bind(this)
						},
						cmdDown: {
							key: "DOWN",
							shortKey: true,
							handler: function() {
								const currentElementIndex = globalStore.elements.findIndex(
									element => {
										return this.props.pageStore.element.id === element.id;
									}
								);
								const nextElement =
									globalStore.elements[currentElementIndex + 1];
								if (nextElement) {
									const nextElementID = nextElement.id;
									globalStore.history.push(
										`/message/${
											nextElement.messageID
										}/elements/${nextElementID}`
									);
								}
							}.bind(this)
						},
						quickFind: {
							key: "K",
							shortKey: true,
							handler: function() {
								globalStore.messageBrowserPanelOpen = !globalStore.messageBrowserPanelOpen;
							}
						}
					}
				},
				toolbar: {
					container: ".quillToolbar",
					handlers: {
						color: value => {
							if (value === "custom-color") {
								value = prompt("Enter Hex/RGB/RGBA");
							}

							this.reactQuillRef.getEditor().format("color", value);
						}
					}
				}
			};
		}

		handleChange(value) {
			if (this.props.pageStore.element) {
				this.props.pageStore.element.notes = value;
			}
		}

		toggleState(field) {
			this.setState({ [field]: !this.state[field] });
		}

		render() {
			if (
				(globalStore.userType === "Read Only" ||
					(globalStore.message && globalStore.message.status === "Published") ||
					globalStore.userType === "Developer" ||
					globalStore.userType === "Reviewer") &&
				this.reactQuillRef
			) {
				this.reactQuillRef.getEditor().enable(false);
			}
			return (
				<div>
					<Row>
						<Col className="panelRow">
							<Row
								onClick={this.toggleState.bind(null, "panelOpen")}
								className="panelCollapsibleRow"
							>
								<Col xs={11}>
									<h2 className="panelName">Notes</h2>
								</Col>
								<Col xs={1} className="text-right">
									{this.state.panelOpen ? (
										<i className="far fa-caret-square-up" />
									) : (
										<i className="far fa-caret-square-down" />
									)}
								</Col>
							</Row>
							{this.state.panelOpen ? (
								<div className="panelContent">
									<Toolbar />
									<ReactQuill
										value={
											this.props.pageStore.element
												? this.props.pageStore.element.notes
												: "Loading"
										}
										onChange={this.handleChange}
										modules={this.modules}
										theme={"snow"}
										onChangeSelection={this.onChangeSelection}
										ref={el => {
											this.reactQuillRef = el;
										}}
									/>
								</div>
							) : null}
						</Col>
					</Row>
				</div>
			);
		}
	}
);

class Toolbar extends Component {
	render() {
		return globalStore.userType === "Read Only" ||
			(globalStore.message && globalStore.message.status === "Published") ||
			globalStore.userType === "Developer" ||
			globalStore.userType === "Reviewer" ? (
			<div className="quillToolbar" />
		) : (
			<div className="quillToolbar">
				<span className="ql-formats">
					<button className="ql-bold" />
					<button className="ql-italic" />
					<button className="ql-underline" />
				</span>
				<span className="ql-formats">
					<button className="ql-list" value="ordered" />
					<button className="ql-list" value="bullet" />
					<button className="ql-blockquote" />
				</span>
				<span className="ql-formats">
					<select className="ql-color">
						{[
							"black",
							"white",
							"silver",
							"gray",
							"red",
							"maroon",
							"yellow",
							"olive",
							"lime",
							"green",
							"aqua",
							"teal",
							"blue",
							"navy",
							"fuchsia",
							"purple",
							"custom-color"
						].map(color => {
							return (
								<option
									key={color}
									value={color}
									defaultValue={color === "black"}
								/>
							);
						})}
					</select>
				</span>
				<span className="ql-formats">
					<button className="ql-clean" />
					<button className="ql-link" />
				</span>
			</div>
		);
	}
}
