import React from "react";
import SidebarRow from "./Row";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import globalStore from "../../../GlobalStore";

const SidebarOutOfMessage = observer(({ history }) => (
	<div id="sidebarContent">
		<div id="logoRow" className="text-center">
			<a href="/dashboard/dashboard" id="topRowNavLink">
				<img src="/bubble.svg" alt="tuzag message bubble logo" />
			</a>
		</div>
		<SidebarRow
			rowName="Dashboard"
			history={history}
			rowURL={"/dashboard/dashboard"}
		>
			<NavLink to="/dashboard/dashboard">Dashboard</NavLink>
			<NavLink to="/dashboard/searchMessages">Search Messages</NavLink>
			<NavLink to="/dashboard/searchComments">Search Comments</NavLink>
		</SidebarRow>
		<SidebarRow rowName="Select" history={history} rowURL={"/selectMessage"}>
			<p>
				Select a message using the pulldown menus. You can also select a message
				at any time by clicking on <i className="far fa-map" /> next to our logo
				and selecting a message from the menu tree.
			</p>
		</SidebarRow>
		{/*<SidebarRow rowName="Manage Profile">*/}
		{/*<p>Select or update your photo, nickname, password and sitewide reference information here.*/}
		{/*Contact your admin to update your name, email address or role.</p>*/}
		{/*</SidebarRow>*/}
		{/*<SidebarRow rowName="Help Center">*/}
		{/*<NavLink to="/">*/}
		{/*User's Guide*/}
		{/*</NavLink>*/}
		{/*<NavLink to="/">*/}
		{/*Video Tutorials*/}
		{/*</NavLink>*/}
		{/*<NavLink to="/">*/}
		{/*Ninja Consults*/}
		{/*</NavLink>*/}
		{/*</SidebarRow>*/}
		{globalStore.userType === "Architect" ? (
			<SidebarRow rowName="Architect Tools">
				<NavLink to="/admin/manageMessages">Manage messages</NavLink>
				<NavLink to="/admin/manageAttsAndOpts">Manage attributes</NavLink>
				<NavLink to="/admin/manageRetrievers">Manage retrievers</NavLink>
				<NavLink to="/admin/manageUsers">Manage users</NavLink>
				<NavLink to="/admin/manageGlossaries">Manage glossaries</NavLink>
				<NavLink to="/admin/publishKnowledgeBase">Publish/Export KB</NavLink>
				<NavLink to="/admin/googleCloudNL">Google Cloud NL</NavLink>
				{/*<NavLink to="/">*/}
				{/*Manage company details*/}
				{/*</NavLink>*/}
				{/*<NavLink to="/">*/}
				{/*Billing information*/}
				{/*</NavLink>*/}
			</SidebarRow>
		) : null}
	</div>
));

export default SidebarOutOfMessage;
