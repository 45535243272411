import firebase from "firebase/app";
let didTimeOut = false;

const API = async (
	url,
	method,
	data,
	resultFunction,
	reauth = false,
	tailor = false
) => {
	let apiURL;

	if (url.includes("login") || url.includes("logout")) {
		if (
			process.env.REACT_APP_ENV === "staging" ||
			process.env.REACT_APP_ENV === "mhd" ||
			process.env.REACT_APP_ENV === "life365"
		) {
			apiURL = "https://api.staging.tuzagdesign.com";
		} else if (process.env.REACT_APP_ENV === "vumc") {
			apiURL = "https://api.vumc.tuzagdesign.com";
		} else {
			apiURL = "https://api.v2.tuzagdesign.com";
		}
	} else {
		if (process.env.REACT_APP_ENV === "staging") {
			apiURL = tailor
				? "https://api.staging.tuzagdesign.com/tailor"
				: "https://api.staging.tuzagdesign.com/design";
		} else if (process.env.REACT_APP_ENV === "mhd") {
			apiURL = tailor
				? "https://api.staging.tuzagdesign.com/tailorMHD"
				: "https://api.staging.tuzagdesign.com/designMHD";
		} else if (process.env.REACT_APP_ENV === "life365") {
			apiURL = tailor
				? "https://api.staging.tuzagdesign.com/tailorLife365"
				: "https://api.staging.tuzagdesign.com/designLife365";
		} else if (process.env.REACT_APP_ENV === "nineZest") {
			apiURL = tailor
				? "https://api.staging.tuzagdesign.com/tailornineZest"
				: "https://api.staging.tuzagdesign.com/designnineZest";
		} else if (process.env.REACT_APP_ENV === "vumc2") {
			apiURL = tailor
				? "https://api.staging.tuzagdesign.com/tailorvumc2"
				: "https://api.staging.tuzagdesign.com/designvumc2";
		} else if (process.env.REACT_APP_ENV === "vumc") {
			apiURL = tailor
				? "https://api.vumc.tuzagdesign.com/tailor"
				: "https://api.vumc.tuzagdesign.com/design";
		} else {
			apiURL = tailor
				? "https://api.v2.tuzagdesign.com/tailor"
				: "https://api.v2.tuzagdesign.com/design";
		}
	}

	if (process.env.NODE_ENV === "development") {
		if (url.includes("login") || url.includes("logout")) {
			apiURL = "http://localhost:3000";
		} else {
			if (true) {
				//mhd
				apiURL = tailor
					? "http://localhost:3000/tailorMHD"
					: "http://localhost:3000/designMHD";
			} else {
				apiURL = tailor
					? "http://localhost:3000/tailor"
					: "http://localhost:3000/design";
			}
		}
	}

	if (tailor && typeof data === "object") {
		data.apiKey = "nRvtIpRUNsxAuTUQ3w8DnRvtIpRUNsxAuTUQ3w8D";
	}

	let firebasetoken = firebase.auth().currentUser
		? await firebase.auth().currentUser.getIdToken(false)
		: "";

	new Promise(function(resolve, reject) {
		const timeout = setTimeout(function() {
			didTimeOut = true;
			reject(new Error("Request timed out"));
		}, 20000);

		if (firebasetoken) {
			try {
				fetch((url.includes("http") ? "" : apiURL) + url, {
					method,
					credentials: "include",
					mode: "cors",
					headers: {
						"Content-Type": "application/json",
						firebasetoken
					},
					body:
						method === "GET"
							? null
							: data === null
							? null
							: JSON.stringify(data)
				})
					.then(function(response) {
						clearTimeout(timeout);
						if (!didTimeOut) {
							resolve(response);
						}
						if (response.status === 401 && url !== "/login") {
							window.location = "/";
							alert("Please sign in again");
						} else if (!response.ok && process.env.NODE_ENV !== "development") {
							alert("That API called failed. Please try that again.");
							return { error: true };
						} else {
							return response.json();
						}
					})
					.then(resultFunction)
					.catch(function(ex) {
						// resultFunction({ error: true });
						console.log("parsing failed", ex);
						resultFunction({ error: true });
						if (didTimeOut) {
							return;
						}
						reject();
					});
			} catch (e) {
				alert(
					'Something went wrong with the API call. Stop what you\'re doing, hit OK, open the console under "Inspect Element," screenshot it, and send it to Max.'
				);
				console.error(e);
			}
		} else {
			setTimeout(async () => {
				let interval = setInterval(async () => {
					try {
						firebasetoken = firebase.auth().currentUser
							? await firebase.auth().currentUser.getIdToken(false)
							: "";

						if (firebasetoken) {
							clearInterval(interval);

							fetch(apiURL + url, {
								method,
								credentials: "include",
								mode: "cors",
								headers: {
									"Content-Type": "application/json",
									firebasetoken
								},
								body:
									method === "GET"
										? null
										: data === null
										? null
										: JSON.stringify(data)
							})
								.then(function(response) {
									clearTimeout(timeout);
									if (!didTimeOut) {
										resolve(response);
									}
									if (response.status === 401 && url !== "/login") {
										window.location = "/";
										alert("Please sign in again");
									} else if (
										!response.ok &&
										process.env.NODE_ENV !== "development"
									) {
										alert("That API called failed. Please try that again.");
										return { error: true };
									} else {
										return response.json();
									}
								})
								.then(resultFunction)
								.catch(function(ex) {
									console.log("parsing failed", ex);
									resultFunction({ error: true });
									if (didTimeOut) {
										return;
									}
									reject();
								});
						}
					} catch (e) {
						alert(
							'Something went wrong with the API call. Stop what you\'re doing, hit OK, open the console under "Inspect Element," screenshot it, and send it to Max.'
						);
						console.error(e);
					}
				}, 250);
			}, 250);
		}
	})
		.then(function() {
			//do nothing, we already handle a success
		})
		.catch(function(err) {
			// Error: response error, request timeout or runtime error
			if (process.env.NODE_ENV !== "development") {
				alert("That API called failed. Please try that again.");
				resultFunction({ error: true });
				return { error: true };
			}
		});
};

export default API;
