import React, { Component } from "react";
import autoBind from "react-autobind";
import { Row, Col } from "react-bootstrap";
import { observer } from "mobx-react";
import { observable } from "mobx";
import shortid from "shortid";
import CaseContainerV1 from "./CaseContainerV1";
import Sortable from "sortablejs";
import { arrayMove } from "../../../../HelperFunctions";

export default observer(
	class BitCasesPanelV1 extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				panelOpen: true,
				reorderMode: false
			};
		}

		openPanel() {
			this.setState({ panelOpen: !this.state.panelOpen });
		}

		componentWillReceiveProps(nextProps) {
			//if you save something with no cases, add a blank case in
			if (nextProps.pageStore.bitCases.length === 0) {
				nextProps.pageStore.bitCases.push(
					observable({
						id: shortid.generate(),
						order: 0,
						bitID: this.props.match.params.bitID,
						content: "",
						bitCaseRules: [],
						bitCaseRuleGroups: []
					})
				);
			}
		}

		componentDidMount() {
			setTimeout(() => {
				const el = document.getElementById("cases");
				this.sortable = Sortable.create(el, {
					disabled: true,
					onEnd: evt => {
						this.props.pageStore.bitCases = arrayMove(
							this.props.pageStore.bitCases,
							evt.oldIndex,
							evt.newIndex
						).map((att, index) => {
							att.order = index;
							return att;
						});
					}
				});
			}, 250);
		}

		toggleReorderMode() {
			this.setState({ reorderMode: !this.state.reorderMode });

			if (this.sortable.option("disabled")) {
				this.sortable.option("disabled", false);
			} else {
				this.sortable.option("disabled", true);
			}
		}

		copyBit() {
			localStorage.bitToPaste = this.props.match.params.bitID;
		}

		render() {
			return (
				<div id="bitCasesPanel">
					<div>
						<Row>
							<Col className="panelRow">
								<Row onClick={this.openPanel} className="panelCollapsibleRow">
									<Col xs={11}>
										<h2 className="panelName">Bit Cases</h2>
									</Col>
									<Col xs={1} className="text-right">
										{this.state.panelOpen ? (
											<i className="far fa-caret-square-up" />
										) : (
											<i className="far fa-caret-square-down" />
										)}
									</Col>
								</Row>
								{this.state.panelOpen ? (
									<div id="panelContent">
										<span>
											<a onClick={this.toggleReorderMode}>
												{this.state.redorderMode ? "Done" : "Reorder Cases"}
											</a>{" "}
											| <a onClick={this.copyBit}>Copy Bit</a>
										</span>
										<div id="cases">
											{this.props.pageStore.bitCases.map((bitCase, index) => {
												return (
													<CaseContainerV1
														index={index}
														key={bitCase.id}
														bitCase={bitCase}
														save={this.props.save}
														pageStore={this.props.pageStore}
														match={this.props.match}
														reorderMode={this.state.reorderMode}
													/>
												);
											})}
										</div>
									</div>
								) : null}
							</Col>
						</Row>
					</div>
				</div>
			);
		}
	}
);
