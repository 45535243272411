import React from "react";
import { Row, Col } from "react-bootstrap";
import Footer from "../structure/Footer";
import { Link } from "react-router-dom";

const GettingStarted = () => (
	<div id="gettingStarted" className="onboarding">
		<div className="container">
			<Row className="text-center" id="logoRow">
				<img src="/tuzagTCSLogo.svg" alt="tuzag TCS logo" />
			</Row>
			<Row>
				<p className="pageName">3. Getting Started</p>
			</Row>
			<Row id="instructionRow">
				<Col md={10} mdOffset={1}>
					<p>
						Brief welcome copy goes here to help new users understand what the
						hell they should do next. Everyone lands here from the support page
						(no #3 needed, but I'm guessing that's a tailoring criteria thing).
						Most of this page content will be tailored based on the user.
					</p>
				</Col>
			</Row>
			<Row>
				<Col md={4}>
					<p className="text-center pageName">Watch these...</p>
				</Col>
				<Col md={4}>
					<p className="text-center pageName">Read these...</p>
				</Col>
				<Col md={4} className="text-center">
					<p className="text-center pageName">Dive in to these...</p>
					<Link to="/dashboard/dashboard">
						<div id="tuzagDesignDiveBox">
							<img src="/tuzagDesignLogo.svg" alt="tuzag Design logo" />
						</div>
					</Link>
					<div id="tuzagTailorDiveBox">
						<img src="/tuzagTailorLogo.svg" alt="tuzag Tailor logo" />
					</div>
				</Col>
			</Row>
		</div>
		<Footer color="purple" />
	</div>
);

export default GettingStarted;
