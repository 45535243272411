import React, { Component } from "react";
import autoBind from "react-autobind";
import { Form, Text } from "react-form";
import { Row, Col } from "react-bootstrap";
import Snackbar from "../../structure/Snackbar";
import API from "../../../API";
import { toJS } from "mobx";
import globalStore from "../../../GlobalStore";
import { observer } from "mobx-react";
import ReactFormPhone from "../../structure/ReactFormPhone";

export default observer(
	class SendTest extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				snackbarMessage: "",
				sendingEmail: false,
				sendingSMS: false,
				phoneNumber: null
			};
		}

		render() {
			const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
			return (
				<div id="sendTest">
					<h2 className="colName">Preview</h2>
					{globalStore.message &&
					globalStore.message.emailTemplate &&
					globalStore.message.emailVariableType &&
					globalStore.message.emailSubjectElementID ? (
						<Row>
							<Form
								onSubmit={values => {
									this.setState({ sendingEmail: true });
									API(
										`/message/${this.props.match.params.messageID}/email`,
										"POST",
										{
											returnElementNames: true,
											bypassMapping: true,
											elementID: this.props.match.params.elementID,
											...toJS(this.props.pageStore.selectedValues),
											emailAddress: values.emailAddress
										},
										data => {
											if (data.success) {
												this.setState({
													snackbarMessage: "Sent!",
													sendingEmail: false
												});
											} else {
												this.setState({
													snackbarMessage: "ERROR!",
													sendingEmail: false
												});
											}
										},
										false,
										true
									);
								}}
								validate={({ emailAddress }) => {
									return {
										emailAddress:
											!emailAddress || !emailRegex.test(emailAddress)
												? "A valid email is required"
												: undefined
									};
								}}
							>
								{({ submitForm }) => {
									return (
										<form onSubmit={submitForm}>
											<Col xs={2} className="text-right">
												Email:
											</Col>
											<Col xs={9}>
												<Text
													field="emailAddress"
													placeholder="Email Address"
												/>
											</Col>
											<Col xs={1} className="text-center">
												<a
													type="submit"
													onClick={submitForm}
													className="saveButton"
													disabled={this.state.sendingEmail}
												>
													{this.state.sendingEmail ? "Sending..." : "Send"}
												</a>
											</Col>
										</form>
									);
								}}
							</Form>
						</Row>
					) : (
						<h3>Please set up email in the technical tab</h3>
					)}
					{globalStore.message && globalStore.message.smsContentElementID ? (
						<Row>
							<Form
								onSubmit={values => {
									this.setState({ sendingSMS: true });
									API(
										`/message/${this.props.match.params.messageID}/sms`,
										"POST",
										{
											returnElementNames: true,
											bypassMapping: true,
											elementID: this.props.match.params.elementID,
											...toJS(this.props.pageStore.selectedValues),
											//strip ( ) +1 - or space from the phone number
											phoneNumber: values.phoneNumber.replace(
												/\(|\)| |-|\+1/g,
												""
											)
										},
										data => {
											if (data.success) {
												this.setState({
													snackbarMessage: "Sent!",
													sendingSMS: false
												});
											} else {
												this.setState({
													snackbarMessage: "ERROR!",
													sendingSMS: false
												});
											}
										},
										false,
										true
									);
								}}
								validate={({ phoneNumber }) => {
									return {
										phoneNumber:
											!phoneNumber ||
											phoneNumber.replace(/\(|\)| |-|\+1/g, "").length !== 10
												? "A valid phone number is required"
												: undefined
									};
								}}
							>
								{({ submitForm }) => {
									return (
										<form onSubmit={submitForm}>
											<Col xs={2} className="text-right">
												SMS:
											</Col>
											<Col xs={9}>
												<ReactFormPhone field="phoneNumber" />
											</Col>
											<Col xs={1} className="text-center">
												<a
													type="submit"
													onClick={submitForm}
													className="saveButton"
													disabled={this.state.sendingSMS}
												>
													{this.state.sendingSMS ? "Sending..." : "Send"}
												</a>
											</Col>
										</form>
									);
								}}
							</Form>
						</Row>
					) : (
						<h3>Please set up email in the SMS tab</h3>
					)}
					<Snackbar message={this.state.snackbarMessage} />
				</div>
			);
		}
	}
);
