/* eslint no-eval: 0 */
import React, { Component } from "react";
import autoBind from "react-autobind";
import AceEditor from "react-ace";
import "brace/mode/javascript";
import "brace/theme/github";
import { Row, Col } from "react-bootstrap";
import { getMatches } from "../../../../HelperFunctions";
import API from "../../../../API";
import Mousetrap from "mousetrap";
import Snackbar from "../../../structure/Snackbar";
import shortid from "shortid";
import prettier from "prettier/standalone";
import plugins from "prettier/parser-babylon";
import moment from "moment-timezone";

export default class CodeEditor extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			detectedKeys: [],
			saveButtonText: "Save",
			output: null,
			code: "",
			console: []
		};

		this.interval = setInterval(() => {
			if (this.state.code) {
				const regex = /reqData(?:\["history"])?\[["'](.+?)["']]/g;
				const detectedKeys = [...new Set(getMatches(this.state.code, regex))];

				detectedKeys.map(key => {
					if (!this.state[`preview-${key}`]) {
						this.setState({ [`preview-${key}`]: "" });
					}
					return true; //shut up the compiler
				});

				this.setState({
					detectedKeys
				});
			}
		}, 1000);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
		Mousetrap.unbind(["ctrl+s", "command+s"]);
	}

	save() {
		this.setState(
			{
				saveButtonText: "Saving...",
				code: prettier.format(this.state.code, {
					parser: "babylon",
					useTabs: true,
					plugins: [plugins]
				})
			},
			() => {
				this.setState({ saveButtonText: "Saving..." });
				API(
					`/attribute/${this.props.match.params.attributeID}/calculatedCode`,
					"PUT",
					{ code: this.state.code },
					data => {
						this.setState({
							saveButtonText: "Save",
							snackbarMessage: "Saved!",
							lastSaveID: shortid.generate()
						});
					}
				);
			}
		);
	}

	componentDidMount() {
		Mousetrap.bind(["ctrl+s", "command+s"], event => {
			event.preventDefault();
			this.save();
		});

		Mousetrap.prototype.stopCallback = function(e, element, combo) {
			// if the element has the class "mousetrap" then no need to stop
			if ((" " + element.className + " ").indexOf(" mousetrap ") > -1) {
				return false;
			}

			if (element && element.className.includes("ace_text-input")) {
				return false;
			}

			// stop for input, select, and textarea
			return (
				element.tagName === "INPUT" ||
				element.tagName === "SELECT" ||
				element.tagName === "TEXTAREA" ||
				(element.contentEditable && element.contentEditable === "true")
			);
		};

		API(
			`/attribute/${this.props.match.params.attributeID}/calculatedCode`,
			"GET",
			null,
			data => {
				//call onChange instead of setting state directly so it detects the
				//keys in the code
				this.setState({
					code: data.code ? data.code.code : "",
					attributeName: data.attribute.name
				});
			}
		);
	}

	onChange(newValue) {
		this.setState({ output: null, code: newValue });
	}

	run() {
		let reqData = {};
		for (const [key, value] of Object.entries(this.state)) {
			if (key.includes("preview-") && value) {
				reqData[key.substring(8)] = value;
			}
		}

		reqData.userTimezone = moment.tz.guess();

		//this is a beta version of the calculated test. Leaving it enabled for now
		//but we might need to switch back to the other one which still is enabled
		//in the backend but doesn't support the `matchListFromRaw` function
		// API(
		// 	`/calculatedTest/${this.props.match.params.attributeID}`,
		// 	"POST",
		// 	{ reqData },
		// 	data => {
		// 		try {
		// 			this.setState({
		// 				output: Object.values(data.attribute)[0]
		// 			});
		// 		} catch (e) {
		// 			this.setState({
		// 				output: "Something went wrong"
		// 			});
		// 			console.error(e);
		// 		}
		// 	},
		// 	false,
		// 	true
		// );

		API(
			"/calculatedTest",
			"POST",
			{ code: this.state.code, reqData },
			data => {
				this.setState({
					output: data.error ? data.error : data.output,
					console: data.console
				});
			},
			false,
			true
		);
	}

	render() {
		return (
			<div>
				<h2 className="colName">
					{this.state.attributeName ? `${this.state.attributeName}: ` : ""}Code
					Editor
				</h2>
				<Row>
					<Col xs={6}>
						<AceEditor
							mode="javascript"
							theme="github"
							onChange={this.onChange}
							value={this.state.code}
							width="auto"
							tabSize={2}
						/>
						<div
							style={{ cursor: "pointer" }}
							className="text-right saveButton"
							onClick={this.save}
						>
							{this.state.saveButtonText}
						</div>
					</Col>
					<Col xs={6}>
						{this.state.output ? (
							<div
								style={{
									backgroundColor: "white",
									border: "1px solid black",
									width: "100%",
									padding: 10
								}}
							>
								<p>Output</p>
								<code>{this.state.output}</code>
							</div>
						) : null}
						{this.state.console.length > 0 ? (
							<div
								style={{
									backgroundColor: "white",
									border: "1px solid black",
									width: "100%",
									padding: 10
								}}
							>
								<p>Console:</p>
								{this.state.console.map((consoleOutput, index) => {
									return (
										<div key={index}>
											<code>{consoleOutput}</code>
										</div>
									);
								})}
							</div>
						) : null}
						<div className="text-right">
							<a onClick={this.run} className="saveButton">
								Run
							</a>
						</div>
						<form>
							{this.state.detectedKeys.map((key, index) => {
								return (
									<div key={index}>
										<p>{key}:</p>
										<input
											className="FormInput"
											type="text"
											value={this.state[`preview-${key}`]}
											onChange={evt => {
												this.setState({ [`preview-${key}`]: evt.target.value });
											}}
										/>
									</div>
								);
							})}
						</form>
					</Col>
				</Row>
				<Snackbar
					message={this.state.snackbarMessage}
					lastSaveID={this.state.lastSaveID}
				/>
			</div>
		);
	}
}
