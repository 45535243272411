import React, { Component } from "react";
import autoBind from "react-autobind";
import { Switch, Route } from "react-router";
import UsedAttributesContainer from "../../message/usedAttributes/UsedAttributesContainer";
import MessageName from "../../message/MessageHeader";
import API from "../../../API";
import ElementContainer from "../../message/elements/ElementContainer";
import Web from "../../message/technical/Web";
import PreviewContainer from "../../message/testing/Preview";
import { observer } from "mobx-react";
import Email from "../../message/technical/Email";
import SMS from "../../message/technical/SMS";
import Print from "../../message/technical/Print";
import Export from "../../message/testing/Export";
import Chat, { ChatRedirecter } from "../../message/technical/Chat";
import Modal from "react-modal";
import ReactQuill, { Quill } from "react-quill";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import globalStore from "../../../GlobalStore";
import Loading from "../Loading";
import Mention from "../../../quill-mention-master/src/quill.mention.js";
import Retriever from "../../message/technical/Retriever";
import Video from "../../message/technical/Video";

const rootURL = "/message/:messageID";

export default observer(
	class StructureMessage extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = { commentContent: "", comments: null, users: [] };

			this.modules = {
				toolbar: [["bold", "italic", "underline"]],
				mention: {
					source: (searchTerm, renderList, mentionChar) => {
						let values;

						if (mentionChar === "@") {
							values = this.state.users.map(user => {
								return { id: user.id, value: user.displayName };
							});
						}

						if (searchTerm.length === 0) {
							renderList(values, searchTerm);
						} else {
							const matches = [];
							for (let i = 0; i < values.length; i++)
								if (
									~values[i].value
										.toLowerCase()
										.indexOf(searchTerm.toLowerCase())
								)
									matches.push(values[i]);
							renderList(matches, searchTerm);
						}
					}
				}
			};

			Quill.register("mention", Mention, true);
		}

		componentDidMount() {
			API(
				`/messageOpenHistory/${this.props.match.params.messageID}`,
				"PUT",
				{},
				data => {}
			);

			API(`/users`, "GET", {}, data => {
				this.setState({ users: data.users });
			});
		}

		componentWillReceiveProps(nextProps) {
			API(
				`/messageOpenHistory/${nextProps.match.params.messageID}`,
				"PUT",
				{},
				data => {}
			);
		}

		postComment() {
			if (globalStore.commentsElementID) {
				API(
					`/elements/${globalStore.commentsElementID}/comments`,
					"PUT",
					{ content: this.state.commentContent },
					data => {
						this.setState({
							comments: data.comments,
							commentContent: ""
						});
					}
				);
			} else if (globalStore.commentsMessageID) {
				API(
					`/messages/${globalStore.commentsMessageID}/comments`,
					"PUT",
					{ content: this.state.commentContent },
					data => {
						this.setState({
							comments: data.comments,
							commentContent: ""
						});
					}
				);
			} else if (globalStore.commentsBitCaseID) {
				API(
					`/bitCases/${globalStore.commentsBitCaseID}/comments`,
					"PUT",
					{ content: this.state.commentContent },
					data => {
						this.setState({
							comments: data.comments,
							commentContent: ""
						});
					}
				);
			}
		}

		createMarkup(content) {
			return { __html: content };
		}

		render() {
			return (
				<div id="messageContainer">
					<MessageName match={this.props.match} />
					<Switch>
						<Route
							exact
							path={`${rootURL}/attributes`}
							component={UsedAttributesContainer}
						/>
						<Route
							exact
							path={`${rootURL}/elements/:elementID/bit/:bitID`}
							component={ElementContainer}
							showBits={true}
						/>
						<Route
							exact
							path={`${rootURL}/elements/:elementID`}
							component={ElementContainer}
							showBits={false}
						/>
						<Route path={`${rootURL}/technical/web`} component={Web} />
						<Route path={`${rootURL}/technical/email`} component={Email} />
						<Route path={`${rootURL}/technical/sms`} component={SMS} />
						<Route
							path={`${rootURL}/technical/retriever`}
							component={Retriever}
						/>

						<Route
							path={`${rootURL}/technical/chat/:attributeID`}
							component={Chat}
						/>
						<Route
							path={`${rootURL}/technical/chat`}
							component={ChatRedirecter}
						/>
						<Route path={`${rootURL}/technical/print`} component={Print} />
						<Route path={`${rootURL}/technical/graphic`} component={Video} />

						<Route
							path={`${rootURL}/testing/quickPreview/element/:elementID`}
							component={PreviewContainer}
						/>
						<Route
							path={`${rootURL}/testing/quickPreview`}
							component={PreviewContainer}
						/>
						<Route
							path={`${rootURL}/testing/previewInContext`}
							component={PreviewContainer}
						/>
						<Route
							path={`${rootURL}/testing/sendTest`}
							component={PreviewContainer}
						/>
						<Route path={`${rootURL}/testing/export`} component={Export} />
						<Route
							path={`${rootURL}/testing/video`}
							component={PreviewContainer}
						/>
						<Route
							path={`${rootURL}/testing/graphic`}
							component={PreviewContainer}
						/>
					</Switch>
					<Modal
						isOpen={globalStore.commentsModalOpen}
						onRequestClose={() => {
							globalStore.commentsModalOpen = false;
							globalStore.commentsElementID = null;
							globalStore.commentsBitCaseID = null;
							globalStore.commentsMessageID = null;

							this.setState({
								comments: [],
								commentContent: ""
							});
						}}
						style={{
							content: {
								top: "50%",
								left: "50%",
								width: 500,
								height: 600,
								overflowY: "auto",
								right: "auto",
								bottom: "auto",
								marginRight: "-50%",
								transform: "translate(-50%, -50%)"
							}
						}}
						onAfterOpen={() => {
							if (globalStore.commentsElementID) {
								API(
									`/elements/${globalStore.commentsElementID}/comments?markAsRead=true`,
									"GET",
									{},
									data => {
										this.setState({
											comments: data.comments
										});
									}
								);
							}

							if (globalStore.commentsMessageID) {
								API(
									`/messages/${globalStore.commentsMessageID}/comments?markAsRead=true`,
									"GET",
									{},
									data => {
										this.setState({
											comments: data.comments
										});
									}
								);
							}

							if (globalStore.commentsBitCaseID) {
								API(
									`/bitCases/${globalStore.commentsBitCaseID}/comments?markAsRead=true`,
									"GET",
									{},
									data => {
										this.setState({
											comments: data.comments
										});
									}
								);
							}
						}}
					>
						<h3 style={{ marginTop: 0 }}>Comments</h3>
						<ReactQuill
							value={this.state.commentContent || ""}
							onChange={value => {
								this.setState({ commentContent: value });
							}}
							theme={"snow"}
							placeholder="enter your comment here"
							rows={3}
							modules={this.modules}
						/>
						<Row>
							<Col xs={12} className="text-right">
								<a onClick={this.postComment} className="saveButton">
									Post Comment
								</a>
							</Col>
						</Row>
						<div
							style={{ height: 400, overflowY: "auto", overflowX: "hidden" }}
						>
							{this.state.comments === null ? (
								<Loading />
							) : this.state.comments.length === 0 ? (
								"No comments yet"
							) : (
								this.state.comments.map(comment => {
									return (
										<div
											key={comment.id}
											style={{
												border: "1px solid black",
												marginTop: 10,
												marginBottom: 10,
												padding: 10
											}}
										>
											<Row style={{ borderBottom: "1px solid gray" }}>
												<Col xs={6}>
													<p style={{ marginBottom: 0 }}>
														{comment.user.displayName
															? comment.user.displayName
															: "Unknown"}
													</p>
												</Col>
												<Col xs={6} className="text-right">
													<p style={{ marginBottom: 0, color: "darkgray" }}>
														{moment(comment.createdAt).format("h:mm a, M/D/YY")}
													</p>
												</Col>
											</Row>
											<Row style={{ marginTop: 10 }}>
												<Col xs={12}>
													<div
														className="commentContent"
														dangerouslySetInnerHTML={this.createMarkup(
															comment.content
														)}
													/>
												</Col>
											</Row>
										</div>
									);
								})
							)}
						</div>
					</Modal>
				</div>
			);
		}
	}
);
