import React, { Component } from "react";
import autoBind from "react-autobind";
import "react-quill/dist/quill.snow.css";
import { observable, toJS } from "mobx";
import { observer } from "mobx-react";
import API from "../../../API";
import ElementContent from "./ElementContentPanel";
import Notes from "./NotesPanel";
import Mousetrap from "mousetrap";
import Snackbar from "../../structure/Snackbar";
import BitCasesPanelV1 from "./v1/BitCasesPanelV1";
import BitCasesPanelV2 from "./v2/BitCasesPanelV2";
import shortid from "shortid";
import globalStore from "../../../GlobalStore";
import Loading from "../../structure/Loading";
import { Container } from "react-bootstrap";
//import { getMatches } from "../../../HelperFunctions";

let pageStore = observable({
	element: null,
	bitCases: null,
	availableAttributes: null
});

export default observer(
	class ElementContainer extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				snackbarMessage: "",
				lastSaveID: ""
			};
		}

		componentDidMount() {
			API(
				`/message/${this.props.match.params.messageID}/elements/${
					this.props.match.params.elementID
				}`,
				"GET",
				{},
				data => {
					pageStore.element = data.element;
				}
			);

			Mousetrap.bind(["ctrl+s", "command+s"], event => {
				event.preventDefault();
				this.save();
			});

			Mousetrap.bind(
				["ctrl+up", "command+up"],
				function(event) {
					event.preventDefault();
					const currentElementIndex = globalStore.elements.findIndex(
						element => {
							return pageStore.element.id === element.id;
						}
					);
					const nextElement = globalStore.elements[currentElementIndex - 1];
					if (nextElement) {
						const nextElementID = nextElement.id;
						globalStore.history.push(
							`/message/${
								this.props.match.params.messageID
							}/elements/${nextElementID}`
						);
					}
				}.bind(this)
			);

			Mousetrap.bind(
				["ctrl+down", "command+down"],
				function(event) {
					event.preventDefault();
					const currentElementIndex = globalStore.elements.findIndex(
						element => {
							return pageStore.element.id === element.id;
						}
					);
					const nextElement = globalStore.elements[currentElementIndex + 1];
					if (nextElement) {
						const nextElementID = nextElement.id;
						globalStore.history.push(
							`/message/${
								this.props.match.params.messageID
							}/elements/${nextElementID}`
						);
					}
				}.bind(this)
			);

			Mousetrap.prototype.stopCallback = function(e, element, combo) {
				// if the element has the class "mousetrap" then no need to stop
				if ((" " + element.className + " ").indexOf(" mousetrap ") > -1) {
					return false;
				}

				if (element.id && element.id.includes("react-select")) {
					return false;
				}

				// stop for input, select, and textarea
				return (
					element.tagName === "INPUT" ||
					element.tagName === "SELECT" ||
					element.tagName === "TEXTAREA" ||
					(element.contentEditable && element.contentEditable === "true")
				);
			};

			this.loadBitCases();

			API(
				`/message/${
					this.props.match.params.messageID
				}/usedAttributesAndOptions`,
				"GET",
				{},
				data => {
					pageStore.availableAttributes = data.usedAttributes;
				}
			);
		}

		loadBitCases(props = this.props) {
			if (props.match.params.bitID) {
				const interval = setInterval(() => {
					if (globalStore.message) {
						clearInterval(interval);
						if (globalStore.message.dataModelVersion === "1") {
							API(
								`/bits/${props.match.params.bitID}/bitCases`,
								"GET",
								{},
								data => {
									if (data.bitCases.length === 0) {
										pageStore.bitCases = observable.array([
											observable.object({
												bitID: props.match.params.bitID,
												content: "",
												id: shortid.generate(),
												order: 0,
												bitCaseRules: [],
												bitCaseRuleGroups: []
											})
										]);
									} else {
										pageStore.bitCases = data.bitCases;
									}
								}
							);
						} else {
							API(
								`/bits/${props.match.params.bitID}/bitCasesV2`,
								"GET",
								{},
								data => {
									if (data.bitCases.length === 0) {
										pageStore.bitCases = observable.array([
											observable.object({
												bitID: props.match.params.bitID,
												content: "",
												id: shortid.generate(),
												order: 0,
												parentRuleGroup: {
													id: shortid.generate(),
													bitCaseRules: observable.array([]),
													chainedOperator: "The End"
												}
											})
										]);
									} else {
										pageStore.bitCases = data.bitCases;
									}
								}
							);
						}
					}
				}, 250);
			}
		}

		componentWillUnmount() {
			Mousetrap.unbind(["ctrl+s", "command+s"]);
			pageStore.element = null;
			pageStore.bitCases = null;
		}

		componentWillReceiveProps(nextProps) {
			if (
				this.props.match.params.elementID !== nextProps.match.params.elementID
			) {
				pageStore.element = null;
				pageStore.bitCases = null;
				API(
					`/message/${nextProps.match.params.messageID}/elements/${
						nextProps.match.params.elementID
					}`,
					"GET",
					{},
					data => {
						pageStore.element = data.element;
					}
				);

				API(
					`/message/${
						this.props.match.params.messageID
					}/usedAttributesAndOptions`,
					"GET",
					{},
					data => {
						pageStore.availableAttributes = data.usedAttributes;
					}
				);
			}

			if (!nextProps.match.params.bitID) {
				pageStore.bitCases = null;
			} else if (
				this.props.match.params.bitID !== nextProps.match.params.bitID
			) {
				this.loadBitCases(nextProps);
			}
		}

		save(saveCases = true) {
			if (!this.state.saving) {
				this.setState({ saving: true });

				// remove chat splits from bits
				//DISABLED UNTIL WE CONFIRM THESE SAME FIXES IN TAILOR DON'T BREAK SHIT
				//for some reason the bits have their bit tags duplicated so the format ends up being something like
				//<span BIT><span SPLIT/></span BIT><span REALBITCONTENT/>
				//we fix that to: <span SPLIT/> <span REALBITCONTENT />
				// pageStore.element.content = pageStore.element.content.replace(
				// 	/<span class="quill-chatMessageSplit" contenteditable="false"><span class="quill-bit" data-bitid="\d+" contenteditable="false">\(\[Chat Message Split]\)<\/span><\/span>/g,
				// 	'<span class="quill-chatMessageSplit" contenteditable="false">([Chat Message Split])</span>'
				// );
				//remove chat splits from links
				// this.removeSplitFromLinks(pageStore.element);

				pageStore.element.content = pageStore.element.content.replace(
					/background-color: rgb\(255, 255, 255\)/g,
					""
				);

				if (globalStore.message.dataModelVersion === "1") {
					API(
						`/message/${this.props.match.params.messageID}/element/${
							this.props.match.params.elementID
						}`,
						"PUT",
						pageStore.element,
						data => {
							if (data.error) {
								this.setState({ saving: false });
							} else {
								pageStore.element = data.element;
								if (pageStore.bitCases && saveCases) {
									API(
										`/bits/${this.props.match.params.bitID}/bitCases`,
										"PUT",
										{ bitCases: toJS(pageStore.bitCases) },
										data => {
											pageStore.bitCases = data.bitCases;

											this.setState({
												saving: false,
												snackbarMessage: "Saved!",
												lastSaveID: shortid.generate()
											});
										}
									);
								} else {
									this.setState({
										saving: false,
										snackbarMessage: "Saved!",
										lastSaveID: shortid.generate()
									});
								}
							}
						}
					);
				} else {
					API(
						`/message/${this.props.match.params.messageID}/element/${
							this.props.match.params.elementID
						}`,
						"PUT",
						{ ...pageStore.element, skipName: true },
						data => {
							if (data.error) {
								this.setState({ saving: false });
							} else {
								pageStore.element = data.element;
								if (pageStore.bitCases && saveCases) {
									//DISABLED UNTIL WE CONFIRM THESE SAME FIXES IN TAILOR DON'T BREAK SHIT
									// for (const bitCase of pageStore.bitCases) {
									// this.removeSplitFromLinks(bitCase);
									// }

									const bitCasesToSave = toJS(pageStore.bitCases).map(
										bitCase => {
											bitCase.content = bitCase.content.replace(
												/background-color: rgb\(255, 255, 255\)/g,
												""
											);

											return bitCase;
										}
									);

									API(
										`/bits/${this.props.match.params.bitID}/bitCasesV2`,
										"PUT",
										{ bitCases: bitCasesToSave },
										data => {
											if (data.error) {
												this.setState({ saving: false });
											} else {
												pageStore.bitCases = data.bitCases;

												this.setState({
													saving: false,
													snackbarMessage: "Saved!",
													lastSaveID: shortid.generate()
												});
											}
										}
									);
								} else {
									this.setState({
										saving: false,
										snackbarMessage: "Saved!",
										lastSaveID: shortid.generate()
									});
								}
							}
						}
					);
				}
			}
		}

		//DISABLED UNTIL WE CONFIRM THESE SAME FIXES IN TAILOR DON'T BREAK SHIT

		// removeSplitFromLinks(bitCase) {
		// 	//quill blots like to put other blots inside of them, but tailor needs it
		// 	// on the outside (ie blot next to blot, not blot in blot), so we run some
		// 	//fancy regexes to move the nested blot to neighbor blots
		//
		// 	//find element links that have a split in them before the content
		// 	const elementLinkWithLeftSplitInIt = bitCase.content.match(
		// 		/<span class="quill-elementLink" data-elementid="\d*" contenteditable="false">(<span class="quill-chatMessageSplit" contenteditable="false">\(\[Chat Message Split]\)<\/span>).*?<\/span>/g
		// 	);
		//
		// 	//if you find them...
		// 	if (
		// 		elementLinkWithLeftSplitInIt &&
		// 		elementLinkWithLeftSplitInIt.length > 0
		// 	) {
		// 		//run a similar regex, but just get the content of the of the split blot
		// 		const chatMessageSplitBlotInArray = getMatches(
		// 			bitCase.content,
		// 			/<span.*?>(<span class="quill-chatMessageSplit" contenteditable="false">\(\[Chat Message Split]\)<\/span>).*?<\/span>/g
		// 		);
		//
		// 		//replace just the element link with the split in it (not the entire bitcase content)
		// 		//with the chat message split blot/span PLUS (the element link MINUS the split blot/span)
		// 		bitCase.content = bitCase.content.replace(
		// 			elementLinkWithLeftSplitInIt[0],
		// 			chatMessageSplitBlotInArray[0] +
		// 				elementLinkWithLeftSplitInIt[0].replace(
		// 					chatMessageSplitBlotInArray[0],
		// 					""
		// 				)
		// 		);
		// 	}
		//
		// 	//repeat again if the chat message split is on the right side of the element link except put the split on the right of the link instead of the right
		// 	const elementLinkWithRightSplitInIt = bitCase.content.match(
		// 		/<span class="quill-elementLink" data-elementid="\d*" contenteditable="false">.*?(<span class="quill-chatMessageSplit" contenteditable="false">\(\[Chat Message Split]\)<\/span>)<\/span>/g
		// 	);
		//
		// 	if (
		// 		elementLinkWithRightSplitInIt &&
		// 		elementLinkWithRightSplitInIt.length > 0
		// 	) {
		// 		const chatMessageSplitBlotInArray = getMatches(
		// 			bitCase.content,
		// 			/<span.*?>(<span class="quill-chatMessageSplit" contenteditable="false">\(\[Chat Message Split]\)<\/span>).*?<\/span>/g
		// 		);
		//
		// 		bitCase.content = bitCase.content.replace(
		// 			elementLinkWithRightSplitInIt[0],
		// 			elementLinkWithRightSplitInIt[0].replace(
		// 				chatMessageSplitBlotInArray[0],
		// 				""
		// 			) + chatMessageSplitBlotInArray[0]
		// 		);
		// 	}
		// }

		modifyPageStore(newElement) {
			pageStore.element = newElement;
		}

		render() {
			let bitCasesPanel;

			if (
				"bitID" in this.props.match.params &&
				pageStore.bitCases &&
				pageStore.availableAttributes
			) {
				if (globalStore.message.dataModelVersion === "1") {
					bitCasesPanel = (
						<BitCasesPanelV1
							match={this.props.match}
							save={this.save}
							pageStore={pageStore}
						/>
					);
				} else if (globalStore.message.dataModelVersion === "2") {
					bitCasesPanel = (
						<BitCasesPanelV2
							match={this.props.match}
							save={this.save}
							pageStore={pageStore}
						/>
					);
				}
			}
			return (
				<Container fluid id="elementContainer">
					{pageStore.element ? (
						<div>
							{globalStore.userType === "Read Only" ||
							(globalStore.message &&
								globalStore.message.status === "Published") ||
							globalStore.userType === "Developer" ||
							globalStore.userType === "Reviewer" ? null : (
								<div className="text-right">
									<a
										disabled={this.state.saving}
										onClick={this.save}
										className={`saveButton ${
											this.state.saving ? "saveButtonDisabled" : ""
										}`}
									>
										{this.state.saving ? "Saving..." : "Save"}
									</a>
								</div>
							)}
							<Notes
								pageStore={pageStore}
								match={this.props.match}
								modifyPageStore={this.modifyPageStore}
								save={this.save}
							/>
							<ElementContent
								pageStore={pageStore}
								match={this.props.match}
								modifyPageStore={this.modifyPageStore}
								save={this.save}
							/>
							{bitCasesPanel}
							{globalStore.userType === "Read Only" ||
							(globalStore.message &&
								globalStore.message.status === "Published") ||
							globalStore.userType === "Developer" ||
							globalStore.userType === "Reviewer" ? null : (
								<div className="text-right">
									<a
										disabled={this.state.saving}
										onClick={this.save}
										className="saveButton"
									>
										{this.state.saving ? "Saving..." : "Save"}
									</a>
								</div>
							)}
							<Snackbar
								message={this.state.snackbarMessage}
								lastSaveID={this.state.lastSaveID}
							/>
						</div>
					) : (
						<Loading />
					)}
				</Container>
			);
		}
	}
);
