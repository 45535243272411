import React, { Component } from "react";
import autoBind from "react-autobind";
import { observer } from "mobx-react";
import Loading from "../../Loading";
import API from "../../../../API";
import { Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import shortID from "shortid";
import { Form, Text } from "react-form";
import globalStore from "../../../../GlobalStore";
import Sortable from "sortablejs";
import { arrayMove } from "../../../../HelperFunctions";
import bowser from "bowser";
import shortid from "shortid";
import Snackbar from "../../Snackbar";
import Modal from "react-modal";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";
import { observable } from "mobx";

export const pageStore = new observable({
	mapAttributesModalOpen: false,
	attributesToMap: []
});

export const Elements = observer(
	class Elements extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {};
		}

		async paste(evt) {
			if (evt.shiftKey) {
				try {
					const dataFromClipboard = JSON.parse(
						await navigator.clipboard.readText()
					);

					pageStore.mapAttributesModalOpen = true;
					pageStore.attributesToMap = dataFromClipboard.messageAtts.messageUsedAttributes.map(
						usedAttribute => {
							return usedAttribute.attribute;
						}
					);
				} catch (e) {
					alert("Error reading data from clipboard. Try copying again.");
				}
			} else {
				if (!localStorage.elementIDToCopy) {
					alert("Nothing to paste!");
				} else {
					API(
						`/elements/${localStorage.elementIDToCopy}/duplicate`,
						"POST",
						{ messageID: this.props.messageID, duplicateAttributes: true },
						data => {
							globalStore.elements = [...globalStore.elements, data.element];
						}
					);
				}
			}
		}

		new() {
			globalStore.elements.push({
				id: shortID.generate(),
				order: globalStore.elements.length
			});
		}

		componentDidMount() {
			//this is disabled because drag and drop was causing issues with the apple
			//pencil on the iPad. Once we get order figured out, we'll make this work
			//with the iPad.
			if (!bowser.ios) {
				setTimeout(() => {
					const el = document.getElementById("elementsList");
					Sortable.create(el, {
						onEnd: evt => {
							globalStore.elements = arrayMove(
								globalStore.elements,
								evt.oldIndex,
								evt.newIndex
							).map((att, index) => {
								att.order = index;
								return att;
							});

							API("/elements/order", "PUT", globalStore.elements, data => {
								// do nothing
							});
						}
					});
				}, 250);
			}
		}

		render() {
			return (
				<div id="elementsSidebar">
					{globalStore.elements ? (
						<div>
							{globalStore.elements.length === 0 ? (
								<h2>No Elements (Yet)</h2>
							) : (
								<div
									id="elementsList"
									style={{ maxHeight: 350, overflowY: "auto" }}
								>
									{globalStore.elements.map(element => {
										return (
											<ElementRow
												element={element}
												key={element.id}
												generateURL={this.props.generateURL}
												messageID={this.props.messageID}
												location={this.props.location}
											/>
										);
									})}
								</div>
							)}
							{globalStore.userType === "Read Only" ||
							(globalStore.message &&
								globalStore.message.status === "Published") ||
							globalStore.userType === "Developer" ||
							globalStore.userType === "Reviewer" ? null : (
								<Row id="buttonRow">
									<Col xs={6}>
										<a onClick={this.paste}>Paste</a>
									</Col>
									<Col xs={6} className="text-right">
										<a onClick={this.new}>New</a>
									</Col>
								</Row>
							)}
						</div>
					) : (
						<Loading />
					)}
				</div>
			);
		}
	}
);

export const ElementRow = observer(
	class Elements extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				editMode: false,
				lastSaveID: "",
				snackbarMessage: "",
				attributeMapping: {},
				options: [],
				pasteText: "Paste!"
			};
		}

		componentWillMount() {
			if (shortID.isValid(this.props.element.id)) {
				this.setState({ editMode: "new" });
			}
		}

		editMode() {
			this.setState({ editMode: "edit" });
		}

		delete() {
			if (
				window.confirm(
					"Are you sure you want to delete this element? " +
						"It will delete all the content and rules inside of it."
				)
			) {
				API(`/elementDelete/${this.props.element.id}`, "GET", {}, data => {
					if (data.safeToDelete) {
						API(`/elements/${this.props.element.id}`, "DELETE", {}, data => {
							globalStore.elements = data.elements;
							globalStore.history.push(`/message/${this.props.messageID}/`);
						});
					} else {
						if (
							window.confirm(
								`Just a heads up, this element was linked in "${
									data.messageName
								}" (MID ${data.messageID})`
							)
						) {
							API(`/elements/${this.props.element.id}`, "DELETE", {}, data => {
								globalStore.elements = data.elements;
								globalStore.history.push(`/message/${this.props.messageID}/`);
							});
						}
					}
				});
			}
		}

		duplicate() {
			API(`/elements/${this.props.element.id}/duplicate`, "POST", {}, data => {
				globalStore.elements.push(data.element);
			});
		}

		copyToClipboard = str => {
			const el = document.createElement("textarea");
			el.value = str;
			document.body.appendChild(el);
			el.select();
			document.execCommand("copy");
			document.body.removeChild(el);
		};

		copy(e) {
			if (e.shiftKey) {
				API(
					`/exportElementForCopying/${this.props.element.id}`,
					"GET",
					{},
					data => {
						console.log(data);
						this.copyToClipboard(JSON.stringify(data));
						this.setState({
							snackbarMessage: "Copied!",
							lastSaveID: shortid.generate()
						});
					}
				);
			} else {
				localStorage.elementIDToCopy = this.props.element.id;
			}
		}

		generateOptions() {
			API(`/availableAttributesAndOptions`, "GET", {}, data => {
				this.setState(
					{
						options: data.availableAttributes.map(group => {
							return {
								label: group.name,
								groupID: group.id,
								options: group.attributes.reduce((result, attribute) => {
									if (!attribute.passthrough && attribute.options) {
										//filter out direct attributes that don't have options
										let defaultFound = false;

										//also filter out attributes that don't have a default option
										attribute.options.map(option => {
											if (option.defaultOption === true) {
												defaultFound = true;
											}
											return null; //shut up a compiler warning and return anything
										});

										if (defaultFound) {
											result.push({
												label: attribute.name,
												value: attribute.attributeID
													? attribute.attributeID
													: attribute.id
											});
										} else {
											result.push({
												label: attribute.name,
												value: attribute.attributeID
													? attribute.attributeID
													: attribute.id,
												isDisabled: true
											});
										}
									} else {
										//add all other kinds of attribute types
										result.push({
											label: attribute.name,
											value: attribute.attributeID
												? attribute.attributeID
												: attribute.id
										});
									}
									return result;
								}, [])
							};
						})
					},
					() => {
						let autoMatches = {};
						for (const attribute of pageStore.attributesToMap) {
							API(
								"/attributeSearchForCopyAndPaste",
								"POST",
								{
									searchTerm: attribute.name
								},
								data => {
									if (data.attribute) {
										autoMatches[attribute.id] = {
											label: data.attribute.name,
											value: data.attribute.id
										};
									}
									this.setState({ attributeMapping: autoMatches });
								}
							);
						}
					}
				);
			});
		}

		filterAttributes = debounce((inputValue, callback) => {
			API(
				"/attributeSearch",
				"POST",
				{
					searchTerm: inputValue,
					usedAttributes: []
				},
				data => {
					callback(data.results);
				}
			);
		}, 300);

		executePastePostMatching = async () => {
			try {
				this.setState({ pasteText: "Pasting..." });
				const clipboardData = JSON.parse(await navigator.clipboard.readText());
				API(
					`/pasteElement/${this.props.messageID}`,
					"POST",
					{
						attributeMapping: this.state.attributeMapping,
						element: clipboardData.element,
						messageAtts: clipboardData.messageAtts
					},
					data => {
						this.setState({ pasteText: "Paste!" });
						if (data.error) {
							alert(
								"Options for an attribute don't match the other env. Please check the rules."
							);
						}
						window.location.reload(false);
					}
				);
			} catch (e) {
				alert(
					"Clipboard data mismatch. Please close the modal, copy the source data again, then retry the paste."
				);
			}
		};

		render() {
			return (
				<div className="elementsSidebarRow">
					{this.state.editMode === "new" || this.state.editMode === "edit" ? (
						<div>
							<Form
								onSubmit={values => {
									if (this.state.editMode === "new") {
										API(
											`/message/${this.props.messageID}/elements`,
											"POST",
											{
												name: values.name,
												id: this.props.element.id,
												notes: this.state.editMode === "new" ? "" : null,
												content: this.state.editMode === "new" ? "" : null,
												order: globalStore.elements.length - 1
											},
											data => {
												globalStore.elements = data.elements;
												const lastElement =
													data.elements[data.elements.length - 1];
												globalStore.history.push(
													`/message/${lastElement.messageID}/elements/${
														lastElement.id
													}`
												);
											}
										);
									} else {
										API(
											`/message/${this.props.messageID}/elements/name`,
											"PUT",
											{
												name: values.name,
												id: this.props.element.id
											},
											data => {
												globalStore.elements = data.elements;
												this.setState({ editMode: false });
											}
										);
									}
								}}
								values={{
									name:
										this.state.editMode === "edit"
											? this.props.element.name
											: ""
								}}
								validate={({ name }) => {
									return {
										name: !name ? "An element name is required" : undefined
									};
								}}
							>
								{({ submitForm }) => {
									return (
										<form onSubmit={submitForm}>
											<Text field="name" placeholder="element name goes here" />
											<a
												onClick={submitForm}
												className="saveButton text-right"
												type="submit"
											>
												Save
											</a>
										</form>
									);
								}}
							</Form>
						</div>
					) : (
						<div>
							<h3>
								<NavLink
									to={this.props.generateURL(
										`elements/${this.props.element.id}`
									)}
									isActive={(match, location) => {
										return (
											location.pathname.indexOf(
												`/elements/${this.props.element.id}`
											) !== -1
										);
									}}
								>
									{this.props.element.name}
								</NavLink>
							</h3>
							{globalStore.userType === "Read Only" ||
							(globalStore.message &&
								globalStore.message.status === "Published") ||
							globalStore.userType === "Developer" ||
							globalStore.userType === "Reviewer" ? null : (
								<h4>
									<a onClick={this.editMode}>edit</a> | &nbsp;
									<a onClick={this.copy}>copy</a> | &nbsp;
									<a onClick={this.duplicate}>duplicate</a> | &nbsp;
									<a onClick={this.delete} className="removeLink">
										remove
									</a>
								</h4>
							)}
						</div>
					)}
					<Snackbar
						message={this.state.snackbarMessage}
						lastSaveID={this.state.lastSaveID}
					/>
					<Modal
						isOpen={pageStore.mapAttributesModalOpen}
						onRequestClose={() => {
							this.setState({ attributeMapping: {} });
							pageStore.mapAttributesModalOpen = false;
						}}
						style={{
							content: {
								top: "50%",
								left: "50%",
								width: 500,
								height: 500,
								overflowY: "auto",
								right: "auto",
								bottom: "auto",
								marginRight: "-50%",
								transform: "translate(-50%, -50%)"
							}
						}}
						onAfterOpen={() => {
							this.generateOptions();
						}}
					>
						<h2 style={{ marginTop: 0 }}>Map Attributes</h2>
						{pageStore.attributesToMap.map(attribute => {
							return (
								<Row
									key={attribute.id}
									style={{ marginTop: 5, marginBottom: 5 }}
								>
									<Col xs={6}>{attribute.name}</Col>
									<Col xs={6}>
										<AsyncSelect
											value={this.state.attributeMapping[attribute.id]}
											loadOptions={(inputValue, callback) => {
												if (inputValue === "") {
													const interval = setInterval(() => {
														if (this.state.options.length > 0) {
															clearInterval(interval);
															callback(this.state.options);
														}
													}, 50);
												} else {
													this.filterAttributes(inputValue, callback);
												}
											}}
											clearable={false}
											onChange={val => {
												let attributeMapping = this.state.attributeMapping;
												attributeMapping[attribute.id] = val;
												this.setState({ attributeMapping });
											}}
											defaultOptions
										/>
									</Col>
								</Row>
							);
						})}
						<div className="text-right">
							<div
								className="saveButton"
								onClick={this.executePastePostMatching}
								style={{ cursor: "pointer" }}
								disabled={this.state.pasteText === "Pasting..."}
							>
								{this.state.pasteText}
							</div>
						</div>
					</Modal>
				</div>
			);
		}
	}
);

// export default Elements;
