import React, { Component } from "react";
import autoBind from "react-autobind";
import { observer } from "mobx-react";
import { observable, toJS } from "mobx";
import ReactQuill, { Quill } from "react-quill";
import { Row, Col } from "react-bootstrap";
import RuleContainerV1 from "./RuleContainerV1";
import shortid from "shortid";
import classNames from "classnames";
import MessageBrowser from "../../../messageBrowser/MessageBrowser";
import globalStore from "../../../../GlobalStore";
import API from "../../../../API";
import CartesianProduct from "cartesian-product";

export default observer(
	class CaseContainerV1 extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				showLinkElementDropdown: false,
				showLinkElement: false,
				brokenLink: false,
				loadingBrokenLinkChecker: false
			};

			this.modules = {
				keyboard: {
					bindings: {
						custom: {
							key: "S",
							shortKey: true,
							handler: function() {
								props.save();
							}
						},
						cmdUp: {
							key: "UP",
							shortKey: true,
							handler: function() {
								const currentElementIndex = globalStore.elements.findIndex(
									element => {
										return this.props.pageStore.element.id === element.id;
									}
								);
								const nextElement =
									globalStore.elements[currentElementIndex - 1];
								if (nextElement) {
									const nextElementID = nextElement.id;
									globalStore.history.push(
										`/message/${
											nextElement.messageID
										}/elements/${nextElementID}`
									);
								}
							}.bind(this)
						},
						cmdDown: {
							key: "DOWN",
							shortKey: true,
							handler: function() {
								const currentElementIndex = globalStore.elements.findIndex(
									element => {
										return this.props.pageStore.element.id === element.id;
									}
								);
								const nextElement =
									globalStore.elements[currentElementIndex + 1];
								if (nextElement) {
									const nextElementID = nextElement.id;
									globalStore.history.push(
										`/message/${
											nextElement.messageID
										}/elements/${nextElementID}`
									);
								}
							}.bind(this)
						},
						cmdL: {
							key: "L",
							shortKey: true,
							handler: function() {
								this.setState({ showLinkElementDropdown: true });
							}.bind(this)
						},
						quickFind: {
							key: "K",
							shortKey: true,
							handler: function() {
								globalStore.messageBrowserPanelOpen = !globalStore.messageBrowserPanelOpen;
							}
						},
						escKey: {
							key: "escape",
							handler: () => {
								this.setState({ showLinkElementDropdown: false });
							}
						}
					}
				},
				toolbar: {
					container: ".quillToolbar-" + props.bitCase.id,
					handlers: {
						elementLink: function() {
							this.setState({
								showLinkElementDropdown: !this.state.showLinkElementDropdown
							});
						}.bind(this)
					}
				}
			};
		}

		handleChange(value) {
			this.props.bitCase.content = value;
		}

		addCase() {
			this.props.pageStore.bitCases.push(
				observable({
					id: shortid.generate(),
					order: this.props.pageStore.bitCases.length,
					bitID: this.props.match.params.bitID,
					content: "",
					bitCaseRules: [],
					bitCaseRuleGroups: [],
					defaultCase: false,
					alwaysTrue: false
				})
			);
		}

		removeCase() {
			this.props.pageStore.bitCases.splice(this.props.index, 1);

			//there should always be an empty case
			if (this.props.pageStore.bitCases.length === 0) {
				this.props.pageStore.bitCases.push(
					observable({
						id: shortid.generate(),
						order: 0,
						bitID: this.props.match.params.bitID,
						content: "",
						bitCaseRules: [],
						bitCaseRuleGroups: []
					})
				);
			} else {
				this.props.pageStore.bitCases = this.props.pageStore.bitCases.map(
					(bitcase, index) => {
						bitcase.order = index;
						return bitcase;
					}
				);
			}
		}

		toggleDefaultCase() {
			this.props.pageStore.bitCases.map(bitCase => {
				bitCase.defaultCase = false;
				return true; //silence warning
			});
			this.props.bitCase.defaultCase = !this.props.bitCase.defaultCase;
		}

		toggleAlwaysTrue() {
			this.props.bitCase.alwaysTrue = !this.props.bitCase.alwaysTrue;
		}

		onChangeSelection(range) {
			this.setState({
				showAddBit: range && !!range.length,
				showLinkElement: range && range.length === 0
			});

			//range/selection isn't getting properly set in firefox & safari, but it works in Chrome so this is a workaround
			if (range !== null) {
				globalStore.range = range;
			} else {
				range = globalStore.range;
			}

			if (
				this.state.showLinkElementDropdown &&
				!(range && range.length === 0)
			) {
				this.setState({
					showLinkElementDropdown: false
				});
			}
		}

		componentDidMount() {
			if (!this.state.quillRef && !this.props.reorderMode) {
				this.attachQuillRefs();
			}

			if (this.props.bitCase.content) {
				const elementLinkRegEx = /<span class="quill-elementLink" data-elementid="[0-9]+" contenteditable="false"?>.*?<\/span>/g;
				const elementLinksToReplace = this.props.bitCase.content.match(
					elementLinkRegEx
				);
				if (elementLinksToReplace) {
					this.setState({ loadingBrokenLinkChecker: true });
					for (const elementLink of elementLinksToReplace) {
						const elementLinkIDDOMTag = elementLink.match(
							/data-elementid="[0-9]+"/g
						)[0];
						const elementLinkIDAsString = elementLinkIDDOMTag.match(
							/[0-9]+/g
						)[0];
						const elementLinkID = parseInt(elementLinkIDAsString, 10);

						if (!this.props.reorderMode) {
							this.timeout = setTimeout(() => {
								API(`/element/${elementLinkID}`, "GET", {}, data => {
									this.setState({ loadingBrokenLinkChecker: false });
									if (data.element === null) {
										this.setState({ brokenLink: true });
									}
								});
							}, this.props.index * 750);
						}
					}
				}
			}
		}

		componentWillUnmount() {
			clearTimeout(this.timeout);
		}

		componentDidUpdate() {
			if (!this.state.quillRef && !this.props.reorderMode) {
				this.attachQuillRefs();
			}
		}

		attachQuillRefs = () => {
			//this has to be put in the state so when the editor finally mounts, you can put data in it
			this.setState({
				quillRef: this.reactQuillRef.getEditor()
			});
		};

		hideElementDropdown() {
			this.setState({ showLinkElementDropdown: false });
		}

		duplicateCase() {
			let bitCaseDuplicate = { ...toJS(this.props.bitCase) };

			bitCaseDuplicate.defaultCase = false;
			bitCaseDuplicate.id = shortid();
			bitCaseDuplicate.bitCaseRules = bitCaseDuplicate.bitCaseRules.map(
				rule => {
					rule.id = shortid();
					return rule;
				}
			);

			bitCaseDuplicate.bitCaseRuleGroups = bitCaseDuplicate.bitCaseRuleGroups.map(
				group => {
					group.id = shortid();

					group.bitCaseRules = group.bitCaseRules.map(rule => {
						rule.id = shortid();
						return rule;
					});

					return group;
				}
			);

			bitCaseDuplicate.order = this.props.pageStore.bitCases.length + 1;

			this.props.pageStore.bitCases.push(bitCaseDuplicate);
		}
		createMarkup() {
			return { __html: this.props.bitCase.content };
		}

		attemptLinkFix() {
			API(
				`/fixBrokenLink`,
				"POST",
				{ content: this.props.bitCase.content },
				data => {
					if (data.success) {
						this.setState({ brokenLink: false });
						this.props.bitCase.content = data.content;
					} else {
						alert("No luck. Try deleting the link and adding it back");
					}
				}
			);
		}

		generateCases() {
			let usedAttributes = this.props.bitCase.bitCaseRules.map(bitCaseRule => {
				return bitCaseRule.attributeID;
			});
			usedAttributes = [...new Set(usedAttributes)];

			let permuationsToGenerate = [];

			for (const usedAttribute of usedAttributes) {
				let dataToPush = [];

				const attribute = this.props.pageStore.availableAttributes.find(
					bitCaseRule => {
						return bitCaseRule.attributeID === usedAttribute;
					}
				);

				for (const option of attribute.options) {
					dataToPush.push(`${usedAttribute}-${option.id}`);
				}
				permuationsToGenerate.push(dataToPush);
			}

			let casesToGenerate = CartesianProduct(permuationsToGenerate);
			casesToGenerate.splice(0, 1);

			for (const caseToGenerate of casesToGenerate) {
				let bitCaseRules = caseToGenerate.map((combination, index) => {
					const combinationArray = combination.split("-");
					return {
						id: shortid.generate(),
						attributeID: parseInt(combinationArray[0], 10),
						optionID: parseInt(combinationArray[1], 10),
						passthroughValue: null,
						equalityOperator: "Equal To",
						chainedOperator:
							caseToGenerate.length - 1 === index ? "The End" : "And",
						order: index
					};
				});

				this.props.pageStore.bitCases.push(
					observable({
						id: shortid.generate(),
						order: this.props.pageStore.bitCases.length,
						bitID: this.props.match.params.bitID,
						content: "",
						bitCaseRules,
						bitCaseRuleGroups: [],
						defaultCase: false,
						alwaysTrue: false
					})
				);
			}
		}

		render() {
			return (
				<div className="caseContainer">
					<Row className="topRow">
						<Col xs={10}>
							<p className="caseNumber">
								CASE: {this.props.index + 1}{" "}
								{this.state.loadingBrokenLinkChecker ? (
									<i className="	far fa-circle-notch fa-spin" />
								) : null}
								{this.state.brokenLink ? (
									<a
										onClick={this.attemptLinkFix}
										style={{ color: "red", display: "inline", fontWeight: 700 }}
									>
										BROKEN LINK
									</a>
								) : null}
							</p>
						</Col>
						<Col xs={2} className="text-right">
							<a className="addCaseButton" onClick={this.addCase}>
								Add Case
							</a>
						</Col>
					</Row>
					{this.props.reorderMode ? null : (
						<Toolbar
							showLinkElement={this.state.showLinkElement}
							caseID={this.props.bitCase.id}
						/>
					)}
					{this.state.showLinkElementDropdown ? (
						<div className="linkElementDropdown">
							<MessageBrowser
								linkElementPanel={true}
								quillReact={this.reactQuillRef}
								quillJS={this.state.quillRef}
								hideDropdown={this.hideElementDropdown}
							/>
						</div>
					) : null}
					{this.props.reorderMode ? (
						<div
							className="noneditable"
							dangerouslySetInnerHTML={this.createMarkup()}
						/>
					) : (
						<ReactQuill
							value={this.props.bitCase.content}
							onChange={this.handleChange}
							modules={this.modules}
							theme={"snow"}
							onChangeSelection={this.onChangeSelection}
							ref={el => {
								this.reactQuillRef = el;
							}}
						/>
					)}
					{this.props.reorderMode ? null : (
						<Row className="buttonRow">
							<span>
								<input
									className="mousetrap"
									type="checkbox"
									onChange={this.toggleDefaultCase}
									checked={this.props.bitCase.defaultCase}
								/>&nbsp;
								<a
									className="defaultCaseLabel"
									onClick={this.toggleDefaultCase}
								>
									Default
								</a>
							</span>
							<span>
								<input
									className="mousetrap"
									type="checkbox"
									onChange={this.toggleAlwaysTrue}
									checked={this.props.bitCase.alwaysTrue}
								/>&nbsp;
								<a className="defaultCaseLabel" onClick={this.toggleAlwaysTrue}>
									Always True
								</a>
							</span>
							<span>
								<a onClick={this.duplicateCase} className="duplicateCaseButton">
									Duplicate
								</a>
							</span>
							<span>
								<a onClick={this.removeCase} className="removeCaseButton">
									Remove
								</a>
							</span>
							{this.props.index === 0 ? (
								<span>
									<a onClick={this.generateCases}>Generate Cases</a>
								</span>
							) : null}
						</Row>
					)}
					{this.props.bitCase.alwaysTrue ? null : (
						<Row>
							<RuleContainerV1
								quillJS={this.state.quillRef}
								bitCase={this.props.bitCase}
								pageStore={this.props.pageStore}
								reorderMode={this.props.reorderMode}
							/>
						</Row>
					)}
				</div>
			);
		}
	}
);

class QuillElementLink extends Quill.import("blots/inline") {
	static create(data) {
		let node = super.create(data);
		node.dataset.elementid = data.elementid;
		node.contentEditable = false;

		if (data.newLink) {
			node.innerHTML = data.text;
		}

		node.addEventListener("dblclick", event => {
			//sometimes there's a nested span tag where you need to look into the parent instead of the actual clicked element
			const elementid = event.target.dataset.elementid
				? event.target.dataset.elementid
				: event.target.parentElement.dataset.elementid;

			API(`/midByEID/${elementid}`, "GET", null, data => {
				console.log(data);
				if (data.messageID !== null) {
					globalStore.history.push(
						`/message/${data.messageID}/elements/${elementid}`
					);
				}
			});
		});

		return node;
	}

	static formats(domNode) {
		return {
			elementid: domNode.dataset.elementid,
			text: domNode.innerHTML
		};
	}

	static value(domNode) {
		// console.log(domNode);
		return {
			elementid: domNode.dataset.elementid,
			text: domNode.innerHTML
		};
	}
}

QuillElementLink.blotName = "elementLink";
QuillElementLink.className = "quill-elementLink";
QuillElementLink.tagName = "span";

class QuillPassthroughBit extends Quill.import("blots/inline") {
	static create(data) {
		let node = super.create(data);
		node.dataset.attributeid = data.attributeid;
		node.contentEditable = false;

		if (data.newLink) {
			node.innerHTML = data.text;
		}

		// node.addEventListener('click', (event) => {
		// 	function generateURL(dest) {
		// 		return /\/message\/[0-9]+\/elements\/[0-9]+/g.exec(globalStore.history.location.pathname)[0] + dest;
		// 	}
		//
		// 	//sometimes there's a nested span tag where you need to look into the parent instead of the actual clicked element
		// 	const bitid = event.target.dataset.bitid ? event.target.dataset.bitid : event.target.parentElement.dataset.bitid;
		//
		// 	globalStore.history.push(generateURL(`/bit/${bitid}`))
		// });

		return node;
	}

	static formats(domNode) {
		return {
			attributeid: domNode.dataset.attributeid,
			text: domNode.innerHTML
		};
	}

	static value(domNode) {
		// console.log(domNode);
		return {
			attributeid: domNode.dataset.attributeid,
			text: domNode.innerHTML
		};
	}
}

QuillPassthroughBit.blotName = "passthroughBit";
QuillPassthroughBit.className = "quill-passthroughBit";
QuillPassthroughBit.tagName = "span";

Quill.register({
	"formats/elementLink": QuillElementLink,
	"formats/passthroughBit": QuillPassthroughBit
});

class Toolbar extends Component {
	render() {
		return (
			<div className={"quillToolbar quillToolbar-" + this.props.caseID}>
				<span className="ql-formats">
					<button className="ql-bold" />
					<button className="ql-italic" />
					<button className="ql-underline" />
				</span>
				<span className="ql-formats">
					<button className="ql-list" value="ordered" />
					<button className="ql-list" value="bullet" />
					<button className="ql-blockquote" />
				</span>
				<span className="ql-formats">
					<button className="ql-clean" />
					<button className="ql-link" />
				</span>
				<span className="" style={{ float: "right" }}>
					<button
						className={classNames(
							"ql-elementLink",
							this.props.showLinkElement ? "" : "hide"
						)}
					>
						Link Message Element
					</button>
				</span>
			</div>
		);
	}
}
//extend the clipboard module to remove copybits when pasting
const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");

class PlainClipboard extends Clipboard {
	onPaste(e) {
		if (e.defaultPrevented || !this.quill.isEnabled()) return;
		let range = this.quill.getSelection();
		let delta = new Delta().retain(range.index);
		let scrollTop = this.quill.scrollingContainer.scrollTop;
		this.container.focus();
		this.quill.selection.update(Quill.sources.SILENT);
		setTimeout(() => {
			delta = delta.concat(this.convert()).delete(range.length);
			delta.ops = delta.ops.map(op => {
				//here's we we remove linked message elements & bits
				if ("attributes" in op && "bit" in op.attributes) {
					delete op.attributes.bit;
				}
				if ("attributes" in op && "elementLink" in op.attributes) {
					delete op.attributes.elementLink;
				}
				return op;
			});
			console.log(delta.ops);
			this.quill.updateContents(delta, Quill.sources.USER);
			// range.length contributes to delta.length()
			this.quill.setSelection(
				delta.length() - range.length,
				Quill.sources.SILENT
			);
			this.quill.scrollingContainer.scrollTop = scrollTop;
			this.quill.focus();
		}, 1);
	}
}

Quill.register("modules/clipboard", PlainClipboard, true);
