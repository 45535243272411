export function arrayMove(arr, old_index, new_index) {
	if (new_index >= arr.length) {
		let k = new_index - arr.length + 1;
		while (k--) {
			arr.push(undefined);
		}
	}
	arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
	return arr;
}

export function mapToObject(aMap) {
	const obj = {};
	aMap.forEach((v, k) => {
		obj[k] = v;
	});
	return obj;
}

export function getMatches(string, pattern, index = 1) {
	let result = [];

	const matches = string.match(pattern);
	if (matches) {
		for (let i = 0; i < matches.length; i++) {
			result.push(
				new RegExp(pattern.source, pattern.flags).exec(matches[i])[index]
			);
		}
	}
	return result;
}
