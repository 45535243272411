import React, { Component } from "react";
import { observer } from "mobx-react";
import SelectEdit from "../../lib/SelectEdit";
import { Col, Row } from "react-bootstrap";

export default observer(
	class ManageRetrievers extends Component {
		state = { selectedItem: null };

		setSelectedItem = value => {
			this.setState({ selectedItem: value });
		};

		render() {
			return (
				<div id="manageRetrievers">
					<h1 className="pageName">Manage Retrievers:</h1>
					<Row>
						<Col xs={3}>
							<SelectEdit
								name="retrievers"
								endpointName="retriever"
								selectedItem={this.state.selectedItem}
								setSelectedItem={this.setSelectedItem}
								fields={["name", "url"]}
							/>
						</Col>
					</Row>
				</div>
			);
		}
	}
);
