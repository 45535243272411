import React, { Component } from "react";
import autoBind from "react-autobind";
import API from "../../../API";
import { observer } from "mobx-react";
import camelcase from "camel-case";
import Loading from "../../structure/Loading";
import { toJS } from "mobx";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";

export default observer(
	class extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				tailoredContent: null,
				loading: false,
				voiceSelection: { value: "mentor", label: "Mentor" }
			};
		}

		componentWillReceiveProps(nextProps) {
			this.setState({ tailoredContent: null });
		}

		loadData() {
			this.setState({ loading: true });
			API(
				`/message/${this.props.match.params.messageID}`,
				"POST",
				{
					returnElementNames: true,
					bypassMapping: true,
					elementID: this.props.match.params.elementID,
					...toJS(this.props.pageStore.selectedValues)
				},
				data => {
					this.setState({
						tailoredContent: data,
						loading: false
					});
				},
				null,
				true
			);
		}

		createMarkup(content) {
			return { __html: content };
		}

		async play(text) {
			API(
				`/voiceTest`,
				"POST",
				{ textarea: text, voiceSelection: this.state.voiceSelection.value },
				async data => {
					const uInt8Array = new Uint8Array(data.audio.data);
					const arrayBuffer = uInt8Array.buffer;
					const blob = new Blob([arrayBuffer]);
					const url = URL.createObjectURL(blob);

					this.setState(
						{
							audioURL: url,
							transcription: data.transcription,
							uploading: false
						},
						() => {
							this.audioDOM.load();
							this.audioDOM.play();
						}
					);
				}
			);
		}

		async download(text, elementName) {
			API(
				`/voiceTest`,
				"POST",
				{ textarea: text, voiceSelection: this.state.voiceSelection.value },
				async data => {
					const uInt8Array = new Uint8Array(data.audio.data);
					const arrayBuffer = uInt8Array.buffer;
					const blob = new Blob([arrayBuffer], { type: "octet/stream" });
					const url = URL.createObjectURL(blob);

					this.setState(
						{
							audioURL: url,
							downloadFileName: elementName.replace(/ /g, "_") + ".mp3"
						},
						() => {
							this.urlLink.click();
						}
					);
				}
			);
		}

		render() {
			let tailoredContent = [];

			for (const key in this.state.tailoredContent) {
				if (this.state.tailoredContent.hasOwnProperty(key)) {
					tailoredContent.push(
						<div key={camelcase(key)}>
							<div>
								<span className="elementName">{key}</span>{" "}
								<i
									className="far fa-play-circle"
									aria-hidden="true"
									onClick={this.play.bind(
										this,
										this.state.tailoredContent[key]
									)}
									style={{ fontSize: 20 }}
								/>
								<i
									className="fa fa-download"
									aria-hidden="true"
									onClick={this.download.bind(
										this,
										this.state.tailoredContent[key],
										key
									)}
									style={{ fontSize: 20, marginLeft: 10 }}
								/>
							</div>
							<div
								className="elementContent"
								dangerouslySetInnerHTML={this.createMarkup(
									this.state.tailoredContent[key]
								)}
							/>
						</div>
					);
				}
			}

			return (
				<div>
					<a
						href={this.state.audioURL}
						style={{ display: "none" }}
						download={this.state.downloadFileName}
						ref={el => {
							this.urlLink = el;
						}}
					>
						{" "}
					</a>
					{/*this needs content to shut up a compiler warning*/}
					<audio
						controls
						ref={audio => {
							this.audioDOM = audio;
						}}
						style={{ display: "none" }}
					>
						<source src={this.state.audioURL} type="audio/ogg" />
					</audio>
					<h1 className="colName">Preview:</h1>
					<Row>
						<Col xs={8}>
							<a onClick={this.loadData} id="showPreviewButton">
								Show preview
							</a>
						</Col>
						<Col xs={4}>
							<Select
								options={[
									{ value: "mentor", label: "Mentor" },
									{ value: "mom", label: "Mom" },
									{ value: "coach", label: "Coach" },
									{ value: "bff", label: "BFF" },
									{ value: "drillSergeant", label: "Drill Sergeant" }
								]}
								value={this.state.voiceSelection}
								onChange={val => {
									this.setState({ voiceSelection: val });
								}}
							/>
						</Col>
					</Row>
					{this.state.loading ? (
						<Loading />
					) : this.state.tailoredContent ? (
						<div id="tailoredContentPanel">{tailoredContent}</div>
					) : null}
				</div>
			);
		}
	}
);
