import React, { Component } from "react";
import autoBind from "react-autobind";
import { Row, Col } from "react-bootstrap";
import ReactQuill, { Quill } from "react-quill";
import globalStore from "../../../GlobalStore";
import classNames from "classnames";
import { observer } from "mobx-react";
import API from "../../../API";
import MessageBrowser from "../../messageBrowser/MessageBrowser";
import striptags from "striptags";
import debounce from "lodash.debounce";

const Delta = Quill.import("delta");

export default observer(
	class ElementContent extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.quillRef = null; // Quill instance
			this.reactQuillRef = null; // ReactQuill component

			this.state = {
				showAddBit: false,
				showLinkElement: false,
				panelOpen: true,
				showLinkElementDropdown: false,
				brokenLink: false,
				validJSON: null
			};

			this.modules = {
				keyboard: {
					bindings: {
						custom: {
							key: "S",
							shortKey: true,
							handler: function() {
								props.save();
							}
						},
						cmdUp: {
							key: "UP",
							shortKey: true,
							handler: function() {
								const currentElementIndex = globalStore.elements.findIndex(
									element => {
										return this.props.pageStore.element.id === element.id;
									}
								);
								const nextElement =
									globalStore.elements[currentElementIndex - 1];
								if (nextElement) {
									const nextElementID = nextElement.id;
									globalStore.history.push(
										`/message/${
											nextElement.messageID
										}/elements/${nextElementID}`
									);
								}
							}.bind(this)
						},
						cmdDown: {
							key: "DOWN",
							shortKey: true,
							handler: function() {
								const currentElementIndex = globalStore.elements.findIndex(
									element => {
										return this.props.pageStore.element.id === element.id;
									}
								);
								const nextElement =
									globalStore.elements[currentElementIndex + 1];
								if (nextElement) {
									const nextElementID = nextElement.id;
									globalStore.history.push(
										`/message/${
											nextElement.messageID
										}/elements/${nextElementID}`
									);
								}
							}.bind(this)
						},
						cmdL: {
							key: "L",
							shortKey: true,
							handler: function() {
								this.setState({ showLinkElementDropdown: true });
							}.bind(this)
						},
						quickFind: {
							key: "K",
							shortKey: true,
							handler: function() {
								globalStore.messageBrowserPanelOpen = !globalStore.messageBrowserPanelOpen;
							}
						},
						escKey: {
							key: "escape",
							handler: () => {
								this.setState({ showLinkElementDropdown: false });
							}
						}
					}
				},
				toolbar: {
					container: ".quillToolbarElementContent",
					handlers: {
						chatMessageSplit: function() {
							const selection = this.quillRef.getSelection();

							this.quillRef.insertEmbed(selection.index, "chatMessageSplit", {
								text: "([Chat Message Split])",
								newSplit: true
							});
						}.bind(this),
						color: value => {
							if (value === "custom-color") {
								value = prompt("Enter Hex/RGB/RGBA");
							}

							this.reactQuillRef.getEditor().format("color", value);
						},
						pasteBit: function() {
							const selection = this.quillRef.getSelection();

							API(
								`/bitText/${localStorage.bitToPaste}`,
								"GET",
								{},
								async data => {
									API(
										`/duplicateBit/${localStorage.bitToPaste}/${
											this.props.pageStore.element.id
										}`,
										"PUT",
										{},
										dataInsert => {
											this.quillRef.insertEmbed(selection.index, "bit", {
												text: data.text,
												bitid: dataInsert.bit,
												newBit: true
											});
										}
									);
								}
							);
						}.bind(this),
						bit: function(value) {
							const selection = this.quill.getSelection();

							const selectionText = this.quill.getText(
								selection.index,
								selection.length
							);
							// this.quill;
							this.quill.updateContents(
								new Delta().retain(selection.index).delete(selection.length)
							);

							API(
								"/bit",
								"PUT",
								{
									elementID: props.pageStore.element.id,
									content: `<p>${selectionText}</p>`
								},
								data => {
									this.quill.insertEmbed(selection.index, "bit", {
										text: selectionText,
										bitid: data.bit.id,
										newBit: true
									});

									props.save(false);

									globalStore.history.push(
										`/message/${props.pageStore.element.messageID}/elements/${
											props.pageStore.element.id
										}/bit/${data.bit.id}`
									);
								}
							);
						},
						elementLink: function() {
							this.setState({ showLinkElementDropdown: true });
						}.bind(this)
					}
				}
			};

			Quill.register({
				"formats/bit": QuillBit,
				"formats/elementLink": QuillElementLink,
				"formats/chatMessageSplit": QuillChatMessageSplit
			});
		}
		attemptLinkFix() {
			API(
				`/fixBrokenLink`,
				"POST",
				{ content: this.props.pageStore.element.content },
				data => {
					if (data.success) {
						this.setState({ brokenLink: false });
						this.props.pageStore.element.content = data.content;
					} else {
						alert("No luck. Try deleting the link and adding it back");
					}
				}
			);
		}

		componentDidMount() {
			if (this.state.panelOpen) {
				this.attachQuillRefs();
			}
			if (this.props.pageStore.element.content) {
				const elementLinkRegEx = /<span class="quill-elementLink" data-elementid="[0-9]+" contenteditable="false"?>.*?<\/span>/g;
				const elementLinksToReplace = this.props.pageStore.element.content.match(
					elementLinkRegEx
				);
				if (elementLinksToReplace) {
					for (const elementLink of elementLinksToReplace) {
						const elementLinkIDDOMTag = elementLink.match(
							/data-elementid="[0-9]+"/g
						)[0];
						const elementLinkIDAsString = elementLinkIDDOMTag.match(
							/[0-9]+/g
						)[0];
						const elementLinkID = parseInt(elementLinkIDAsString, 10);

						setTimeout(() => {
							API(`/element/${elementLinkID}`, "GET", {}, data => {
								if (data.element === null) {
									this.setState({ brokenLink: true });
								}
							});
						}, 750);
					}
				}

				if (this.props.pageStore.element.content.substring(0, 5) === "<p>[{") {
					this.validateJSON();
				}
			}
		}

		componentWillUnmount() {
			globalStore.range = null;
		}

		componentDidUpdate() {
			if (this.state.panelOpen) {
				this.attachQuillRefs();
			}
		}

		attachQuillRefs = () => {
			if (typeof this.reactQuillRef.getEditor !== "function") return;
			this.quillRef = this.reactQuillRef.getEditor();
		};

		openPanel() {
			this.setState({ panelOpen: !this.state.panelOpen });
		}

		handleChange(value) {
			if (this.props.pageStore.element) {
				this.props.pageStore.element.content = value;

				if (value.substring(0, 5) === "<p>[{") {
					this.validateJSON();
				}
			}
		}

		onChangeSelection(range) {
			this.setState({
				showAddBit: range && !!range.length,
				showLinkElement: range && range.length === 0
			});

			//range/selection isn't getting properly set in firefox & safari, but it works in Chrome so this is a workaround
			if (range !== null) {
				globalStore.range = range;
			} else {
				range = globalStore.range;
			}

			if (
				this.state.showLinkElementDropdown &&
				!(range && range.length === 0)
			) {
				this.setState({
					showLinkElementDropdown: false
				});
			}
		}

		hideElementDropdown() {
			this.setState({ showLinkElementDropdown: false });
		}

		validateJSON = debounce(() => {
			try {
				JSON.parse(striptags(this.props.pageStore.element.content));
				this.setState({ validJSON: true });
			} catch (e) {
				this.setState({ validJSON: false });
			}
		}, 1500);

		render() {
			if (
				(globalStore.userType === "Read Only" ||
					(globalStore.message && globalStore.message.status === "Published") ||
					globalStore.userType === "Developer" ||
					globalStore.userType === "Reviewer") &&
				this.reactQuillRef
			) {
				this.reactQuillRef.getEditor().enable(false);
			}

			return (
				<Row>
					<Col className="panelRow">
						<Row className="panelCollapsibleRow">
							<Col xs={11}>
								<h2 className="panelName">Element Content</h2>
								{this.state.brokenLink ? (
									<a
										style={{
											color: "red",
											display: "inline",
											fontWeight: 700
										}}
										onClick={this.attemptLinkFix}
									>
										BROKEN LINK
									</a>
								) : null}
							</Col>
							<Col xs={1} className="text-right">
								<i
									//"fas fa-comment-exclamation"
									className="fas fa-comment-edit"
									style={{ fontSize: 20, marginRight: 10 }}
									onClick={() => {
										globalStore.commentsElementID = this.props.pageStore.element.id;
										globalStore.commentsModalOpen = true;
									}}
								/>
								{this.state.panelOpen ? (
									<i
										className="far fa-caret-square-up"
										onClick={this.openPanel}
									/>
								) : (
									<i
										className="far fa-caret-square-down"
										onClick={this.openPanel}
									/>
								)}
							</Col>
						</Row>
						{this.state.panelOpen ? (
							<div className="panelContent">
								<Toolbar
									showAddBit={this.state.showAddBit}
									showLinkElement={this.state.showLinkElement}
								/>
								{this.state.showLinkElementDropdown ? (
									<div className="linkElementDropdown">
										<MessageBrowser
											linkElementPanel={true}
											quillReact={this.reactQuillRef}
											quillJS={this.quillRef}
											hideDropdown={this.hideElementDropdown}
										/>
									</div>
								) : null}
								<ReactQuill
									value={
										this.props.pageStore.element
											? this.props.pageStore.element.content
											: "Loading"
									}
									onChange={this.handleChange}
									modules={this.modules}
									theme={"snow"}
									onChangeSelection={this.onChangeSelection}
									ref={el => {
										this.reactQuillRef = el;
									}}
									className={`${
										this.props.pageStore.element &&
										this.props.pageStore.element.content.substring(0, 5) ===
											"<p>[{"
											? "jsonQuill"
											: null
									}`}
								/>
								{this.state.validJSON === null ? null : this.state.validJSON ? (
									<span style={{ color: "green" }}>Valid JSON</span>
								) : (
									<div>
										<span
											style={{
												color: "red"
											}}
										>
											INVALID JSON! PANIC! Everyone PANIC! Unhappy developers!{" "}
										</span>
										<img
											aria-label="rage emoji"
											style={{ display: "inline", width: 30 }}
											src="/computerrage.gif"
											alt="computer rage"
										/>
									</div>
								)}
							</div>
						) : null}
					</Col>
				</Row>
			);
		}
	}
);

class QuillBit extends Quill.import("blots/inline") {
	static create(data) {
		let node = super.create(data);
		node.dataset.bitid = data.bitid;
		node.contentEditable = false;

		if (data.newBit) {
			node.innerHTML = data.text;
		}

		node.addEventListener("click", event => {
			function generateURL(dest) {
				return (
					/\/message\/[0-9]+\/elements\/[0-9]+/g.exec(
						window.location.pathname
					)[0] + dest
				);
			}

			const bitid = event.target.dataset.bitid;
			globalStore.history.push(generateURL(`/bit/${bitid}`));
		});

		return node;
	}

	static formats(domNode) {
		return {
			bitid: domNode.dataset.bitid,
			text: domNode.innerHTML
		};
	}

	static value(domNode) {
		// console.log(domNode);
		return {
			bitid: domNode.dataset.bitid,
			text: domNode.innerHTML
		};
	}
}

QuillBit.blotName = "bit";
QuillBit.className = "quill-bit";
QuillBit.tagName = "span";

class QuillElementLink extends Quill.import("blots/inline") {
	static create(data) {
		let node = super.create(data);
		node.dataset.elementid = data.elementid;
		node.contentEditable = false;

		if (data.newLink) {
			node.innerHTML = data.text;
		}

		node.addEventListener("dblclick", event => {
			//sometimes there's a nested span tag where you need to look into the parent instead of the actual clicked element
			const elementid = event.target.dataset.elementid
				? event.target.dataset.elementid
				: event.target.parentElement.dataset.elementid;

			API(`/midByEID/${elementid}`, "GET", null, data => {
				console.log(data);
				if (data.messageID !== null) {
					globalStore.history.push(
						`/message/${data.messageID}/elements/${elementid}`
					);
				}
			});
		});

		return node;
	}

	static formats(domNode) {
		return {
			elementid: domNode.dataset.elementid,
			text: domNode.innerHTML
		};
	}

	static value(domNode) {
		// console.log(domNode);
		return {
			elementid: domNode.dataset.elementid,
			text: domNode.innerHTML
		};
	}
}

QuillElementLink.blotName = "elementLink";
QuillElementLink.className = "quill-elementLink";
QuillElementLink.tagName = "span";

class QuillChatMessageSplit extends Quill.import("blots/inline") {
	static create(data) {
		let node = super.create(data);
		node.contentEditable = false;

		if (data.newSplit) {
			node.innerHTML = data.text;
		}

		return node;
	}

	static formats(domNode) {
		return {
			text: domNode.innerHTML
		};
	}

	static value(domNode) {
		return {
			text: domNode.innerHTML
		};
	}
}

QuillChatMessageSplit.blotName = "chatMessageSplit";
QuillChatMessageSplit.className = "quill-chatMessageSplit";
QuillChatMessageSplit.tagName = "span";

class Toolbar extends Component {
	constructor(props) {
		super(props);
		this.state = { bitInLocalStorage: false };
		setTimeout(() => {
			if (localStorage.bitToPaste) {
				this.setState({ bitInLocalStorage: true });
			} else if (this.state.bitInLocalStorage && !localStorage.bitToPaste) {
				this.setState({ bitInLocalStorage: false });
			}
		}, 1000);
	}

	render() {
		return globalStore.userType === "Read Only" ||
			(globalStore.message && globalStore.message.status === "Published") ||
			globalStore.userType === "Developer" ||
			globalStore.userType === "Reviewer" ? (
			<div className="quillToolbarElementContent" />
		) : (
			<div className="quillToolbarElementContent">
				<span className="ql-formats">
					<button className="ql-bold" />
					<button className="ql-italic" />
					<button className="ql-underline" />
				</span>
				<span className="ql-formats">
					<button className="ql-list" value="ordered" />
					<button className="ql-list" value="bullet" />
					<button className="ql-blockquote" />
				</span>
				<span className="ql-formats">
					<select className="ql-color">
						{[
							"black",
							"white",
							"silver",
							"gray",
							"red",
							"maroon",
							"yellow",
							"olive",
							"lime",
							"green",
							"aqua",
							"teal",
							"blue",
							"navy",
							"fuchsia",
							"purple",
							"custom-color"
						].map(color => {
							return (
								<option
									key={color}
									value={color}
									defaultValue={color === "black"}
								/>
							);
						})}
					</select>
				</span>
				<span className="ql-formats">
					<button className="ql-clean" />
					<button className="ql-link" />
				</span>
				<span className="ql-formats">
					<button className="ql-chatMessageSplit">
						<i className="fas fa-unlink" />
					</button>
				</span>
				<span className="" style={{ float: "right" }}>
					<button
						className={classNames(
							"ql-elementLink",
							this.props.showLinkElement ? "" : "hide"
						)}
					>
						Link Message Element
					</button>
					<button
						className={classNames(
							"ql-bit",
							this.props.showAddBit ? "" : "hide"
						)}
					>
						Add Bit
					</button>
					<button
						className={classNames(
							"ql-pasteBit",
							this.state.bitInLocalStorage && this.props.showLinkElement
								? ""
								: "hide"
						)}
					>
						Paste Bit
					</button>
				</span>
			</div>
		);
	}
}

//extend the clipboard module to remove copybits when pasting
const Clipboard = Quill.import("modules/clipboard");

class PlainClipboard extends Clipboard {
	onPaste(e) {
		if (e.defaultPrevented || !this.quill.isEnabled()) return;
		let range = this.quill.getSelection();
		let delta = new Delta().retain(range.index);
		let scrollTop = this.quill.scrollingContainer.scrollTop;
		this.container.focus();
		this.quill.selection.update(Quill.sources.SILENT);
		setTimeout(() => {
			delta = delta.concat(this.convert()).delete(range.length);
			delta.ops = delta.ops.map(op => {
				//here's we we remove linked message elements & bits
				if ("attributes" in op && "bit" in op.attributes) {
					delete op.attributes.bit;
				}
				if ("attributes" in op && "elementLink" in op.attributes) {
					delete op.attributes.elementLink;
				}
				return op;
			});
			console.log(delta.ops);
			this.quill.updateContents(delta, Quill.sources.USER);
			// range.length contributes to delta.length()
			this.quill.setSelection(
				delta.length() - range.length,
				Quill.sources.SILENT
			);
			this.quill.scrollingContainer.scrollTop = scrollTop;
			this.quill.focus();
		}, 1);
	}
}

Quill.register("modules/clipboard", PlainClipboard, true);
