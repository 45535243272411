import React, { Component } from "react";
import autoBind from "react-autobind";
import { NotificationStack } from "react-notification";

export default class Snackbar extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			notifications: []
		};
	}

	componentWillReceiveProps(nextProps) {
		const newNotifications = this.state.notifications;
		if (nextProps.message !== "" && this.state.notifications.length < 1) {
			if (
				!("lastSaveID" in nextProps) ||
				nextProps.lastSaveID !== this.props.lastSaveID
			) {
				newNotifications.push({
					message: nextProps.message,
					key: newNotifications.length + 1,
					dismissAfter: 2000,
					activeBarStyle: { left: "50%" }
				});
			}
		}
		return this.setState({
			notifications: newNotifications
		});
	}

	render() {
		return (
			<div>
				<NotificationStack
					notifications={this.state.notifications}
					onDismiss={notification => {
						this.setState({
							notifications: this.state.notifications.splice(
								notification.key,
								1
							)
						});
					}}
				/>
			</div>
		);
	}
}
