import React, { Component } from "react";
import globalStore from "../../GlobalStore";
import { Form, Text } from "react-form";
import classNames from "classnames";
import autoBind from "react-autobind";
import { observer } from "mobx-react";
import ReactTooltip from "react-tooltip";
import API from "../../API";
// import Mousetrap from "mousetrap";

export default observer(
	class Message extends Component {
		constructor(props) {
			super(props);

			autoBind(this);

			this.state = {
				editMode: false,
				showChildrenElements: false,
				tooltipText: "Loading...",
				usersOnMessage: []
			};
		}

		componentDidMount() {
			API(
				`/message/${this.props.message.id}/usersOnMessage`,
				"GET",
				{},
				data => {
					this.generateUsersTooltip(data.usersOnMessage);
				}
			);
		}

		messageClicked(event) {
			event.preventDefault();
			if (this.props.showElements) {
				this.setState({
					showChildrenElements: !this.state.showChildrenElements
				});
			} else if (this.props.messageSelected) {
				this.props.messageSelected(this.props.message.id, event);
			} else {
				if (this.state.editMode) {
					event.preventDefault();
				} else {
					globalStore.messageBrowserPanelOpen = false;
					globalStore.history.push(`/message/${this.props.message.id}/`);
				}
			}
		}

		toggleEdit() {
			this.setState({ editMode: true });
		}

		generateUsersTooltip(usersOnMessage) {
			if (usersOnMessage.length) {
				this.setState({
					tooltipText: usersOnMessage
						.map(userOnMessage => {
							return userOnMessage.user.displayName;
						})
						.join("<br>")
				});
			} else {
				this.setState({
					tooltipText: "No one on message"
				});
			}

			this.setState({ usersOnMessage });
		}

		userIsOnMessage() {
			for (const userOnMessage of this.state.usersOnMessage) {
				if (userOnMessage.user.firebaseUID === globalStore.firebaseUser.uid) {
					return true;
				}
			}
			return false;
		}

		render() {
			let messageStatusColor = "messageFontColor";

			if (this.props.message.status) {
				messageStatusColor = this.props.message.status.replace(/ /g, "");
				messageStatusColor =
					messageStatusColor.substring(0, 1).toLowerCase() +
					messageStatusColor.substring(1) +
					"FontColor";
			}

			return (
				<div
					className="messageContainer"
					{...this.props.tooltipProps}
					title={`ID: ${this.props.message.id}`}
				>
					<div
						className={classNames(
							"messageRow",
							this.state.editMode ? "editModeOn" : null,
							this.props.highlighted ? "highlighted" : null
						)}
						onMouseEnter={() => {
							// Mousetrap.bind(["a"], event => {
							// 	event.preventDefault();
							// 	if (this.userIsOnMessage()) {
							// 		API(
							// 			`/message/removeFromMessage/${this.props.message.id}`,
							// 			"POST",
							// 			{},
							// 			data => {
							// 				this.generateUsersTooltip(data.usersOnMessage);
							// 			}
							// 		);
							// 	} else {
							// 		API(
							// 			`/message/addToMessage/${this.props.message.id}`,
							// 			"POST",
							// 			{},
							// 			data => {
							// 				this.generateUsersTooltip(data.usersOnMessage);
							// 			}
							// 		);
							// 	}
							// });
						}}
						onMouseLeave={() => {
							// Mousetrap.unbind(["a"]);
						}}
					>
						<a
							onClick={this.messageClicked}
							href={`/message/${this.props.message.id}`}
						>
							<div className="message">
								<i className={classNames("fas fa-file", messageStatusColor)} />
								<span className="name">
									{this.state.editMode ? (
										<Form
											values={{
												name: this.props.message.name
											}}
											validate={values => {
												return {
													name: !values.name ? "A name is required" : undefined
												};
											}}
											onSubmit={values => {
												this.props.edit(
													"Message",
													values.name,
													this.props.message.id
												);
												this.setState({
													editMode: false
												});
											}}
											className="editForm"
										>
											{({ values, submitForm }) => {
												return (
													<form onSubmit={submitForm}>
														<Text
															field="name"
															placeholder={"Message name goes here..."}
														/>
														<i
															className="fa fa-floppy-o"
															type="submit"
															onClick={submitForm}
														/>
													</form>
												);
											}}
										</Form>
									) : (
										this.props.message.name
									)}
								</span>
							</div>
						</a>
						{this.state.editMode ? null : (
							<span>
								<i
									className="fas fa-users"
									style={{
										fontSize: 13,
										color: this.userIsOnMessage() ? "orange" : "black"
									}}
									data-for={`tooltip-${this.props.message.id}`}
									data-tip=""
									onClick={() => {
										if (globalStore.userType === "Architect") {
											globalStore.assignMessageModalOpen = true;
											globalStore.assignMessageID = this.props.message.id;
										} else {
											if (this.userIsOnMessage()) {
												API(
													`/message/removeFromMessage/${this.props.message.id}`,
													"POST",
													{},
													data => {
														this.generateUsersTooltip(data.usersOnMessage);
													}
												);
											} else {
												API(
													`/message/addToMessage/${this.props.message.id}`,
													"POST",
													{},
													data => {
														this.generateUsersTooltip(data.usersOnMessage);
													}
												);
											}
										}
									}}
								/>
								<ReactTooltip
									multiline={true}
									id={`tooltip-${this.props.message.id}`}
									html={true}
								>
									{this.state.tooltipText}
								</ReactTooltip>
							</span>
						)}
					</div>
					{this.props.createMode && !this.state.editMode ? (
						<span>
							<i
								className="fas fa-plus"
								onClick={this.props.create.bind(
									this,
									"Message",
									this.props.parentFolderID
								)}
							/>
							<i
								className="fas fa-minus"
								onClick={this.props.delete.bind(
									this,
									"Message",
									this.props.message.id
								)}
							/>
							{this.props.message.archived ? (
								<i
									className="fas fa-file-archive"
									onClick={() => {
										API(
											`/message/${this.props.message.id}/unarchive`,
											"POST",
											{},
											data => {
												this.props.refreshParent();
											}
										);
									}}
								/>
							) : (
								<i
									className="fas fa-archive"
									onClick={() => {
										API(
											`/message/${this.props.message.id}/archive`,
											"POST",
											{},
											data => {
												this.props.refreshParent();
											}
										);
									}}
								/>
							)}
							{globalStore.messagesToMove.find(message => {
								return message.id === this.props.message.id;
							}) || globalStore.folderToMove ? null : (
								<i
									className="fas fa-person-dolly-empty"
									onClick={() => {
										globalStore.messagesToMove.push({
											id: this.props.message.id,
											name: this.props.message.name
										});
									}}
								/>
							)}
							<i className="fas fa-pencil-alt" onClick={this.toggleEdit} />
						</span>
					) : null}
					{this.state.showChildrenElements ? this.props.children : null}
				</div>
			);
		}
	}
);
