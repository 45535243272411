import React, { Component } from "react";
import autoBind from "react-autobind";
import API from "../../../API";
import { observer } from "mobx-react";
import camelcase from "camel-case";
import Loading from "../../structure/Loading";
import { toJS } from "mobx";

export default observer(
	class extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				tailoredContent: null,
				loading: false,
				zoom: 0.5,
				contentDiv: null
			};

			this.videoAPI =
				process.env.REACT_APP_ENV === "local"
					? "http://localhost:8000"
					: "https://video.tuzagtailor.com";
		}

		componentWillReceiveProps(nextProps) {
			this.setState({ tailoredContent: null });
		}

		loadData() {
			this.setState({ loading: true });
			API(
				`${this.videoAPI}/generateVideo`,
				"POST",
				{
					bypassMapping: true,
					...toJS(this.props.pageStore.selectedValues)
				},
				data => {
					this.setState({
						loading: false,
						contentDiv: (
							<video controls autoPlay style={{ width: "100%" }}>
								<source
									src={`${this.videoAPI}/video/${data.videoID}`}
									type="video/mp4"
								/>
							</video>
						)
					});
				},
				false
			);
		}

		render() {
			return (
				<div>
					<h1 className="colName">Preview:</h1>
					{this.state.loading ? (
						<Loading />
					) : (
						<div>
							<a onClick={this.loadData} id="showPreviewButton">
								Show preview
							</a>
							{this.state.contentDiv}
						</div>
					)}
				</div>
			);
		}
	}
);
