import React, { Component } from "react";
import autoBind from "react-autobind";
import { observer } from "mobx-react";
import { pageStore } from "../../../message/usedAttributes/UsedAttributesContainer";
import shortid from "shortid";
import { extendObservable } from "mobx";
import globalStore from "../../../../GlobalStore";

export default observer(
	class AvailableAttributeGroups extends Component {
		constructor(props) {
			super(props);
			autoBind(this);
		}

		add(group) {
			const eligibleAttsToAdd = group.attributes.reduce((result, attribute) => {
				//don't add duplicate attributes that have already been added
				for (const usedAttribute of pageStore.usedAttributes) {
					if (usedAttribute.attributeID === attribute.id) {
						//check to see if the attributeID has already been used
						return result; //if it has it's not eligible to be added
					}
				}

				if (attribute.type === "Direct" && attribute.options) {
					//filter out direct attributes that don't have options
					let defaultFound = false;

					//also filter out attributes that don't have a default option
					attribute.options.map(option => {
						if (option.defaultOption === true) {
							defaultFound = true;
						}
						return null;
					});

					if (defaultFound) {
						result.push(attribute);
					}
				} else if (attribute.type !== "Direct") {
					//add all other kinds of attribute types
					result.push(attribute);
				}
				return result;
			}, []);

			if (eligibleAttsToAdd) {
				eligibleAttsToAdd.map(attribute => {
					pageStore.usedAttributes.push(
						extendObservable(attribute, {
							defaultOptionValue: null,
							defaultOptionID: null,
							id: shortid.generate(),
							attributeID: attribute.id,
							order: pageStore.usedAttributes.length - 1
						})
					);
					return null;
				});
				pageStore.showSave = true;
			}

			if (
				pageStore.usedAttributes[0] &&
				(!("attributeID" in pageStore.usedAttributes[0]) ||
					pageStore.usedAttributes[0].attributeID === null)
			) {
				pageStore.usedAttributes.splice(0, 1); //remove the first attribute if it's an empty object
			}
		}

		render() {
			return globalStore.userType === "Read Only" ||
				(globalStore.message && globalStore.message.status === "Published") ||
				globalStore.userType === "Developer" ||
				globalStore.userType === "Reviewer" ? null : (
				<div id="availableAttributeGroups">
					<h2>Available Attribute Groups</h2>
					<div style={{ maxHeight: 300, overflowY: "auto" }}>
						{pageStore.availableAttributes &&
							pageStore.availableAttributes.map(group => {
								return (
									<div key={group.id}>
										<h3>{group.name}</h3>
										<a onClick={this.add.bind(null, group)}>add all...</a>
									</div>
								);
							})}
					</div>
				</div>
			);
		}
	}
);
