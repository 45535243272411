import React, { Component } from "react";
import autoBind from "react-autobind";
import classNames from "classnames";
import { Form, Text } from "react-form";
import globalStore from "../../GlobalStore";
import { observer } from "mobx-react";
import API from "../../API";

export default observer(
	class Folder extends Component {
		constructor(props) {
			super(props);

			autoBind(this);

			this.state = {
				open: "open" in props ? props.open : false,
				editMode: false
			};
		}

		componentWillReceiveProps(nextProps) {
			if (this.props.open !== nextProps.open) {
				this.setState({ open: nextProps.open });
			}
		}

		toggleFolderOpen(event) {
			if (this.props.rootFolder) {
				this.props.hideRootFolder(this.props.folder);
			} else {
				this.setState({ open: !this.state.open });
			}
		}

		toggleEdit() {
			this.setState({ editMode: true });
		}

		render() {
			return (
				<div className="folder">
					<span
						className={classNames(
							"folderRow",
							this.props.className,
							this.state.editMode ? "editModeOn" : null
						)}
						onClick={this.toggleFolderOpen}
						title={`ID: ${this.props.folder.id}`}
					>
						{(this.props.rootFolder && this.props.open) || this.state.open ? (
							<i className="fas fa-folder-open" />
						) : (
							<i className="fas fa-folder" />
						)}
						<span className="name">
							{this.state.editMode ? (
								<Form
									values={{
										name: this.props.folder.name
									}}
									validate={values => {
										return {
											name: !values.name ? "A name is required" : undefined
										};
									}}
									onSubmit={values => {
										this.props.edit(
											"Folder",
											values.name,
											this.props.folder.id
										);
										this.setState({
											editMode: false
										});
									}}
									className="editForm"
								>
									{({ values, submitForm }) => {
										return (
											<form onSubmit={submitForm}>
												<Text
													field="name"
													placeholder={"Folder name goes here..."}
												/>
												<i
													className="fa fa-floppy-o"
													type="submit"
													onClick={submitForm}
												/>
											</form>
										);
									}}
								</Form>
							) : (
								this.props.folder.name
							)}
						</span>
						{this.props.rootFolder || this.state.editMode ? null : this.state
							.open ? (
							<i className="far fa-caret-square-up" />
						) : (
							<i className="far fa-caret-square-down" />
						)}
					</span>
					<span>
						{globalStore.messagesToMove.length > 0 ? (
							<i
								className="fas fa-person-dolly"
								onClick={() => {
									this.props.refreshParent();
									API(
										`/moveMessage/${this.props.folder.id}`,
										"POST",
										{ messagesToMove: globalStore.messagesToMove },
										data => {
											globalStore.messagesToMove = [];
										}
									);
								}}
							/>
						) : null}
					</span>
					{this.props.createMode && !this.state.editMode ? (
						<span>
							<i
								className="fas fa-plus"
								onClick={this.props.create.bind(
									this,
									"Folder",
									this.props.folder.id,
									this.props.rootFolder
								)}
							/>
							{/*{this.props.rootFolder && !localStorage.superAdmin ? null : (*/}
							{/*<span>*/}
							{/*<i*/}
							{/*className="fas fa-minus"*/}
							{/*onClick={this.props.delete.bind(*/}
							{/*this,*/}
							{/*"Folder",*/}
							{/*this.props.folder.id*/}
							{/*)}*/}
							{/*/>*/}
							{/*<i className="fa fa-pencil" onClick={this.toggleEdit} />*/}
							{/*</span>*/}
							{/*)}*/}
							{globalStore.messagesToMove.length === 0 &&
							!globalStore.folderToMove &&
							!this.props.rootFolder ? (
								<i
									className="fas fa-person-dolly-empty"
									onClick={() => {
										globalStore.folderToMove = {
											id: this.props.folder.id,
											name: this.props.folder.name
										};
									}}
								/>
							) : null}
							{globalStore.folderToMove &&
							globalStore.folderToMove.id !== this.props.folder.id ? (
								<i
									className="fas fa-person-dolly"
									onClick={() => {
										this.props.refreshParent();
										API(
											`/moveFolder/${globalStore.folderToMove.id}/${
												this.props.folder.id
											}`,
											"POST",
											{},
											data => {
												globalStore.folderToMove = null;
											}
										);
									}}
								/>
							) : null}
						</span>
					) : null}
					{this.state.open || this.props.rootFolder ? (
						<div className="contents">{this.props.children}</div>
					) : null}
				</div>
			);
		}
	}
);
