import React, { Component } from "react";
import autoBind from "react-autobind";
import Folder from "./Folder";
import Message from "./Message";
import MessageSearchResult from "./MessageSearchResult";
import API from "../../API";
import Loading from "../structure/Loading";
import Element from "./Element";
// import ReactTooltip from "react-tooltip";
import Mousetrap from "mousetrap";
import globalStore from "../../GlobalStore";
import { observer } from "mobx-react";

export default observer(
	class MessageBrowser extends Component {
		constructor(props) {
			super();
			autoBind(this);

			this.state = {
				foldersAndMessages: null,
				toggleAll: false,
				searchText: "",
				searchResults: null,
				messageHistory: null,
				highlightedMessageIndex: null,
				highlightedElementIndex: null,
				hideArchived: true
			};
		}

		componentWillReceiveProps(nextProps) {
			//copy the open status of the old folders into the new folders
			const recursivelySetOpen = newFolders => {
				newFolders.folders = newFolders.folders.map(newFolder => {
					const oldFolder = this.state.foldersAndMessages.folders.find(
						oldFolder => {
							return oldFolder.id === newFolder.id;
						}
					);
					if (oldFolder) {
						newFolder.open = oldFolder.open;

						if (newFolder.subfolders) {
							newFolder.folders = recursivelySetOpen(newFolder.folders);
						}
					}

					return newFolder;
				});

				return newFolders;
			};

			if (
				nextProps.newData !== null &&
				nextProps.newData !== undefined &&
				Object.keys(nextProps).length !== 0
			) {
				let newFolders = nextProps.newData;

				newFolders = recursivelySetOpen(newFolders);

				this.setState({ foldersAndMessages: newFolders });
			}
		}

		refresh() {
			delete sessionStorage.foldersAndMessages;

			this.setState(
				{
					foldersAndMessages: null
				},
				() => {
					this.getData();
				}
			);
		}

		getData() {
			if (
				sessionStorage.foldersAndMessages &&
				this.props.manageMessageScreen !== true
			) {
				this.setState({
					foldersAndMessages: JSON.parse(sessionStorage.foldersAndMessages)
				});
			} else {
				API(`/foldersAndMessages`, "GET", null, foldersAndMessages => {
					this.setState({
						foldersAndMessages
					});

					delete sessionStorage.foldersAndMessages;
					// sessionStorage.foldersAndMessages = JSON.stringify(
					// 	foldersAndMessages
					// );
				});
			}
		}

		componentDidMount() {
			this.getData();

			if (this.props.topbar) {
				API("/messageOpenHistory", "GET", {}, data => {
					this.setState({
						messageHistory: data.messageOpenHistory
					});
				});
			}

			if (this.props.linkElementPanel || this.props.topbar) {
				Mousetrap.bind("enter", event => {
					event.preventDefault();
					if (
						this.props.topbar &&
						this.state.searchText &&
						!isNaN(this.state.searchText)
					) {
						globalStore.messageBrowserPanelOpen = false;
						globalStore.history.push(`/message/${this.state.searchText}/`);
					} else {
						if (
							this.state.searchText &&
							document.activeElement.id === "searchInput"
						) {
							this.filterFolders();
							this.searchInput.blur();
						} else if (!this.state.searchText) {
							this.setState({ searchResults: null });
						} else if (this.props.topbar) {
							if (this.state.highlightedElementIndex) {
								const message = this.state.searchResults[
									this.state.highlightedMessageIndex
								];

								const element = this.state.searchResults[
									this.state.highlightedMessageIndex
								].elements[this.state.highlightedElementIndex];

								globalStore.messageBrowserPanelOpen = false;
								globalStore.history.push(
									`/message/${message.id}/elements/${element.id}`
								);
							} else {
								const message = this.state.searchResults[
									this.state.highlightedMessageIndex
								];
								globalStore.messageBrowserPanelOpen = false;
								globalStore.history.push(`/message/${message.id}/`);
							}
						} else if (
							this.props.linkElementPanel &&
							!isNaN(this.state.highlightedElementIndex)
						) {
							const message = this.state.searchResults[
								this.state.highlightedMessageIndex
							];

							const element = this.state.searchResults[
								this.state.highlightedMessageIndex
							].elements[this.state.highlightedElementIndex];

							this.linkElement({
								name: element.name,
								messageName: message.name,
								id: element.id
							});
						}
					}
				});

				Mousetrap.bind("up", event => {
					event.preventDefault();
					if (this.state.searchResults.length > 0) {
						event.preventDefault();

						if (this.state.highlightedMessageIndex - 1 >= 0) {
							this.setState({
								highlightedMessageIndex: this.state.highlightedMessageIndex - 1
							});
						}
					}
				});

				Mousetrap.bind("right", event => {
					event.preventDefault();

					if (this.state.searchResults.length > 0) {
						event.preventDefault();

						if (this.state.highlightedElementIndex === null) {
							this.setState({ highlightedElementIndex: 0 });
						} else if (
							this.state.highlightedElementIndex + 1 <
							this.state.searchResults[this.state.highlightedMessageIndex]
								.elements.length
						) {
							this.setState({
								highlightedElementIndex: this.state.highlightedElementIndex + 1
							});
						}
					}
				});

				Mousetrap.bind("left", event => {
					event.preventDefault();

					if (this.state.searchResults.length > 0) {
						event.preventDefault();

						if (this.state.highlightedElementIndex - 1 < 0) {
							this.setState({
								highlightedElementIndex: null
							});
						} else {
							this.setState({
								highlightedElementIndex: this.state.highlightedElementIndex - 1
							});
						}
					}
				});

				Mousetrap.bind("down", event => {
					event.preventDefault();
					if (this.state.searchResults.length > 0) {
						event.preventDefault();

						if (
							this.state.highlightedMessageIndex + 1 <
							this.state.searchResults.length
						) {
							this.setState({
								highlightedMessageIndex: this.state.highlightedMessageIndex + 1
							});
						}
					}
				});
			} else {
				Mousetrap.bind("enter", event => {
					event.preventDefault();
					this.filterFolders();
				});
			}

			if (this.props.hideDropdown) {
				Mousetrap.bind("esc", event => {
					this.props.hideDropdown();
				});
			}

			Mousetrap.bind(["alt+r"], event => {
				event.preventDefault();
				this.refresh();
			});
		}

		componentWillUnmount() {
			if (this.props.topbar || this.props.linkMessageElement) {
				Mousetrap.unbind(["enter", "up", "down", "left", "right"]);
			} else {
				Mousetrap.unbind(["enter"]);
			}

			if (this.props.hideDropdown) {
				Mousetrap.unbind(["esc"]);
			}

			Mousetrap.unbind(["alt+r"]);
		}

		hideRootFolder(clickedFolder) {
			const folders = this.state.foldersAndMessages.folders.map(folder => {
				if (folder.id === clickedFolder.id) {
					folder.open = !folder.open;
				}
				return folder;
			});

			this.setState({ folderAndMessages: folders });
		}

		linkElement(data) {
			this.props.hideDropdown();
			let selection = this.props.quillReact.getEditorSelection();

			if (!selection) {
				selection = globalStore.range;
			}

			this.props.quillJS.insertEmbed(selection.index, "elementLink", {
				text: `([${data.messageName}]:[${data.name}])`,
				elementid: data.id,
				newLink: true
			});
		}

		subFolderBuilder(folder, rootFolder) {
			let subFolder = (
				<div>
					{this.renderFolders(folder.folders, false)}
					{folder.messages.length === 0 && folder.folders.length === 0 ? (
						<p className="noActiveMessages">
							You have no active messages in this folder
						</p>
					) : null}
					{folder.messages.map(message => {
						return message.archived && this.state.hideArchived ? null : (
							<Message
								key={message.id}
								message={message}
								createMode={this.props.createMode}
								create={this.props.create}
								parentFolderID={folder.id}
								delete={this.props.delete}
								edit={this.props.edit}
								messageSelected={this.props.messageSelected}
								showElements={this.props.linkElementPanel}
								refreshParent={this.props.refreshParent}
							>
								{this.props.linkElementPanel ? (
									<div>
										{message.elements.map(element => {
											return (
												<Element
													message={message}
													element={element}
													key={element.id}
													quillReact={this.props.quillReact}
													quillJS={this.props.quillJS}
													hideDropdown={this.props.hideDropdown}
													linkElement={this.linkElement}
												/>
											);
										})}
									</div>
								) : null}
							</Message>
						);
					})}
				</div>
			);

			if (!this.state.toggleAll && rootFolder && !folder.open) {
				subFolder = null;
			}

			return subFolder;
		}

		renderFolders(folders, rootFolder) {
			return folders.map(folder => {
				return (
					<Folder
						open={rootFolder ? folder.open : this.state.toggleAll}
						folder={folder}
						key={folder.id}
						createMode={this.props.createMode}
						create={this.props.create}
						delete={this.props.delete}
						edit={this.props.edit}
						className={rootFolder ? "rootFolder" : null}
						rootFolder={rootFolder}
						hideRootFolder={this.hideRootFolder}
						refreshParent={this.props.refreshParent}
					>
						{this.subFolderBuilder(folder, rootFolder)}
					</Folder>
				);
			});
		}

		toggleAll() {
			this.setState({ toggleAll: !this.state.toggleAll });
		}

		filterFolders() {
			API(
				"/message/messageBrowserSearch",
				"POST",
				{ searchQuery: this.state.searchText.toLowerCase() },
				data => {
					this.setState({ searchResults: data.messages });
					if (data.messages.length > 0) {
						this.setState({ highlightedMessageIndex: 0 });
						this.searchInput.blur();

						if (this.props.linkElementPanel) {
							this.setState({ highlightedElementIndex: 0 });
						}
					}
				}
			);
		}

		render() {
			return (
				<div
					className={"messageBrowser" + (this.props.topbar ? " topbar" : "")}
				>
					<div>
						{this.props.hideDropdown ? (
							<div className="text-right">
								<i
									onClick={this.props.hideDropdown}
									className="far fa-window-close"
								/>
							</div>
						) : null}
						<div className="browserCol">
							{globalStore.messagesToMove.length > 0 ? (
								<div>
									Moving Messages:{" "}
									{globalStore.messagesToMove.map((message, index) => {
										const messageName = message.name;
										return (
											<span key={message.id}>
												<a
													onClick={() => {
														globalStore.messagesToMove.splice(index, 1);
													}}
													key={message.id}
												>
													{messageName}
												</a>
												{globalStore.messagesToMove.length - 1 !== index
													? ", "
													: null}
											</span>
										);
									})}
								</div>
							) : null}

							{globalStore.folderToMove ? (
								<div>
									Moving Folder:{" "}
									<a
										onClick={() => {
											globalStore.folderToMove = null;
										}}
									>
										{globalStore.folderToMove.name}
									</a>
								</div>
							) : null}
							<input
								id="searchInput"
								className="mousetrap"
								type="text"
								value={this.state.searchText}
								onChange={event => {
									this.setState({ searchText: event.target.value });
								}}
								placeholder="search"
								autoFocus={true}
								style={{ width: "100%", outline: "none" }}
								ref={el => {
									this.searchInput = el;
								}}
							/>
							<div id="folderNavigator">
								<br />
								{this.state.searchResults ? (
									this.state.searchResults.map((message, index) => {
										return (
											<div key={message.id}>
												<MessageSearchResult
													key={message.id}
													message={message}
													showElements={this.state.searchResults.length < 4}
													linkElementPanel={this.props.linkElementPanel}
													linkMessageElement={this.props.linkMessageElement}
													highlighted={
														this.state.highlightedMessageIndex === index
													}
													messageSelected={this.props.messageSelected}
												>
													{!this.props.manageMessageScreen &&
													!this.props.copyFromExisting &&
													(this.props.linkElementPanel ||
														this.state.searchResults.length < 4) ? (
														<ul>
															{message.elements.map((element, elementIndex) => {
																return (
																	<Element
																		message={message}
																		element={element}
																		key={element.id}
																		quillReact={this.props.quillReact}
																		quillJS={this.props.quillJS}
																		hideDropdown={this.props.hideDropdown}
																		linkElement={this.linkElement}
																		topBar={this.props.topbar}
																		highlighted={
																			this.state.highlightedElementIndex ===
																				elementIndex &&
																			this.state.highlightedMessageIndex ===
																				index
																		}
																	/>
																);
															})}
														</ul>
													) : null}
												</MessageSearchResult>
												{/*<ReactTooltip*/}
												{/*place="right"*/}
												{/*id={message.id.toString()}*/}
												{/*getContent={() => (*/}
												{/*<TooltipContent messageID={message.id} />*/}
												{/*)}*/}
												{/*className="react-tooltip"*/}
												{/*/>*/}
											</div>
										);
									})
								) : this.state.foldersAndMessages ? (
									<div>
										<a onClick={this.toggleAll}>
											{this.state.toggleAll ? "Collapse All" : "Expand All"}
										</a>{" "}
										|{"   "}
										<i
											onClick={this.refresh}
											className="far fa-sync"
											style={{ fontSize: 14 }}
										/>
										|{" "}
										{this.state.hideArchived ? (
											<i
												className="fas fa-archive"
												onClick={() => {
													this.setState({ hideArchived: false });
												}}
												style={{ fontSize: 16 }}
											/>
										) : (
											<i
												onClick={() => {
													this.setState({ hideArchived: true });
												}}
												className="fas fa-archive"
												style={{ color: "#f7ab13", fontSize: 16 }}
											/>
										)}
										{this.renderFolders(
											this.state.foldersAndMessages.folders,
											true
										)}
									</div>
								) : (
									<Loading />
								)}
							</div>
						</div>
						{this.props.topbar && this.state.messageHistory ? (
							<div className="recentCol">
								<h4>Recent Messages</h4>
								{this.state.messageHistory.map(message => {
									if (message) {
										return (
											<a
												style={{ display: "block" }}
												href={`/message/${message.messageID}/`}
												key={message.id}
											>
												{message.name}
											</a>
										);
									} else {
										return null;
									}
								})}
							</div>
						) : null}
					</div>
				</div>
			);
		}
	}
);

// class TooltipContent extends Component {
// 	constructor(props) {
// 		super(props);
//
// 		this.state = {
// 			path: null
// 		};
// 	}
//
// 	componentDidMount() {
// 		if (this.props.messageID) {
// 			this.timeout = setTimeout(() => {
// 				API(`/messageFolderPath/${this.props.messageID}`, "GET", null, data => {
// 					let pathAsString = "";
// 					if (data.messagePath.length === 2) {
// 						pathAsString = data.messagePath[1];
// 					} else if (data.messagePath.length === 3) {
// 						pathAsString = data.messagePath[1] + " : " + data.messagePath[2];
// 					} else {
// 						pathAsString =
// 							data.messagePath[1] +
// 							(data.messagePath.length === 4 ? " : " : " ... ") +
// 							data.messagePath[data.messagePath.length - 2] +
// 							" : " +
// 							data.messagePath[data.messagePath.length - 1];
// 					}
//
// 					this.setState({
// 						path: pathAsString
// 					});
// 				});
// 			}, 1400);
// 		}
// 	}
//
// 	componentWillUnmount() {
// 		clearTimeout(this.timeout);
// 	}
//
// 	render() {
// 		return this.state.path;
// 	}
// }
