import React, { Component } from "react";
import autoBind from "react-autobind";
import { Table } from "react-bootstrap";
import Loading from "../../structure/Loading";
import API from "../../../API";
import { observable } from "mobx";
import { observer } from "mobx-react";
import Select from "react-select-plus";
import { Row, Col } from "react-bootstrap";

export const pageStore = new observable({
	users: []
});

export default observer(
	class ManageUsers extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = { addUserName: "", addUserType: null, addUserEmail: "" };
		}

		componentDidMount() {
			API("/users", "GET", null, data => {
				pageStore.users = data.users;
			});
		}

		render() {
			return pageStore.users === null ? (
				<Loading />
			) : pageStore.users.length === 0 ? (
				<p>Huh. No users. Not even yourself. How'd you manage that?</p>
			) : (
				<div id="manageUsers">
					<h1 className="pageName">Manage Users</h1>
					<p>Click to edit name & email fields</p>
					<Table striped bordered hover>
						<thead>
							<tr>
								<th>Name</th>
								<th>Type</th>
								<th>Email</th>
								<th>Password</th>
								<th>Delete</th>
							</tr>
						</thead>
						<tbody>
							{pageStore.users.map(user => {
								return <UserRow key={user.id} user={user} />;
							})}
						</tbody>
					</Table>

					<h3>Add User</h3>
					<form
						onSubmit={evt => {
							evt.preventDefault();

							API(
								"/user",
								"POST",
								{
									name: this.state.addUserName,
									email: this.state.addUserEmail,
									type: this.addUserType
								},
								data => {
									pageStore.users = data.users;
									this.setState({
										addUserName: "",
										addUserType: null,
										addUserEmail: ""
									});
								}
							);
						}}
					>
						<Row>
							<Col xs={3}>
								<p>Name:</p>
								<input
									type="text"
									value={this.state.addUserName}
									onChange={evt => {
										this.setState({ addUserName: evt.target.value });
									}}
									required
									className="form-control"
								/>
								<br />
								<p>Email:</p>
								<input
									type="email"
									value={this.state.addUserEmail}
									onChange={evt => {
										this.setState({ addUserEmail: evt.target.value });
									}}
									required
									className="form-control"
								/>

								<br />
								<p>Type:</p>
								<Select
									value={this.state.addUserType}
									onChange={value => {
										this.setState({ addUserType: value });
									}}
									clearable={false}
									options={[
										"Architect",
										"Writer",
										"Reviewer",
										"Developer",
										"Read Only"
									].map(option => {
										return { value: option, label: option };
									})}
								/>
							</Col>
						</Row>
						<br />
						<Row>
							<Col xs={3} className="text-center">
								<button
									type="submit"
									className="saveButton"
									style={{ display: "inline" }}
								>
									Add User
								</button>
							</Col>
						</Row>
					</form>
				</div>
			);
		}
	}
);

const UserRow = observer(
	class UserRow extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				editName: false,
				editEmail: false,
				editPassword: false,
				passwordText: ""
			};
		}

		render() {
			let userTypeOptions = [
				"Architect",
				"Writer",
				"Reviewer",
				"Developer",
				"Read Only"
			];

			if (this.props.user.type === "Revoked") {
				userTypeOptions.push("Revoked");
			}

			return (
				<tr key={this.props.user.id}>
					<td
						onClick={() => {
							if (this.state.editName === false) {
								this.setState({ editName: true });
							}
						}}
					>
						{this.state.editName ? (
							<input
								type="text"
								value={this.props.user.displayName}
								onChange={evt => {
									this.props.user.displayName = evt.target.value;
								}}
								onKeyPress={evt => {
									if (evt.key === "Enter") {
										this.setState({ editName: false });

										API(`/users/${this.props.user.id}`, "PUT", {
											displayName: this.props.user.displayName
										});
									}
								}}
								className="form-control"
							/>
						) : (
							this.props.user.displayName
						)}
					</td>
					<td>
						<Select
							disabled={this.props.user.type === "Revoked"}
							value={this.props.user.type}
							onChange={value => {
								this.props.user.type = value.value;

								API(`/users/${this.props.user.id}`, "PUT", {
									type: this.props.user.type
								});
							}}
							clearable={false}
							options={userTypeOptions.map(option => {
								return { value: option, label: option };
							})}
						/>
					</td>
					<td
						onClick={() => {
							if (this.state.editEmail === false) {
								this.setState({ editEmail: true });
							}
						}}
					>
						{this.state.editEmail ? (
							<input
								type="text"
								value={this.props.user.email}
								onChange={evt => {
									this.props.user.email = evt.target.value;
								}}
								onKeyPress={evt => {
									if (evt.key === "Enter") {
										this.setState({ editEmail: false });

										API(`/users/${this.props.user.id}`, "PUT", {
											email: this.props.user.email
										});
									}
								}}
								className="form-control"
							/>
						) : (
							this.props.user.email
						)}
					</td>
					{this.props.user.type !== "Revoked" ? (
						<td>
							{this.state.editPassword ? (
								<input
									type="text"
									value={this.state.passwordText}
									onChange={evt => {
										this.setState({ passwordText: evt.target.value });
									}}
									onKeyPress={evt => {
										if (evt.key === "Enter") {
											API(
												`/users/${this.props.user.id}`,
												"PUT",
												{
													password: this.state.passwordText
												},
												() => {
													this.setState({
														editPassword: false,
														passwordText: ""
													});
												}
											);
										}
									}}
									className="form-control"
								/>
							) : (
								<a
									onClick={() => {
										this.setState({ editPassword: true });
									}}
								>
									Change
								</a>
							)}
						</td>
					) : (
						<td />
					)}
					<td>
						{this.props.user.type !== "Revoked" ? (
							<a
								onClick={() => {
									if (
										window.confirm(
											"Are you sure? You're about to remove tD access, and that would make anyone sad."
										)
									) {
										API(`/users/${this.props.user.id}`, "DELETE", {}, data => {
											pageStore.users = data.users;
											alert(
												"User deleted. It may take thirty minutes for their access to be revoked."
											);
										});
									}
								}}
							>
								Delete
							</a>
						) : null}
					</td>
				</tr>
			);
		}
	}
);
