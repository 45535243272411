import React from "react";
import { FormInput } from "react-form";
import Phone from "react-phone-number-input";

const ReactFormPhone = ({ field, ...rest }) => {
	return (
		<FormInput field={field}>
			{({ setValue, getValue, setTouched }) => {
				return (
					<Phone
						placeholder="Phone Number"
						value={getValue()}
						countries={["US"]}
						onChange={val => setValue(val)}
					/>
				);
			}}
		</FormInput>
	);
};

export default ReactFormPhone;
