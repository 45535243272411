import React, { Component } from "react";
import autoBind from "react-autobind";
import MessageBrowser from "../messageBrowser/MessageBrowser";

export default class SelectMessage extends Component {
	constructor() {
		super();
		autoBind(this);
	}

	render() {
		return (
			<div id="selectMessage">
				<h1 className="pageName">Select Message</h1>
				<MessageBrowser />
			</div>
		);
	}
}
