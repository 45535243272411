import React, { Component } from "react";
import autoBind from "react-autobind";
import { Row, Col, Table } from "react-bootstrap";
import API from "../../API";
import { Link } from "react-router-dom";
import Loading from "../structure/Loading";
import MessageStatus from "../MessageStatus";
import { observer } from "mobx-react";
import globalStore from "../../GlobalStore";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import shortid from "shortid";
import Snackbar from "../structure/Snackbar";

export default observer(
	class Dashboard extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				messageHistory: [],
				loading: true,
				loadingAssigned: true,
				assignedMessages: [],
				notifications: null,
				failResponseMessages: null,
				snackbarMessage: "",
				lastSaveID: ""
			};
		}

		componentWillMount() {
			API("/messageOpenHistory", "GET", {}, data => {
				this.setState({
					messageHistory: data.messageOpenHistory,
					loading: false
				});
			});

			API(`/assignedMessages`, "GET", {}, data => {
				this.setState({
					assignedMessages: data.messages,
					loadingAssigned: false
				});
			});

			API("/commentNotifications", "GET", {}, data => {
				this.setState({ notifications: data.notifications });
			});

			API("/messagesWithFailResponses", "GET", {}, data => {
				this.setState({
					failResponseMessages: data.messages.filter(message => {
						return message !== null;
					})
				});
			});

			setTimeout(() => {
				if (!globalStore.firebaseUser) {
					this.props.history.push("/login");
				}
			}, 1000);
		}

		createMarkup(content) {
			return { __html: content };
		}

		getNotificationRow(notification) {
			if (notification.messageCommentID) {
				return (
					<tr key={notification.id}>
						<td style={{ textAlign: "center" }}>
							<a
								onClick={() => {
									API(
										`/markNotificationRead/${notification.id}`,
										"POST",
										{},
										data => {
											API("/commentNotifications", "GET", {}, data => {
												this.setState({ notifications: data.notifications });
											});
										}
									);
								}}
							>
								<i className="fas fa-comment-alt-check" />
							</a>
							{"  "}
							<i
								className="fas fa-calendar-alt"
								data-tip={moment(notification.messageComment.createdAt).format(
									"h:mm a, M/D/YY"
								)}
								data-for={`tooltipData-${notification.id}`}
							/>
							<ReactTooltip id={`tooltipData-${notification.id}`} />
						</td>
						<td>
							<a
								onClick={() => {
									this.props.history.push(
										`/message/${notification.messageComment.message.id}/`
									);
								}}
							>
								<p style={{ display: "inline" }}>
									{notification.messageComment.message.name}
								</p>
							</a>
							{"  "}
							<a
								onClick={() => {
									this.props.history.push(
										`/message/${notification.messageComment.message.id}/`
									);
									globalStore.commentsModalOpen = true;
									globalStore.commentsMessageID =
										notification.messageComment.message.id;
								}}
							>
								<i className="fas fa-comments" />
							</a>
						</td>
						<td>
							<p>{notification.messageComment.user.displayName}</p>
						</td>
						<td
							dangerouslySetInnerHTML={this.createMarkup(
								notification.messageComment.content
							)}
						/>
					</tr>
				);
			} else if (notification.elementCommentID) {
				return (
					<tr key={notification.id}>
						<td style={{ textAlign: "center" }}>
							<a
								onClick={() => {
									API(
										`/markNotificationRead/${notification.id}`,
										"POST",
										{},
										data => {
											API("/commentNotifications", "GET", {}, data => {
												this.setState({ notifications: data.notifications });
											});
										}
									);
								}}
							>
								<i className="fas fa-comment-alt-check" />
							</a>
							{"  "}
							<i
								className="fas fa-calendar-alt"
								data-tip={moment(notification.elementComment.createdAt).format(
									"h:mm a, M/D/YY"
								)}
								data-for={`tooltipData-${notification.id}`}
							/>
							<ReactTooltip id={`tooltipData-${notification.id}`} />
						</td>
						<td>
							<a
								onClick={() => {
									this.props.history.push(
										`/message/${
											notification.elementComment.element.message.id
										}/elements/${notification.elementComment.element.id}`
									);
								}}
							>
								<p style={{ display: "inline" }}>
									{notification.elementComment.element.message.name} :{" "}
									{notification.elementComment.element.name}
								</p>
							</a>
							{"  "}
							<a
								onClick={() => {
									this.props.history.push(
										`/message/${
											notification.elementComment.element.message.id
										}/elements/${notification.elementComment.element.id}`
									);
									globalStore.commentsModalOpen = true;
									globalStore.commentsElementID =
										notification.elementComment.element.id;
								}}
							>
								<i className="fas fa-comments" />
							</a>
						</td>
						<td>
							<p>{notification.elementComment.user.displayName}</p>
						</td>
						<td
							dangerouslySetInnerHTML={this.createMarkup(
								notification.elementComment.content
							)}
						/>
					</tr>
				);
			} else if (notification.bitCaseCommentID) {
				return (
					<tr key={notification.id}>
						<td style={{ textAlign: "center" }}>
							<a
								onClick={() => {
									API(
										`/markNotificationRead/${notification.id}`,
										"POST",
										{},
										data => {
											API("/commentNotifications", "GET", {}, data => {
												this.setState({ notifications: data.notifications });
											});
										}
									);
								}}
							>
								<i className="fas fa-comment-alt-check" />
							</a>
							{"  "}
							<i
								className="fas fa-calendar-alt"
								data-tip={moment(notification.bitCaseComment.createdAt).format(
									"h:mm a, M/D/YY"
								)}
								data-for={`tooltipData-${notification.id}`}
							/>
							<ReactTooltip id={`tooltipData-${notification.id}`} />
						</td>
						<td>
							<p style={{ display: "inline" }}>
								<a
									onClick={() => {
										this.props.history.push(
											`/message/${
												notification.bitCaseComment.bitCase.bit.element.message
													.id
											}/elements/${
												notification.bitCaseComment.bitCase.bit.element.id
											}/bit/${notification.bitCaseComment.bitCase.bit.id}`
										);
									}}
								>
									{notification.bitCaseComment.bitCase.bit.element.message.name}{" "}
									: {notification.bitCaseComment.bitCase.bit.element.name} :
									Case #
									{parseInt(notification.bitCaseComment.bitCase.order, 10) + 1}
								</a>
							</p>{" "}
							<a
								onClick={() => {
									this.props.history.push(
										`/message/${
											notification.bitCaseComment.bitCase.bit.element.message.id
										}/elements/${
											notification.bitCaseComment.bitCase.bit.element.id
										}/bit/${notification.bitCaseComment.bitCase.bit.id}`
									);
									globalStore.commentsModalOpen = true;
									globalStore.commentsBitCaseID =
										notification.bitCaseComment.bitCase.id;
								}}
							>
								<i className="fas fa-comments" />
							</a>
						</td>
						<td>
							<p>{notification.bitCaseComment.user.displayName}</p>
						</td>
						<td
							dangerouslySetInnerHTML={this.createMarkup(
								notification.bitCaseComment.content
							)}
						/>
					</tr>
				);
			}
		}

		render() {
			return (
				<div id="recentMessages">
					<h1>
						Welcome back
						{globalStore.firebaseUser
							? `, ${globalStore.firebaseUser.displayName}`
							: null}
						!
					</h1>
					<Row>
						<Col xs={12}>
							<h3>Unread Comments</h3>
							{this.state.notifications === null ? (
								<Loading />
							) : this.state.notifications.length === 0 ? (
								<p>
									You're all caught up!{" "}
									<span role="img" aria-label="whiskey">
										🥃
									</span>{" "}
									time?
								</p>
							) : (
								<Table striped bordered hover>
									<thead>
										<tr>
											<th />
											<th>Message</th>
											<th>By</th>
											<th>Comment</th>
										</tr>
									</thead>
									<tbody>
										{this.state.notifications.map(notification => {
											return this.getNotificationRow(notification);
										})}
									</tbody>
								</Table>
							)}
						</Col>
					</Row>
					<Row>
						<Col xs={6} style={{ maxHeight: 525, overflowY: "auto" }}>
							<h3>Assigned Messages</h3>
							<Row>
								<Col xs={8} className="topRow">
									<h1 className="colName">Message</h1>
								</Col>
								<Col xs={4} className="topRow">
									<h1 className="colName">Status</h1>
								</Col>
							</Row>
							{this.state.assignedMessages.length !== 0 ? (
								this.state.assignedMessages.map(message => {
									if (message) {
										return <MessageRow message={message} key={message.id} />;
									}
									return null;
								})
							) : (
								<div>
									{this.state.loadingAssigned ? (
										<div className="text-center">
											<Loading />
										</div>
									) : (
										<p>
											You currently are not assigned to any messages.{" "}
											<span role="img" aria-label="beer">
												🍺
											</span>{" "}
											time?
										</p>
									)}
								</div>
							)}
						</Col>
						<Col xs={6} style={{ maxHeight: 525, overflowY: "auto" }}>
							<h3>Recent Messages</h3>
							<Row>
								<Col xs={8} className="topRow">
									<h1 className="colName">Message</h1>
								</Col>
								<Col xs={4} className="topRow">
									<h1 className="colName">Status</h1>
								</Col>
							</Row>
							{this.state.messageHistory.length !== 0 ? (
								this.state.messageHistory.map(message => {
									if (message) {
										return <MessageRow message={message} key={message.id} />;
									}
									return null;
								})
							) : (
								<div>
									{this.state.loadingAssigned ? (
										<div className="text-center">
											<Loading />
										</div>
									) : (
										<p>
											No recent messages to show. You should go open one so this
											section isn't empty.
										</p>
									)}
								</div>
							)}
						</Col>
					</Row>
					<Row>
						<Col xs={6} style={{ maxHeight: 525, overflowY: "auto" }}>
							<h3>Fail Response Inbox</h3>
							{this.state.failResponseMessages === null ? (
								<Loading />
							) : this.state.failResponseMessages.length === 0 ? (
								<p>
									You're all caught up.{" "}
									<span role="img" aria-label="wine">
										🍷
									</span>{" "}
									time? Or I guess you can settle for making Alexa better.
									<br />
									<a
										onClick={() => {
											API("/amazonSync", "GET", {}, data => {
												this.setState({
													snackbarMessage: "Sync In Progress",
													lastSaveID: shortid.generate()
												});
											});
										}}
										className="saveButton"
									>
										Sync To Amazon
									</a>
								</p>
							) : (
								this.state.failResponseMessages.map(message => {
									return (
										<div
											key={message.id}
											style={{ marginTop: 5, marginBottom: 5 }}
										>
											<a href={`/message/${message.id}/technical/chat`}>
												{message.name}
											</a>
										</div>
									);
								})
							)}
						</Col>
					</Row>
					<Snackbar
						message={this.state.snackbarMessage}
						lastSaveID={this.state.lastSaveID}
					/>
				</div>
			);
		}
	}
);

class MessageRow extends Component {
	constructor(props) {
		super(props);
		autoBind(this);
	}

	render() {
		return (
			<Row>
				<Col xs={8}>
					<Link
						to={`/message/${
							this.props.message.messageID
								? this.props.message.messageID
								: this.props.message.id
						}/`}
					>
						{this.props.message.name}
					</Link>
				</Col>
				<Col xs={4}>
					<MessageStatus message={this.props.message} />
				</Col>
			</Row>
		);
	}
}
