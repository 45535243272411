import React, { Component } from "react";
import autoBind from "react-autobind";
import API from "../../../API";
import { Row, Col } from "react-bootstrap";
import Loading from "../../structure/Loading";
import firebase from "firebase/app";

export default class PublishKnowledgeBase extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			publishStatus: "",
			publishableMessages: null,
			checkedMessages: []
		};
	}

	componentDidMount() {
		API("/publishStatus", "GET", {}, data => {
			this.setState({ publishStatus: data.message });
			if (
				!this.refreshInterval &&
				data.message &&
				data.message.includes("messages published")
			) {
				this.startInterval();
			}
		});

		API("/publishableMessages", "GET", {}, data => {
			this.setState({
				publishableMessages: data.messages,
				checkedMessages: data.messages.map(message => {
					return message.id;
				})
			});
		});
	}

	startInterval() {
		API("/publishStatus", "GET", {}, data => {
			this.setState({ publishStatus: data.message });
		});

		this.refreshInterval = setInterval(() => {
			API("/publishStatus", "GET", {}, data => {
				this.setState({ publishStatus: data.message });
				if (data.message && data.message.includes("Last publish")) {
					clearInterval(this.refreshInterval);
				}
			});
		}, 5000);
	}

	publish() {
		this.setState({ publishStatus: "Publishing. Standby for progress..." });
		API("/publish", "POST", {}, () => {
			setTimeout(() => {
				if (!this.refreshInterval) {
					this.startInterval();
				}
			}, 1500);
		});
	}

	publishIndividual() {
		this.setState({ publishStatus: "Publishing. Standby for progress..." });
		API(
			"/publish",
			"POST",
			{ midsToPublish: this.state.checkedMessages },
			() => {
				setTimeout(() => {
					if (!this.refreshInterval) {
						this.startInterval();
					}
				}, 1500);
			}
		);
	}

	render() {
		let apiURL;
		if (process.env.NODE_ENV === "development") {
			apiURL = "http://localhost:3000/designMHD";
		} else if (process.env.REACT_APP_ENV === "life365") {
			apiURL = "https://api.staging.tuzagdesign.com/designLife365";
		} else if (process.env.REACT_APP_ENV === "nineZest") {
			apiURL = "https://api.staging.tuzagdesign.com/designnineZest";
		} else if (process.env.REACT_APP_ENV === "vumc2") {
			apiURL = "https://api.staging.tuzagdesign.com/designvumc2";
		} else if (process.env.REACT_APP_ENV === "staging") {
			apiURL = "https://api.staging.tuzagdesign.com/design";
		} else if (process.env.REACT_APP_ENV === "vumc") {
			apiURL = "https://api.vumc.tuzagdesign.com/design";
		} else {
			apiURL = "https://api.v2.tuzagdesign.com/design";
		}
		return (
			<div>
				<Row>
					<Col xs={12}>
						<h3>Publish Status:</h3>
						<p>{this.state.publishStatus}</p>
					</Col>
				</Row>
				<Row>
					<Col>
						<h3>Publish Individual Messages</h3>
					</Col>
				</Row>
				{this.state.publishableMessages === null ? (
					<Loading />
				) : (
					this.state.publishableMessages.map(message => {
						return (
							<Row key={message.id}>
								<Col xs={1}>
									<input
										type="checkbox"
										checked={this.state.checkedMessages.includes(message.id)}
										onChange={evt => {
											if (evt.target.checked) {
												this.setState({
													checkedMessages: [
														...this.state.checkedMessages,
														message.id
													]
												});
											} else {
												this.setState({
													checkedMessages: this.state.checkedMessages.filter(
														checkedMID => {
															return checkedMID !== message.id;
														}
													)
												});
											}
										}}
									/>
								</Col>
								<Col>{message.name}</Col>
							</Row>
						);
					})
				)}
				<Row>
					<Col xs={3}>
						<button
							className="btn btn-primary"
							onClick={this.publishIndividual}
							disabled={
								!this.state.publishStatus.includes("Last publish") &&
								this.state.publishStatus !== "Unknown"
							}
						>
							{this.state.publishStatus.includes("Last publish") ||
							this.state.publishStatus === "Unknown"
								? "Publish Checked Messages"
								: "Publishing..."}
						</button>
					</Col>
				</Row>
				<Row>
					<Col>
						<h3>Publish All Messages</h3>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<button
							className="btn btn-primary"
							onClick={this.publish}
							disabled={
								!this.state.publishStatus.includes("Last publish") &&
								this.state.publishStatus !== "Unknown"
							}
						>
							{this.state.publishStatus.includes("Last publish") ||
							this.state.publishStatus === "Unknown"
								? "Publish Everything"
								: "Publishing..."}
						</button>
					</Col>
				</Row>

				<Row>
					<Col>
						<h3>Export Everything</h3>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						<button
							className="btn btn-primary"
							style={{ marginRight: 25 }}
							onClick={async () => {
								window.open(
									`${apiURL}/exportAll/doc?firebasetoken=${await firebase
										.auth()
										.currentUser.getIdToken(false)}`
								);
							}}
						>
							Word
						</button>
						<button
							className="btn btn-primary"
							onClick={async () => {
								window.open(
									`${apiURL}/exportAll/xls?firebasetoken=${await firebase
										.auth()
										.currentUser.getIdToken(false)}`
								);
							}}
						>
							Excel
						</button>
					</Col>
				</Row>
				<Row>
					<Col xs={6}>
						<p style={{ color: "gray", fontSize: 12 }}>
							Warning, after clicking either export button, be patient. It's
							going to take a while to generate the zip file based off how large
							the database is. Just keep the tab open.
						</p>
					</Col>
				</Row>
			</div>
		);
	}
}
