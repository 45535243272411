import React, { Component } from "react";
import autoBind from "react-autobind";
import { Row, Col } from "react-bootstrap";
import Loading from "../../structure/Loading";
import API from "../../../API";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { observable, extendObservable } from "mobx";
import { observer } from "mobx-react";
import shortid from "shortid";
import Snackbar from "../../structure/Snackbar";
import Sortable from "sortablejs";
import globalStore from "../../../GlobalStore";
import bowser from "bowser";
import debounce from "lodash.debounce";

export const pageStore = new observable({
	availableAttributes: null,
	usedAttributes: null,
	showSave: false
});

export default observer(
	class UsedAttributesContainer extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				snackbarMessage: "",
				searchResults: [],
				quickAdd: ""
			};
		}

		componentDidMount() {
			API(
				`/message/${
					this.props.match.params.messageID
				}/usedAttributesAndOptions`,
				"GET",
				{},
				data => {
					pageStore.usedAttributes = data.usedAttributes
						? data.usedAttributes
						: [];
					if (pageStore.usedAttributes.length === 0) {
						pageStore.usedAttributes.push({ order: 0 });
					}

					this.generateOptions();

					if (
						pageStore.usedAttributes &&
						pageStore.usedAttributes.length !== 0 &&
						!bowser.ios
					) {
						const interval = setInterval(() => {
							const el = document.getElementById("attributeRow");
							if (el) {
								clearInterval(interval);
								Sortable.create(el, {
									onEnd: function(evt) {
										pageStore.usedAttributes[evt.oldIndex].order = evt.newIndex;
										pageStore.usedAttributes = pageStore.usedAttributes
											.sort((a, b) => {
												return a.order >= b.order;
											})
											.map((att, index) => {
												att.order = index;
												return att;
											});
										pageStore.showSave = true;
									}
								});
							}
						}, 500);
					}
				}
			);
		}

		generateOptions() {
			API(`/availableAttributesAndOptions`, "GET", {}, data => {
				pageStore.availableAttributes = data.availableAttributes
					? data.availableAttributes
					: [];

				this.setState({
					options: pageStore.availableAttributes.map(group => {
						return {
							label: group.name,
							groupID: group.id,
							options: group.attributes.reduce((result, attribute) => {
								if (!attribute.passthrough && attribute.options) {
									//filter out direct attributes that don't have options
									let defaultFound = false;

									//also filter out attributes that don't have a default option
									attribute.options.map(option => {
										if (option.defaultOption === true) {
											defaultFound = true;
										}
										return null; //shut up a compiler warning and return anything
									});

									if (defaultFound) {
										result.push({
											label: attribute.name,
											value: attribute.attributeID
												? attribute.attributeID
												: attribute.id,
											isDisabled: !!pageStore.usedAttributes.find(
												usedAttribute => {
													if ("attributeID" in attribute) {
														return (
															usedAttribute.attributeID ===
															attribute.attributeID
														);
													} else {
														return usedAttribute.attributeID === attribute.id;
													}
												}
											)
										});
									} else {
										result.push({
											label: attribute.name,
											value: attribute.attributeID
												? attribute.attributeID
												: attribute.id,
											isDisabled: true
										});
									}
								} else {
									//add all other kinds of attribute types
									result.push({
										label: attribute.name,
										value: attribute.attributeID
											? attribute.attributeID
											: attribute.id,
										isDisabled: !!pageStore.usedAttributes.find(
											usedAttribute => {
												if ("attributeID" in attribute) {
													return (
														usedAttribute.attributeID === attribute.attributeID
													);
												} else {
													return usedAttribute.attributeID === attribute.id;
												}
											}
										)
									});
								}
								return result;
							}, [])
						};
					})
				});
			});
		}

		save() {
			API(
				`/message/${
					this.props.match.params.messageID
				}/usedAttributesAndOptions`,
				"PUT",
				pageStore.usedAttributes,
				data => {
					this.setState({ snackbarMessage: "Saved!" });
					pageStore.showSave = false;
				}
			);
		}

		filterAttributes = debounce(() => {
			if (!this.state.quickAdd || this.state.quickAdd === "") {
				this.setState({ searchResults: [] });
			} else {
				API(
					"/attributeSearch",
					"POST",
					{
						searchTerm: this.state.quickAdd,
						usedAttributes: pageStore.usedAttributes.map(attribute => {
							if (attribute.attributeID) {
								return attribute.attributeID;
							} else {
								return attribute.id;
							}
						})
					},
					data => {
						this.setState({ searchResults: data.results });
					}
				);
			}
		}, 300);

		addAttribute(value) {
			for (const attributeGroup of pageStore.availableAttributes) {
				for (const availableAttributeToAdd of attributeGroup.attributes) {
					if (
						(availableAttributeToAdd.attributeID
							? availableAttributeToAdd.attributeID
							: availableAttributeToAdd.id) === value
					) {
						const index = pageStore.usedAttributes.length;
						//regardless of the type, still set the dropdown to the new value
						pageStore.usedAttributes[index] = extendObservable(
							availableAttributeToAdd,
							{
								defaultOptionValue: null,
								defaultOptionID: null,
								attributeID: availableAttributeToAdd.id
							}
						);
						pageStore.usedAttributes[index].order =
							pageStore.usedAttributes.length;
						pageStore.usedAttributes[index].id = shortid.generate();

						pageStore.showSave = true;
						break;
					}
				}
			}

			let searchResultsCopy = [...this.state.searchResults];

			for (const attributeGroup of searchResultsCopy) {
				for (const attribute of attributeGroup.options) {
					if (attribute.value === value) {
						attribute.isDisabled = true;

						this.setState({ searchResults: searchResultsCopy });

						break;
					}
				}
			}
		}

		render() {
			return (
				<div id="usedAttributesAndOpts">
					<Snackbar message={this.state.snackbarMessage} />
					{pageStore.usedAttributes === null ||
					pageStore.availableAttributes === null ||
					!this.state.options ? (
						<Loading />
					) : (
						<div>
							{globalStore.userType === "Read Only" ||
							(globalStore.message &&
								globalStore.message.status === "Published") ||
							globalStore.userType === "Developer" ||
							globalStore.userType === "Reviewer" ? null : (
								<Row>
									<Col xs={6}>
										<h2 className="colName">Quick Add</h2>
										<form
											onSubmit={evt => {
												evt.preventDefault();
												this.filterAttributes();
											}}
										>
											<input
												className="form-control"
												type="text"
												value={this.state.quickAdd}
												onChange={evt => {
													this.setState({ quickAdd: evt.target.value });
												}}
											/>
										</form>
									</Col>
									<Col xs={6} style={{ maxHeight: 400, overflow: "auto" }}>
										{this.state.searchResults.map(searchResultGroup => {
											return searchResultGroup.options.length === 0 ? null : (
												<div key={searchResultGroup.value}>
													{searchResultGroup.label}
													<ul>
														{searchResultGroup.options.map(option => {
															return (
																<li key={option.value}>
																	<a
																		className={
																			option.isDisabled ? "disabled" : ""
																		}
																		onClick={() => {
																			if (!option.isDisabled) {
																				this.addAttribute(option.value);
																			}
																		}}
																	>
																		{option.label}
																	</a>
																</li>
															);
														})}
													</ul>
												</div>
											);
										})}
									</Col>
								</Row>
							)}
							<Row>
								<Col xs={6}>
									<h2 className="colName">Attribute</h2>
								</Col>
								<Col xs={6}>
									<h2 className="colName">Default Option</h2>
								</Col>
							</Row>
							<div id="attributeRow">
								{pageStore.usedAttributes.map((attribute, index) => {
									return (
										<UsedAttributeRow
											key={attribute.id ? attribute.id : shortid.generate()}
											index={index}
											attribute={attribute}
											options={this.state.options}
											setDropdown={this.setDropdown}
										/>
									);
								})}
							</div>
							{pageStore.showSave &&
							globalStore.userType !== "Read Only" &&
							globalStore.userType !== "Developer" &&
							globalStore.userType !== "Reviewer" ? (
								<Row>
									<Col xs={12}>
										<div className="text-right">
											<a
												type="submit"
												onClick={this.save}
												className="saveButton"
											>
												Save
											</a>
										</div>
									</Col>
								</Row>
							) : null}
						</div>
					)}
				</div>
			);
		}
	}
);

const UsedAttributeRow = observer(
	class extends Component {
		constructor(props) {
			super(props);
			autoBind(this);
		}

		setDropdown(value) {
			for (const attributeGroup of pageStore.availableAttributes) {
				for (const availableAttributeToAdd of attributeGroup.attributes) {
					if (
						(availableAttributeToAdd.attributeID
							? availableAttributeToAdd.attributeID
							: availableAttributeToAdd.id) === value.value
					) {
						//regardless of the type, still set the dropdown to the new value
						pageStore.usedAttributes[this.props.index] = extendObservable(
							availableAttributeToAdd,
							{
								defaultOptionValue: null,
								defaultOptionID: null,
								attributeID: availableAttributeToAdd.id
							}
						);
						pageStore.usedAttributes[this.props.index].order = this.props.index;
						pageStore.usedAttributes[this.props.index].id = shortid.generate();

						pageStore.showSave = true;
						break;
					}
				}
			}
		}

		addButton() {
			pageStore.usedAttributes.push({
				order: pageStore.usedAttributes.length
			});
			pageStore.showSave = true;
		}

		removeButton() {
			pageStore.usedAttributes.splice(this.props.index, 1);
			pageStore.usedAttributes = pageStore.usedAttributes.map((att, index) => {
				att.order = index;
				return att;
			});
			pageStore.showSave = true;

			if (pageStore.usedAttributes.length === 0) {
				pageStore.usedAttributes.push({ order: 0 });
			}
		}

		filterAttributes = debounce((inputValue, callback) => {
			API(
				"/attributeSearch",
				"POST",
				{
					searchTerm: inputValue,
					usedAttributes: pageStore.usedAttributes.map(attribute => {
						if (attribute.attributeID) {
							return attribute.attributeID;
						} else {
							return attribute.id;
						}
					})
				},
				data => {
					callback(data.results);
				}
			);
		}, 300);

		render() {
			return (
				<Row className="usedAttributeRow">
					<Col xs={6}>
						<AsyncSelect
							value={
								this.props.attribute
									? {
											label: this.props.attribute.name,
											value: this.props.attribute.attributeID
												? this.props.attribute.attributeID
												: this.props.attribute.id
									  }
									: null
							}
							onChange={this.setDropdown}
							loadOptions={(inputValue, callback) => {
								if (inputValue === "") {
									callback(this.props.options);
								} else {
									this.filterAttributes(inputValue, callback);
								}
							}}
							clearable={false}
							className="attributeSelect"
							disabled={
								globalStore.userType === "Read Only" ||
								(globalStore.message &&
									globalStore.message.status === "Published") ||
								globalStore.userType === "Developer" ||
								globalStore.userType === "Reviewer"
							}
							styles={{
								menu: styles => Object.assign(styles, { zIndex: 1000 })
							}}
							defaultOptions
						/>

						{globalStore.userType === "Read Only" ||
						(globalStore.message &&
							globalStore.message.status === "Published") ||
						globalStore.userType === "Developer" ||
						globalStore.userType === "Reviewer" ? null : (
							<div className="plusMinusCol">
								<a onClick={this.removeButton}>
									<i className="fas fa-minus" />
								</a>
								{this.props.index === pageStore.usedAttributes.length - 1 &&
								this.props.attribute.attributeID ? (
									<a onClick={this.addButton}>
										<i className="fas fa-plus" style={{ marginLeft: 5 }} />
									</a>
								) : null}
							</div>
						)}
					</Col>
					<Col xs={6}>
						{this.props.attribute && !this.props.attribute.passthrough ? (
							<DefaultOption
								index={this.props.index}
								selectedAttribute={this.props.attribute}
							/>
						) : null}
					</Col>
				</Row>
			);
		}
	}
);

const DefaultOption = observer(
	class extends Component {
		constructor(props) {
			super(props);

			autoBind(this);
		}

		setDropdown(value) {
			pageStore.usedAttributes[this.props.index].defaultOptionID = value
				? value.value
				: null;
			pageStore.showSave = true;
		}

		render() {
			let options;
			let defaultOptionName;

			const makeOptionArray = optionDB => {
				if (optionDB.defaultOption) {
					defaultOptionName = optionDB.name;
				}

				return {
					value: optionDB.id,
					label: optionDB.name
				};
			};

			for (const attributeGroup of pageStore.availableAttributes) {
				for (const attribute of attributeGroup.attributes) {
					if (
						(attribute.attributeID ? attribute.attributeID : attribute.id) ===
						this.props.selectedAttribute.attributeID
					) {
						options = attribute.options.map(makeOptionArray);
						break;
					}
				}
			}

			return (
				<div>
					<Select
						styles={{ menu: styles => Object.assign(styles, { zIndex: 1000 }) }}
						value={
							pageStore.usedAttributes[this.props.index].defaultOptionID
								? {
										value:
											pageStore.usedAttributes[this.props.index]
												.defaultOptionID,
										label: options.find(element => {
											return (
												element.value ===
												pageStore.usedAttributes[this.props.index]
													.defaultOptionID
											);
										}).label
								  }
								: null
						}
						placeholder={defaultOptionName}
						onChange={this.setDropdown}
						options={options}
						clearable={
							globalStore.userType !== "Read Only" &&
							globalStore.userType !== "Developer" &&
							globalStore.userType !== "Reviewer"
						}
						disabled={
							globalStore.userType === "Read Only" ||
							(globalStore.message &&
								globalStore.message.status === "Published") ||
							globalStore.userType === "Developer" ||
							globalStore.userType === "Reviewer"
						}
					/>
				</div>
			);
		}
	}
);
