import React from "react";
import { Row, Col } from "react-bootstrap";
import Footer from "../structure/Footer";
import { Link } from "react-router-dom";

const Steps = () => (
	<div id="steps" className="onboarding">
		<div className="container">
			<Row>
				<Col md={10} mdOffset={1} className="text-center" id="mastheadRow">
					<img
						src="./masthead.svg"
						id="masthead"
						alt="Masthead of tuzagTCS, tuzagDesign, and tuzagTailor logos"
					/>
				</Col>
			</Row>
			<Row>
				<Col md={10} mdOffset={1}>
					<p id="preColText">
						Introduction to what happens next. The assumption here is that
						people who have gotten this far are at least interested in trying it
						out. We'll want to set up some kind of 30-day free trial, I'm
						guessing. At any rate, this introduction needs to be three lines or
						less.
					</p>
				</Col>
			</Row>
			<Row>
				<Col md={4}>
					<Row>
						<Col xs={2} className="bigNumber">
							1
						</Col>
						<Col xs={10} className="stepDescription">
							<p>
								Create a private, secure account for your company or team...
							</p>
						</Col>
					</Row>
				</Col>
				<Col md={4}>
					<Row>
						<Col xs={2} className="bigNumber">
							2
						</Col>
						<Col xs={10} className="stepDescription">
							<p>
								Enter your payment information or sign up for a free 30-day
								trial...
							</p>
						</Col>
					</Row>
				</Col>
				<Col md={4}>
					<Row>
						<Col xs={2} className="bigNumber">
							3
						</Col>
						<Col xs={10} className="stepDescription">
							<p>Start creating tailored content!</p>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Link to="/accountSetup">
					<div className="orangeButton">Get started!</div>
				</Link>
			</Row>
		</div>
		<Footer color="purple" />
	</div>
);

export default Steps;
