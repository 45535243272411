import React, { Component } from "react";
import autoBind from "react-autobind";
import classNames from "classnames";

export default class SidebarRow extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			open:
				this.props.rowName in localStorage
					? localStorage[this.props.rowName]
					: false
		};
	}

	rowClicked() {
		this.setState({ open: !this.state.open });

		if (this.props.rowURL) {
			this.props.history.push(this.props.rowURL);
		}

		localStorage[this.props.rowName] = !this.state.open;
	}

	render() {
		return (
			<div className={classNames("sidebarRow", this.props.className)}>
				<div className="uncollapsibleContent" onClick={this.rowClicked}>
					<h1>{this.props.rowName}</h1>
					{this.state.open ? (
						<i className="far fa-caret-square-up" />
					) : (
						<i className="far fa-caret-square-down" />
					)}
					<div className="clearFloat" />
				</div>
				{this.state.open ? (
					<div className="collapsibleContent">{this.props.children}</div>
				) : null}
			</div>
		);
	}
}
