import React, { Component } from "react";
import autoBind from "react-autobind";
import API from "../../../API";
import Select from "react-select-plus";
import SelectNew from "react-select";
import { Row, Col, Table } from "react-bootstrap";
import shortid from "shortid";
import { observer } from "mobx-react";
import globalStore from "../../../GlobalStore";
import { Redirect } from "react-router";
import OptionTable from "../../nonMessage/admin/manageAttributes/OptionTable";
import Mousetrap from "mousetrap";
import Snackbar from "../../structure/Snackbar";

export default observer(
	class Chat extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				availableAttributes: [],
				selectedAttributeID: null,
				selectedChatBotID: null,
				bots: [],
				glossaries: []
			};
		}

		componentWillMount() {
			this.loadData(this.props);

			Mousetrap.bind(["ctrl+s", "command+s"], event => {
				event.preventDefault();
				this.save();
			});
		}

		componentWillUnmount() {
			Mousetrap.unbind(["ctrl+s", "command+s"]);
		}

		componentWillReceiveProps(nextProps) {
			this.loadData(nextProps);
		}

		loadData() {
			API(
				`/message/${this.props.match.params.messageID}/technical/chat/${
					this.props.match.params.attributeID
				}`,
				"GET",
				{},
				data => {
					const selectedBot = data.message.chatBotID
						? data.bots.find(bot => {
								return bot.id === data.message.chatBotID;
						  })
						: null;

					let selectedAttribute = isNaN(this.props.match.params.attributeID)
						? null
						: data.message.messageUsedAttributes.find(messageUsedAttribute => {
								return (
									messageUsedAttribute.attributeID ===
									parseInt(this.props.match.params.attributeID, 10)
								);
						  });

					if (
						selectedAttribute &&
						selectedAttribute.attribute.name.includes("Raw")
					) {
						const rawName = selectedAttribute.attribute.name;
						this.setState({
							listAttribute: data.message.messageUsedAttributes.find(
								messageUsedAttribute => {
									return (
										messageUsedAttribute.attribute.name ===
										rawName.replace("Raw", "List")
									);
								}
							)
						});
					}

					this.setState({
						glossaries: data.glossaries.map(glossary => {
							return { label: glossary.name, value: glossary.id };
						}),
						bots: data.bots.map(bot => {
							return { label: bot.name, value: bot.id };
						}),
						selectedChatBotID: selectedBot
							? { value: selectedBot.id, label: selectedBot.name }
							: null,
						selectedAttributeID: selectedAttribute
							? {
									value: selectedAttribute.attributeID,
									label: selectedAttribute.attribute.name,
									options: selectedAttribute.attribute.options
							  }
							: null,
						availableAttributes: data.message.messageUsedAttributes.map(
							messageUsedAttribute => {
								return {
									label: messageUsedAttribute.attribute.name,
									value: messageUsedAttribute.attributeID
								};
							}
						)
					});
				}
			);

			API(
				`/failResponses/${this.props.match.params.messageID}`,
				"GET",
				{},
				data => {
					this.setState({ failResponses: data.failResponses });
				}
			);
		}

		setDropdown(value) {
			let synonyms = {};
			if (value) {
				for (const attributeGroup of this.state.availableAttributes) {
					for (const attribute of attributeGroup.attributes) {
						if (attribute.id === value.value) {
							for (const option of attribute.options) {
								synonyms[option.id] = option.name;
							}
							break;
						}
					}
				}
			}
			this.setState({
				selectedAttributeID: value ? value.value : null,
				synonyms
			});
		}

		setBotDropdown(value) {
			this.setState({ selectedChatBotID: value ? value.value : null });
		}

		save() {
			// let sentiments = {};
			// let glossaries = {};

			// for (const key in this.state) {
			// 	if (key.includes("sentiment*|*") && this.state[key]) {
			// 		sentiments[key.split("*|*")[1]] = this.state[key].value;
			// 	}
			//
			// 	if (key.includes("glossary*|*") && this.state[key]) {
			// 		glossaries[key.split("*|*")[1]] = this.state[key].value;
			// 	}
			// }

			API(
				`/message/${this.props.match.params.messageID}/technical/chat`,
				"PUT",
				{
					attributeIDInURL: this.props.match.params.attributeID,
					chatBotID: this.state.selectedChatBotID.value,
					chatAttributeID:
						this.state.selectedChatBotID && this.state.selectedAttributeID
							? this.state.selectedAttributeID.value
							: null
					// synonyms:
					// 	this.state.selectedChatBotID && this.state.selectedAttributeID
					// 		? this.state.synonyms
					// 		: [],
					// sentiments:
					// 	this.state.selectedChatBotID && this.state.selectedAttributeID
					// 		? sentiments
					// 		: [],
					// glossaries:
					// 	this.state.selectedChatBotID && this.state.selectedAttributeID
					// 		? glossaries
					// 		: []
				},
				data => {
					this.setState({
						snackbarMessage: "Saved!",
						lastSaveID: shortid.generate()
					});

					globalStore.message.messageChatAttributes = data.attributes;
					if (this.state.selectedChatBotID && this.state.selectedAttributeID) {
						globalStore.history.push(
							`./${this.state.selectedAttributeID.value}`
						);
					} else {
						globalStore.history.push(`./`);
					}
				}
			);
		}

		setFailResponseDropdown(value, failResponse, index) {
			API(
				`/markAsMatched/${value.failResponseID}/${
					this.props.match.params.messageID
				}?prod=${!!value.prod}`,
				"PUT",
				{
					optionID: value.value.toString(),
					attributeID: this.state.listAttribute
						? this.state.listAttribute.attributeID
						: this.state.selectedAttributeID.value
				},
				() => {}
			);

			if (!value.label.includes("Mark As Matched")) {
				if (
					typeof value.value === "string" &&
					value.value.includes("glossaryAddTo*|*")
				) {
					API(
						`/glossaryKeyword/${value.value.split("*|*")[1]}`,
						"PUT",
						{ keyword: failResponse.valueCol },
						() => {}
					);
				} else {
					API(
						`/optionKeyword/${value.value}`,
						"PUT",
						{ keyword: failResponse.valueCol },
						() => {}
					);
				}
			}

			this.setState({
				failResponses: this.state.failResponses.filter(response => {
					return response.id !== failResponse.id;
				})
			});
		}

		render() {
			return (
				<div id="chatTechnical">
					<Row>
						<Col xs={4}>
							<h4>Bot:</h4>
							<SelectNew
								options={this.state.bots}
								onChange={value => {
									this.setState({ selectedChatBotID: value });
								}}
								value={this.state.selectedChatBotID}
								disabled={
									globalStore.userType === "Read Only" ||
									(globalStore.message &&
										globalStore.message.status === "Published")
								}
							/>
						</Col>
					</Row>
					{this.state.selectedChatBotID ? (
						<div>
							<Row>
								<Col xs={4}>
									<h4>Attribute</h4>
									<SelectNew
										options={this.state.availableAttributes}
										onChange={value => {
											this.setState({ selectedAttributeID: value });
										}}
										value={this.state.selectedAttributeID}
										isDisabled={
											globalStore.userType === "Read Only" ||
											(globalStore.message &&
												globalStore.message.status === "Published")
										}
										isClearable={true}
									/>
								</Col>
							</Row>
							<div>
								{globalStore.userType === "Read Only" ||
								(globalStore.message &&
									globalStore.message.status === "Published") ? null : (
									<Row
										style={{
											marginBottom: 30,
											marginTop: 10
										}}
									>
										<Col xs={12}>
											<div
												className="saveButton"
												onClick={this.save}
												style={{ cursor: "pointer" }}
											>
												Save
											</div>
										</Col>
									</Row>
								)}
								{this.state.selectedAttributeID ? (
									<Row>
										<Col>
											<OptionTable
												attribute={{
													id: this.state.listAttribute
														? this.state.listAttribute.attributeID
														: this.state.selectedAttributeID.value
												}}
												glossaries={this.state.glossaries}
											/>
										</Col>
									</Row>
								) : null}
							</div>
						</div>
					) : null}
					{this.state.selectedAttributeID &&
					this.state.selectedAttributeID.options &&
					this.state.failResponses &&
					this.state.failResponses.length > 0 ? (
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>Incoming Text</th>
									<th>Add to</th>
								</tr>
							</thead>
							<tbody>
								{this.state.failResponses.map((failResponse, index) => {
									let addToOptions = [
										{
											label: "Mark As Matched",
											value: "Mark As Matched",
											failResponseID: failResponse.id,
											prod: failResponse.prod
										}
									];

									let options;
									if (this.state.listAttribute) {
										options = this.state.listAttribute.attribute.options;
									} else {
										options = this.state.selectedAttributeID.options;
									}

									for (const option of options) {
										addToOptions.push({
											label: `${option.name} `,
											value: option.id,
											failResponseID: failResponse.id,
											prod: failResponse.prod
										});

										if (option.glossaryID) {
											addToOptions.push({
												label: `${option.name} `,
												value: `glossaryAddTo*|*${option.glossaryID}`,
												failResponseID: failResponse.id,
												prod: failResponse.prod
											});
										}
									}

									return (
										<tr key={index}>
											<td className="name">{failResponse.valueCol}</td>
											<td>
												<Select
													options={addToOptions}
													valueRenderer={option => {
														if (option.label.includes(" ")) {
															return (
																<span>
																	{option.label.replace(" ", "")}
																	{"  "}
																	<i className="fas fa-envelope" />
																</span>
															);
														} else if (option.label.includes(" ")) {
															return (
																<span>
																	{option.label.replace(" ", "")}
																	{"  "}
																	<i className="far fa-book" />
																</span>
															);
														} else {
															return option.label;
														}
													}}
													optionRenderer={option => {
														if (option.label.includes(" ")) {
															return (
																<span>
																	{option.label.replace(" ", "")}
																	{"  "}
																	<i className="fas fa-envelope" />
																</span>
															);
														} else if (option.label.includes(" ")) {
															return (
																<span>
																	{option.label.replace(" ", "")}
																	{"  "}
																	<i className="far fa-book" />
																</span>
															);
														} else {
															return option.label;
														}
													}}
													onChange={value => {
														this.setFailResponseDropdown(
															value,
															failResponse,
															index
														);
													}}
													value={this.state[`failResponseDropdown${index}`]}
												/>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					) : null}
					<Snackbar
						message={this.state.snackbarMessage}
						lastSaveID={this.state.lastSaveID}
					/>
				</div>
			);
		}
	}
);

export const ChatRedirecter = observer(
	class Chat extends Component {
		render() {
			return globalStore.message &&
				globalStore.message.messageChatAttributes.length === 1 ? (
				<Redirect
					to={`/message/${this.props.match.params.messageID}/technical/chat/${
						globalStore.message.messageChatAttributes[0].attributeID
					}`}
				/>
			) : (
				<p>Select attribute in the sidebar to continue</p>
			);
		}
	}
);
