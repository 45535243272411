import React, { Component } from "react";
import autoBind from "react-autobind";
import { observer } from "mobx-react";
import MessageBrowser from "../messageBrowser/MessageBrowser";
import globalStore from "../../GlobalStore";
import Mousetrap from "mousetrap";

export default observer(
	class Topbar extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {};
		}

		togglePanel() {
			globalStore.messageBrowserPanelOpen = !globalStore.messageBrowserPanelOpen;
		}

		componentDidMount() {
			Mousetrap.bind(["escape"], event => {
				globalStore.messageBrowserPanelOpen = false;
			});
		}

		componentWillUnmount() {
			Mousetrap.unbind(["esc"]);
		}

		render() {
			return (
				<div id="topbar">
					<div id="leftNav">
						{/*<i className="fa fa-bars" />*/}
						<i className="fa fa-map" onClick={this.togglePanel} />
						{globalStore.messageBrowserPanelOpen ? (
							<div className="messageBrowserPanel">
								<MessageBrowser topbar={true} />
							</div>
						) : null}
						<a
							onClick={() => {
								this.props.history.push("/dashboard/dashboard");
							}}
						>
							<img src="/tuzagDesignLogoWhite.svg" alt="tuzagDesign logo" />
						</a>
						{process.env.REACT_APP_ENV === "staging" ? (
							<p style={{ float: "right", marginLeft: 25 }}>STAGING</p>
						) : process.env.REACT_APP_ENV === "local" ? (
							<p style={{ float: "right", marginLeft: 25 }}>LOCAL</p>
						) : null}
					</div>
					<div className="text-right" id="rightNav">
						{/*<i className="fa fa-book" />*/}
						<span id="username">
							{globalStore.firebaseUser
								? globalStore.firebaseUser.displayName
								: null}
						</span>
						{/*<i className="fa fa-user" />*/}
					</div>
					<div className="clearFloat" />
				</div>
			);
		}
	}
);
