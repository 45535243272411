import React, { Component } from "react";
import autoBind from "react-autobind";
import API from "../../../API";
import Loading from "../../structure/Loading";

export default class ExportTemplate extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = { data: null };
	}

	componentWillMount() {
		API(
			`/message/${this.props.match.params.messageID}/export/json`,
			"GET",
			{},
			data => {
				console.log(data);
				this.setState({ data });
			}
		);
	}

	render() {
		return (
			<div className="exportHTML container">
				{this.state.data ? (
					<div>
						<div className="row text-center">
							<h3>Tailored Objects Review Worksheet</h3>
						</div>
						<div>
							<strong>Message: </strong>
							{this.state.data.messageName}
						</div>
						<div>
							<strong>Path: </strong> {this.state.data.messagePath}
						</div>
						<div>
							<strong>Link: </strong>
							<a href={this.state.data.link}>{this.state.data.link}</a>
						</div>
						<div>
							{this.state.data.elements.map((element, index) => {
								return (
									<div key={index}>
										<hr />
										<div>
											<strong>Element Name: </strong> {element.elementName}
										</div>
										<div>
											<strong>Baseline Copy: </strong> {element.elementCopy}
										</div>
										{element.bits.map((bit, index) => {
											return (
												<table
													key={index}
													className="table table-striped table-hover table-bordered"
												>
													<thead>
														<tr>
															<td>#</td>
															<td>Attribute</td>
															<td>Value</td>
															<td>Option</td>
															<td>Content</td>
														</tr>
													</thead>
													<tbody>
														{bit.bitCases.map((bitCase, index) => {
															return (
																<tr key={index}>
																	<td>{bitCase.order}</td>
																	<td>
																		{bitCase.rules.map((rule, index) => {
																			return (
																				<p key={index}>{rule.attribute}</p>
																			);
																		})}
																	</td>
																	<td>
																		{bitCase.rules.map((rule, index) => {
																			return (
																				<p key={index}>
																					{rule.equalityOperator}
																				</p>
																			);
																		})}
																	</td>
																	<td>
																		{bitCase.rules.map((rule, index) => {
																			return (
																				<p key={index}>
																					{rule.option} {rule.chainedOperator}
																				</p>
																			);
																		})}
																	</td>
																	<td>{bitCase.content}</td>
																</tr>
															);
														})}
													</tbody>
												</table>
											);
										})}
									</div>
								);
							})}
						</div>
					</div>
				) : (
					<Loading />
				)}
			</div>
		);
	}
}
