import React, { Component } from "react";
import autoBind from "react-autobind";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import globalStore from "../../../../GlobalStore";

export default observer(
	class ChatVoice extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {};
		}

		render() {
			return (
				<div>
					{globalStore.message ? (
						<div>
							<div
								onClick={() => {
									return false;
								}}
								style={{
									cursor: "disabled",
									fontSize: 16,
									marginLeft: 15,
									marginRight: 15,
									marginBottom: 5,
									marginTop: 5,
									color: "black"
								}}
							>
								Chat/Voice:
							</div>
							<ul>
								{globalStore.message.messageChatAttributes.map(attribute => {
									return (
										<li key={attribute.attributeID}>
											<NavLink
												to={this.props.generateURL(
													`technical/chat/${attribute.attributeID}`
												)}
												style={{ marginLeft: 0, marginRight: 0 }}
											>
												{attribute.attribute.name}
											</NavLink>
										</li>
									);
								})}
								<li>
									<NavLink
										to={this.props.generateURL(`technical/chat/new`)}
										style={{ marginLeft: 0, marginRight: 0 }}
									>
										Add New
									</NavLink>
								</li>
							</ul>
						</div>
					) : (
						<a
							onClick={() => {
								return false;
							}}
							style={{ cursor: "disabled" }}
						>
							Chat/Voice
						</a>
					)}
				</div>
			);
		}
	}
);
