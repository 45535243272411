import React, { Component } from "react";
import autoBind from "react-autobind";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import globalStore from "../../../../GlobalStore";

export default observer(
	class QuickPreview extends Component {
		constructor(props) {
			super(props);
			autoBind(this);
		}

		render() {
			return (
				<div>
					<NavLink to={this.props.generateURL("testing/quickPreview")}>
						Quick Preview
					</NavLink>
					{this.props.location.pathname.includes("/testing/quickPreview") ? (
						<div style={{ maxHeight: 350, overflowY: "auto" }}>
							{globalStore.elements.map(element => {
								return (
									<NavLink
										to={this.props.generateURL(
											`testing/quickPreview/element/${element.id}`
										)}
										key={element.id}
										className="previewElement"
									>
										{element.name}
									</NavLink>
								);
							})}
						</div>
					) : null}
				</div>
			);
		}
	}
);
