import React, { Component } from "react";
import autoBind from "react-autobind";
import Select from "react-select-plus";
import { Form, Text } from "react-form";
import titleCase from "title-case";
import { observer } from "mobx-react";
import shortid from "shortid";
import API from "../../../API";
import Snackbar from "../../structure/Snackbar";
import { Row, Col } from "react-bootstrap";
import MessageBrowser from "../../messageBrowser/MessageBrowser";
import SelectEdit from "../../lib/SelectEdit";

export default observer(
	class ManageChatbots extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				bots: [],
				editMode: false,
				loading: true,
				showEdit: false,
				snackbarMessage: "",
				lastSaveID: null,
				selectedBot: null,
				selectedBotObject: null,
				showEditButton: false,
				showMessageBrowser: false,
			};
		}

		componentDidMount() {
			API("/bots", "GET", null, (data) => {
				this.setState(
					{
						bots: data.bots.map((bot) => {
							return {
								value: bot.id,
								label: bot.name,
								apiKey: bot.apiKey,
								startMessageID: bot.startMessageID,
							};
						}),
						loading: false,
					},
					() => {
						if (
							this.props.match.params.botID &&
							!isNaN(this.props.match.params.botID)
						) {
							this.setState({
								selectedBot: parseInt(this.props.match.params.botID, 10),
								showEditButton: true,
								selectedBotObject: data.bots.find((bot) => {
									return (
										bot.value === parseInt(this.props.match.params.botID, 10)
									);
								}),
							});
						}
					}
				);
			});
		}

		toggleNewMode() {
			if (this.state.editMode) {
				this.setState({
					editMode: false,
				});
			} else {
				this.setState({
					editMode: "new",
				});
			}
		}

		toggleEditMode() {
			if (this.state.editMode) {
				this.setState({
					editMode: false,
				});
			} else {
				this.setState({
					editMode: "edit",
				});
			}
		}

		deleteBot() {
			if (window.confirm("Are you sure you want to delete this bot?")) {
				API(
					`/bots/${this.state.selectedBotObject.value}`,
					"DELETE",
					{},
					(data) => {
						this.setState({
							bots: data.bots
								? data.bots.map((bot) => {
										return {
											value: bot.id,
											label: bot.name,
											apiKey: bot.apiKey,
											startMessageID: bot.startMessageID,
										};
								  })
								: [],
							editMode: false,
							snackbarMessage: "Deleted",
							lastSaveID: shortid.generate(),
							selectedBot: null,
							selectedBotObject: null,
							showEditButton: false,
						});
					}
				);
			}
		}

		setDropdown(value) {
			this.setState({
				selectedBot: value.value,
				showEditButton: !!value,
				selectedBotObject: this.state.bots.find((bot) => {
					return bot.value === value.value;
				}),
			});
		}

		messageSelected(setValue, values, messageID) {
			this.setState({ showMessageBrowser: false }, () => {
				for (const key in values) {
					setValue(key, values[key]);
				}
				setValue("startMessageID", messageID);
			});
		}

		render() {
			return (
				<div id="manageBots">
					<h1 className="pageName">Select Bot:</h1>
					<Row>
						<Col xs={3}>
							<Select
								name="botSelect"
								value={this.state.loading ? "Loading" : this.state.selectedBot}
								onChange={this.setDropdown}
								options={
									this.state.loading
										? [
												{
													value: "Loading",
													label: "Loading...",
													disabled: true,
												},
										  ]
										: this.state.bots
								}
								clearable={false}
								disabled={!!this.state.editMode || this.state.loading}
							/>
							<div className="buttonRow">
								{this.state.showEditButton && this.state.editMode !== "new" ? (
									<span>
										<a onClick={this.toggleEditMode} className="editButton">
											{this.state.editMode === "edit" ? "Hide edit" : "Edit"}
										</a>
										{this.state.editMode === "edit" ? (
											<a onClick={this.deleteBot} className="deleteButton">
												Delete
											</a>
										) : null}
									</span>
								) : null}
								{this.state.editMode !== "edit" ? (
									<a onClick={this.toggleNewMode} className="newButton">
										{this.state.editMode === "new" ? "Hide new" : "New"}
									</a>
								) : null}
								<div className="clearFloat" />
							</div>
							{this.state.editMode ? (
								<div>
									<h2 className="colName">
										{titleCase(this.state.editMode)} Bot Name:
									</h2>
									<Form
										defaultValues={{
											startMessageID:
												this.state.editMode === "edit"
													? this.state.selectedBotObject.startMessageID
													: "",
											name:
												this.state.editMode === "edit"
													? this.state.selectedBotObject.label
													: "",
											apiKey:
												this.state.editMode === "edit"
													? this.state.selectedBotObject.apiKey
													: "",
										}}
										validate={(values) => {
											return {
												name: !values.name ? "A name is required" : undefined,
												apiKey: !values.apiKey
													? "An API Key is required"
													: undefined,
												startMessageID: !values.startMessageID
													? "A Start Message is required"
													: undefined,
											};
										}}
										onSubmit={(values) => {
											if (this.state.editMode === "edit") {
												values.id = this.state.selectedBotObject.value;
												API("/bots", "PUT", values, (data) => {
													this.setState({
														snackbarMessage: "Saved!",
														lastSaveID: shortid.generate(),
														bots: data.bots.map((bot) => {
															return {
																value: bot.id,
																label: bot.name,
																apiKey: bot.apiKey,
																startMessageID: bot.startMessageID,
															};
														}),
														editMode: false,
													});
													values.name = null;
													values.apiKey = null;
												});
											} else {
												API("/bots", "POST", values, (data) => {
													this.setState({
														snackbarMessage: "Saved!",
														lastSaveID: shortid.generate(),
														bots: data.bots.map((bot) => {
															return {
																value: bot.id,
																label: bot.name,
																apiKey: bot.apiKey,
																startMessageID: bot.startMessageID,
															};
														}),
														editMode: false,
													});
													values.name = null;
													values.apiKey = null;
												});
											}
										}}
									>
										{({ submitForm, setValue, values }) => {
											return (
												<form onSubmit={submitForm}>
													<Text
														field="name"
														placeholder="bot name goes here..."
													/>
													<Text
														field="apiKey"
														placeholder="API Key goes here..."
													/>
													<Text
														field="startMessageID"
														placeholder="Start Message"
														onClick={() => {
															this.setState(
																{ showMessageBrowser: true },
																() => {
																	for (const key in values) {
																		setValue(key, values[key]);
																	}
																}
															);
														}}
													/>
													{this.state.showMessageBrowser ? (
														<div className="messageBrowserPanel">
															<MessageBrowser
																messageSelected={this.messageSelected.bind(
																	this,
																	setValue,
																	values
																)}
															/>
														</div>
													) : null}
													<div className="text-right">
														<a
															type="submit"
															onClick={submitForm}
															className="saveButton"
														>
															Save
														</a>
													</div>
												</form>
											);
										}}
									</Form>
								</div>
							) : null}
						</Col>
					</Row>
					{this.state.selectedBot && !this.state.editMode ? (
						<Row>
							<Col xs={12}>
								<a
									onClick={() => {
										API("/amazonSync", "GET", {}, (data) => {
											this.setState({
												snackbarMessage: "Sync In Progress",
												lastSaveID: shortid.generate(),
											});
										});
									}}
									className="saveButton"
								>
									Sync To Amazon
								</a>
							</Col>
						</Row>
					) : null}
					{!this.state.editMode ? (
						<ManageGlossaries
							selectedBot={this.state.selectedBot}
							match={this.props.match}
						/>
					) : null}
					<Snackbar
						message={this.state.snackbarMessage}
						lastSaveID={this.state.lastSaveID}
					/>
				</div>
			);
		}
	}
);

class ManageGlossaries extends Component {
	constructor(props) {
		super(props);

		this.state = { selectedItem: null };
	}

	setSelectedItem = (value) => {
		this.setState({ selectedItem: value });
	};

	componentDidMount() {
		API(`/chatGlossary`, "GET", {}, (data) => {
			this.setState({ glossaries: data.glossaries, loading: false });
			if (
				this.props.match.params.glossaryID &&
				!isNaN(this.props.match.params.glossaryID)
			) {
				this.setState({
					selectedGlossary: parseInt(this.props.match.params.glossaryID, 10),
				});

				API(
					`/glossaryKeywords/${this.props.match.params.glossaryID}`,
					"GET",
					{},
					(data) => {
						let textToSet = "";
						for (const keyword of data.keywords) {
							textToSet = textToSet.concat(keyword + "\n");
						}
						this.setState({ glossaryTextArea: textToSet.trim() });
					}
				);
			}
		});
	}

	render() {
		return (
			<div>
				<Row id="manageGlossaries">
					<Col xs={12}>
						<h1 className="pageName">Manage Glossaries:</h1>
					</Col>
				</Row>
				<Row>
					<Col xs={3}>
						<SelectEdit
							name="glossaries"
							endpointName="chatGlossary"
							selectedItem={this.state.selectedItem}
							setSelectedItem={this.setSelectedItem}
							fields={["name"]}
						/>
					</Col>
					{this.state.selectedItem ? (
						<KeywordEditor selectedGlossary={this.state.selectedItem.value} />
					) : null}
				</Row>
			</div>
		);
	}
}

class KeywordEditor extends Component {
	state = { glossaryTextArea: "", saveText: "Save" };

	componentDidMount() {
		this.loadKeywords();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.selectedGlossary !== this.props.selectedGlossary) {
			this.loadKeywords();
		}
	}

	loadKeywords = () => {
		API(
			`/glossaryKeywords/${this.props.selectedGlossary}`,
			"GET",
			{},
			(data) => {
				let textToSet = "";
				for (const keyword of data.keywords) {
					textToSet = textToSet.concat(keyword + "\n");
				}
				this.setState({ glossaryTextArea: textToSet.trim() });
			}
		);
	};

	saveKeywords = () => {
		if (!this.state.saving) {
			this.setState({ saving: true, saveText: "Saving..." });
			API(
				`/glossaryKeywords/${this.props.selectedGlossary}`,
				"PUT",
				{
					keywords: this.state.glossaryTextArea
						.split(/\n|,/g)
						.map((keyword) => {
							return keyword.trim();
						}),
				},
				(data) => {
					this.setState({ saving: false, saveText: "Saved!" });
					setTimeout(() => {
						this.setState({ saveText: "Save" });
					}, 2500);
				}
			);
		}
	};

	loadSynonyms = (type = null) => {
		API(
			"/getSynonymsFromKeywords/wordnet",
			"POST",
			{
				keywords: { "0": this.state.glossaryTextArea.split(/\n/g).join(", ") },
				type,
			},
			(data) => {
				this.setState({ suggestedSynonyms: data.suggestedSynonyms[0] });
			}
		);
	};

	addSuggestedSynonym = (suggestedSynonym) => {
		this.setState({
			glossaryTextArea: this.state.glossaryTextArea.concat(
				`\n${suggestedSynonym}`
			),
		});
	};

	sort = () => {
		let keywordsArray = this.state.glossaryTextArea
			.split(/\n|,/g)
			.map((keyword) => {
				return keyword.trim().toLowerCase();
			})
			.sort();
		this.setState({ glossaryTextArea: keywordsArray.join("\n") });
	};

	dedupe = () => {
		let keywordsArray = this.state.glossaryTextArea
			.split(/\n|,/g)
			.map((keyword) => {
				return keyword.trim().toLowerCase();
			})
			.sort();
		this.setState({ glossaryTextArea: [...new Set(keywordsArray)].join("\n") });
	};

	render() {
		return [
			<Col xs={6} key={1}>
				<textarea
					value={this.state.glossaryTextArea}
					style={{ width: "100%", height: 400 }}
					onChange={(evt) => {
						this.setState({ glossaryTextArea: evt.target.value });
					}}
				/>
				<div className="text-right">
					<a type="submit" onClick={this.saveKeywords} className="saveButton">
						{this.state.saveText}
					</a>
				</div>
			</Col>,
			<Col xs={3} key={2}>
				<div>
					Load Synonyms By:{" "}
					<a onClick={this.loadSynonyms.bind(this, "null")}>All</a> |{" "}
					<a onClick={this.loadSynonyms.bind(this, "adj")}>Adj</a> |{" "}
					<a onClick={this.loadSynonyms.bind(this, "adv")}>Adv</a> |{" "}
					<a onClick={this.loadSynonyms.bind(this, "noun")}>Noun</a> |{" "}
					<a onClick={this.loadSynonyms.bind(this, "verb")}>Verb</a>
				</div>
				<div>
					<a onClick={this.dedupe}>Dedupe</a> | <a onClick={this.sort}>Sort</a>
				</div>
				{this.state.suggestedSynonyms ? (
					<div>
						{this.state.suggestedSynonyms.map((suggestedSynonym, index) => {
							return (
								<div key={suggestedSynonym}>
									<a
										onClick={this.addSuggestedSynonym.bind(
											this,
											suggestedSynonym
										)}
									>
										{suggestedSynonym}
									</a>
									{index === this.state.suggestedSynonyms.length - 1
										? null
										: "\n"}
								</div>
							);
						})}
					</div>
				) : (
					<div />
				)}
			</Col>,
		];
	}
}
