import React, { Component } from "react";
import autoBind from "react-autobind";
import { observer } from "mobx-react";
import { observable } from "mobx";
import { Row, Col } from "react-bootstrap";
import Select from "react-select-plus";
import shortid from "shortid";

export default observer(
	class RuleContainer extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {};
		}

		componentWillMount() {
			if (
				this.props.bitCase.bitCaseRules.length === 0 &&
				this.props.bitCase.bitCaseRuleGroups.length === 0
			) {
				this.props.bitCase.bitCaseRules.push(
					observable({
						id: shortid.generate(),
						attributeID: null,
						optionID: null,
						equalityOperator: null,
						chainedOperator: null,
						order: 0
					})
				);
			}

			this.props.bitCase.bitCaseRuleGroups.map(group => {
				if (group.bitCaseRules.length === 0) {
					group.bitCaseRules.push(
						observable({
							id: shortid.generate(),
							attributeID: null,
							optionID: null,
							equalityOperator: null,
							chainedOperator: null,
							order: 0
						})
					);
				}
				return true; //silence warning about map not returning anything
			});
		}

		componentWillUpdate(nextProps) {
			if (
				nextProps.bitCase.bitCaseRules.length === 0 &&
				this.props.bitCase.bitCaseRuleGroups.length === 0
			) {
				nextProps.bitCase.bitCaseRules.push(
					observable({
						id: shortid.generate(),
						attributeID: null,
						optionID: null,
						equalityOperator: null,
						chainedOperator: null,
						order: 0
					})
				);
			}
			nextProps.bitCase.bitCaseRuleGroups.map(group => {
				if (group.bitCaseRules.length === 0) {
					group.bitCaseRules.push(
						observable({
							id: shortid.generate(),
							attributeID: null,
							optionID: null,
							equalityOperator: null,
							chainedOperator: null,
							order: 0
						})
					);
				}
				return true; //silence warning about map not returning anything
			});
		}

		render() {
			// let elements = [];
			let allRules = this.props.bitCase.bitCaseRules
				.map((item, ruleArrayIndex) => {
					return observable({
						...item,
						type: "rule",
						ruleArrayIndex: ruleArrayIndex
					});
				})
				.concat(
					this.props.bitCase.bitCaseRuleGroups.map((item, groupIndex) => {
						return observable({
							...item,
							type: "group",
							groupIndex: groupIndex
						});
					})
				)
				.sort((a, b) => {
					return a.order > b.order;
				});

			const elements = allRules.map((item, i) => {
				if (item.type === "group") {
					return (
						<Group
							key={"group-" + item.id}
							pageStore={this.props.pageStore}
							group={item}
							allRules={allRules}
							bitCase={this.props.bitCase}
							allItemsIndex={i}
							rules={this.props.bitCase.bitCaseRules}
							allRuleIndex={i}
							reorderMode={this.props.reorderMode}
						>
							{item.bitCaseRules.map((rule, ruleArrayIndex) => {
								return (
									<Rule
										quillJS={this.props.quillJS}
										key={"rule-" + rule.id}
										rule={rule}
										pageStore={this.props.pageStore}
										bitCase={this.props.bitCase}
										groupIndex={item.order}
										ruleIndex={ruleArrayIndex}
										rules={item.bitCaseRules}
										group={item}
										ruleArrayIndex={ruleArrayIndex}
										allItemsIndex={i}
										allRules={allRules}
										reorderMode={this.props.reorderMode}
									/>
								);
							})}
						</Group>
					);
				} else {
					//non nested rule
					return (
						<Rule
							quillJS={this.props.quillJS}
							key={"rule-" + item.id}
							rule={item}
							pageStore={this.props.pageStore}
							bitCase={this.props.bitCase}
							ruleIndex={i}
							rules={this.props.bitCase.bitCaseRules}
							ruleArrayIndex={item.ruleArrayIndex}
							allItemsIndex={i}
							allRules={allRules}
							reorderMode={this.props.reorderMode}
						/>
					);
				}
			});

			return <div className="ruleContainer">{elements}</div>;
		}
	}
);

const Group = observer(
	class extends Component {
		constructor(props) {
			super(props);
			autoBind(this);
		}

		setDropdown(field, value) {
			if (field === "attributeID" && value.value !== "{{GROUP}}") {
				this.props.bitCase.bitCaseRuleGroups.splice(
					this.props.group.groupIndex,
					1
				);
				this.props.bitCase.bitCaseRules.splice(
					this.props.group.order,
					0,
					observable({
						id: shortid.generate(),
						attributeID: value.value,
						optionID: null,
						equalityOperator: "Equal To",
						chainedOperator: this.props.group.chainedOperator,
						order: this.props.group.order
					})
				);
			} else if (field === "chainedOperator") {
				if (value.value === "The End") {
					this.props.bitCase.bitCaseRuleGroups[this.props.group.groupIndex][
						field
					] =
						value.value;

					this.props.bitCase.bitCaseRules = this.props.bitCase.bitCaseRules.filter(
						item => {
							return item.order <= this.props.group.order;
						}
					);

					this.props.bitCase.bitCaseRuleGroups = this.props.bitCase.bitCaseRuleGroups.filter(
						item => {
							return item.order <= this.props.group.order;
						}
					);
				} else {
					this.props.bitCase.bitCaseRuleGroups[this.props.group.groupIndex][
						field
					] =
						value.value;

					if (this.props.allItemsIndex === this.props.allRules.length - 1) {
						this.props.bitCase.bitCaseRules.push(
							observable({
								id: shortid.generate(),
								attributeID: null,
								optionID: null,
								equalityOperator: null,
								chainedOperator: null,
								order: this.props.group.order + 1
							})
						);
					}
				}
			}
		}

		removeGroup() {
			const lastRule = this.props.allRules[this.props.allRules.length - 2];

			if (lastRule.type === "rule") {
				this.props.bitCase.bitCaseRules[
					lastRule.ruleArrayIndex
				].chainedOperator =
					"The End";
			} else {
				this.props.bitCase.bitCaseRuleGroups[
					lastRule.groupIndex
				].chainedOperator =
					"The End";
			}

			this.props.bitCase.bitCaseRuleGroups.splice(
				this.props.group.groupIndex,
				1
			);

			this.props.allRules.splice(this.props.allItemsIndex, 1);

			this.props.allRules.map((rule, newOrder) => {
				if (rule.type === "rule") {
					console.log(rule);
					this.props.bitCase.bitCaseRules[rule.ruleArrayIndex].order = newOrder;
				} else {
					this.props.bitCase.bitCaseRuleGroups[
						rule.groupIndex
					].order = newOrder;
				}
				return rule;
			});
		}

		render() {
			const attributeOptions = this.props.pageStore.availableAttributes.map(
				(attribute, index) => {
					return {
						value: attribute.attributeID,
						label: attribute.name
					};
				}
			);

			const firstDropdownOptions = [
				{
					value: "{{GROUP}}",
					label: "Group"
				},
				{
					label: "Attributes",
					options: attributeOptions
				}
			];

			return (
				<div className="group">
					<Row className="ruleRow">
						<Col xs={3}>
							<Select
								value={"{{GROUP}}"}
								onChange={this.setDropdown.bind(this, "attributeID")}
								options={firstDropdownOptions}
								clearable={false}
								disabled={this.props.reorderMode}
							/>
						</Col>
						<Col xs={3}>
							<Select
								value={this.props.group.chainedOperator}
								onChange={this.setDropdown.bind(this, "chainedOperator")}
								options={[
									{
										value: "The End",
										label: "The End"
									},
									{
										value: "And",
										label: "And"
									},
									{
										value: "Or",
										label: "Or"
									}
								]}
								clearable={false}
								className="chainedOperatorSelect"
								defaultValue={{ value: "The End", label: "The End" }}
								disabled={this.props.reorderMode}
							/>
							{this.props.reorderMode ? null : (
								<i className="far fa-times-circle" onClick={this.removeGroup} />
							)}
						</Col>
					</Row>
					<Row className="groupRules">{this.props.children}</Row>
				</div>
			);
		}
	}
);

const Rule = observer(
	class extends Component {
		constructor(props) {
			super(props);
			autoBind(this);
		}

		setDropdown(field, selectedAttribute, value) {
			if (field === "attributeID" && value.value === "{{GROUP}}") {
				this.props.bitCase.bitCaseRules.splice(this.props.ruleArrayIndex, 1);
				this.props.bitCase.bitCaseRuleGroups.push(
					observable({
						id: shortid.generate(),
						order: this.props.ruleIndex,
						bitCaseID: this.props.bitCase.id,
						groupID: null, //this will eventually be for nested groups
						chainedOperator: "The End",
						bitCaseRules: observable.array([
							observable({
								id: shortid.generate(),
								attributeID: null,
								optionID: null,
								passthroughValue: null,
								equalityOperator: null,
								chainedOperator: null,
								order: 0
							})
						])
					})
				);
			} else {
				if (this.props.rule.type === "rule") {
					this.props.bitCase.bitCaseRules[this.props.ruleArrayIndex][field] =
						value.value;
				} else {
					this.props.bitCase.bitCaseRuleGroups[
						this.props.group.groupIndex
					].bitCaseRules[this.props.ruleArrayIndex][field] =
						value.value;
				}

				if (field === "chainedOperator") {
					if (value.value === "The End") {
						if (this.props.rule.type === "rule") {
							this.props.bitCase.bitCaseRules[this.props.ruleArrayIndex][
								field
							] =
								value.value;

							this.props.bitCase.bitCaseRules = this.props.bitCase.bitCaseRules.filter(
								(item, index) => {
									return item.order <= this.props.rule.order;
								}
							);

							this.props.bitCase.bitCaseRuleGroups = this.props.bitCase.bitCaseRuleGroups.filter(
								(item, index) => {
									return item.order < this.props.rule.order;
								}
							);
						} else {
							this.props.bitCase.bitCaseRuleGroups[
								this.props.group.groupIndex
							].bitCaseRules[this.props.ruleIndex][field] =
								value.value;
							this.props.bitCase.bitCaseRuleGroups[
								this.props.group.groupIndex
							].bitCaseRules = this.props.bitCase.bitCaseRuleGroups[
								this.props.group.groupIndex
							].bitCaseRules.filter((item, index) => {
								return index <= this.props.rule.order;
							});
						}
					} else if (
						!this.props.group &&
						this.props.allItemsIndex === this.props.allRules.length - 1
					) {
						this.props.bitCase.bitCaseRules[this.props.ruleArrayIndex][field] =
							value.value;

						this.props.bitCase.bitCaseRules.push(
							observable({
								id: shortid.generate(),
								attributeID: null,
								optionID: null,
								passthroughValue: null,
								equalityOperator: null,
								chainedOperator: null,
								order: this.props.rule.order + 1
							})
						);
					} else if (
						this.props.group &&
						this.props.ruleArrayIndex + 1 ===
							this.props.group.bitCaseRules.length
					) {
						this.props.group.bitCaseRules.push(
							observable({
								id: shortid.generate(),
								attributeID: null,
								optionID: null,
								passthroughValue: null,
								equalityOperator: null,
								chainedOperator: null,
								order: this.props.ruleArrayIndex + 1
							})
						);
					}
				}

				if (field === "attributeID") {
					let rule;
					if (this.props.rule.type === "rule") {
						rule = this.props.bitCase.bitCaseRules[this.props.ruleArrayIndex];
					} else {
						rule = this.props.bitCase.bitCaseRuleGroups[
							this.props.group.groupIndex
						].bitCaseRules[this.props.ruleIndex];
					}
					rule.equalityOperator = "Equal To";
					rule.optionID = null;
					rule.passthroughValue = null;
				}

				if (field === "optionID" || field === "passthroughValue") {
					let rule;
					if (this.props.rule.type === "rule") {
						rule = this.props.bitCase.bitCaseRules[this.props.ruleArrayIndex];
					} else {
						rule = this.props.bitCase.bitCaseRuleGroups[
							this.props.group.groupIndex
						].bitCaseRules[this.props.ruleIndex];
					}

					if (!rule.chainedOperator) {
						rule.chainedOperator = "The End";
					}
				}

				if (field === "passthroughValue" && value.value === "Not Empty") {
					//insert the passthrough bit at the end of the editor
					this.props.quillJS.insertEmbed(
						this.props.quillJS.getLength() - 1,
						"passthroughBit",
						{
							text: `(${selectedAttribute.name})`,
							attributeid: selectedAttribute.attributeID,
							newLink: true
						}
					);
				}
			}
		}

		removeRule() {
			if (this.props.group) {
				this.props.group.bitCaseRules.splice(this.props.rule.ruleIndex, 1);

				this.props.group.bitCaseRules = this.props.group.bitCaseRules.map(
					(rule, newOrder) => {
						rule.order = newOrder;
						return rule;
					}
				);
			} else {
				const lastRule = this.props.allRules[this.props.allRules.length - 2];

				if (lastRule.type === "rule") {
					this.props.bitCase.bitCaseRules[
						lastRule.ruleArrayIndex
					].chainedOperator =
						"The End";
				} else {
					this.props.bitCase.bitCaseRuleGroups[
						lastRule.groupIndex
					].chainedOperator =
						"The End";
				}

				this.props.bitCase.bitCaseRules.splice(
					this.props.rule.ruleArrayIndex,
					1
				);

				this.props.allRules.splice(this.props.rule.ruleArrayIndex, 1);

				this.props.allRules.map((rule, newOrder) => {
					if (rule.type === "rule") {
						this.props.bitCase.bitCaseRules[
							rule.ruleArrayIndex
						].order = newOrder;
					} else {
						this.props.bitCase.bitCaseRuleGroups[
							rule.groupIndex
						].order = newOrder;
					}
					return rule;
				});
			}
		}

		render() {
			let selectedAttributeIndex;
			let selectedAttributeIsPassthrough = false;
			let selectedAttribute;

			const attributeOptions = this.props.pageStore.availableAttributes.map(
				(attribute, index) => {
					if (attribute.attributeID === this.props.rule.attributeID) {
						selectedAttributeIndex = index;
						selectedAttributeIsPassthrough = attribute.passthrough;
						selectedAttribute = attribute;
					}

					return {
						value: attribute.attributeID,
						label: attribute.name,
						optionsDropdown:
							attribute.type === "Passthrough"
								? [
										{
											value: "Empty",
											label: "Empty"
										},
										{
											value: "Not Empty",
											label: "Not Empty"
										}
								  ]
								: attribute.options.map(option => {
										return {
											value: option.id,
											label: option.name
										};
								  })
					};
				}
			);

			const firstDropdownOptions = [
				{
					value: "{{GROUP}}",
					label: "Group"
				},
				{
					label: "Attributes",
					options: attributeOptions
				}
			];

			return (
				<Row className="ruleRow">
					<Col xs={3}>
						<Select
							value={this.props.rule.attributeID}
							onChange={this.setDropdown.bind(this, "attributeID", null)}
							options={firstDropdownOptions}
							clearable={false}
							disabled={this.props.reorderMode}
						/>
					</Col>
					{this.props.rule.attributeID ? (
						<Col xs={3}>
							<span className="isText">is</span>
							<Select
								value={this.props.rule.equalityOperator}
								onChange={this.setDropdown.bind(this, "equalityOperator", null)}
								options={[
									{
										value: "Equal To",
										label: "Equal To"
									},
									{
										value: "Not Equal To",
										label: "Not Equal To"
									}
								]}
								clearable={false}
								className="equalitySelect"
								disabled={this.props.reorderMode}
							/>
						</Col>
					) : null}
					{this.props.rule.equalityOperator ? (
						<Col xs={3}>
							<Select
								value={
									selectedAttributeIsPassthrough
										? this.props.rule.passthroughValue
										: this.props.rule.optionID
								}
								onChange={this.setDropdown.bind(
									this,
									selectedAttributeIsPassthrough
										? "passthroughValue"
										: "optionID",
									selectedAttribute
								)}
								options={
									attributeOptions[selectedAttributeIndex].optionsDropdown
								}
								clearable={false}
								disabled={this.props.reorderMode}
							/>
						</Col>
					) : null}
					{this.props.rule.optionID || this.props.rule.passthroughValue ? (
						<Col xs={3}>
							<Select
								value={this.props.rule.chainedOperator}
								onChange={this.setDropdown.bind(this, "chainedOperator", null)}
								options={[
									{
										value: "The End",
										label: "The End"
									},
									{
										value: "And",
										label: "And"
									},
									{
										value: "Or",
										label: "Or"
									}
								]}
								clearable={false}
								className="chainedOperatorSelect"
								defaultValue={{ value: "The End", label: "The End" }}
								disabled={this.props.reorderMode}
							/>
							{this.props.reorderMode ? null : (
								<i className="far fa-times-circle" onClick={this.removeRule} />
							)}
						</Col>
					) : null}
				</Row>
			);
		}
	}
);
