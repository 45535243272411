import React, { Component } from "react";
import API from "../../../API";
import globalStore from "../../../GlobalStore";
import { observer } from "mobx-react";
import { Row, Col, Table, Button } from "react-bootstrap";
import Select from "react-select";

export default observer(
	class Retriever extends Component {
		state = { retrievers: [], messageRetrievers: [] };
		componentDidMount() {
			API("/retriever", "GET", {}, data => {
				this.setState({ retrievers: data.retrievers });
			});

			API(
				`/message/${this.props.match.params.messageID}/retrievers`,
				"GET",
				{},
				data => {
					this.setState({ messageRetrievers: data.messageRetrievers });
				}
			);
		}

		addMessageRetriever = () => {
			API(
				`/message/${this.props.match.params.messageID}/retrievers`,
				"POST",
				{
					retrieverID: this.state.selectedRetriever.value,
					elementID: this.state.selectedElement.value
				},
				data => {
					this.setState({ messageRetrievers: data.messageRetrievers });
				}
			);
		};

		removeMessageRetriever = messageRetrieverID => {
			API(
				`/message/${
					this.props.match.params.messageID
				}/retrievers/${messageRetrieverID}`,
				"DELETE",
				{},
				data => {
					this.setState({ messageRetrievers: data.messageRetrievers });
				}
			);
		};

		render() {
			return (
				<div id="techRetrievers">
					<div className="card" style={{ marginTop: 20 }}>
						<form className="card-body">
							<h5 style={{ fontWeight: 700 }}>Add Retriever</h5>
							<Row>
								<Col>
									<label>Retriever:</label>
									<Select
										onChange={val => {
											this.setState({ selectedRetriever: val });
										}}
										value={this.state.selectedRetriever}
										options={this.state.retrievers.map(option => {
											return { label: option.name, value: option.id };
										})}
									/>
								</Col>
								<Col>
									<label>Element:</label>
									<Select
										onChange={val => {
											this.setState({ selectedElement: val });
										}}
										value={this.state.selectedElement}
										options={globalStore.elements.map(element => {
											return { label: element.name, value: element.id };
										})}
									/>
								</Col>
							</Row>
							{this.state.selectedRetriever && this.state.selectedElement ? (
								<Row>
									<Col className="text-right" style={{ marginTop: 15 }}>
										<a
											className="saveButton"
											onClick={this.addMessageRetriever}
										>
											Add Retriever
										</a>
									</Col>
								</Row>
							) : null}
						</form>
					</div>

					{this.state.messageRetrievers.length > 0 &&
					this.state.retrievers.length > 0 ? (
						<div className="card" style={{ marginTop: 20 }}>
							<div className="card-body">
								<h5 style={{ fontWeight: 700 }}>Retrievers On This Message</h5>
								<Table striped bordered hover>
									<thead>
										<tr>
											<th>Retriever</th>
											<th>Element</th>
											<th style={{ width: 50 }}>Remove</th>
										</tr>
									</thead>
									<tbody>
										{this.state.messageRetrievers.map(messageRetriever => {
											const element = globalStore.elements.find(element => {
												return element.id === messageRetriever.elementID;
											});

											const retriever = this.state.retrievers.find(
												retriever => {
													return retriever.id === messageRetriever.retrieverID;
												}
											);
											return (
												<tr key={messageRetriever.id}>
													<td>{retriever.name}</td>
													<td>{element ? element.name : null}</td>
													<td>
														<Button
															variant="danger"
															onClick={() => {
																this.removeMessageRetriever(
																	messageRetriever.id
																);
															}}
															style={{ width: "100%" }}
														>
															<i className="fas fa-trash" />
														</Button>
													</td>
												</tr>
											);
										})}
									</tbody>
								</Table>
							</div>
						</div>
					) : null}
				</div>
			);
		}
	}
);
