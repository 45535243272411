import React, { Component } from "react";
import autoBind from "react-autobind";
import ManageMessages from "../../nonMessage/admin/ManageMessages";
import Dashboard from "../../nonMessage/Dashboard";
import SelectMessage from "../../nonMessage/SelectMessage";
import { Route, Switch } from "react-router-dom";
import ManageChatbots from "../../nonMessage/admin/ManageGlossaries";
import PublishKnowledgeBase from "../../nonMessage/admin/PublishKnowledgeBase";
import SearchMessages from "../../nonMessage/SearchMessages";
import SearchComments from "../../nonMessage/SearchComments";
import ManageUsers from "../../nonMessage/admin/ManageUsers";
import ManageAttsAndOpts from "../../nonMessage/admin/manageAttributes/ManageAttributes";
import CodeEditor from "../../nonMessage/admin/manageAttributes/CodeEditor";
import ManageRetrievers from "../../nonMessage/admin/ManageRetrievers";
import GoogleCloudNL from "../../nonMessage/admin/GoogleCloudNL";

export default class NonMessageRoutes extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {};
	}

	render() {
		return (
			<Switch>
				<Route exact path="/dashboard/dashboard" component={Dashboard} />
				<Route
					exact
					path="/dashboard/searchMessages"
					component={SearchMessages}
				/>
				<Route
					exact
					path="/dashboard/searchComments"
					component={SearchComments}
				/>
				<Route exact path="/selectMessage" component={SelectMessage} />
				<Route exact path="/admin/manageMessages" component={ManageMessages} />
				<Route
					exact
					path="/admin/manageGlossaries"
					component={ManageChatbots}
				/>
				<Route exact path="/admin/manageUsers" component={ManageUsers} />
				<Route
					exact
					path="/admin/manageGlossaries/:botID"
					component={ManageChatbots}
				/>
				<Route
					exact
					path="/admin/manageGlossaries/:botID/:glossaryID"
					component={ManageChatbots}
				/>
				<Route
					exact
					path="/admin/manageRetrievers"
					component={ManageRetrievers}
				/>
				<Route
					exact
					path="/admin/manageAttsAndOpts"
					component={ManageAttsAndOpts}
				/>
				<Route
					exact
					path="/admin/publishKnowledgeBase"
					component={PublishKnowledgeBase}
				/>
				<Route
					exact
					path="/admin/manageAttsAndOpts/codeEditor/:attributeID"
					component={CodeEditor}
				/>
				<Route exact path="/admin/googleCloudNL" component={GoogleCloudNL} />
			</Switch>
		);
	}
}
