import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import ToggleButton from "react-toggle-button";
import Footer from "../structure/Footer";
import { Link } from "react-router-dom";

export default class PaymentSetup extends Component {
	constructor() {
		super();

		this.state = {
			billingFreqYearly: false
		};
	}

	render() {
		return (
			<div id="paymentSetup" className="onboarding">
				<div className="container">
					<Row className="text-center" id="logoRow">
						<img src="/tuzagTCSLogo.svg" alt="tuzag TCS logo" />
					</Row>
					<Row>
						<p className="pageName">2. Payment Setup</p>
					</Row>
					<Row>
						<Col md={10} mdOffset={1}>
							<p>
								Brief instructions go here. Seriously, no more than two lines of
								friendly encouragement go here.
							</p>
						</Col>
					</Row>
					<Row id="designRow">
						<Col md={3}>
							<img src="/tuzagDesignLogo.svg" alt="tuzag Design logo" />
						</Col>
						<Col md={4}>
							<p>
								This is some light descriptive type letting users know what the
								hell it is.
							</p>
						</Col>
					</Row>
					<Row className="text-center billingFreqToggleRow">
						<span>Monthly</span>
						<ToggleButton
							containerStyle={{ display: "inline-block" }}
							inactiveLabel=""
							activeLabel=""
							colors={{
								activeThumb: {
									base: "#DD662E"
								},
								inactiveThumb: {
									base: "#00B6F4"
								},
								active: {
									base: "rgb(65,66,68)",
									hover: "rgb(95,96,98)"
								},
								inactive: {
									base: "rgb(65,66,68)",
									hover: "rgb(95,96,98)"
								}
							}}
							value={this.state.billingFreqYearly}
							onToggle={value => {
								this.setState({
									billingFreqYearly: !value
								});
							}}
						/>
						<span>Yearly</span>
					</Row>

					<Row>
						<Col md={3}>
							<div className="pricingBox" id="box1">
								<Row className="text-center numUsers">1-5 Users</Row>
								<Row>
									<Col xs={3} className="price">
										$1,000
									</Col>
									<Col xs={7}>yearly (17.6% discount)</Col>
								</Row>
								<Row className="monthlyRow">
									<Col xs={3} className="price">
										$100
									</Col>
									<Col xs={7}>monthly</Col>
								</Row>
							</div>
						</Col>
						<Col md={3}>
							<div className="pricingBox" id="box2">
								<Row className="text-center numUsers">1-5 Users</Row>
								<Row>
									<Col xs={3} className="price">
										$1,000
									</Col>
									<Col xs={7}>yearly (17.6% discount)</Col>
								</Row>
								<Row className="monthlyRow">
									<Col xs={3} className="price">
										$100
									</Col>
									<Col xs={7}>monthly</Col>
								</Row>
							</div>
						</Col>
						<Col md={3}>
							<div className="pricingBox" id="box3">
								<Row className="text-center numUsers">1-5 Users</Row>
								<Row>
									<Col xs={3} className="price">
										$1,000
									</Col>
									<Col xs={7}>yearly (17.6% discount)</Col>
								</Row>
								<Row className="monthlyRow">
									<Col xs={3} className="price">
										$100
									</Col>
									<Col xs={7}>monthly</Col>
								</Row>
							</div>
						</Col>
						<Col md={3}>
							<div className="pricingBox" id="box4">
								<Row className="text-center numUsers">1-5 Users</Row>
								<Row>
									<Col xs={3} className="price">
										$1,000
									</Col>
									<Col xs={7}>yearly (17.6% discount)</Col>
								</Row>
								<Row className="monthlyRow">
									<Col xs={3} className="price">
										$100
									</Col>
									<Col xs={7}>monthly</Col>
								</Row>
							</div>
						</Col>
					</Row>

					<Row id="tailorRow">
						<Col md={3}>
							<img src="/tuzagTailorLogo.svg" alt="tuzag Design logo" />
						</Col>
						<Col md={4}>
							<p>
								This is some light descriptive type letting users know what the
								hell it is.
							</p>
						</Col>
					</Row>

					<Row id="tailorPricingRow">
						<Col md={3}>
							<p>
								First 100,000 API calls/mo.:<br />
								$0.01 per call
							</p>
						</Col>
						<Col md={3}>
							<p>
								First 100,000 API calls/mo.:<br />
								$0.01 per call
							</p>
						</Col>
						<Col md={3}>
							<p>
								First 100,000 API calls/mo.:<br />
								$0.01 per call
							</p>
						</Col>
						<Col md={3}>
							<p>
								First 100,000 API calls/mo.:<br />
								$0.01 per call
							</p>
						</Col>
					</Row>

					<Row>
						<p className="pageName">Payment Info</p>
						<p>TBD</p>
					</Row>

					<Row>
						<Link to="/gettingStarted">
							<div className="orangeButton">Save and continue...</div>
						</Link>
					</Row>
				</div>
				<Footer color="purple" />
			</div>
		);
	}
}
