import { Component } from "react";
import autoBind from "react-autobind";
import API from "../../../../API";
import Textarea from "react-textarea-autosize";
import titleCase from "title-case";
import shortid from "shortid";
import camelcase from "camel-case";
import Loading from "../../../structure/Loading";
import ReactTable from "react-table";
import React from "react";
import Select from "react-select";

export default class OptionTable extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = { data: "Loading", activeEdits: "" };
	}

	filterCaseInsensitive = (filter, row) => {
		const id = filter.pivotId || filter.id;
		return row[id] !== undefined
			? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
			: true;
	};

	componentWillReceiveProps(nextProps, nextContext) {
		if (this.props.attribute.id !== nextProps.attribute.id) {
			this.setState({ data: "Loading" }, () => {
				this.componentDidMount();
			});
		}
	}

	componentDidMount() {
		API(
			`/manageAttributes/${this.props.attribute.id}/options`,
			"GET",
			{},
			data => {
				this.setState({ data: data.options });
			}
		);
	}

	renderDeleteCol() {
		return (
			<div>
				<i className="fas fa-trash" />
			</div>
		);
	}

	renderEditCol(cellInfo) {
		return (
			<div>
				{JSON.stringify(cellInfo.nestingPath) === this.state.activeEdits ? (
					<i className="fas fa-save" />
				) : (
					<i className="fas fa-pencil-alt" />
				)}
			</div>
		);
	}

	renderNewCol() {
		return (
			<div>
				<i className="fas fa-plus" />
			</div>
		);
	}

	renderEditable(cellInfo) {
		if (JSON.stringify(cellInfo.nestingPath) === this.state.activeEdits) {
			return (
				<input
					className="form-control"
					style={{ minHeight: 38 }}
					onChange={evt => {
						let dataCopy = [...this.state.data];
						dataCopy[cellInfo.index].name = evt.target.value;
						const state = this.table.getResolvedState();

						this.setState({ data: dataCopy }, () => {
							this.table.setStateWithData({
								expanded: state.expanded
							});
						});
					}}
					value={
						this.state.data[cellInfo.index].name
							? this.state.data[cellInfo.index].name
							: ""
					}
				/>
			);
		} else {
			return <span>{this.state.data[cellInfo.index].name}</span>;
		}
	}

	renderEditableLabel(cellInfo) {
		if (JSON.stringify(cellInfo.nestingPath) === this.state.activeEdits) {
			return (
				<div>
					<input
						className="form-control"
						style={{ minHeight: 38 }}
						disabled={this.state.data[cellInfo.index].label === "[[HIDDEN]]"}
						onChange={evt => {
							let dataCopy = [...this.state.data];
							dataCopy[cellInfo.index].label = evt.target.value;
							const state = this.table.getResolvedState();

							this.setState({ data: dataCopy }, () => {
								this.table.setStateWithData({
									expanded: state.expanded
								});
							});
						}}
						value={
							this.state.data[cellInfo.index].label
								? this.state.data[cellInfo.index].label
								: ""
						}
					/>
					<label htmlFor={`hide${cellInfo.index}`}>Hide: </label>{" "}
					<input
						id={`hide${cellInfo.index}`}
						type="checkbox"
						checked={this.state.data[cellInfo.index].label === "[[HIDDEN]]"}
						onChange={evt => {
							let dataCopy = [...this.state.data];
							dataCopy[cellInfo.index].label = evt.target.checked
								? "[[HIDDEN]]"
								: "";
							const state = this.table.getResolvedState();

							this.setState({ data: dataCopy }, () => {
								this.table.setStateWithData({
									expanded: state.expanded
								});
							});
						}}
					/>
				</div>
			);
		} else {
			return <span>{this.state.data[cellInfo.index].label}</span>;
		}
	}

	renderEditableCheckbox(cellInfo) {
		if (JSON.stringify(cellInfo.nestingPath) === this.state.activeEdits) {
			return (
				<input
					type="checkbox"
					checked={this.state.data[cellInfo.index].defaultOption}
					onChange={() => {
						let dataCopy = [...this.state.data];
						dataCopy[cellInfo.index].defaultOption = !dataCopy[cellInfo.index]
							.defaultOption;

						const state = this.table.getResolvedState();

						this.setState({ data: dataCopy }, () => {
							this.table.setStateWithData({
								expanded: state.expanded
							});
						});
					}}
				/>
			);
		} else {
			return (
				<input
					type="checkbox"
					disabled
					checked={
						this.state.data[cellInfo.index].defaultOption
							? this.state.data[cellInfo.index].defaultOption
							: false
					}
				/>
			);
		}
	}

	renderEditableGlossary(cellInfo) {
		if (JSON.stringify(cellInfo.nestingPath) === this.state.activeEdits) {
			return (
				<Select
					// this line makes the menu from the dropdown appear over the next row instead of under it
					menuPortalTarget={document.body}
					options={this.props.glossaries}
					onChange={selectedValue => {
						let dataCopy = [...this.state.data];
						dataCopy[cellInfo.index].glossaryID = selectedValue
							? selectedValue.value
							: null;
						const state = this.table.getResolvedState();

						this.setState({ data: dataCopy }, () => {
							this.table.setStateWithData({
								expanded: state.expanded
							});
						});
					}}
					value={this.props.glossaries.find(glossary => {
						return (
							glossary.value === this.state.data[cellInfo.index].glossaryID
						);
					})}
					isClearable={true}
				/>
			);
		} else {
			const glossary = this.props.glossaries.find(glossary => {
				return glossary.value === this.state.data[cellInfo.index].glossaryID;
			});
			return <span>{glossary ? glossary.label : ""}</span>;
		}
	}

	renderEditableKeywords(cellInfo) {
		if (JSON.stringify(cellInfo.nestingPath) === this.state.activeEdits) {
			return (
				<Textarea
					className="form-control"
					value={this.state.data[cellInfo.index].keywords}
					onChange={evt => {
						let dataCopy = [...this.state.data];
						dataCopy[cellInfo.index].keywords = evt.target.value;

						const state = this.table.getResolvedState();

						this.setState({ data: dataCopy }, () => {
							this.table.setStateWithData({
								expanded: state.expanded
							});
						});
					}}
					style={{ minHeight: 38 }}
				/>
			);
		} else {
			return <span>{this.state.data[cellInfo.index].keywords}</span>;
		}
	}

	renderEditableSentiment(cellInfo) {
		if (JSON.stringify(cellInfo.nestingPath) === this.state.activeEdits) {
			return (
				<Select
					// this line makes the menu from the dropdown appear over the next row instead of under it
					menuPortalTarget={document.body}
					options={[
						{ value: "strongPositive", label: "Strong Positive" },
						{ value: "positive", label: "Positive" },
						{ value: "neutral", label: "Neutral" },
						{ value: "negative", label: "Negative" },
						{ value: "strongNegative", label: "Strong Negative" }
					]}
					onChange={selectedValue => {
						let dataCopy = [...this.state.data];
						dataCopy[cellInfo.index].sentiment = selectedValue
							? selectedValue.value
							: null;
						const state = this.table.getResolvedState();

						this.setState({ data: dataCopy }, () => {
							this.table.setStateWithData({
								expanded: state.expanded
							});
						});
					}}
					value={{
						label: titleCase(this.state.data[cellInfo.index].sentiment),
						value: this.state.data[cellInfo.index].sentiment
					}}
					isClearable={true}
				/>
			);
		} else {
			return (
				<span>
					{this.state.data[cellInfo.index].sentiment
						? titleCase(this.state.data[cellInfo.index].sentiment)
						: null}
				</span>
			);
		}
	}

	deleteColumnClicked(row, instance, state) {
		if (window.confirm("Are you sure you want to delete this option?")) {
			if (shortid.isValid(row.original.id)) {
				const dataToSet = this.state.data.filter(attribute => {
					return attribute.id !== row.original.id;
				});
				this.setState({ data: dataToSet }, () => {
					instance.setStateWithData({
						expanded: state.expanded
					});
				});
			} else {
				API(`/option/${row.original.id}`, "DELETE", {}, data => {
					if (data.error) {
						if (window.confirm(data.message)) {
							window.open(data.url, "_blank");
						}
					} else {
						const dataToSet = this.state.data.filter(attribute => {
							return attribute.id !== row.original.id;
						});
						this.setState({ data: dataToSet }, () => {
							instance.setStateWithData({
								expanded: state.expanded
							});
						});
					}
				});
			}
		}
	}

	newColumnClicked(row, instance, state) {
		this.setState(
			{
				data: [
					...this.state.data,
					{
						name: "",
						default: false,
						id: shortid.generate(),
						keywords: "",
						defaultOption: false
					}
				],
				activeEdits: `[${this.state.data.length}]`
			},
			() => {
				instance.setStateWithData({
					expanded: state.expanded
				});
			}
		);
	}

	editColumnClicked(row, instance, state) {
		this.setState(
			{
				activeEdits: JSON.stringify(row.nestingPath)
			},
			() => {
				instance.setStateWithData({
					expanded: state.expanded
				});
			}
		);
	}

	saveColumnClicked(row, instance, state) {
		let toSend = { ...row.original };
		toSend.sourceValue = camelcase(toSend.name);

		API(
			`/attribute/${this.props.attribute.id}/options`,
			"PUT",
			toSend,
			data => {
				this.setState({ data: data.options, activeEdits: "" }, () => {
					instance.setStateWithData({
						expanded: state.expanded
					});
				});
			}
		);
	}

	render() {
		return this.state.data === "Loading" ? (
			<Loading />
		) : (
			<ReactTable
				data={this.state.data}
				defaultFilterMethod={this.filterCaseInsensitive}
				columns={[
					{
						accessor: "id",
						show: false,
						sortMethod: (a, b) => {
							if (shortid.isValid(a)) {
								return -1;
							} else {
								if (a === b) {
									return 0;
								} else {
									return b - a;
								}
							}
						}
					},
					{
						accessor: "name",
						Header: "Option Name",
						Cell: this.renderEditable,
						Footer: (
							<span
								className="btn btn-primary"
								onClick={() => {
									this.newColumnClicked(
										{},
										this.table,
										this.table.getResolvedState()
									);
								}}
							>
								Add Option
							</span>
						)
					},
					{
						accessor: "label",
						Header: "Option Label",
						Cell: this.renderEditableLabel
					},
					{
						accessor: "glossary",
						Header: "Glossary",
						Cell: this.renderEditableGlossary,
						filterable: false
					},
					{
						accessor: "keywords",
						Header: "Keywords",
						Cell: this.renderEditableKeywords
					},
					{
						accessor: "sentiment",
						Header: "Sentiment",
						Cell: this.renderEditableSentiment,
						filterable: false
					},
					{
						Header: "Default",
						accessor: "defaultOption",
						Cell: this.renderEditableCheckbox,
						width: 60,
						filterable: false,
						style: {
							backgroundColor:
								this.state.data &&
								this.state.data.find(option => {
									return option.defaultOption;
								})
									? null
									: "red"
						}
					},
					{
						Cell: this.renderNewCol,
						width: 25,
						filterable: false,
						id: "newCol"
					},
					{
						Cell: this.renderEditCol,
						width: 25,
						filterable: false,
						id: "editCol"
					},
					{
						Cell: this.renderDeleteCol,
						width: 25,
						filterable: false,
						id: "deleteCol"
					}
				]}
				filterable={true}
				className="-striped -highlight"
				defaultPageSize={
					this.state.data && this.state.data.length < 45
						? this.state.data.length + 3
						: 50
				}
				ref={el => {
					this.table = el;
				}}
				defaultSorted={[{ id: "id", desc: true }]}
				getTdProps={(state, row, column, instance) => {
					return {
						onClick: (e, handleOriginal) => {
							if (column.id === "editCol") {
								if (
									this.state.activeEdits.includes(
										JSON.stringify(row.nestingPath)
									)
								) {
									this.saveColumnClicked(row, instance, state);
								} else {
									//edit button pressed
									this.editColumnClicked(row, instance, state);
								}
							} else if (column.id === "deleteCol") {
								this.deleteColumnClicked(row, instance, state);
							} else if (column.id === "newCol") {
								this.newColumnClicked(row, instance, state);
							}
							if (handleOriginal) {
								handleOriginal();
								// instance.setStateWithData({expanded: state.expanded});
							}
						}
					};
				}}
			/>
		);
	}
}
