import React, { Component } from "react";
import autoBind from "react-autobind";
import { observer } from "mobx-react";
import { Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import shortid from "shortid";
import { observable } from "mobx";
import { Checkbox } from "@rmwc/checkbox";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

export default observer(
	class RuleContainerV2 extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			if (
				!("bitCaseRules" in this.props.bitCase.parentRuleGroup) ||
				this.props.bitCase.parentRuleGroup.bitCaseRules.length === 0
			) {
				this.props.bitCase.parentRuleGroup.bitCaseRules = observable.array([
					observable.object({
						id: shortid.generate(),
						groupID: this.props.bitCase.parentRuleGroup.id,
						attributeID: null,
						equalityOperator: null,
						passthroughValue: null,
						optionID: null
					})
				]);
				this.props.bitCase.parentRuleGroup.chainedOperator = "The End";
				this.props.bitCase.parentRuleGroup.id = shortid.generate();
			}
		}

		ungroup(bitCaseRule, group, index) {
			group.bitCaseRules.splice(index, 1);
			this.props.bitCase.parentRuleGroup.bitCaseRules.push(bitCaseRule);

			if (group.bitCaseRules.length === 1) {
				const groupIndex = this.props.bitCase.parentRuleGroup.bitCaseRules.findIndex(
					rule => {
						return rule.id === group.id && "chainedOperator" in rule;
					}
				);
				this.props.bitCase.parentRuleGroup.bitCaseRules.splice(
					groupIndex,
					1,
					group.bitCaseRules[0]
				);
			}
		}

		render() {
			return (
				<div id="ruleContainer">
					<Row
						style={{
							marginLeft: 20,
							marginRight: 20,
							marginTop: 10,
							marginBottom: 10
						}}
					>
						{this.props.bitCase && this.props.bitCase.parentRuleGroup ? (
							<Col xs={12} className="ruleTree">
								<RuleGroup
									key={"parentgroup-" + this.props.bitCase.id}
									bitCase={this.props.bitCase}
									pageStore={this.props.pageStore}
									group={this.props.bitCase.parentRuleGroup}
									caseCheckboxes={this.props.caseCheckboxes}
									ungroup={this.ungroup}
									parentRuleGroup={this.props.bitCase.parentRuleGroup}
									readOnly={this.props.readOnly}
								/>
							</Col>
						) : null}
					</Row>
				</div>
			);
		}
	}
);

const RuleGroup = observer(
	class RuleGroup extends Component {
		constructor(props) {
			super(props);
			autoBind(this);
		}

		addRule() {
			this.props.group.bitCaseRules.push(
				observable.object({
					id: shortid.generate(),
					groupID: this.props.group.id,
					attributeID: null,
					equalityOperator: null,
					optionID: null,
					bitCaseID: this.props.bitCase.id
				})
			);

			if (
				this.props.group.chainedOperator === "The End" &&
				this.props.group.bitCaseRules.length > 1
			) {
				this.props.group.chainedOperator = "And";
			}
		}

		deleteRule(indexNum) {
			this.props.group.bitCaseRules.splice(indexNum, 1);

			if (
				this.props.group.bitCaseRules.length === 1 &&
				this.props.nestedGroup
			) {
				const groupIndex = this.props.parentRuleGroup.bitCaseRules.findIndex(
					rule => {
						return rule.id === this.props.group.id;
					}
				);

				this.props.group.bitCaseRules[0].groupID = this.props.group.id;

				this.props.parentRuleGroup.bitCaseRules.splice(
					groupIndex,
					1,
					this.props.group.bitCaseRules[0]
				);
			} else if (this.props.group.bitCaseRules.length === 0) {
				this.props.group.chainedOperator = "The End";
				this.props.group.bitCaseRules.push(
					observable.object({
						id: shortid.generate(),
						groupID: this.props.group.id,
						attributeID: null,
						equalityOperator: null,
						optionID: null
					})
				);
			} else if (this.props.group.bitCaseRules.length === 1) {
				this.props.group.chainedOperator = "The End";
			}
		}

		toggleChainedOperator() {
			if (!this.props.readOnly) {
				if (this.props.group.chainedOperator === "And") {
					this.props.group.chainedOperator = "Or";
				} else {
					this.props.group.chainedOperator = "And";
				}
			}
		}

		render() {
			let style = { display: "flex" };
			if (this.props.nestedGroup) {
				style.border = "1px solid black";

				if (this.props.parentRuleGroup.bitCaseRules.length === 1) {
					style.borderRadius = "10px 10px 10px 10px";
				} else if (this.props.indexNum === 0) {
					style.borderRadius = "10px 10px 0 0";
				} else if (
					this.props.indexNum ===
					this.props.parentRuleGroup.bitCaseRules.length - 1
				) {
					style.borderRadius = "0 0 10px 10px";
				}

				style.marginLeft = -15;
				style.marginRight = -15;
				style.paddingTop = 5;
				style.paddingRight = 5;
				style.paddingLeft = 5;
				style.paddingBottom = 10;
			}
			const chainedOperator = this.props.group.chainedOperator;
			return (
				<Row style={style}>
					<Col xs={12}>
						<Row style={{ display: "flex" }}>
							<Col
								xs={chainedOperator === "The End" ? 12 : 11}
								className={this.props.nestedGroup ? "ruleGroup" : "ruleRows"}
							>
								{this.props.group.bitCaseRules.map((bitCaseRule, index) => {
									if ("bitCaseRules" in bitCaseRule) {
										return (
											<RuleGroup
												key={"group-" + bitCaseRule.id}
												pageStore={this.props.pageStore}
												group={bitCaseRule}
												nestedGroup={true}
												caseCheckboxes={this.props.caseCheckboxes}
												ungroup={this.props.ungroup}
												parentRuleGroup={this.props.parentRuleGroup}
												indexNum={index}
												bitCase={this.props.bitCase}
												readOnly={this.props.readOnly}
											/>
										);
									} else {
										return (
											<RuleRow
												bitCaseRule={bitCaseRule}
												key={bitCaseRule.id}
												pageStore={this.props.pageStore}
												indexNum={index}
												deleteRule={this.deleteRule}
												bitCaseRules={this.props.group.bitCaseRules}
												group={this.props.group}
												nestedGroup={this.props.nestedGroup}
												caseCheckboxes={this.props.caseCheckboxes}
												ungroup={this.props.ungroup}
												parentRuleGroup={this.props.parentRuleGroup}
												readOnly={this.props.readOnly}
											/>
										);
									}
								})}
							</Col>
							{chainedOperator === "The End" ? null : (
								<Col
									xs={1}
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										flex: 1,
										cursor: this.props.readOnly ? "inherit" : "pointer"
									}}
									onClick={this.toggleChainedOperator}
								>
									<p style={{ display: "flex" }}>
										{this.props.group.chainedOperator}
									</p>
								</Col>
							)}
						</Row>
						{this.props.readOnly ? null : (
							<Row>
								<Button
									style={{ width: "100%", marginTop: 10 }}
									onClick={this.addRule}
									variant="outline-secondary"
								>
									Add Rule{this.props.nestedGroup ? " to Group" : null}
								</Button>
							</Row>
						)}
					</Col>
				</Row>
			);
		}
	}
);

const RuleRow = observer(
	class RuleRow extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			props.caseCheckboxes.set(props.bitCaseRule.id, false);
		}

		toggleEqualityOperator() {
			if (!this.props.readOnly) {
				if (this.props.bitCaseRule.equalityOperator === "Equal To") {
					this.props.bitCaseRule.equalityOperator = "Not Equal To";
				} else {
					this.props.bitCaseRule.equalityOperator = "Equal To";
				}
			}
		}

		render() {
			const attribute = this.props.pageStore.availableAttributes.find(
				attribute => {
					return attribute.attributeID === this.props.bitCaseRule.attributeID;
				}
			);

			let rowStyle = {};

			if (this.props.bitCaseRules.length === 1) {
				//single rule case
				rowStyle = {
					paddingTop: 10,
					paddingBottom: 10,
					background: "white",
					borderLeft: "1px solid #343d47",
					borderRight: "1px solid #343d47",
					borderTop: "1px solid #343d47",
					borderBottom: "1px solid #343d47",
					borderRadius: "10px 10px 10px 10px"
				};
			} else if (this.props.indexNum === 0) {
				//first rule in group
				rowStyle = {
					marginTop: 5,
					paddingTop: 5,
					paddingBottom: 10,
					background: "white",
					borderTop: "1px solid #343d47",
					borderLeft: "1px solid #343d47",
					borderRight: "1px solid #343d47",
					borderRadius: "10px 10px 0 0",
					borderBottom: "1px solid gray"
				};
			} else if (this.props.indexNum + 1 === this.props.bitCaseRules.length) {
				//last rule in group
				rowStyle = {
					paddingTop: 10,
					paddingBottom: 10,
					background: "white",
					borderBottom: "1px solid #343d47",
					borderLeft: "1px solid #343d47",
					borderRight: "1px solid #343d47",
					borderRadius: "0 0 10px 10px"
				};
			} else {
				//any middle rules
				rowStyle = {
					paddingTop: 10,
					paddingBottom: 10,
					background: "white",
					borderLeft: "1px solid #343d47",
					borderRight: "1px solid #343d47",
					borderBottom: "1px solid gray"
				};
			}

			let options = [];
			if (attribute) {
				options = attribute.options.map(option => {
					return {
						value: option.id,
						label: option.name
					};
				});
			}

			return (
				<div>
					<ContextMenuTrigger id={`ruleRow${this.props.bitCaseRule.id}`}>
						<Row style={rowStyle} className="ruleRow">
							{this.props.readOnly ? null : (
								<Col xs={1}>
									{this.props.nestedGroup ? (
										<div style={{ paddingTop: 7 }}>
											<i
												onClick={this.props.ungroup.bind(
													this,
													this.props.bitCaseRule,
													this.props.group,
													this.props.indexNum
												)}
												className="fas fa-arrow-to-left"
												style={{ fontSize: 18, marginLeft: 7 }}
											/>
										</div>
									) : (
										<Checkbox
											checked={
												this.props.caseCheckboxes.get(
													this.props.bitCaseRule.id
												) || false
											}
											onChange={evt => {
												this.props.caseCheckboxes.set(
													this.props.bitCaseRule.id,
													evt.target.checked
												);
											}}
										/>
									)}
								</Col>
							)}
							{this.props.readOnly ? null : (
								<Col xs={1} style={{ paddingTop: 7 }}>
									<i
										className="far fa-trash-alt"
										style={{ fontSize: 18, cursor: "pointer" }}
										onClick={this.props.deleteRule.bind(
											this,
											this.props.indexNum
										)}
									/>
								</Col>
							)}
							<Col
								xs={{
									span: this.props.readOnly ? 5 : 4,
									offset: this.props.readOnly ? 1 : 0
								}}
								style={{ paddingTop: attribute ? 10 : 0 }}
							>
								{attribute ? (
									attribute.name
								) : (
									<AttributeSelector
										pageStore={this.props.pageStore}
										bitCaseRule={this.props.bitCaseRule}
										readOnly={this.props.readOnly}
									/>
								)}
							</Col>
							{attribute ? (
								<Col
									xs={2}
									onClick={this.toggleEqualityOperator}
									style={{
										paddingTop: 10,
										cursor: this.props.readOnly ? "inherit" : "pointer"
									}}
								>
									{this.props.bitCaseRule.equalityOperator}
								</Col>
							) : null}
							{attribute ? (
								<Col xs={4}>
									{attribute.passthrough ? (
										<Select
											styles={{
												menu: styles => Object.assign(styles, { zIndex: 1000 })
											}}
											disabled={this.props.readOnly}
											onChange={value => {
												if (value) {
													this.props.bitCaseRule.passthroughValue = value.value;
												} else {
													this.props.bitCaseRule.passthroughValue = null;
												}
											}}
											value={{
												value: this.props.bitCaseRule.passthroughValue,
												label: this.props.bitCaseRule.passthroughValue
											}}
											options={[
												"Empty",
												"Not Empty",
												"Prefer Not To Answer",
												"Removed By User"
											].map(value => {
												return {
													value,
													label: value
												};
											})}
										/>
									) : (
										<Select
											styles={{
												menu: styles => Object.assign(styles, { zIndex: 1000 })
											}}
											disabled={this.props.readOnly}
											onChange={value => {
												if (value) {
													this.props.bitCaseRule.optionID = value.value;
												} else {
													this.props.bitCaseRule.optionID = null;
												}
											}}
											value={options.find(option => {
												return option.value === this.props.bitCaseRule.optionID;
											})}
											options={options}
										/>
									)}
								</Col>
							) : null}
						</Row>
					</ContextMenuTrigger>
					<ContextMenu id={`ruleRow${this.props.bitCaseRule.id}`}>
						<MenuItem
							onClick={this.props.deleteRule.bind(this, this.props.indexNum)}
						>
							Delete Rule
						</MenuItem>
					</ContextMenu>
				</div>
			);
		}
	}
);

class AttributeSelector extends Component {
	render() {
		return (
			<Select
				styles={{
					menu: styles => Object.assign(styles, { zIndex: 1000 })
				}}
				onChange={value => {
					this.props.bitCaseRule.attributeID = value.value;

					if (!this.props.bitCaseRule.equalityOperator) {
						this.props.bitCaseRule.equalityOperator = "Equal To";
					}
				}}
				options={this.props.pageStore.availableAttributes.map(attribute => {
					return {
						value: attribute.attributeID,
						label: attribute.name
					};
				})}
			/>
		);
	}
}
