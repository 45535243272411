import React, { Component } from "react";
import autoBind from "react-autobind";
import { Row, Col, Container } from "react-bootstrap";
import API from "../../../API";
import camelcase from "camel-case";
import CopyToClipboard from "react-copy-to-clipboard";
import Snackbar from "../../structure/Snackbar";
import globalStore from "../../../GlobalStore";
import { observer } from "mobx-react";

export default observer(
	class SMS extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				snackbarMessage: "",
				variableType: null
			};
		}

		componentDidMount() {
			this.getData(this.props);
		}

		componentWillReceiveProps(nextProps) {
			this.getData(nextProps);
		}

		getData(props) {
			API(`/message/${props.match.params.messageID}`, "GET", {}, data => {
				this.setState({
					body: data.message.smsContentElementID
				});
			});
		}

		setBody(elementID) {
			this.setState({ body: elementID });
		}

		save() {
			API(
				`/message/${this.props.match.params.messageID}/template/sms`,
				"PUT",
				{
					smsContentElementID: this.state.body
				},
				data => {
					this.setState({
						body: data.message.smsContentElementID,
						snackbarMessage: "Saved!"
					});
				}
			);
		}

		render() {
			return (
				<Container fluid id="technicalSMS">
					<Row>
						<Col>
							<Row>
								<div className="colName">Element Map</div>
							</Row>
							<Row className="messageIDRow">
								<Col xs={4}>
									<h3>Message ID:</h3>
								</Col>
								<Col xs={4}>
									<p>{this.props.match.params.messageID}</p>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="elementTableRow">
						<Col>
							<Row className="headers">
								<Col xs={4}>
									<h3 className="purpleColor">Element Name</h3>
								</Col>
								<Col xs={3}>
									<h3 className="purpleColor">Element ID</h3>
								</Col>
								<Col xs={4}>
									<h3 className="purpleColor">Element Slug</h3>
								</Col>
								<Col xs={1}>
									<h3 className="purpleColor">Body</h3>
								</Col>
							</Row>
							{globalStore.elements.map(element => {
								return (
									<Row key={element.id} className="elementRow">
										<CopyToClipboard
											onCopy={() =>
												this.setState({ snackbarMessage: "Copied" })
											}
											text={element.name}
											className="copyRow"
										>
											<Col xs={4}>
												<h3 className="elementName">
													{element.name}
													<i className="fa fa-clipboard" />
												</h3>
											</Col>
										</CopyToClipboard>

										<CopyToClipboard
											onCopy={() =>
												this.setState({ snackbarMessage: "Copied" })
											}
											text={element.id}
											className="copyRow"
										>
											<Col xs={3}>
												<p>
													{element.id}
													<i className="fa fa-clipboard" />
												</p>
											</Col>
										</CopyToClipboard>

										<CopyToClipboard
											onCopy={() =>
												this.setState({ snackbarMessage: "Copied" })
											}
											text={camelcase(element.name)}
											className="copyRow"
										>
											<Col xs={4}>
												<p>
													{camelcase(element.name)}
													<i className="fa fa-clipboard" />
												</p>
											</Col>
										</CopyToClipboard>

										<Col xs={1} className="text-center">
											<input
												type="radio"
												name="body"
												onChange={this.setBody.bind(this, element.id)}
												checked={this.state.body === element.id}
											/>
										</Col>
									</Row>
								);
							})}
						</Col>
					</Row>
					<Row>
						<Col className="text-right">
							<a onClick={this.save} className="saveButton" type="submit">
								Save
							</a>
						</Col>
					</Row>
					<Snackbar message={this.state.snackbarMessage} />
				</Container>
			);
		}
	}
);
