import React, { Component } from "react";
import firebase from "firebase/app";
import firebaseui from "firebaseui";
import { Row, Col } from "react-bootstrap";
import Footer from "./structure/Footer";
import { Link, Redirect } from "react-router-dom";
import globalStore from "../GlobalStore";
import { observer } from "mobx-react";

let authUI;

export default observer(
	class Login extends Component {
		componentDidMount() {
			if (process.env.NODE_ENV !== "development" || true) {
				const uiConfig = {
					signInSuccessUrl: "/postLogin",
					privacyPolicyUrl: "/privacyPolicy",
					signInOptions: [
						// Leave the lines as is for the providers you want to offer your users.
						firebase.auth.EmailAuthProvider.PROVIDER_ID,
						firebase.auth.GoogleAuthProvider.PROVIDER_ID,
						firebase.auth.FacebookAuthProvider.PROVIDER_ID,
						firebase.auth.TwitterAuthProvider.PROVIDER_ID,
						firebase.auth.GithubAuthProvider.PROVIDER_ID
						// firebase.auth.PhoneAuthProvider.PROVIDER_ID
					],
					// Terms of service url.
					tosUrl: "/terms-of-service" //TODO
				};

				if (!authUI) {
					authUI = new firebaseui.auth.AuthUI(firebase.auth());
				}

				// Initialize the FirebaseUI Widget using Firebase.
				// The start method will wait until the DOM is loaded.
				authUI.start("#firebaseui-auth-container", uiConfig);
			}
		}

		componentWillUnmount() {
			authUI.reset();
		}

		render() {
			return globalStore.firebaseUser ? (
				<Redirect to="/dashboard/dashboard" />
			) : (
				<div id="login" className="onboarding">
					<div className="container">
						<Row>
							<Col
								md={{span: 10, offset: 1}}
								className="text-center"
								id="mastheadRow"
							>
								<img
									src="./masthead.svg"
									id="masthead"
									alt="Masthead of tuzagTCS, tuzagDesign, and tuzagTailor logos"
								/>
							</Col>
						</Row>
						<Row className="loginBoxRow">
							<Col md={4}>
								<div className="loginBox">
									<span className="boxName" id="signInBoxName">
										Sign In
									</span>
									<div id="firebaseui-auth-container" />
								</div>
							</Col>
							<Col md={4}>
								<div className="loginBox">
									<span className="boxName" id="signUpBoxName">
										Sign Up
									</span>
									<p id="signUpText">
										To get started, pick an option to the left to create an
										account.
									</p>
								</div>
							</Col>
							<Col md={4}>
								<div className="loginBox">
									<span className="boxName" id="learnMoreBoxName">
										Learn More
									</span>
									<br />
									<img
										id="learnMoreBubble"
										src="/bubble.svg"
										alt="tuzag logo"
									/>
									<p id="learnMoreText">
										Learn more about our company and our other tailored
										communication solutions.
									</p>
									<div id="goButton">
										<Link to="/steps">Go</Link>
									</div>
								</div>
							</Col>
						</Row>
					</div>
					<Footer color="purple" />
				</div>
			);
		}
	}
);
