import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./components/Login";
import RouteContainer from "./components/structure/routes/RouteContainer";
import Steps from "./components/onboarding/Steps";
import AccountSetup from "./components/onboarding/AccountSetup";
import PaymentSetup from "./components/onboarding/PaymentSetup";
import GettingStarted from "./components/onboarding/GettingStarted";
import firebase from "firebase/app";
import API from "./API.jsx";
import HttpsRedirect from "react-https-redirect";
import "react-select-plus/dist/react-select-plus.css";
import { Notification } from "react-notification";
import { observer } from "mobx-react";
import globalStore from "./GlobalStore";
import ExportTemplate from "./components/message/testing/ExportTemplate";
import "material-components-web/dist/material-components-web.css";
import Modal from "react-modal";
import PostLogin from "./components/PostLogin";
import Loading from "./components/structure/Loading";
import "@material/theme/dist/mdc.theme.css";
import { ThemeProvider } from "@rmwc/theme";

const config = {
	apiKey: "AIzaSyD02nmpiQ8Ag-HDQ5tK-Aq3-uvSn0UzQYU",
	authDomain: "tuzagv2firebase.firebaseapp.com",
	databaseURL: "https://tuzagv2firebase.firebaseio.com",
	projectId: "tuzagv2firebase",
	storageBucket: "",
	messagingSenderId: "48228009463"
};
firebase.initializeApp(config);

let App = observer(
	class App extends Component {
		constructor(props) {
			super(props);

			this.state = { allUsers: null, usersOnMessage: null };
		}
		componentWillMount() {
			delete sessionStorage.flattenedMessages;

			firebase.auth().onAuthStateChanged(function(user) {
				if (user) {
					firebase
						.auth()
						.currentUser.getIdToken(true)
						.then(function(idToken) {
							API("/login", "POST", { firebaseID: idToken }, function(data) {
								if (data.error) {
									globalStore.loginError = true;
								}
								globalStore.userType = data.type;
								globalStore.firebaseUser = user;
							});
						});
				} else if (!user) {
					globalStore.firebaseUser = null;
					delete sessionStorage.foldersAndMessages;
				}
			});
			if (process.env.REACT_APP_ENV === "mhd") {
				document.title = "MHDv2 | tuzagDesign";
			} else if (process.env.REACT_APP_ENV === "staging") {
				document.title = "MHDv1 | tuzagDesign";
			} else if (process.env.REACT_APP_ENV === "vumc") {
				document.title = "VUMC | tuzagDesign";
			} else if (process.env.REACT_APP_ENV === "life365") {
				document.title = "Life365 | tuzagDesign";
			} else if (process.env.REACT_APP_ENV === "nineZest") {
				document.title = "9Zest | tuzagDesign";
			} else if (process.env.REACT_APP_ENV === "vumc2") {
				document.title = "VUMC2 | tuzagDesign";
			}

			Modal.setAppElement("#root");
		}

		render() {
			return (
				<BrowserRouter>
					<ThemeProvider options={{ secondary: "#343d47" }}>
						<HttpsRedirect>
							{/*<DevTools />*/}
							<Switch>
								<Route exact path="/" component={Login} />
								<Route exact path="/login" component={Login} />
								<Route exact path="/postLogin" component={PostLogin} />
								<Route exact path="/steps" component={Steps} />
								<Route exact path="/accountSetup" component={AccountSetup} />
								<Route exact path="/paymentSetup" component={PaymentSetup} />
								<Route
									exact
									path="/gettingStarted"
									component={GettingStarted}
								/>
								<Route
									exact
									path="/message/:messageID/testing/exportHTML"
									component={ExportTemplate}
								/>
								<Route exact path="/dashboard" component={RouteContainer} />
								<Route path="/" component={RouteContainer} />
							</Switch>
							<Notification
								isActive={globalStore.newVersion}
								message={
									"There is a new version available. Refresh the page to use it!"
								}
								dismissAfter={false}
							/>
							<Modal
								isOpen={globalStore.assignMessageModalOpen}
								onRequestClose={() => {
									globalStore.assignMessageModalOpen = false;
									globalStore.assignMessageID = null;

									this.setState({ usersOnMessage: null, allUsers: null });
								}}
								style={{
									content: {
										top: "50%",
										left: "50%",
										width: 500,
										height: 250,
										overflowY: "auto",
										right: "auto",
										bottom: "auto",
										marginRight: "-50%",
										transform: "translate(-50%, -50%)"
									}
								}}
								onAfterOpen={() => {
									API(
										`/message/${globalStore.assignMessageID}/usersOnMessage`,
										"GET",
										null,
										data => {
											this.setState({ usersOnMessage: data.usersOnMessage });
										}
									);

									API(`/users`, "GET", null, data => {
										this.setState({ allUsers: data.users });
									});
								}}
							>
								{!this.state.allUsers || !this.state.usersOnMessage ? (
									<Loading />
								) : (
									this.state.allUsers.map(user => {
										return (
											<div key={user.id}>
												<input
													type="checkbox"
													checked={
														!!this.state.usersOnMessage.find(userOnMessage => {
															return user.id === userOnMessage.userID;
														})
													}
													onChange={evt => {
														if (evt.target.checked) {
															this.setState({
																usersOnMessage: [
																	...this.state.usersOnMessage,
																	{ userID: user.id }
																]
															});

															API(
																`/message/addUserToMessage/${
																	globalStore.assignMessageID
																}/${user.id}`,
																"POST",
																{},
																data => {}
															);
														} else {
															this.setState({
																usersOnMessage: this.state.usersOnMessage.filter(
																	userOnMessage => {
																		return userOnMessage.userID !== user.id;
																	}
																)
															});

															API(
																`/message/removeUserFromMessage/${
																	globalStore.assignMessageID
																}/${user.id}`,
																"POST",
																{},
																data => {}
															);
														}
													}}
													id={`checkbox${user.id}`}
												/>
												{"  "}
												<label htmlFor={`checkbox${user.id}`}>
													{user.displayName}
												</label>
											</div>
										);
									})
								)}
								<div className="text-right">
									<a
										onClick={() => {
											globalStore.assignMessageModalOpen = false;
											globalStore.assignMessageID = null;

											this.setState({ usersOnMessage: null, allUsers: null });
										}}
										className="saveButton"
									>
										Save
									</a>
								</div>
							</Modal>
						</HttpsRedirect>
					</ThemeProvider>
				</BrowserRouter>
			);
		}
	}
);

export default App;
