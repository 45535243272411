import React, { Component } from "react";
import globalStore from "../../GlobalStore";
import classNames from "classnames";
import autoBind from "react-autobind";
import { observer } from "mobx-react";

export default observer(
	class MessageSearchResult extends Component {
		constructor(props) {
			super(props);

			autoBind(this);

			this.state = {
				editMode: false,
				showChildrenElements: !!this.props.showElements,
				tooltipText: "Loading...",
				usersOnMessage: []
			};
		}

		messageClicked(event) {
			event.preventDefault();
			if (this.props.linkElementPanel) {
				this.setState({
					showChildrenElements: !this.state.showChildrenElements
				});
			} else if (this.props.messageSelected) {
				this.props.messageSelected(this.props.message.id, event);
			} else {
				if (this.state.editMode) {
					event.preventDefault();
				} else {
					globalStore.messageBrowserPanelOpen = false;
					globalStore.history.push(`/message/${this.props.message.id}/`);
				}
			}
		}

		render() {
			let messageStatusColor = "messageFontColor";

			if (this.props.message.status) {
				messageStatusColor = this.props.message.status.replace(/ /g, "");
				messageStatusColor =
					messageStatusColor.substring(0, 1).toLowerCase() +
					messageStatusColor.substring(1) +
					"FontColor";
			}

			return (
				<div
					className="messageContainer"
					{...this.props.tooltipProps}
					title={`ID: ${this.props.message.id}`}
				>
					<div
						className={classNames(
							"messageRow",
							this.state.editMode ? "editModeOn" : null,
							this.props.highlighted ? "highlighted" : null
						)}
						onMouseLeave={() => {}}
					>
						<a
							onClick={this.messageClicked}
							href={`/message/${this.props.message.id}`}
						>
							<div className="message">
								<i className={classNames("fas fa-file", messageStatusColor)} />
								<span className="name">{this.props.message.name}</span>
							</div>
						</a>
					</div>
					{this.state.showChildrenElements ? this.props.children : null}
				</div>
			);
		}
	}
);
