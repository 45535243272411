import React, { Component } from "react";
import autoBind from "react-autobind";
import { observer } from "mobx-react";
import { Redirect } from "react-router";
import globalStore from "../GlobalStore";
import firebase from "firebase/app";

export default observer(
	class PostLogin extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {};
		}

		signOut() {
			firebase.auth().signOut();
			this.props.history.push("/");
		}

		render() {
			if (globalStore.loginError) {
				return (
					<div className="container text-center" style={{ marginTop: 75 }}>
						<h1>You aren't authorized to use this app.</h1>
						<h4>
							<a onClick={this.signOut}>Sign out</a>
						</h4>
						<iframe
							width="560"
							height="315"
							src="https://www.youtube.com/embed/dQw4w9WgXcQ?rel=0&amp;showinfo=0&autoplay=1"
							frameBorder="0"
							allow="autoplay; encrypted-media"
							allowFullScreen
							title="Rick Roll"
						/>
					</div>
				);
			} else if (globalStore.firebaseUser) {
				return <Redirect to="/dashboard/dashboard" />;
			} else {
				return (
					<div className="container text-center" style={{ marginTop: 75 }}>
						<h1>Loading...</h1>
					</div>
				);
			}
		}
	}
);
