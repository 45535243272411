import React, { Component } from "react";
import autoBind from "react-autobind";
import API from "../../../API";
import { observer } from "mobx-react";
import camelcase from "camel-case";
import Loading from "../../structure/Loading";
import { toJS } from "mobx";

export default observer(
	class extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				tailoredContent: null,
				loading: false
			};
		}

		componentWillReceiveProps(nextProps) {
			this.setState({ tailoredContent: null });
		}

		loadData() {
			this.setState({ loading: true });
			API(
				`/message/${this.props.match.params.messageID}/web`,
				"POST",
				{
					bypassMapping: true,
					...toJS(this.props.pageStore.selectedValues)
				},
				data => {
					this.setState({
						loading: false
					});
					window.open().document.write(data.html);
				},
				null,
				true
			);
		}

		render() {
			let tailoredContent = [];

			for (const key in this.state.tailoredContent) {
				if (this.state.tailoredContent.hasOwnProperty(key)) {
					tailoredContent.push(
						<div key={camelcase(key)}>
							<div className="elementName">{key}</div>
							<div className="elementContent">
								{this.state.tailoredContent[key]}
							</div>
						</div>
					);
				}
			}

			return (
				<div>
					<h1 className="colName">Preview:</h1>
					{this.state.loading ? (
						<Loading />
					) : (
						<a onClick={this.loadData} id="showPreviewButton">
							Open in new tab
						</a>
					)}
				</div>
			);
		}
	}
);
