import React, { Component } from "react";
import autoBind from "react-autobind";
import { Row, Col } from "react-bootstrap";
import API from "../../../API";
import Select from "react-select-plus";
import { observable } from "mobx";
import { observer } from "mobx-react";
import RightPanelPreviewInContext from "./PreviewInContext";
import RightPanelQuickPreview from "./QuickPreview";
import SendTest from "./SendTest";
import VideoPreviewer from "./VideoPreviewer";
import GraphicPreviewer from "./GraphicPreviewer";

let pageStore = observable({
	attsAndOpts: [],
	selectedValues: {},
	screen: { value: "Question", label: "Question" }
});

export default observer(
	class PreviewContainer extends Component {
		constructor(props) {
			super(props);
			autoBind(this);
		}

		loadData(props) {
			API(
				`/message/${props.match.params.messageID}/usedAttributesAndOptions`,
				"GET",
				{},
				data => {
					pageStore.attsAndOpts = data.usedAttributes;
				}
			);
		}

		componentWillMount() {
			this.loadData(this.props);
		}

		componentWillReceiveProps(nextProps) {
			this.loadData(nextProps);
		}

		render() {
			let rightPanel;

			if (this.props.match.path.includes("previewInContext")) {
				rightPanel = (
					<RightPanelPreviewInContext
						match={this.props.match}
						pageStore={pageStore}
					/>
				);
			} else if (this.props.match.path.includes("sendTest")) {
				rightPanel = (
					<SendTest match={this.props.match} pageStore={pageStore} />
				);
			} else if (this.props.match.path.includes("video")) {
				rightPanel = (
					<VideoPreviewer match={this.props.match} pageStore={pageStore} />
				);
			} else if (this.props.match.path.includes("graphic")) {
				rightPanel = (
					<GraphicPreviewer match={this.props.match} pageStore={pageStore} />
				);
			} else {
				rightPanel = (
					<RightPanelQuickPreview
						match={this.props.match}
						pageStore={pageStore}
					/>
				);
			}

			return (
				<div id="quickPreview">
					<Row>
						<Col xs={3}>
							<LeftPanel
								pageStore={pageStore}
								selectedValues={pageStore.selectedValues}
								videoPreviewer={this.props.match.path.includes("graphic")}
							/>
						</Col>
						<Col xs={9}>{rightPanel}</Col>
					</Row>
				</div>
			);
		}
	}
);

const LeftPanel = observer(
	class extends Component {
		constructor(props) {
			super(props);
			autoBind(this);
		}

		render() {
			return (
				<form>
					<h1 className="colName">Tailoring Criteria:</h1>
					{this.props.videoPreviewer ? (
						<Row>
							<Col>
								<h3>Screen:</h3>
								<Select
									options={[
										{ value: "Question", label: "Question" },
										{ value: "Response", label: "Response" }
									]}
									value={pageStore.screen}
									onChange={val => {
										pageStore.screen = val;
									}}
									clearable={false}
								/>
							</Col>
						</Row>
					) : null}
					{pageStore.attsAndOpts.map(attribute => {
						if (attribute.passthrough) {
							return (
								<AttributeTextfield
									attribute={attribute}
									selectedValue={this.props.selectedValues[attribute.id]}
									key={attribute.id}
								/>
							);
						} else {
							return (
								<AttributeDropdown
									attribute={attribute}
									selectedValue={this.props.selectedValues[attribute.id]}
									key={attribute.id}
								/>
							);
						}
					})}
				</form>
			);
		}
	}
);

const AttributeTextfield = observer(
	class extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				selectedValue: null
			};
		}

		change(event) {
			pageStore.selectedValues[this.props.attribute.attributeID] =
				event.target.value;

			//why in gods name I need this and why oberver isn't working is beyond me
			//but it won't rerender if I don't force an update 😡
			this.forceUpdate();
		}

		render() {
			return (
				<Row>
					<Col xs={12}>
						<h3>{this.props.attribute.name}:</h3>
						<input
							className="FormInput"
							type="text"
							value={
								pageStore.selectedValues[this.props.attribute.attributeID]
									? pageStore.selectedValues[this.props.attribute.attributeID]
									: ""
							}
							onChange={this.change}
						/>
					</Col>
				</Row>
			);
		}
	}
);

const AttributeDropdown = observer(
	class extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				selectedValue: null
			};
		}

		selectChange(value) {
			pageStore.selectedValues[this.props.attribute.attributeID] = value.value;

			//why in gods name I need this and why oberver isn't working is beyond me
			//but it won't rerender if I don't force an update 😡
			this.forceUpdate();
		}

		render() {
			const options = this.props.attribute.options.map(option => {
				return {
					value: option.id,
					label: option.name
				};
			});

			return (
				<Row>
					<Col xs={12}>
						<h3>{this.props.attribute.name}:</h3>
						<Select
							value={pageStore.selectedValues[this.props.attribute.attributeID]}
							onChange={this.selectChange}
							options={options}
							clearable={false}
						/>
					</Col>
				</Row>
			);
		}
	}
);
