import React from "react";
import moment from "moment";
import firebase from "firebase/app";
import globalStore from "../../GlobalStore";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Row, Col } from "react-bootstrap";

function signOut() {
	firebase.auth().signOut();
	window.location = "/";
}

const Footer = observer(({ color, location, history }) => (
	<div
		className={classNames(
			"footer",
			process.env.NODE_ENV === "development" ? "" : null
		)}
		style={{ background: color === "purple" ? "#6E4E9F" : "#2C353F" }}
	>
		<Row>
			<Col xs={4}>
				<a href="https://tuzag.co">
					<img src="/tuzagLogoWhite.svg" alt="tuzag logo" />
				</a>
			</Col>
			{/*<div id="socialIconRow">*/}
			{/*<i className="fa fa-tumblr" />*/}
			{/*<i className="fa fa-facebook" />*/}
			{/*<i className="fa fa-linkedin-square" />*/}
			{/*<i className="fa fa-youtube" />*/}
			{/*<i className="fa fa-medium" />*/}
			{/*</div>*/}
			<Col xs={4} className="text-center">
				<p>Copyright {moment().format("Y")} tuzag, inc. All rights reserved.</p>
				<p>v. {process.env.REACT_APP_VERSION}</p>
			</Col>
			<Col xs={4} className="text-right">
				<p>
					customer service | privacy policy
					{globalStore.firebaseUser ? (
						<span>
							{" "}
							| <a onClick={signOut.bind(null)}>sign out</a>
						</span>
					) : null}
				</p>
			</Col>
		</Row>
	</div>
));

export default Footer;
