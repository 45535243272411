import React, { Component } from "react";
import Loading from "../structure/Loading";
import Select from "react-select";
import API from "../../API";
import shortid from "shortid";
import pluralize from "pluralize";
import titleCase from "title-case";
import { Form, Text } from "react-form";

export default class SelectEdit extends Component {
	constructor(props) {
		super(props);

		this.state = { editMode: false };
	}

	generateOptions = data => {
		return data[this.props.name].map(option => {
			return { label: option.name, value: option.id, ...option };
		});
	};

	componentDidMount() {
		API(`/${this.props.endpointName}`, "GET", {}, data => {
			this.setState({
				options: this.generateOptions(data)
			});
		});
	}

	toggleNewMode = () => {
		if (this.state.editMode) {
			this.setState({
				editMode: false
			});
		} else {
			this.setState({
				editMode: "new"
			});
		}
	};

	toggleEditMode = () => {
		if (this.state.editMode) {
			this.setState({
				editMode: false
			});
		} else {
			this.setState({
				editMode: "edit"
			});
		}
	};

	delete = () => {
		if (
			window.confirm(
				`Are you sure you want to delete this ${pluralize(this.props.name, 1)}?`
			)
		) {
			API(
				`/${this.props.endpointName}/${this.props.selectedItem.value}`,
				"DELETE",
				{},
				data => {
					this.setState(
						{
							options: this.generateOptions(data),
							editMode: false,
							snackbarMessage: "Deleted",
							lastSaveID: shortid.generate()
						},
						() => {
							this.props.setSelectedItem(null);
						}
					);
				}
			);
		}
	};

	render() {
		let defaultValues = {};
		for (const field of this.props.fields) {
			defaultValues[field] =
				this.state.editMode === "edit" ? this.props.selectedItem[field] : "";
		}

		return (
			<div id="selectEdit">
				{!this.state.options ? (
					<Loading />
				) : this.state.options.length === 0 ? (
					<p>No {this.props.name} yet</p>
				) : (
					<div>
						<Select
							name="botSelect"
							value={this.props.selectedItem}
							onChange={this.props.setSelectedItem}
							options={this.state.options}
							clearable={false}
							disabled={!!this.state.editMode}
						/>
					</div>
				)}
				<div className="buttonRow">
					{this.props.selectedItem && this.state.editMode !== "new" ? (
						<span>
							<a onClick={this.toggleEditMode} className="editButton">
								{this.state.editMode === "edit" ? "Hide edit" : "Edit"}
							</a>
							{this.state.editMode === "edit" ? (
								<a onClick={this.delete} className="deleteButton">
									Delete
								</a>
							) : null}
						</span>
					) : null}
					{this.state.editMode !== "edit" ? (
						<a onClick={this.toggleNewMode} className="newButton">
							{this.state.editMode === "new" ? "Hide new" : "New"}
						</a>
					) : null}
					<div className="clearFloat" />
				</div>
				{this.state.editMode ? (
					<div>
						<h2 className="colName">
							{titleCase(this.state.editMode)}{" "}
							{titleCase(pluralize(this.props.name, 1))} Name:
						</h2>
						<Form
							defaultValues={defaultValues}
							validate={values => {
								return {
									name: !values.name ? "A name is required" : undefined
								};
							}}
							onSubmit={values => {
								if (this.state.editMode === "edit") {
									values.id = this.props.selectedItem.value;
									API(
										`/${this.props.endpointName}/${
											this.props.selectedItem.value
										}`,
										"PUT",
										values,
										data => {
											this.setState(
												{
													snackbarMessage: "Saved!",
													lastSaveID: shortid.generate(),
													options: this.generateOptions(data),
													editMode: false
												},
												() => {
													const newData = data[this.props.name].find(option => {
														return (
															option.id === this.props.selectedItem.value
														);
													})
													this.props.setSelectedItem({value: newData.id, label: newData.name, ...newData}													);
												}
											);
										}
									);
								} else {
									//not edit, so must be new
									API(`/${this.props.endpointName}`, "PUT", values, data => {
										this.setState({
											snackbarMessage: "Saved!",
											lastSaveID: shortid.generate(),
											options: this.generateOptions(data),
											editMode: false
										});
									});
								}
							}}
						>
							{({ submitForm, setValue, values }) => {
								return (
									<form onSubmit={submitForm}>
										{this.props.fields.map(field => {
											return (
												<Text
													key={field}
													field={field}
													placeholder={`${pluralize(
														this.props.name,
														1
													)} ${field} goes here...`}
													style={{ marginTop: 5, marginBottom: 5 }}
												/>
											);
										})}

										<div className="text-right">
											<a
												type="submit"
												onClick={submitForm}
												className="saveButton"
											>
												Save
											</a>
										</div>
									</form>
								);
							}}
						</Form>
					</div>
				) : null}
			</div>
		);
	}
}
