import React, { Component } from "react";
import autoBind from "react-autobind";
import { Row, Col } from "react-bootstrap";
import firebase from "firebase";

export default class Export extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = { token: "" };
	}

	async componentDidMount() {
		let token;
		const interval = setInterval(async () => {
			token = firebase.auth().currentUser
				? await firebase.auth().currentUser.getIdToken(false)
				: null;

			if (token) {
				clearInterval(interval);

				this.setState({
					token
				});
			}
		}, 500);
	}

	render() {
		let apiURL;
		if (process.env.NODE_ENV === "development") {
			apiURL = "http://localhost:3000/designMHD";
		} else if (process.env.REACT_APP_ENV === "life365") {
			apiURL = "https://api.staging.tuzagdesign.com/designLife365";
		} else if (process.env.REACT_APP_ENV === "nineZest") {
			apiURL = "https://api.staging.tuzagdesign.com/designnineZest";
		} else if (process.env.REACT_APP_ENV === "vumc2") {
			apiURL = "https://api.staging.tuzagdesign.com/designvumc2";
		} else if (process.env.REACT_APP_ENV === "staging") {
			apiURL = "https://api.staging.tuzagdesign.com/design";
		} else if (process.env.REACT_APP_ENV === "vumc") {
			apiURL = "https://api.vumc.tuzagdesign.com/design";
		} else {
			apiURL = "https://api.v2.tuzagdesign.com/design";
		}

		return (
			<div id="export">
				<h1 className="colName">Export:</h1>
				<Row>
					{this.state.token ? (
						<Col xs={12}>
							<a
								href={`${apiURL}/message/${
									this.props.match.params.messageID
								}/export/doc?firebasetoken=${this.state.token}`}
							>
								Export as MS Word...
							</a>
							<br />
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={`/message/${
									this.props.match.params.messageID
								}/testing/exportHTML`}
							>
								Export as HTML for PDF...
							</a>
							<br />
							<a
								href={`${apiURL}/message/${
									this.props.match.params.messageID
								}/export/xlsx?firebasetoken=${this.state.token}`}
							>
								Export as MS Excel...
							</a>
						</Col>
					) : null}
				</Row>
			</div>
		);
	}
}
