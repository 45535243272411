import React, { Component } from "react";
import autoBind from "react-autobind";
import { observer } from "mobx-react";
import { observable, toJS } from "mobx";
import ReactQuill, { Quill } from "react-quill";
import { Row, Col } from "react-bootstrap";
import RuleContainerV2 from "./RuleContainerV2";
import shortid from "shortid";
import classNames from "classnames";
import MessageBrowser from "../../../messageBrowser/MessageBrowser";
import globalStore from "../../../../GlobalStore";
import API from "../../../../API";
import CartesianProduct from "cartesian-product";
import { mapToObject } from "../../../../HelperFunctions";
import debounce from "lodash.debounce";
import striptags from "striptags";

export default observer(
	class CaseContainerV2 extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				showLinkElementDropdown: false,
				showLinkElement: false,
				brokenLink: false,
				loadingBrokenLinkChecker: false,
				generating: false,
				showInsertPassthrough: false,
				validJSON: null
			};

			this.caseCheckboxes = observable.map({});

			this.modules = {
				keyboard: {
					bindings: {
						custom: {
							key: "S",
							shortKey: true,
							handler: function() {
								props.save();
							}
						},
						cmdUp: {
							key: "UP",
							shortKey: true,
							handler: function() {
								const currentElementIndex = globalStore.elements.findIndex(
									element => {
										return this.props.pageStore.element.id === element.id;
									}
								);
								const nextElement =
									globalStore.elements[currentElementIndex - 1];
								if (nextElement) {
									const nextElementID = nextElement.id;
									globalStore.history.push(
										`/message/${
											nextElement.messageID
										}/elements/${nextElementID}`
									);
								}
							}.bind(this)
						},
						cmdDown: {
							key: "DOWN",
							shortKey: true,
							handler: function() {
								const currentElementIndex = globalStore.elements.findIndex(
									element => {
										return this.props.pageStore.element.id === element.id;
									}
								);
								const nextElement =
									globalStore.elements[currentElementIndex + 1];
								if (nextElement) {
									const nextElementID = nextElement.id;
									globalStore.history.push(
										`/message/${
											nextElement.messageID
										}/elements/${nextElementID}`
									);
								}
							}.bind(this)
						},
						cmdL: {
							key: "L",
							shortKey: true,
							handler: function() {
								this.setState({ showLinkElementDropdown: true });
							}.bind(this)
						},
						cmdP: {
							key: "P",
							shortKey: true,
							handler: function() {
								this.setState({ showInsertPassthrough: true });
							}.bind(this)
						},
						quickFind: {
							key: "K",
							shortKey: true,
							handler: function() {
								globalStore.messageBrowserPanelOpen = !globalStore.messageBrowserPanelOpen;
							}
						},
						escKey: {
							key: "escape",
							handler: () => {
								this.setState({ showLinkElementDropdown: false });
							}
						}
					}
				},
				toolbar: {
					container: ".quillToolbar-" + props.bitCase.id,
					handlers: {
						chatMessageSplit: function() {
							const selection = this.reactQuillRef.getEditor().getSelection();

							this.reactQuillRef
								.getEditor()
								.insertEmbed(selection.index, "chatMessageSplit", {
									text: "([Chat Message Split])",
									newSplit: true
								});
						}.bind(this),
						color: value => {
							if (value === "custom-color") {
								value = prompt("Enter Hex/RGB/RGBA");
							}

							this.reactQuillRef.getEditor().format("color", value);
						},
						elementLink: function() {
							this.setState({
								showLinkElementDropdown: !this.state.showLinkElementDropdown
							});
						}.bind(this),
						insertPassthrough: function() {
							this.setState({
								showInsertPassthrough: !this.state.showInsertPassthrough
							});
						}.bind(this)
					}
				}
			};
		}

		handleChange(value) {
			this.props.bitCase.content = value;

			if (typeof value === "string" && value.substring(0, 5) === "<p>[{") {
				this.validateJSON();
			}
		}

		addCase() {
			this.props.pageStore.bitCases.push(
				observable({
					id: shortid.generate(),
					order: this.props.pageStore.bitCases.length,
					bitID: this.props.match.params.bitID,
					content: "",
					parentRuleGroup: {
						id: shortid.generate(),
						bitCaseRules: observable.array([]),
						chainedOperator: "The End"
					},
					defaultCase: false,
					alwaysTrue: false
				})
			);
		}

		removeCase() {
			this.props.pageStore.bitCases.splice(this.props.index, 1);

			//there should always be an empty case
			if (this.props.pageStore.bitCases.length === 0) {
				this.props.pageStore.bitCases.push(
					observable({
						id: shortid.generate(),
						order: 0,
						bitID: this.props.match.params.bitID,
						content: "",
						parentRuleGroup: {
							id: shortid.generate(),
							bitCaseRules: observable.array([]),
							chainedOperator: "The End"
						}
					})
				);
			} else {
				this.props.pageStore.bitCases = this.props.pageStore.bitCases.map(
					(bitcase, index) => {
						bitcase.order = index;
						return bitcase;
					}
				);
			}
		}

		toggleDefaultCase() {
			if (
				globalStore.userType !== "Read Only" &&
				globalStore.userType !== "Developer" &&
				globalStore.userType !== "Reviewer"
			) {
				this.props.pageStore.bitCases.map(bitCase => {
					bitCase.defaultCase = false;
					return true; //silence warning
				});
				this.props.bitCase.defaultCase = !this.props.bitCase.defaultCase;
			}
		}

		toggleAlwaysTrue() {
			if (
				globalStore.userType !== "Read Only" &&
				globalStore.userType !== "Developer" &&
				globalStore.userType !== "Reviewer"
			) {
				this.props.bitCase.alwaysTrue = !this.props.bitCase.alwaysTrue;
			}
		}

		onChangeSelection(range) {
			this.setState({
				showAddBit: range && !!range.length,
				showLinkElement: range && range.length === 0
			});

			//range/selection isn't getting properly set in firefox & safari, but it works in Chrome so this is a workaround
			if (range !== null) {
				globalStore.range = range;
			} else {
				range = globalStore.range;
			}

			if (
				this.state.showLinkElementDropdown &&
				!(range && range.length === 0)
			) {
				this.setState({
					showLinkElementDropdown: false
				});
			}
		}

		validateJSON = debounce(() => {
			try {
				JSON.parse(striptags(this.props.bitCase.content));
				this.setState({ validJSON: true });
			} catch (e) {
				this.setState({ validJSON: false });
			}
		}, 1500);

		componentDidMount() {
			if (!this.state.quillRef && !this.props.reorderMode) {
				this.attachQuillRefs();
			}

			if (this.props.bitCase.content) {
				if (this.props.bitCase.content.substring(0, 5) === "<p>[{") {
					this.validateJSON();
				}

				const elementLinkRegEx = /<span class="quill-elementLink" data-elementid="[0-9]+" contenteditable="false"?>.*?<\/span>/g;
				const elementLinksToReplace = this.props.bitCase.content.match(
					elementLinkRegEx
				);
				if (elementLinksToReplace) {
					this.setState({ loadingBrokenLinkChecker: true });
					for (const elementLink of elementLinksToReplace) {
						const elementLinkIDDOMTag = elementLink.match(
							/data-elementid="[0-9]+"/g
						)[0];
						const elementLinkIDAsString = elementLinkIDDOMTag.match(
							/[0-9]+/g
						)[0];
						const elementLinkID = parseInt(elementLinkIDAsString, 10);

						if (!this.props.reorderMode) {
							this.timeout = setTimeout(() => {
								API(`/element/${elementLinkID}`, "GET", {}, data => {
									this.setState({ loadingBrokenLinkChecker: false });
									if (data.element === null) {
										this.setState({ brokenLink: true });
									}
								});
							}, this.props.index * 750);
						}
					}
				}
			}

			if (
				globalStore.userType === "Read Only" ||
				(globalStore.message && globalStore.message.status === "Published") ||
				globalStore.userType === "Developer" ||
				globalStore.userType === "Reviewer"
			) {
				this.reactQuillRef.getEditor().enable(false);
			}
		}

		componentWillUnmount() {
			clearTimeout(this.timeout);
		}

		componentDidUpdate() {
			if (!this.state.quillRef && !this.props.reorderMode) {
				this.attachQuillRefs();
			}
		}

		attachQuillRefs = () => {
			//this has to be put in the state so when the editor finally mounts, you can put data in it
			this.setState({
				quillRef: this.reactQuillRef.getEditor()
			});
		};

		hideElementDropdown() {
			this.setState({ showLinkElementDropdown: false });
		}

		duplicateCase() {
			let bitCaseDuplicate = { ...toJS(this.props.bitCase) };

			bitCaseDuplicate.defaultCase = false;
			bitCaseDuplicate.id = shortid();
			bitCaseDuplicate.parentRuleGroup.bitCaseRules = bitCaseDuplicate.parentRuleGroup.bitCaseRules.map(
				rule => {
					rule.id = shortid();

					if ("bitCaseRules" in rule) {
						rule.bitCaseRules = rule.bitCaseRules.map(ruleInGroup => {
							ruleInGroup.id = shortid();
							return ruleInGroup;
						});
					}

					return rule;
				}
			);

			bitCaseDuplicate.order = this.props.pageStore.bitCases.length;

			this.props.pageStore.bitCases.push(bitCaseDuplicate);
		}

		createMarkup() {
			return { __html: this.props.bitCase.content };
		}

		attemptLinkFix() {
			API(
				`/fixBrokenLink`,
				"POST",
				{ content: this.props.bitCase.content },
				data => {
					if (data.success) {
						this.setState({ brokenLink: false });
						this.props.bitCase.content = data.content;
					} else {
						alert("No luck. Try deleting the link and adding it back");
					}
				}
			);
		}

		generateCases() {
			this.setState({ generating: true });
			let usedAttributes = this.props.bitCase.parentRuleGroup.bitCaseRules
				.filter(bitCaseRule => {
					//skip over any groups in the first case
					return !("bitCaseRules" in bitCaseRule);
				})
				.map(bitCaseRule => {
					return bitCaseRule.attributeID;
				});
			usedAttributes = [...new Set(usedAttributes)];

			let permuationsToGenerate = [];

			for (const usedAttribute of usedAttributes) {
				let dataToPush = [];

				const attribute = this.props.pageStore.availableAttributes.find(
					bitCaseRule => {
						return bitCaseRule.attributeID === usedAttribute;
					}
				);

				if (!attribute.passthrough) {
					for (const option of attribute.options) {
						dataToPush.push(`${usedAttribute}-${option.id}`);
					}
					permuationsToGenerate.push(dataToPush);
				}
			}

			let casesToGenerate = CartesianProduct(permuationsToGenerate);
			casesToGenerate.splice(0, 1);

			let newBitCases = [];

			for (const caseToGenerate of casesToGenerate) {
				let bitCaseRules = caseToGenerate.map((combination, index) => {
					const combinationArray = combination.split("-");
					return {
						id: shortid.generate(),
						attributeID: parseInt(combinationArray[0], 10),
						optionID: parseInt(combinationArray[1], 10),
						equalityOperator: "Equal To",
						passthroughValue: null,
						order: index
					};
				});

				newBitCases.push(
					observable({
						id: shortid.generate(),
						order: this.props.pageStore.bitCases.length,
						bitID: this.props.match.params.bitID,
						content: "",
						parentRuleGroup: observable.object({
							id: shortid.generate(),
							chainedOperator: bitCaseRules.length === 1 ? "The End" : "And",
							bitID: this.props.match.params.bitID,
							bitCaseRules
						}),
						defaultCase: false,
						alwaysTrue: false
					})
				);
			}

			this.props.pageStore.bitCases.push(...newBitCases);
			this.setState({ generating: false }); //this loading icon isn't working, but it's not doing any harm, so it shall stay
		}

		groupRules(checkedIDs) {
			let bitCaseRules = this.props.bitCase.parentRuleGroup.bitCaseRules;
			checkedIDs = checkedIDs.map(checkedIDArray => {
				if (isNaN(checkedIDArray[0])) {
					return checkedIDArray[0];
				} else {
					return parseInt(checkedIDArray[0], 10);
				}
			});

			let rulesToAddToGroup = bitCaseRules.filter(bitCaseRule => {
				return checkedIDs.includes(bitCaseRule.id);
			});

			bitCaseRules = bitCaseRules.filter(bitCaseRule => {
				return !checkedIDs.includes(bitCaseRule.id);
			});

			const newGroupID = shortid.generate();
			for (const bitCaseRule of rulesToAddToGroup) {
				bitCaseRule.groupID = newGroupID;
			}

			bitCaseRules.push({
				id: newGroupID,
				chainedOperator: "And",
				bitCaseRules: rulesToAddToGroup
			});

			this.props.bitCase.parentRuleGroup.bitCaseRules = bitCaseRules;
		}

		render() {
			const checkedIDs = Object.entries(
				mapToObject(this.caseCheckboxes)
			).filter(value => {
				return value[1];
			});

			return (
				<div
					className="caseContainer"
					style={{ cursor: this.state.generating ? "wait" : null }}
				>
					<Row className="topRow">
						<Col xs={10}>
							<p className="caseNumber">
								CASE: {this.props.index + 1}{" "}
								{this.state.loadingBrokenLinkChecker ? (
									<i className="	far fa-circle-notch fa-spin" />
								) : null}
								{this.state.brokenLink ? (
									<a
										onClick={this.attemptLinkFix}
										style={{ color: "red", display: "inline", fontWeight: 700 }}
									>
										BROKEN LINK
									</a>
								) : null}
							</p>
						</Col>
						<Col xs={2} className="text-right">
							<i
								className="fas fa-comment-edit"
								style={{ fontSize: 20, marginRight: 10 }}
								onClick={() => {
									globalStore.commentsBitCaseID = this.props.bitCase.id;
									globalStore.commentsModalOpen = true;
								}}
							/>
							{globalStore.userType === "Read Only" ||
							(globalStore.message &&
								globalStore.message.status === "Published") ||
							globalStore.userType === "Developer" ||
							globalStore.userType === "Reviewer" ? null : (
								<a className="addCaseButton" onClick={this.addCase}>
									Add Case
								</a>
							)}
						</Col>
					</Row>
					{this.props.reorderMode ? null : (
						<Toolbar
							showLinkElement={this.state.showLinkElement}
							caseID={this.props.bitCase.id}
						/>
					)}
					{this.state.showLinkElementDropdown ? (
						<div className="linkElementDropdown">
							<MessageBrowser
								linkElementPanel={true}
								quillReact={this.reactQuillRef}
								quillJS={this.state.quillRef}
								hideDropdown={this.hideElementDropdown}
							/>
						</div>
					) : null}
					{this.state.showInsertPassthrough ? (
						<div className="linkElementDropdown">
							{this.props.pageStore.availableAttributes
								.sort((a, b) => {
									if (a.passthrough) {
										return 0;
									} else {
										return 1;
									}
								})
								.map(availableAttribute => {
									return (
										<div key={availableAttribute.id}>
											<a
												onClick={() => {
													let selection = this.reactQuillRef.getEditorSelection();

													if (!selection) {
														selection = globalStore.range;
													}

													this.state.quillRef.insertEmbed(
														selection.index,
														"passthroughBit",
														{
															text: `(${availableAttribute.name})`,
															attributeid: availableAttribute.attributeID,
															newLink: true
														}
													);

													this.setState({ showInsertPassthrough: false });
												}}
											>
												{availableAttribute.name}
											</a>
										</div>
									);
								})}
						</div>
					) : null}
					{this.props.reorderMode ? (
						<div
							className="noneditable"
							dangerouslySetInnerHTML={this.createMarkup()}
						/>
					) : (
						<div>
							<ReactQuill
								value={this.props.bitCase.content}
								onChange={this.handleChange}
								modules={this.modules}
								theme={"snow"}
								onChangeSelection={this.onChangeSelection}
								ref={el => {
									this.reactQuillRef = el;
								}}
								className={`${
									this.props.bitCase.content &&
									this.props.bitCase.content.substring(0, 5) === "<p>[{"
										? "jsonQuill"
										: null
								}`}
							/>
							{this.state.validJSON === null ? null : this.state.validJSON ? (
								<span style={{ color: "green" }}>Valid JSON</span>
							) : (
								<div>
									<span
										style={{
											color: "red"
										}}
									>
										INVALID JSON! PANIC! Everyone PANIC! Unhappy developers!{" "}
									</span>
									<img
										aria-label="rage emoji"
										style={{ display: "inline", width: 30 }}
										src="/computerrage.gif"
										alt="computer rage"
									/>
								</div>
							)}
						</div>
					)}
					{this.props.reorderMode ? null : (
						<Row className="buttonRow">
							<Col xs={11}>
								<span>
									<input
										className="mousetrap"
										type="checkbox"
										onChange={this.toggleDefaultCase}
										checked={this.props.bitCase.defaultCase}
										disabled={
											globalStore.userType === "Read Only" ||
											(globalStore.message &&
												globalStore.message.status === "Published") ||
											globalStore.userType === "Developer" ||
											globalStore.userType === "Reviewer"
										}
									/>
									&nbsp;
									<a
										className="defaultCaseLabel"
										onClick={this.toggleDefaultCase}
									>
										Default
									</a>
								</span>
								<span>
									<input
										className="mousetrap"
										type="checkbox"
										onChange={this.toggleAlwaysTrue}
										checked={this.props.bitCase.alwaysTrue}
										disabled={
											globalStore.userType === "Read Only" ||
											(globalStore.message &&
												globalStore.message.status === "Published") ||
											globalStore.userType === "Developer" ||
											globalStore.userType === "Reviewer"
										}
									/>
									&nbsp;
									<a
										className="defaultCaseLabel"
										onClick={this.toggleAlwaysTrue}
									>
										Always True
									</a>
								</span>
								{globalStore.userType === "Read Only" ||
								(globalStore.message &&
									globalStore.message.status === "Published") ||
								globalStore.userType === "Developer" ||
								globalStore.userType === "Reviewer" ? null : (
									<span>
										<span>
											<a
												onClick={this.duplicateCase}
												className="duplicateCaseButton"
											>
												Duplicate
											</a>
										</span>
										<span>
											<a onClick={this.removeCase} className="removeCaseButton">
												Remove
											</a>
										</span>
										{this.props.index === 0 ? (
											<span>
												<a onClick={this.generateCases}>Generate Cases</a>
											</span>
										) : null}
										{checkedIDs.length >= 2 ? (
											<span>
												<a onClick={this.groupRules.bind(this, checkedIDs)}>
													Group
												</a>
											</span>
										) : null}
									</span>
								)}
							</Col>
							<Col xs={1} className="text-right">
								<span style={{ marginRight: 0 }}>
									{striptags(this.props.bitCase.content).length}
								</span>
							</Col>
						</Row>
					)}
					{this.props.bitCase.alwaysTrue ? null : (
						<RuleContainerV2
							caseCheckboxes={this.caseCheckboxes}
							quillJS={this.state.quillRef}
							bitCase={this.props.bitCase}
							pageStore={this.props.pageStore}
							reorderMode={this.props.reorderMode}
							readOnly={
								globalStore.userType === "Read Only" ||
								(globalStore.message &&
									globalStore.message.status === "Published") ||
								globalStore.userType === "Developer" ||
								globalStore.userType === "Reviewer"
							}
						/>
					)}
				</div>
			);
		}
	}
);

class QuillElementLink extends Quill.import("blots/inline") {
	static create(data) {
		let node = super.create(data);
		node.dataset.elementid = data.elementid;
		node.contentEditable = false;

		if (data.newLink) {
			node.innerHTML = data.text;
		}

		node.addEventListener("dblclick", event => {
			//sometimes there's a nested span tag where you need to look into the parent instead of the actual clicked element
			const elementid = event.target.dataset.elementid
				? event.target.dataset.elementid
				: event.target.parentElement.dataset.elementid;

			API(`/midByEID/${elementid}`, "GET", null, data => {
				console.log(data);
				if (data.messageID !== null) {
					globalStore.history.push(
						`/message/${data.messageID}/elements/${elementid}`
					);
				}
			});
		});

		return node;
	}

	static formats(domNode) {
		return {
			elementid: domNode.dataset.elementid,
			text: domNode.innerHTML
		};
	}

	static value(domNode) {
		// console.log(domNode);
		return {
			elementid: domNode.dataset.elementid,
			text: domNode.innerHTML
		};
	}
}

QuillElementLink.blotName = "elementLink";
QuillElementLink.className = "quill-elementLink";
QuillElementLink.tagName = "span";

class QuillPassthroughBit extends Quill.import("blots/inline") {
	static create(data) {
		let node = super.create(data);
		node.dataset.attributeid = data.attributeid;
		node.contentEditable = false;

		if (data.newLink) {
			node.innerHTML = data.text;
		}

		// node.addEventListener('click', (event) => {
		// 	function generateURL(dest) {
		// 		return /\/message\/[0-9]+\/elements\/[0-9]+/g.exec(globalStore.history.location.pathname)[0] + dest;
		// 	}
		//
		// 	//sometimes there's a nested span tag where you need to look into the parent instead of the actual clicked element
		// 	const bitid = event.target.dataset.bitid ? event.target.dataset.bitid : event.target.parentElement.dataset.bitid;
		//
		// 	globalStore.history.push(generateURL(`/bit/${bitid}`))
		// });

		return node;
	}

	static formats(domNode) {
		return {
			attributeid: domNode.dataset.attributeid,
			text: domNode.innerHTML
		};
	}

	static value(domNode) {
		// console.log(domNode);
		return {
			attributeid: domNode.dataset.attributeid,
			text: domNode.innerHTML
		};
	}
}

QuillPassthroughBit.blotName = "passthroughBit";
QuillPassthroughBit.className = "quill-passthroughBit";
QuillPassthroughBit.tagName = "span";

class QuillChatMessageSplit extends Quill.import("blots/inline") {
	static create(data) {
		let node = super.create(data);
		node.contentEditable = false;

		if (data.newSplit) {
			node.innerHTML = data.text;
		}

		return node;
	}

	static formats(domNode) {
		return {
			text: domNode.innerHTML
		};
	}

	static value(domNode) {
		return {
			text: domNode.innerHTML
		};
	}
}

QuillChatMessageSplit.blotName = "chatMessageSplit";
QuillChatMessageSplit.className = "quill-chatMessageSplit";
QuillChatMessageSplit.tagName = "span";

Quill.register({
	"formats/elementLink": QuillElementLink,
	"formats/passthroughBit": QuillPassthroughBit,
	"formats/chatMessageSplit": QuillChatMessageSplit
});

class Toolbar extends Component {
	render() {
		return globalStore.userType === "Read Only" ||
			(globalStore.message && globalStore.message.status === "Published") ||
			globalStore.userType === "Developer" ||
			globalStore.userType === "Reviewer" ? (
			<div className={"quillToolbar quillToolbar-" + this.props.caseID} />
		) : (
			<div className={"quillToolbar quillToolbar-" + this.props.caseID}>
				<span className="ql-formats">
					<button className="ql-bold" />
					<button className="ql-italic" />
					<button className="ql-underline" />
				</span>
				<span className="ql-formats">
					<button className="ql-list" value="ordered" />
					<button className="ql-list" value="bullet" />
					<button className="ql-blockquote" />
				</span>
				<span className="ql-formats">
					<select className="ql-color">
						{[
							"black",
							"white",
							"silver",
							"gray",
							"red",
							"maroon",
							"yellow",
							"olive",
							"lime",
							"green",
							"aqua",
							"teal",
							"blue",
							"navy",
							"fuchsia",
							"purple",
							"custom-color"
						].map(color => {
							return (
								<option
									key={color}
									value={color}
									defaultValue={color === "black"}
								/>
							);
						})}
					</select>
				</span>
				<span className="ql-formats">
					<button className="ql-clean" />
					<button className="ql-link" />
				</span>
				<span className="ql-formats">
					<button className="ql-chatMessageSplit">
						<i className="fas fa-unlink" />
					</button>
				</span>
				<span className="" style={{ float: "right" }}>
					<button
						className={classNames(
							"ql-elementLink",
							this.props.showLinkElement ? "" : "hide"
						)}
					>
						Link Message Element
					</button>
				</span>
				<span className="" style={{ float: "right" }}>
					<button
						className={classNames(
							"ql-insertPassthrough",
							this.props.showLinkElement ? "" : "hide"
						)}
					>
						Insert Passthrough
					</button>
				</span>
			</div>
		);
	}
}
//extend the clipboard module to remove copybits when pasting
const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");

class PlainClipboard extends Clipboard {
	onPaste(e) {
		if (e.defaultPrevented || !this.quill.isEnabled()) return;
		let range = this.quill.getSelection();
		let delta = new Delta().retain(range.index);
		let scrollTop = this.quill.scrollingContainer.scrollTop;
		this.container.focus();
		this.quill.selection.update(Quill.sources.SILENT);
		setTimeout(() => {
			delta = delta.concat(this.convert()).delete(range.length);
			delta.ops = delta.ops.map(op => {
				//here's we we remove linked message elements & bits
				if ("attributes" in op && "bit" in op.attributes) {
					delete op.attributes.bit;
				}
				if ("attributes" in op && "elementLink" in op.attributes) {
					delete op.attributes.elementLink;
				}
				return op;
			});
			this.quill.updateContents(delta, Quill.sources.USER);
			// range.length contributes to delta.length()
			this.quill.setSelection(
				delta.length() - range.length,
				Quill.sources.SILENT
			);
			this.quill.scrollingContainer.scrollTop = scrollTop;
			this.quill.focus();
		}, 1);
	}
}

Quill.register("modules/clipboard", PlainClipboard, true);
