import { observable } from "mobx";

const globalStore = new observable({
	firebaseUser: null,
	messageBrowserPanelOpen: false,
	newContent: false,
	elements: [],
	message: null,
	range: null,
	commentsModalOpen: false,
	commentsElementID: null,
	commentsMessageID: null,
	commentsBitCaseID: null,
	userType: "",
	loginError: false,
	assignMessageModalOpen: false,
	assignMessageID: null,
	messagesToMove: [],
	folderToMove: null
});

export default globalStore;
