import React, { Component } from "react";
import autoBind from "react-autobind";
import "react-table/react-table.css";
import ReactTable from "react-table";
import API from "../../../../API";
import Loading from "../../../structure/Loading";
import shortid from "shortid";
import camelcase from "camel-case";
import OptionTable from "./OptionTable";
import Dropzone from "react-dropzone";
import firebase from "firebase/app";
import Select from "react-select";

//There are bunch of commented out callbacks with the expanded table data
//Because we are no longer pivoting on attribute groups, we don't need to worry
//about hackily setting the expanded state back to what it was before the data
//update. However, it pained me too much to completely delete this, hence the comments.

export default class ManageAttributes extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			data: "Loading",
			activeEdits: "",
			codeEditorAttributeID: false
		};
	}

	filterCaseInsensitive = (filter, row) => {
		const id = filter.pivotId || filter.id;
		try {
			return row[id] !== undefined
				? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
				: true;
		} catch (e) {
			return true;
		}
	};

	componentDidMount() {
		API("/manageAttributes", "GET", {}, data => {
			this.setState({
				data: data.attributes,
				attributeGroups: data.attributeGroups,
				glossaries: data.glossaries.map(glossary => {
					return { value: glossary.id, label: glossary.name };
				})
			});
		});
	}

	renderEditable(cellInfo) {
		if (JSON.stringify(cellInfo.nestingPath) === this.state.activeEdits) {
			return (
				<div
					style={{ backgroundColor: "#fafafa" }}
					contentEditable
					suppressContentEditableWarning
					onBlur={e => {
						const data = [...this.state.data];
						data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
						// this.setState({ data });
					}}
					dangerouslySetInnerHTML={{
						__html: !isNaN(cellInfo.index)
							? this.state.data[cellInfo.index][cellInfo.column.id]
							: ""
					}}
				/>
			);
		} else {
			return (
				<div
					dangerouslySetInnerHTML={{
						__html: isNaN(cellInfo.index)
							? ""
							: this.state.data[cellInfo.index][cellInfo.column.id]
					}}
				/>
			);
		}
	}

	renderEditableLabel(cellInfo) {
		if (JSON.stringify(cellInfo.nestingPath) === this.state.activeEdits) {
			return (
				<div>
					{/*<input*/}
					{/*style={{ backgroundColor: "#fafafa" }}*/}
					{/*disabled={*/}
					{/*this.state.data[cellInfo.index].attributeLabel === "[[HIDDEN]]"*/}
					{/*}*/}
					{/*contentEditable*/}
					{/*suppressContentEditableWarning*/}
					{/*onBlur={e => {*/}
					{/*const data = [...this.state.data];*/}
					{/*data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;*/}
					{/*// this.setState({ data });*/}
					{/*}}*/}
					{/*dangerouslySetInnerHTML={{*/}
					{/*__html: !isNaN(cellInfo.index)*/}
					{/*? this.state.data[cellInfo.index][cellInfo.column.id]*/}
					{/*: ""*/}
					{/*}}*/}
					{/*/>*/}
					<input
						className="form-control"
						style={{ minHeight: 38 }}
						disabled={
							this.state.data[cellInfo.index][cellInfo.column.id] ===
							"[[HIDDEN]]"
						}
						onChange={evt => {
							let dataCopy = [...this.state.data];
							dataCopy[cellInfo.index][cellInfo.column.id] = evt.target.value;
							const state = this.table.getResolvedState();

							this.setState({ data: dataCopy }, () => {
								this.table.setStateWithData({
									expanded: state.expanded
								});
							});
						}}
						value={
							this.state.data[cellInfo.index][cellInfo.column.id]
								? this.state.data[cellInfo.index][cellInfo.column.id]
								: ""
						}
					/>
					<label htmlFor={`hide${cellInfo.index}`}>Hide: </label>{" "}
					<input
						id={`hide${cellInfo.index}`}
						type="checkbox"
						checked={
							this.state.data[cellInfo.index].attributeLabel === "[[HIDDEN]]"
						}
						onChange={evt => {
							let dataCopy = [...this.state.data];
							dataCopy[cellInfo.index].attributeLabel = evt.target.checked
								? "[[HIDDEN]]"
								: "";
							const state = this.table.getResolvedState();

							this.setState({ data: dataCopy }, () => {
								this.table.setStateWithData({
									expanded: state.expanded
								});
							});
						}}
					/>
				</div>
			);
		} else {
			return (
				<div
					dangerouslySetInnerHTML={{
						__html: isNaN(cellInfo.index)
							? ""
							: this.state.data[cellInfo.index][cellInfo.column.id]
					}}
				/>
			);
		}
	}

	renderEditableGroup(cellInfo) {
		if (JSON.stringify(cellInfo.nestingPath) === this.state.activeEdits) {
			return (
				<Select
					menuPortalTarget={document.body}
					value={this.state.attributeGroups.find(attributeGroup => {
						return (
							attributeGroup.value ===
							this.state.data[cellInfo.index].attributeGroupID
						);
					})}
					options={this.state.attributeGroups}
					onChange={selectedValue => {
						let dataCopy = [...this.state.data];
						dataCopy[cellInfo.index].attributeGroupID = selectedValue
							? selectedValue.value
							: null;

						this.setState({ data: dataCopy });
					}}
				/>
			);
		} else {
			const attributeGroup = this.state.attributeGroups.find(attributeGroup => {
				return (
					attributeGroup.value ===
					this.state.data[cellInfo.index].attributeGroupID
				);
			});

			return (
				<div
					dangerouslySetInnerHTML={{
						__html: attributeGroup ? attributeGroup.label : ""
					}}
				/>
			);
		}
	}

	renderEditableCheckbox(cellInfo) {
		if (JSON.stringify(cellInfo.nestingPath) === this.state.activeEdits) {
			return (
				<input
					type="checkbox"
					checked={
						cellInfo.index
							? this.state.data[cellInfo.index][cellInfo.column.id]
							: false
					}
					onChange={() => {
						let dataCopy = [...this.state.data];
						dataCopy[cellInfo.index][cellInfo.column.id] = !this.state.data[
							cellInfo.index
						][cellInfo.column.id];

						// const state = this.table.getResolvedState();

						this.setState(
							{ data: dataCopy }
							// 	() => {
							// 	this.table.setStateWithData({
							// 		expanded: state.expanded
							// 	});
							// }
						);
					}}
				/>
			);
		} else {
			return (
				<input
					type="checkbox"
					disabled
					checked={
						isNaN(cellInfo.index)
							? false
							: this.state.data[cellInfo.index][cellInfo.column.id]
					}
				/>
			);
		}
	}

	renderEditCol(cellInfo) {
		return (
			<div>
				{JSON.stringify(cellInfo.nestingPath) === this.state.activeEdits ? (
					<i className="fas fa-save" />
				) : (
					<i className="fas fa-pencil-alt" />
				)}
			</div>
		);
	}

	renderDeleteCol(cellInfo) {
		return cellInfo.subRows ? null : (
			<div>
				<i className="fas fa-trash" />
			</div>
		);
	}

	renderCodeCol(cellInfo) {
		return cellInfo.subRows ? null : (
			<div>
				<i className="fas fa-code" />
			</div>
		);
	}

	renderNewCol() {
		return (
			<div>
				<i className="fas fa-plus" />
			</div>
		);
	}

	renderBlankCol() {
		return <div />;
	}

	editAttributeClick(row, instance, state) {
		this.setState(
			{
				activeEdits: JSON.stringify(row.nestingPath)
			}
			// () => {
			// 	instance.setStateWithData({
			// 		expanded: state.expanded
			// 	});
			// }
		);
	}

	saveAttributeClick(row, instance, state) {
		let toSend = { ...row.original };
		toSend.name = toSend.attributeName;
		toSend.sourceVariable = camelcase(toSend.name);

		if (!row.original.attributeGroupID) {
			alert("Please select an attribute group before saving a new attribute");
		} else {
			API(
				`/attributeGroups/${row.original.attributeGroupID}/attributes`,
				"PUT",
				toSend,
				data => {
					if (data.newID) {
						const newData = [...this.state.data];
						const newAttribute = newData.find(attribute => {
							return attribute.id === row.original.id;
						});
						newAttribute.id = data.newID;
						this.setState(
							{ data: newData, activeEdits: "" }
							// 	() => {
							// 	instance.setStateWithData({
							// 		expanded: state.expanded
							// 	});
							// }
						);
					} else {
						this.setState(
							{
								activeEdits: ""
							}
							// () => {
							// 	instance.setStateWithData({
							// 		expanded: state.expanded
							// 	});
							// }
						);
					}
				}
			);
		}
	}

	deleteAttributeClick(row, instance, state) {
		if (
			window.confirm(
				"Are you sure you want to delete this attribute? All child options will be deleted."
			)
		) {
			if (shortid.isValid(row.original.id)) {
				const dataToSet = this.state.data.filter(attribute => {
					return attribute.id !== row.original.id;
				});
				this.setState(
					{ data: dataToSet }
					// 	() => {
					// 	instance.setStateWithData({
					// 		expanded: state.expanded
					// 	});
					// }
				);
			} else {
				API(`/attribute/${row.original.id}`, "DELETE", {}, data => {
					if (data.error) {
						if (window.confirm(data.message)) {
							window.open(data.url, "_blank");
						}
					} else {
						const dataToSet = this.state.data.filter(attribute => {
							return attribute.id !== row.original.id;
						});
						this.setState(
							{ data: dataToSet }
							// 	() => {
							// 	instance.setStateWithData({
							// 		expanded: state.expanded
							// 	});
							// }
						);
					}
				});
			}
		}
	}

	newAttributeClick(row, instance, state) {
		let attributeGroup = null;
		let attributeGroupID = null;

		if (
			state.filtered &&
			state.filtered.length > 0 &&
			state.filtered[0].id === "attributeGroup"
		) {
			attributeGroup = state.filtered[0].value;
			attributeGroupID = this.state.attributeGroups.find(attributeGroup => {
				return attributeGroup.label === state.filtered[0].value;
			}).value;
		}

		this.setState(
			{
				data: [
					...this.state.data,
					{
						attributeGroup,
						attributeGroupID,
						attributeName: "",
						id: shortid.generate(),
						passthrough: false,
						sourceVariable: ""
					}
				],
				activeEdits: `[${row.nestingPath[0]}]`
			}
			// () => {
			// 	instance.setStateWithData({
			// 		expanded: state.expanded,
			// 		page: 0
			// 	});
			// }
		);
	}

	editAttributeGroupClick(row, instance, state) {
		const name = window.prompt("Attribute Group Name?", row.row.attributeGroup);

		if (name) {
			API(
				"/attributeGroups",
				"PUT",
				{ id: row.row._subRows[0]._original.attributeGroupID, name },
				() => {
					this.componentDidMount();
				}
			);
		}
	}

	newAttributeGroupClick() {
		const groupName = window.prompt("New attribute group name?");
		if (groupName) {
			const attributeName = window.prompt("First attribute in the new group?");
			if (attributeName) {
				API("/attributeGroups", "POST", { groupName, attributeName }, data => {
					this.componentDidMount();
				});
			}
		}
	}

	render() {
		return (
			<div>
				{this.state.data === "Loading" ? (
					<Loading />
				) : (
					<div>
						<div className="text-right">
							<div
								className="saveButton"
								onClick={this.newAttributeGroupClick}
								style={{ cursor: "pointer" }}
							>
								New Attribute Group
							</div>
						</div>
						<ReactTable
							defaultFilterMethod={this.filterCaseInsensitive}
							data={this.state.data}
							columns={[
								{
									accessor: "id",
									show: false,
									sortMethod: (a, b) => {
										if (shortid.isValid(a)) {
											return -1;
										} else {
											if (a === b) {
												return 0;
											} else {
												return a - b;
											}
										}
									}
								},
								{
									accessor: "attributeGroup",
									Header: "Attribute Group",
									Cell: this.renderEditableGroup,
									Filter: ({ filter, onChange }) => {
										return (
											<Select
												onChange={value => {
													if (value) {
														onChange(value.label);
													} else {
														onChange("");
													}
												}}
												options={this.state.attributeGroups}
												menuPortalTarget={document.body}
												isClearable={true}
											/>
										);
									}
								},
								{
									expander: true,
									Expander: ({ isExpanded, ...rest }) => {
										if (rest.original && rest.original.passthrough) {
											return (
												<i
													style={{ cursor: "not-allowed" }}
													className="fas fa-arrow-to-right"
												/>
											);
										} else {
											return isExpanded ? (
												<i className="fas fa-caret-down" />
											) : (
												<i className="fas fa-caret-right" />
											);
										}
									},
									className: "text-center"
								},
								{
									Header: "Attribute Name",
									accessor: "attributeName",
									Cell: this.renderEditable,
									Aggregated: this.renderBlankCol
								},
								{
									Header: "Attribute Label",
									accessor: "attributeLabel",
									Cell: this.renderEditableLabel,
									Aggregated: this.renderBlankCol
								},
								{
									Header: "Source Variable",
									accessor: "sourceVariable",
									Cell: this.renderEditable,
									show: false
								},
								{
									Header: "Pass",
									accessor: "passthrough",
									Cell: this.renderEditableCheckbox,
									width: 50,
									filterable: false,
									Aggregated: this.renderBlankCol
								},
								{
									Cell: this.renderNewCol,
									width: 25,
									filterable: false,
									Aggregated: this.renderBlankCol,
									id: "newCol"
								},
								{
									Cell: this.renderEditCol,
									width: 25,
									filterable: false,
									Aggregated: this.renderEditCol,
									id: "editCol"
								},
								{
									Cell: this.renderDeleteCol,
									width: 25,
									filterable: false,
									Aggregated: this.renderDeleteCol,
									id: "deleteCol"
								},
								{
									Cell: this.renderCodeCol,
									width: 25,
									filterable: false,
									Aggregated: this.renderCodeCol,
									id: "codeCol"
								}
							]}
							filterable={true}
							className="-striped -highlight"
							defaultPageSize={25}
							ref={el => {
								this.table = el;
							}}
							defaultSorted={[
								{ id: "attributeGroup", desc: false },
								{ id: "id", desc: false }
							]}
							SubComponent={row => {
								return !row.original.passthrough ? (
									<OptionTable
										attribute={row.original}
										glossaries={this.state.glossaries}
									/>
								) : null;
							}}
							getTdProps={(state, row, column, instance) => {
								return {
									onClick: (e, handleOriginal) => {
										if (row) {
											if (column.id === "editCol") {
												if (
													this.state.activeEdits.includes(
														JSON.stringify(row.nestingPath)
													)
												) {
													this.saveAttributeClick(row, instance, state);
												} else {
													this.editAttributeClick(row, instance, state);
												}
											} else if (column.id === "deleteCol") {
												this.deleteAttributeClick(row, instance, state);
											} else if (column.id === "newCol") {
												this.newAttributeClick(row, instance, state);
											} else if (column.id === "codeCol") {
												this.props.history.push(
													`/admin/manageAttsAndOpts/codeEditor/${
														row.original.id
													}`
												);
											}
										}
										if (handleOriginal) {
											handleOriginal();
										}
									}
								};
							}}
						/>
					</div>
				)}
				<div className="importRow">
					<div className="colName">Import:</div>
					<Dropzone
						multiple={false}
						children={() => {
							return (
								<p
									style={{
										color: "gray",
										marginTop: 70,
										padding: 10,
										textAlign: "center"
									}}
								>
									{this.state.dragAndDropText}
								</p>
							);
						}}
						onDrop={async files => {
							const reader = new FileReader();
							reader.onload = async () => {
								this.setState({ dragAndDropText: "Doing Shit" });
								const fileAsBinaryString = reader.result;
								const blob = new Blob([fileAsBinaryString], {
									type: "text/csv"
								});
								const fd = new FormData();
								fd.append("file", blob);

								let apiURL;

								if (process.env.NODE_ENV === "development") {
									apiURL = "http://localhost:3000/designMHD/attributes/import";
								} else if (process.env.REACT_APP_ENV === "staging") {
									apiURL =
										"https://api.staging.tuzagdesign.com/design/attributes/import";
								} else if (process.env.REACT_APP_ENV === "mhd") {
									apiURL =
										"https://api.staging.tuzagdesign.com/designMHD/attributes/import";
								} else if (process.env.REACT_APP_ENV === "life365") {
									apiURL =
										"https://api.staging.tuzagdesign.com/designLife365/attributes/import";
								} else if (process.env.REACT_APP_ENV === "nineZest") {
									apiURL =
										"https://api.staging.tuzagdesign.com/designnineZest/attributes/import";
								} else if (process.env.REACT_APP_ENV === "vumc2") {
									apiURL =
										"https://api.staging.tuzagdesign.com/designvumc2/attributes/import";
								} else if (process.env.REACT_APP_ENV === "vumc") {
									apiURL =
										"https://api.vumc.tuzagdesign.com/design/attributes/import";
								} else {
									apiURL =
										"https://api.v2.tuzagdesign.com/design/attributes/import";
								}

								const firebasetoken = firebase.auth().currentUser
									? await firebase.auth().currentUser.getIdToken(false)
									: "";

								fetch(apiURL, {
									method: "post",
									body: fd,
									credentials: "include",
									mode: "cors",
									headers: { firebasetoken }
								}).then(() => {
									this.setState({
										dragAndDropText: "Shits Done",
										lastImport: shortid.generate(),
										group: null,
										attribute: null,
										optionColToShow: null
									});
									setTimeout(() => {
										this.setState({
											dragAndDropText: "Drag and Drop CSV File"
										});
									}, 5000);
								});
							};
							reader.onabort = () => console.log("file reading was aborted");
							reader.onerror = () => console.log("file reading has failed");

							reader.readAsBinaryString(files[0]);
						}}
					/>
				</div>
			</div>
		);
	}
}
