import React, { Component } from "react";
import autoBind from "react-autobind";
import globalStore from "../../GlobalStore";

export default class Element extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {};
	}

	render() {
		return (
			<li
				className={`element ${this.props.highlighted ? "highlighted" : ""}`}
				onClick={
					this.props.topbar
						? () => {
								globalStore.messageBrowserPanelOpen = false;
								globalStore.history.push(
									`/message/${this.props.message.id}/element/${
										this.props.element.id
									}`
								);
						  }
						: this.props.linkElement.bind(this, {
								name: this.props.element.name,
								messageName: this.props.message.name,
								id: this.props.element.id
						  })
				}
			>
				<a className="name">{this.props.element.name}</a>
			</li>
		);
	}
}
