import React, { Component } from "react";
import autoBind from "react-autobind";
import SidebarOutOfMessage from "../sidebar/NonMessage";
import SidebarMessage from "../sidebar/Message";
import Footer from "../Footer";
import Topbar from "../Topbar";
import { Route, Switch } from "react-router-dom";
import Message from "./Message";
import NonMessage from "./NonMessage";
import globalStore from "../../../GlobalStore";
import Mousetrap from "mousetrap";
import { observer } from "mobx-react";

export default observer(
	class Structure extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				sidebarOpen: true
			};
		}

		componentDidMount() {
			//open the sidebar by default
			setTimeout(() => {
				this.setState({
					sidebarOpen: true
				});
			}, 1);

			Mousetrap.bind(["command+k", "ctrl+k"], event => {
				if (event.preventDefault) {
					event.preventDefault();
				}
				globalStore.messageBrowserPanelOpen = !globalStore.messageBrowserPanelOpen;
			});

			Mousetrap.bind(["esc"], event => {
				globalStore.messageBrowserPanelOpen = false;
			});
		}

		componentWillMount() {
			globalStore.history = this.props.history;
		}

		componentWillUnmount() {
			Mousetrap.unbind(["command+k", "ctrl+k", "esc"]);
		}

		render() {
			return (
				<div id="mainInterface">
					{this.props.location.pathname.substring(0, 9) === "/message/" ? (
						<SidebarMessage {...this.props} />
					) : (
						<SidebarOutOfMessage history={this.props.history} />
					)}
					<Topbar history={this.props.history} />
					<div id="mainContent">
						<Switch>
							<Route path="/message/:messageID" component={Message} />
							<Route path="/" component={NonMessage} />
						</Switch>
					</div>
					<Footer history={this.props.history} />
				</div>
			);
		}
	}
);
