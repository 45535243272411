import React from "react";
import ReactJson from "react-json-view";
import API from "../../../API";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class GoogleCloudNL extends React.Component {
	constructor(props) {
		super(props);
	}

	state = {
		text: "",
		sentimentResult: null,
		entitiesResult: null,
		syntaxResult: null,
		entitySentimentResult: null,
		classifyingContentResult: null,
		sentimentCheckbox: true,
		entitiesCheckbox: true,
		syntaxCheckbox: true,
		entitySentimentCheckbox: true,
		classifyingContentCheckbox: true,
		enableClassifyingContent: false
	};

	analyze = () => {
		this.setState({
			sentimentResult: null,
			entitiesResult: null,
			syntaxResult: null,
			entitySentimentResult: null,
			classifyingContentResult: null
		});

		if (this.state.sentimentCheckbox) {
			API(`/analyzeNL/sentiment`, "POST", { text: this.state.text }, data => {
				this.setState({ sentimentResult: data.result });
			});
		}

		if (this.state.entitySentimentCheckbox) {
			API(
				`/analyzeNL/entitySentiment`,
				"POST",
				{ text: this.state.text },
				data => {
					this.setState({ entitySentimentResult: data.result });
				}
			);
		}

		if (this.state.syntaxCheckbox) {
			API(`/analyzeNL/syntax`, "POST", { text: this.state.text }, data => {
				this.setState({ syntaxResult: data.result });
			});
		}

		if (this.state.entitiesCheckbox) {
			API(`/analyzeNL/entities`, "POST", { text: this.state.text }, data => {
				this.setState({ entitiesResult: data.result });
			});
		}

		if (
			this.state.enableClassifyingContent &&
			this.state.classifyingContentCheckbox
		) {
			API(
				`/analyzeNL/classifyingContent`,
				"POST",
				{ text: this.state.text },
				data => {
					this.setState({ classifyingContentResult: data.result });
				}
			);
		}
	};

	render() {
		return (
			<div>
				<textarea
					placeholder="Whatchu want the big G to analyze?"
					value={this.state.text}
					onChange={evt => {
						this.setState({ text: evt.target.value });
						if (
							evt.target.value &&
							typeof evt.target.value === "string" &&
							evt.target.value.trim().split(" ").length > 19
						) {
							this.setState({ enableClassifyingContent: true });
						} else {
							this.setState({ enableClassifyingContent: false });
						}
					}}
					className="form-control"
				/>
				<Row>
					<Col>
						<input
							type="checkbox"
							id="sentimentCheckbox"
							onChange={evt => {
								this.setState({
									sentimentCheckbox: !this.state.sentimentCheckbox
								});
							}}
							checked={this.state.sentimentCheckbox}
						/>{" "}
						<label htmlFor="sentimentCheckbox">Sentiment</label>
					</Col>
					<Col>
						<input
							type="checkbox"
							id="entitiesCheckbox"
							onChange={evt => {
								this.setState({
									entitiesCheckbox: !this.state.entitiesCheckbox
								});
							}}
							checked={this.state.entitiesCheckbox}
						/>{" "}
						<label htmlFor="entitiesCheckbox">Entities</label>
					</Col>
					<Col>
						<input
							type="checkbox"
							id="syntaxCheckbox"
							onChange={evt => {
								this.setState({
									syntaxCheckbox: !this.state.syntaxCheckbox
								});
							}}
							checked={this.state.syntaxCheckbox}
						/>{" "}
						<label htmlFor="syntaxCheckbox">Syntax</label>
					</Col>
					<Col>
						<input
							type="checkbox"
							id="entitySentimentCheckbox"
							onChange={evt => {
								this.setState({
									entitySentimentCheckbox: !this.state.entitySentimentCheckbox
								});
							}}
							checked={this.state.entitySentimentCheckbox}
						/>{" "}
						<label htmlFor="entitySentimentCheckbox">Entities Sentiment</label>
					</Col>
					<Col>
						<input
							type="checkbox"
							id="classifyingContentCheckbox"
							onChange={evt => {
								this.setState({
									classifyingContentCheckbox: !this.state
										.classifyingContentCheckbox
								});
							}}
							checked={
								!this.state.enableClassifyingContent
									? false
									: this.state.classifyingContentCheckbox
							}
							disabled={!this.state.enableClassifyingContent}
						/>{" "}
						<label htmlFor="classifyingContentCheckbox">
							Classifying Content
						</label>
					</Col>
				</Row>
				<div className="text-right" style={{ marginTop: 10 }}>
					<button onClick={this.analyze} className="btn btn-primary">
						Analyze
					</button>
				</div>

				{this.state.sentimentResult ? (
					<>
						<h4>Sentiment:</h4>
						<ReactJson src={this.state.sentimentResult} />
					</>
				) : null}
				{this.state.entitiesResult ? (
					<>
						<h4>Entities:</h4>
						<ReactJson src={this.state.entitiesResult} />
					</>
				) : null}
				{this.state.syntaxResult ? (
					<>
						<h4>Syntax:</h4>
						<ReactJson src={this.state.syntaxResult} />
					</>
				) : null}
				{this.state.entitySentimentResult ? (
					<>
						<h4>Entity Sentiment:</h4>
						<ReactJson src={this.state.entitySentimentResult} />
					</>
				) : null}
				{this.state.classifyingContentResult ? (
					<>
						<h4>Classifying Content:</h4>
						<ReactJson src={this.state.classifyingContentResult} />
					</>
				) : null}
			</div>
		);
	}
}
