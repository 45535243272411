import React, { Component } from "react";
import autoBind from "react-autobind";
import API from "../../API";
import MessageStatus from "../MessageStatus";
import { Row, Col } from "react-bootstrap";
import globalStore from "../../GlobalStore";
import Mousetrap from "mousetrap";
import ReactTooltip from "react-tooltip";
import { observer } from "mobx-react";
import moment from "moment";
import shortid from "shortid";
import Snackbar from "../structure/Snackbar";
import Modal from "react-modal";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";

export default observer(
	class messageName extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				messagePath: "Loading",
				usersOnMessage: [],
				saveHistory: "Loading",
				snackbarMessage: "",
				mapAttributesModalOpen: false,
				attributesToMap: [],
				options: [],
				attributeMapping: {},
				pasteText: "Paste!",
				lastSaveID: ""
			};
		}

		userIsOnMessage() {
			if (!globalStore.firebaseUser) {
				return false;
			}
			for (const userOnMessage of this.state.usersOnMessage) {
				if (userOnMessage.user.firebaseUID === globalStore.firebaseUser.uid) {
					return true;
				}
			}
			return false;
		}

		generateUsersTooltip(usersOnMessage) {
			if (usersOnMessage.length) {
				this.setState({
					tooltipText: usersOnMessage
						.map(userOnMessage => {
							return userOnMessage.user.displayName;
						})
						.join("<br>")
				});
			} else {
				this.setState({
					tooltipText: "No one on message"
				});
			}

			this.setState({ usersOnMessage });
		}

		generateSaveHistoryTooltip(saveHistory) {
			this.setState({
				saveHistory: saveHistory
					.map(save => {
						return `${save["user.displayName"]}: ${moment(save.saveTime).format(
							"h:mm a M/D/YY"
						)}`;
					})
					.join("<br>")
			});
		}

		buildAndSetPath(props) {
			API(
				`/messageFolderPath/${props.match.params.messageID}`,
				"GET",
				null,
				data => {
					let pathAsString = "";

					if (data.messagePath.length === 2) {
						pathAsString = data.messagePath[1];
					} else if (data.messagePath.length === 3) {
						pathAsString = data.messagePath[1] + " : " + data.messagePath[2];
					} else {
						pathAsString =
							data.messagePath[1] +
							(data.messagePath.length === 4 ? " : " : " ... ") +
							data.messagePath[data.messagePath.length - 2] +
							" : " +
							data.messagePath[data.messagePath.length - 1];
					}

					this.setState({
						messagePath: pathAsString
					});
				}
			);
		}

		componentWillMount() {
			this.buildAndSetPath(this.props);

			API(
				`/message/${this.props.match.params.messageID}?includeElements=true`,
				"GET",
				null,
				data => {
					globalStore.message = data.message;
					globalStore.elements = data.message.elements;
				}
			);
		}

		componentDidMount() {
			API(
				`/message/${this.props.match.params.messageID}/usersOnMessage`,
				"GET",
				{},
				data => {
					this.generateUsersTooltip(data.usersOnMessage);
				}
			);

			Mousetrap.bind(
				["pageup"],
				function(event) {
					event.preventDefault();
					let pathname = globalStore.history.location.pathname;

					if (pathname.includes("/elements")) {
						pathname = `/message/${this.props.match.params.messageID + 1}/`;
					} else {
						pathname = pathname.replace(
							`/message/${this.props.match.params.messageID}`,
							`/message/${this.props.match.params.messageID + 1}`
						);
					}

					globalStore.history.push(pathname);
				}.bind(this)
			);

			Mousetrap.bind(
				["pagedown"],
				function(event) {
					event.preventDefault();
					let pathname = globalStore.history.location.pathname;

					if (this.props.match.params.messageID > 1) {
						if (pathname.includes("/elements")) {
							pathname = `/message/${this.props.match.params.messageID - 1}/`;
						} else {
							pathname = pathname.replace(
								`/message/${this.props.match.params.messageID}`,
								`/message/${this.props.match.params.messageID - 1}`
							);
						}
					}

					globalStore.history.push(pathname);
				}.bind(this)
			);
		}

		componentWillUnmount() {
			if (process.env.REACT_APP_ENV === "mhd") {
				document.title = "MHDv2 | tuzagDesign";
			} else if (process.env.REACT_APP_ENV === "staging") {
				document.title = "MHDv1 | tuzagDesign";
			} else if (process.env.REACT_APP_ENV === "vumc") {
				document.title = "VUMC | tuzagDesign";
			} else if (process.env.REACT_APP_ENV === "life365") {
				document.title = "Life365 | tuzagDesign";
			} else if (process.env.REACT_APP_ENV === "nineZest") {
				document.title = "9Zest | tuzagDesign";
			} else if (process.env.REACT_APP_ENV === "vumc2") {
				document.title = "VUMC2 | tuzagDesign";
			}
		}

		componentWillReceiveProps(nextProps) {
			if (
				this.props.match.params.messageID !== nextProps.match.params.messageID
			) {
				this.buildAndSetPath(nextProps);

				API(
					`/message/${this.props.match.params.messageID}/usersOnMessage`,
					"GET",
					{},
					data => {
						this.generateUsersTooltip(data.usersOnMessage);
					}
				);

				API(
					`/message/${nextProps.match.params.messageID}?includeElements=true`,
					"GET",
					null,
					data => {
						globalStore.message = data.message;
						globalStore.elements = data.message.elements;
					}
				);
			}
		}

		copyToClipboard = str => {
			const el = document.createElement("textarea");
			el.value = str;
			document.body.appendChild(el);
			el.select();
			document.execCommand("copy");
			document.body.removeChild(el);
		};

		copyToOtherEnv = () => {
			API(
				`/exportMessageForCopying/${this.props.match.params.messageID}`,
				"GET",
				{},
				data => {
					this.copyToClipboard(JSON.stringify(data));
					this.setState({
						snackbarMessage: "Copied!",
						lastSaveID: shortid.generate()
					});
				}
			);
		};

		pasteFromOtherEnv = async () => {
			try {
				const dataFromClipboard = JSON.parse(
					await navigator.clipboard.readText()
				);

				this.setState({
					mapAttributesModalOpen: true,
					attributesToMap: dataFromClipboard.messageAtts.messageUsedAttributes.map(
						usedAttribute => {
							return usedAttribute.attribute;
						}
					)
				});
			} catch (e) {
				alert("Error reading data from clipboard. Try copying again.");
			}
		};

		generateOptions() {
			API(`/availableAttributesAndOptions`, "GET", {}, data => {
				this.setState(
					{
						options: data.availableAttributes.map(group => {
							return {
								label: group.name,
								groupID: group.id,
								options: group.attributes.reduce((result, attribute) => {
									if (!attribute.passthrough && attribute.options) {
										//filter out direct attributes that don't have options
										let defaultFound = false;

										//also filter out attributes that don't have a default option
										attribute.options.map(option => {
											if (option.defaultOption === true) {
												defaultFound = true;
											}
											return null; //shut up a compiler warning and return anything
										});

										if (defaultFound) {
											result.push({
												label: attribute.name,
												value: attribute.attributeID
													? attribute.attributeID
													: attribute.id
											});
										} else {
											result.push({
												label: attribute.name,
												value: attribute.attributeID
													? attribute.attributeID
													: attribute.id,
												isDisabled: true
											});
										}
									} else {
										//add all other kinds of attribute types
										result.push({
											label: attribute.name,
											value: attribute.attributeID
												? attribute.attributeID
												: attribute.id
										});
									}
									return result;
								}, [])
							};
						})
					},
					async () => {
						let autoMatches = {};
						let counter = 0;
						for (const attribute of this.state.attributesToMap) {
							API(
								"/attributeSearchForCopyAndPaste",
								"POST",
								{
									searchTerm: attribute.name
								},
								// This is a stupid eslint rule that gets thrown because of scope, which works perfectly fine, so we just tell eslint to STFU
								// eslint-disable-next-line
								data => {
									autoMatches[attribute.id] = {
										label: data.attribute.name,
										value: data.attribute.id
									};
									this.setState({ attributeMapping: autoMatches }, () => {
										counter++;
									});
								}
							);
						}

						const dataFromClipboard = JSON.parse(
							await navigator.clipboard.readText()
						);
						if (window.location.hostname === dataFromClipboard.hostname) {
							let autoMatchChecker = setInterval(() => {
								if (counter === this.state.attributesToMap.length) {
									clearInterval(autoMatchChecker);

									setTimeout(() => {
										this.executePastePostMatching();
									}, 250);
								}
							}, 100);
						}
					}
				);
			});
		}

		filterAttributes = debounce((inputValue, callback) => {
			API(
				"/attributeSearch",
				"POST",
				{
					searchTerm: inputValue,
					usedAttributes: []
				},
				data => {
					callback(data.results);
				}
			);
		}, 300);

		executePastePostMatching = async () => {
			try {
				this.setState({ pasteText: "Pasting..." });
				const clipboardData = JSON.parse(await navigator.clipboard.readText());
				API(
					`/pasteMessage/${this.props.match.params.messageID}`,
					"POST",
					{
						attributeMapping: this.state.attributeMapping,
						message: clipboardData.message,
						messageAtts: clipboardData.messageAtts
					},
					data => {
						this.setState({ pasteText: "Paste!" });
						if (
							data.error &&
							window.localtion.hostname !== clipboardData.hostname
						) {
							alert(
								"Options for an attribute don't match the other env. Please check the rules."
							);
						}
						window.location.reload(false);
					}
				);
			} catch (e) {
				alert(
					"Clipboard data mismatch. Please close the modal, copy the source data again, then retry the paste."
				);
			}
		};

		render() {
			if (!globalStore.message) {
				document.title = `MHDv2 | tD`;
			} else if (process.env.REACT_APP_ENV === "mhd") {
				document.title = `MHDv2 | ${globalStore.message.name} | tD`;
			} else if (process.env.REACT_APP_ENV === "staging") {
				document.title = `MHDv1 | ${globalStore.message.name} | tD`;
			} else if (process.env.REACT_APP_ENV === "vumc") {
				document.title = `VUMC | ${globalStore.message.name} | tD`;
			} else if (process.env.REACT_APP_ENV === "life365") {
				document.title = `Life365 | ${globalStore.message.name} | tD`;
			} else if (process.env.REACT_APP_ENV === "local") {
				document.title = `tD Local | ${globalStore.message.name} | tD`;
			} else if (process.env.REACT_APP_ENV === "nineZest") {
				document.title = `9Zest | ${globalStore.message.name} | tD`;
			} else if (process.env.REACT_APP_ENV === "vumc2") {
				document.title = `VUMC2 | ${globalStore.message.name} | tD`;
			}

			return globalStore.message === null ? null : (
				<Row id="messageHeader">
					<Col xs={9}>
						<h1
							id="messageName"
							className="text-left"
							style={{ display: "inline" }}
						>
							{this.state.messagePath}
						</h1>
						<span>
							<i
								className="fas fa-clipboard"
								style={{ marginLeft: 10 }}
								onClick={this.copyToOtherEnv}
							/>
							<i
								className="fas fa-paste"
								style={{ marginLeft: 10 }}
								onClick={this.pasteFromOtherEnv}
							/>
						</span>
					</Col>
					<Col
						xs={1}
						className="text-right"
						style={{ paddingTop: 5, paddingLeft: 0, paddingRight: 0 }}
					>
						<i
							className="fas fa-history"
							style={{ fontSize: 20, marginRight: 10 }}
							data-for={`tooltipHistory-${this.props.match.params.messageID}`}
							data-tip=""
							onMouseEnter={() => {
								if (this.state.saveHistory === "Loading") {
									API(
										`/message/${this.props.match.params.messageID}/saveHistory`,
										"GET",
										{},
										data => {
											this.generateSaveHistoryTooltip(data.saveHistory);
										}
									);
								}
							}}
						/>
						<i
							className="fas fa-comment-edit"
							style={{ fontSize: 20, marginRight: 10 }}
							onClick={() => {
								globalStore.commentsMessageID = this.props.match.params.messageID;
								globalStore.commentsModalOpen = true;
							}}
						/>
						<i
							className="fas fa-users"
							style={{
								fontSize: 20,
								color: this.userIsOnMessage() ? "orange" : "black"
							}}
							data-for={`tooltip-${this.props.match.params.messageID}`}
							data-tip=""
							onClick={() => {
								if (globalStore.userType === "Architect") {
									globalStore.assignMessageModalOpen = true;
									globalStore.assignMessageID = this.props.match.params.messageID;
								} else {
									if (this.userIsOnMessage()) {
										API(
											`/message/removeFromMessage/${
												this.props.match.params.messageID
											}`,
											"POST",
											{},
											data => {
												this.generateUsersTooltip(data.usersOnMessage);
											}
										);
									} else {
										API(
											`/message/addToMessage/${
												this.props.match.params.messageID
											}`,
											"POST",
											{},
											data => {
												this.generateUsersTooltip(data.usersOnMessage);
											}
										);
									}
								}
							}}
						/>
						<div className="text-center">
							<ReactTooltip
								multiline={true}
								id={`tooltip-${this.props.match.params.messageID}`}
								html={true}
								afterShow={() => {
									// Mousetrap.bind(["a"], event => {
									// 	event.preventDefault();
									// 	if (this.userIsOnMessage()) {
									// 		API(
									// 			`/message/removeFromMessage/${this.props.match.params.messageID}`,
									// 			"POST",
									// 			{},
									// 			data => {
									// 				this.generateUsersTooltip(data.usersOnMessage);
									// 			}
									// 		);
									// 	} else {
									// 		API(
									// 			`/message/addToMessage/${this.props.match.params.messageID}`,
									// 			"POST",
									// 			{},
									// 			data => {
									// 				this.generateUsersTooltip(data.usersOnMessage);
									// 			}
									// 		);
									// 	}
									// });
								}}
								afterHide={() => {
									// Mousetrap.unbind(["a"]);
								}}
							>
								{this.state.tooltipText}
							</ReactTooltip>
							<ReactTooltip
								multiline={true}
								id={`tooltipHistory-${this.props.match.params.messageID}`}
								html={true}
								className="text-left"
							>
								{this.state.saveHistory}
							</ReactTooltip>
						</div>
					</Col>
					<Col xs={2}>
						<MessageStatus message={globalStore.message} />
					</Col>
					<Snackbar message={this.state.snackbarMessage} />
					<Modal
						isOpen={this.state.mapAttributesModalOpen}
						onRequestClose={() => {
							this.setState({
								mapAttributesModalOpen: false,
								attributeMapping: {}
							});
						}}
						style={{
							content: {
								top: "50%",
								left: "50%",
								width: 500,
								height: 500,
								overflowY: "auto",
								right: "auto",
								bottom: "auto",
								marginRight: "-50%",
								transform: "translate(-50%, -50%)"
							}
						}}
						onAfterOpen={() => {
							this.generateOptions();
						}}
					>
						<h2 style={{ marginTop: 0 }}>Map Attributes</h2>
						{this.state.attributesToMap.map(attribute => {
							return (
								<Row
									key={attribute.id}
									style={{ marginTop: 5, marginBottom: 5 }}
								>
									<Col xs={6}>{attribute.name}</Col>
									<Col xs={6}>
										<AsyncSelect
											value={this.state.attributeMapping[attribute.id]}
											loadOptions={(inputValue, callback) => {
												if (inputValue === "") {
													const interval = setInterval(() => {
														if (this.state.options.length > 0) {
															clearInterval(interval);
															callback(this.state.options);
														}
													}, 50);
												} else {
													this.filterAttributes(inputValue, callback);
												}
											}}
											clearable={false}
											onChange={val => {
												let attributeMapping = this.state.attributeMapping;
												attributeMapping[attribute.id] = val;
												this.setState({ attributeMapping });
											}}
											defaultOptions
										/>
									</Col>
								</Row>
							);
						})}
						<div className="text-right">
							<div
								className="saveButton"
								onClick={this.executePastePostMatching}
								style={{ cursor: "pointer" }}
								disabled={this.state.pasteText === "Pasting..."}
							>
								{this.state.pasteText}
							</div>
						</div>
					</Modal>
				</Row>
			);
		}
	}
);
