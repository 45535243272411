import React, { Component } from "react";
import autoBind from "react-autobind";
import API from "../../../API";
import { observer } from "mobx-react";
import camelcase from "camel-case";
import Loading from "../../structure/Loading";
import { toJS } from "mobx";

export default observer(
	class extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				tailoredContent: null,
				loading: false,
				zoom: 0.5,
				contentDiv: null
			};
		}

		componentWillReceiveProps(nextProps) {
			this.setState({ tailoredContent: null });
		}

		loadData() {
			this.setState({ loading: true });
			API(
				`/message/${this.props.match.params.messageID}/videoPreview/${this.props.pageStore.screen.value}`,
				"GET",
				{},
				videoElementsData => {
					const elementIDArray = videoElementsData.videoElements.map(
						element => {
							return element.elementID;
						}
					);

					API(
						`/message/${this.props.match.params.messageID}`,
						"POST",
						{
							bypassMapping: true,
							...toJS(this.props.pageStore.selectedValues),
							elementID: elementIDArray
						},
						tailorData => {
							this.setState({
								loading: false,
								contentDiv: (
									<div
										style={{
											width: 1920 * this.state.zoom,
											height: 1080 * this.state.zoom,
											border: "1px solid black",
											position: "absolute",
											overflow: "hidden",
											background: "white",
											marginBottom: 150
										}}
									>
										{videoElementsData.videoElements.map((element, index) => {
											if (element.type === "Image") {
												const url = tailorData[element.elementID];
												if (url !== "") {
													return (
														<img
															key={index}
															style={{
																height: element.height * this.state.zoom,
																width: element.width * this.state.zoom,
																position: "absolute",
																top: element.y * this.state.zoom,
																left: element.x * this.state.zoom,
																animation:
																	element.transitionIn === "Fade"
																		? `fadein ${element.transitionInLength}s`
																		: ""
															}}
															src={encodeURI(url.trim())}
														/>
													);
												}
											} else if (element.type === "Video") {
												const url = tailorData[element.elementID];
												return (
													<video
														width={element.width * this.state.zoom}
														height={element.height * this.state.zoom}
														controls={false}
														autoPlay
														style={{
															top: element.y * this.state.zoom,
															left: element.x * this.state.zoom,
															position: "absolute",
															animation:
																element.transitionIn === "Fade"
																	? `fadein ${element.transitionInLength}s`
																	: ""
														}}
													>
														<source
															src={encodeURI(url.trim())}
															type="video/mp4"
														/>
														Your browser does not support the video tag.
													</video>
												);
											} else if (element.type === "Text") {
												let additionalStyling = {};

												if (element.fontStyle === "Regular") {
													additionalStyling.fontWeight = 400;
												} else if (element.fontStyle === "Italic") {
													additionalStyling.fontStyle = "italic";
												} else if (element.fontStyle === "Ultralight") {
													additionalStyling.fontWeight = 100;
												} else if (element.fontStyle === "Ultralight Italic") {
													additionalStyling.fontWeight = 100;
													additionalStyling.fontStyle = "italic";
												} else if (element.fontStyle === "Thin") {
													additionalStyling.fontWeight = 200;
												} else if (element.fontStyle === "Thin Italic") {
													additionalStyling.fontWeight = 200;
													additionalStyling.fontStyle = "italic";
												} else if (element.fontStyle === "Light") {
													additionalStyling.fontWeight = 300;
												} else if (element.fontStyle === "Light Italic") {
													additionalStyling.fontWeight = 300;
													additionalStyling.fontStyle = "italic";
												} else if (element.fontStyle === "Medium") {
													additionalStyling.fontWeight = 500;
												} else if (element.fontStyle === "Medium Italic") {
													additionalStyling.fontWeight = 500;
													additionalStyling.fontStyle = "italic";
												} else if (element.fontStyle === "Semibold") {
													additionalStyling.fontWeight = 600;
												} else if (element.fontStyle === "Semibold Italic") {
													additionalStyling.fontWeight = 600;
													additionalStyling.fontStyle = "italic";
												} else if (element.fontStyle === "Bold") {
													additionalStyling.fontWeight = 700;
												} else if (element.fontStyle === "Bold Italic") {
													additionalStyling.fontWeight = 700;
													additionalStyling.fontStyle = "italic";
												} else if (element.fontStyle === "Heavy") {
													additionalStyling.fontWeight = 800;
												} else if (element.fontStyle === "Heavy Italic") {
													additionalStyling.fontWeight = 800;
													additionalStyling.fontStyle = "italic";
												} else if (element.fontStyle === "Black") {
													additionalStyling.fontWeight = 900;
												} else if (element.fontStyle === "Black Italic") {
													additionalStyling.fontWeight = 900;
													additionalStyling.fontStyle = "italic";
												}

												return (
													<p
														key={index}
														style={{
															...additionalStyling,
															top: element.y * this.state.zoom,
															left: element.x * this.state.zoom,
															textAlign: element.alignment
																? element.alignment.toLowerCase()
																: "left",
															width: element.width * this.state.zoom,
															color: element.fontColor
																? element.fontColor
																: "black",
															fontSize: element.size
																? element.size * this.state.zoom + "pt"
																: "12pt",
															fontFamily:
																element.fontFamily &&
																element.fontFamily !== "Default"
																	? element.fontFamily
																	: "",
															position: "absolute",
															margin: 0,
															padding: 0,
															animation:
																element.transitionIn === "Fade"
																	? `fadein ${element.transitionInLength}s`
																	: "",
															overflow: "hidden",
															textOverflow: "ellipsis",
															display: "-webkit-box",
															WebkitBoxOrient: "vertical",
															WebkitLineClamp: `${
																element.maxNumLines ? element.maxNumLines : 2
															}`,
															lineHeight: 1.25
														}}
														dangerouslySetInnerHTML={{
															__html: tailorData[element.elementID]
																? tailorData[element.elementID]
																: ""
														}}
													></p>
												);
											}
										})}
									</div>
								)
							});
						},
						null,
						true
					);
				}
			);
		}

		render() {
			return (
				<div>
					<h1 className="colName">Preview:</h1>
					{this.state.loading ? (
						<Loading />
					) : (
						<div>
							<a onClick={this.loadData} id="showPreviewButton">
								Show preview
							</a>
							<br />
							<a
								onClick={() => {
									this.setState({ zoom: 0.25 }, () => {
										this.loadData();
									});
								}}
								className={this.state.zoom === 0.25 ? "active" : ""}
							>
								.25x
							</a>{" "}
							|{" "}
							<a
								onClick={() => {
									this.setState({ zoom: 0.5 }, () => {
										this.loadData();
									});
								}}
								className={this.state.zoom === 0.5 ? "active" : ""}
							>
								.5x
							</a>{" "}
							|{" "}
							<a
								onClick={() => {
									this.setState({ zoom: 1 }, () => {
										this.loadData();
									});
								}}
								className={this.state.zoom === 1 ? "active" : ""}
							>
								1x
							</a>
							{this.state.contentDiv}
						</div>
					)}
				</div>
			);
		}
	}
);
