import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "@material/form-field/dist/mdc.form-field.css";
import "@material/checkbox/dist/mdc.checkbox.css";
import "./style.scss";

// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById("root"));
// registerServiceWorker();
