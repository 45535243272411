import React, { Component } from "react";
import autoBind from "react-autobind";
import MessageBrowser from "../../messageBrowser/MessageBrowser";
import { Row, Col } from "react-bootstrap";
import { Form, Radio, RadioGroup, Text } from "react-form";
import API from "../../../API";
import Loading from "../../structure/Loading";
import globalStore from "../../../GlobalStore";
import { observer } from "mobx-react";

export default observer(
	class ManageMessages extends Component {
		constructor() {
			super();

			this.state = {
				showCreateFields: false,
				newData: null
			};

			autoBind(this);
		}

		componentWillUnmount() {
			API("/foldersAndMessages", "GET", null, foldersAndMessages => {
				delete sessionStorage.foldersAndMessages;
				// sessionStorage.foldersAndMessages = JSON.stringify(foldersAndMessages);
			});
		}

		create(type, id) {
			this.setState({
				showCreateFields: true,
				id,
				type
			});
		}

		delete(type, id) {
			let confirmText = "Are you sure you want to delete this item?";
			if (type === "Folder") {
				confirmText += " All items in this folder will also be deleted.";
			}
			if (window.confirm(confirmText)) {
				API(
					`/foldersAndMessages/${id}`,
					"DELETE",
					{
						type
					},
					result => {
						this.setState({ newData: result });
					}
				);
			}
		}

		edit(type, name, id) {
			API(
				`/foldersAndMessages/${id}`,
				"PUT",
				{
					type,
					name
				},
				result => {
					this.setState({ newData: result });
				}
			);
		}

		newFoldersAndMessages(newData) {
			this.setState({
				newData,
				showCreateFields: false
			});
		}

		refreshParent() {
			API("/foldersAndMessages", "GET", null, foldersAndMessages => {
				this.setState({
					newData: foldersAndMessages
				});

				delete sessionStorage.foldersAndMessages;
				// sessionStorage.foldersAndMessages = JSON.stringify(foldersAndMessages);
			});
		}

		render() {
			return (
				<div id="manageMessage">
					<h1 className="pageName">Manage Messages</h1>
					<Row>
						<Col xs={this.state.showCreateFields ? 8 : 12}>
							<MessageBrowser
								createMode={true}
								create={this.create}
								newData={this.state.newData}
								delete={this.delete}
								edit={this.edit}
								manageMessageScreen={true}
								refreshParent={this.refreshParent}
							/>
						</Col>
						{this.state.showCreateFields ? (
							<Col xs={3}>
								<CreateMessageForm
									id={this.state.id}
									type={this.state.type}
									newFoldersAndMessages={this.newFoldersAndMessages}
								/>
							</Col>
						) : null}
					</Row>
				</div>
			);
		}
	}
);

const CreateMessageForm = observer(
	class CreateMessageForm extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				loading: false
			};
		}

		messageSelected(messageID, event) {
			event.persist();
			this.setState({
				loading: true
			});

			API(
				`/message/${messageID}/duplicate`,
				"POST",
				{
					parentFolderID: this.props.id,
					name: this.form.getValue("name")
				},
				data => {
					if (!event.shiftKey) {
						globalStore.history.push(`/message/${data.message.id}/`);
					} else {
						API("/foldersAndMessages", "GET", {}, result => {
							//new folder was created, so show the new data
							this.props.newFoldersAndMessages(result);
						});
					}
				}
			);
		}

		render() {
			return (
				<div id="createMessageForm">
					<Form
						ref={form => {
							this.form = form;
						}}
						values={{
							type: this.props.type
						}}
						validate={values => {
							return {
								name: !values.name ? "A name is required" : undefined
							};
						}}
						onSubmit={values => {
							values.id = this.props.id;
							API("/foldersAndMessages", "POST", values, result => {
								if (result.id) {
									//created a new message, so redirect to it
									globalStore.history.push(`/message/${result.id}/`);
								} else {
									//new folder was created, so show the new data
									this.props.newFoldersAndMessages(result);
								}
							});
						}}
					>
						{({ values, submitForm }) => {
							return (
								<form onSubmit={submitForm}>
									<RadioGroup field="type">
										<Row id="createFolderOrMessage">
											<Col>
												<Radio value="Folder" />
												&nbsp;Folder
											</Col>
											<Col>
												<Radio value="Message" />
												&nbsp;Message
											</Col>
											{globalStore.userType === "Architect" ? (
												<Col>
													<Radio value="Client Folder" />
													&nbsp;Client Folder
												</Col>
											) : null}
										</Row>
									</RadioGroup>
									<Row>
										<h1 id="folderNameLabel">{values.type} Name:</h1>
										<Text
											field="name"
											placeholder={values.type + " name goes here..."}
										/>
									</Row>
									<Row id="submitRow">
										<a onClick={submitForm} type="submit">
											Create
										</a>
									</Row>
									{values.type === "Message" ? (
										<Row>
											<div className="text-center">
												<h1>&mdash; Or &mdash;</h1>
											</div>
											<div>
												<h1>Copy from Existing:</h1>
												{this.state.loading ? (
													<Loading />
												) : (
													<div>
														<div className="messageBrowserDropdown">
															<MessageBrowser
																messageSelected={this.messageSelected}
																copyFromExisting={true}
															/>
														</div>
													</div>
												)}
											</div>
										</Row>
									) : null}
								</form>
							);
						}}
					</Form>
				</div>
			);
		}
	}
);
