import React, { Component } from "react";
import autoBind from "react-autobind";
import API from "../API";
import Select from "react-select-plus";
import globalStore from "../GlobalStore";
import { observer } from "mobx-react";

export default observer(
	class MessageStatus extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				selectValue: {
					value: this.props.message.status,
					label: this.props.message.status
				},
				saving: false
			};
		}

		componentWillReceiveProps(nextProps) {
			this.setState({
				selectValue: {
					value: nextProps.message.status,
					label: nextProps.message.status
				},
				saving: false
			});
		}

		setDropdown(value) {
			this.setState({
				selectValue: value,
				saving: true
			});

			const messageID = this.props.message.messageID
				? this.props.message.messageID
				: this.props.message.id;

			API(
				`/message/${messageID}/status`,
				"PUT",
				{ status: value.value },
				data => {
					globalStore.message.status = value.value;
					if (!data.message) {
						alert("Something went wrong!");
					} else {
						this.setState({
							saving: false
						});
					}
				}
			);
		}

		render() {
			let options = [
				"Backlog",
				"To Do",
				"In Process",
				"In Preview",
				"In Review",
				"In Testing",
				"Ready to Publish",
				"Published",
				"Roadblocked"
			];
			if (globalStore.userType === "Writer") {
				options = options.map(value => {
					if (
						[
							"In Testing",
							"Ready to Publish",
							"Published",
							"Roadblocked"
						].includes(value)
					) {
						return { value, label: value, disabled: true };
					} else {
						return { value, label: value };
					}
				});
			} else if (
				globalStore.userType === "Read Only" ||
				globalStore.userType === "Developer"
			) {
				options.map(value => {
					return { value, label: value, disabled: true };
				});
			} else {
				options = options.map(value => {
					return { value, label: value };
				});
			}

			return (
				<Select
					value={
						this.state.saving
							? { label: "Saving", value: "Saving" }
							: this.state.selectValue.value
								? this.state.selectValue
								: null
					}
					onChange={this.setDropdown}
					options={options}
					clearable={false}
					className={
						this.state.selectValue.value
							? `Select-${this.state.selectValue.value.replace(/\s/g, "")}`
							: null
					}
					disabled={
						this.state.saving ||
						globalStore.userType === "Read Only" ||
						globalStore.userType === "Developer"
					}
				/>
			);
		}
	}
);
