import React, { Component } from "react";
import SidebarRow from "./Row";
import { NavLink } from "react-router-dom";
import autoBind from "react-autobind";
import AvailableAttributeGroups from "./sidebarModules/AvailableAttributeGroups";
import { Elements } from "./sidebarModules/Elements";
import QuickPreview from "./sidebarModules/QuickPreview";
import API from "../../../API";
import { observer } from "mobx-react";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import globalStore from "../../../GlobalStore";
import ChatVoice from "./sidebarModules/ChatVoice";

export default observer(
	class SidebarMessage extends Component {
		constructor(props) {
			super(props);

			autoBind(this);
		}

		generateURL(dest) {
			return (
				/\/message\/[0-9]+\//g.exec(this.props.location.pathname)[0] + dest
			);
		}

		messageID() {
			const messageIDWithSlashes = /\/[0-9]+\//g.exec(
				this.props.location.pathname
			)[0];
			return messageIDWithSlashes.substring(1, messageIDWithSlashes.length - 1);
		}

		componentWillReceiveProps(nextProps) {
			if (nextProps.match.params.messageID !== localStorage.messageID) {
				delete localStorage.Attributes;
				delete localStorage.Elements;
				delete localStorage.Technical;
				delete localStorage.Testing;
				delete localStorage.Comments;
			}

			localStorage.messageID = nextProps.match.params.messageID;
		}

		dedupAttributes() {
			API(`/dedupAttributes/${this.messageID()}`, "GET", {}, data => {
				alert("You've been deduped!");
			});
		}

		deleteAllElements() {
			if (window.confirm("Are you sure you'd like to delete ALL elements?")) {
				API(`/deleteAllElements/${this.messageID()}`, "DELETE", {}, data => {
					globalStore.elements = [];
				});
			}
		}

		render() {
			return (
				<div id="sidebarContent">
					<div id="logoRow" className="text-center">
						<a href="/dashboard/dashboard" id="topRowNavLink">
							<img src="/bubble.svg" alt="tuzag message bubble logo" />
						</a>
					</div>
					<ContextMenuTrigger id="attributesRightClickMenu">
						<SidebarRow
							rowName="Attributes"
							history={this.props.history}
							rowURL={this.generateURL("attributes")}
						>
							<AvailableAttributeGroups />
						</SidebarRow>
					</ContextMenuTrigger>
					<ContextMenu id="attributesRightClickMenu">
						<MenuItem onClick={this.dedupAttributes}>
							Dedupe Attributes
						</MenuItem>
					</ContextMenu>
					<ContextMenuTrigger id="elementsRightClickMenu">
						<SidebarRow rowName="Elements" history={this.props.history}>
							<Elements
								messageID={this.messageID()}
								generateURL={this.generateURL}
								location={this.props.location}
							/>
						</SidebarRow>
						<ContextMenu id="elementsRightClickMenu">
							<MenuItem onClick={this.deleteAllElements}>
								Clear Message
							</MenuItem>
						</ContextMenu>
					</ContextMenuTrigger>
					<SidebarRow rowName="Technical">
						<h2>Media Type:</h2>
						<ChatVoice generateURL={this.generateURL} />
						{globalStore.userType === "Read Only" ||
						(globalStore.message &&
							globalStore.message.status === "Published") ||
						globalStore.userType === "Reviewer" ||
						globalStore.userType === "Writer" ? null : (
							<div>
								<NavLink to={this.generateURL("technical/email")}>
									Email
								</NavLink>
								<NavLink to={this.generateURL("technical/sms")}>SMS</NavLink>
								<NavLink to={this.generateURL("technical/web")}>Web</NavLink>
								<NavLink to={this.generateURL("technical/print")}>
									Print
								</NavLink>
								<NavLink to={this.generateURL("technical/retriever")}>
									Retrievers
								</NavLink>
								<NavLink to={this.generateURL("technical/graphic")}>
									Graphic
								</NavLink>
							</div>
						)}
					</SidebarRow>
					<SidebarRow rowName="Testing" className="testing">
						{/*<h2>Verify Content:</h2>*/}
						{/*<NavLink to={this.generateURL("testing/missingContent")}>*/}
						{/*Check for missing content*/}
						{/*</NavLink>*/}
						{/*<NavLink to={this.generateURL("testing/shortestLongest")}>*/}
						{/*Find shortest/longest*/}
						{/*</NavLink>*/}
						{/*<NavLink to={this.generateURL("testing/junkCases")}>*/}
						{/*Find junk cases*/}
						{/*</NavLink>*/}

						<h2>Preview Content:</h2>
						<QuickPreview
							messageID={this.messageID()}
							generateURL={this.generateURL}
							location={this.props.location}
						/>
						<NavLink to={this.generateURL("testing/previewInContext")}>
							Preview in Context
						</NavLink>
						<NavLink to={this.generateURL("testing/sendTest")}>
							Send Test
						</NavLink>
						<NavLink to={this.generateURL("testing/graphic")}>Graphic</NavLink>
						<NavLink to={this.generateURL("testing/video")}>Video</NavLink>

						<h2>Reviews:</h2>
						{/*<NavLink to={this.generateURL("testing/reviewers")}>*/}
						{/*Reviewers*/}
						{/*</NavLink>*/}
						<NavLink to={this.generateURL("testing/export")}>Export</NavLink>
					</SidebarRow>
				</div>
			);
		}
	}
);
