import React, { Component } from "react";
import autoBind from "react-autobind";
import API from "../../API";
import { Col, Row, Table } from "react-bootstrap";
import Select from "react-select-plus";
import globalStore from "../../GlobalStore";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

export default class SearchComments extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			team: [],
			teamFilter: null,
			type: null,
			searching: false,
			searchResults: [],
			content: "",
			beforeDate: null,
			afterDate: null
		};
	}

	componentDidMount() {
		API("/users", "GET", {}, data => {
			this.setState({
				team: data.users.map(user => {
					return { value: user.id, label: user.displayName };
				})
			});
		});
	}

	search() {
		this.setState({ searching: true });
		API(
			"/comments/search",
			"POST",
			{
				content: this.state.content,
				type: this.state.type ? this.state.type.value : null,
				commenter: this.state.teamFilter ? this.state.teamFilter.value : null,
				beforeDate: this.state.beforeDate ? this.state.beforeDate[0] : null,
				afterDate: this.state.afterDate ? this.state.afterDate[0] : null
			},
			data => {
				this.setState({ searchResults: data.searchResults, searching: false });
			}
		);
	}

	renderSearchResult(result) {
		if (result.elementID) {
			return (
				<tr key={`element-${result.id}`}>
					<td>
						<a
							onClick={() => {
								this.props.history.push(
									`/message/${result.element.message.id}/elements/${
										result.element.id
									}`
								);
							}}
						>
							<p style={{ display: "inline" }}>
								{result.element.message.name} : {result.element.name}
							</p>{" "}
						</a>
						{"  "}
						<a
							onClick={() => {
								this.props.history.push(
									`/message/${result.element.message.id}/elements/${
										result.element.id
									}`
								);
								globalStore.commentsModalOpen = true;
								globalStore.commentsElementID = result.element.id;
							}}
						>
							<i className="fas fa-comments" />
						</a>
						{"  "}
						<i
							className="fas fa-calendar-alt"
							data-tip={moment(result.createdAt).format("h:mm a, M/D/YY")}
							data-for={`tooltipDataElement-${result.id}`}
						/>
						<ReactTooltip id={`tooltipDataElement-${result.id}`} />
					</td>
					<td>
						<p>{result.user.displayName}</p>
					</td>
					<td dangerouslySetInnerHTML={this.createMarkup(result.content)} />
				</tr>
			);
		} else if (result.messageID) {
			return (
				<tr key={`message-${result.id}`}>
					<td>
						<a
							onClick={() => {
								this.props.history.push(`/message/${result.message.id}/`);
							}}
						>
							<p style={{ display: "inline" }}>{result.message.name}</p>
						</a>
						{"  "}
						<a
							onClick={() => {
								this.props.history.push(`/message/${result.message.id}/`);
								globalStore.commentsModalOpen = true;
								globalStore.commentsMessageID = result.message.id;
							}}
						>
							<i className="fas fa-comments" />
						</a>
						{"  "}
						<i
							className="fas fa-calendar-alt"
							data-tip={moment(result.createdAt).format("h:mm a, M/D/YY")}
							data-for={`tooltipDataMessage-${result.id}`}
						/>
						<ReactTooltip id={`tooltipDataMessage-${result.id}`} />
					</td>
					<td>
						<p>{result.user.displayName}</p>
					</td>
					<td dangerouslySetInnerHTML={this.createMarkup(result.content)} />
				</tr>
			);
		} else if (result.bitCaseID) {
			return (
				<tr key={`bitCase-${result.id}`}>
					<td>
						<p style={{ display: "inline" }}>
							<a
								onClick={() => {
									this.props.history.push(
										`/message/${
											result.bitCase.bit.element.message.id
										}/elements/${result.bitCase.bit.element.id}/bit/${
											result.bitCase.bit.id
										}`
									);
								}}
							>
								{result.bitCase.bit.element.message.name} :{" "}
								{result.bitCase.bit.element.name} : Case #{parseInt(
									result.bitCase.order,
									10
								) + 1}
							</a>
						</p>
						{"  "}
						<a
							onClick={() => {
								this.props.history.push(
									`/message/${result.bitCase.bit.element.message.id}/elements/${
										result.bitCase.bit.element.id
									}/bit/${result.bitCase.bit.id}`
								);
								globalStore.commentsModalOpen = true;
								globalStore.commentsBitCaseID = result.bitCase.id;
							}}
						>
							<i className="fas fa-comments" />
						</a>
						{"  "}
						<i
							className="fas fa-calendar-alt"
							data-tip={moment(result.createdAt).format("h:mm a, M/D/YY")}
							data-for={`tooltipDataBitCase-${result.id}`}
						/>
						<ReactTooltip id={`tooltipDataBitCase-${result.id}`} />
					</td>
					<td>
						<p>{result.user.displayName}</p>
					</td>
					<td dangerouslySetInnerHTML={this.createMarkup(result.content)} />
				</tr>
			);
		}
	}

	createMarkup(content) {
		return { __html: content };
	}

	render() {
		return (
			<div id="searchComments">
				<h1>Search Comments</h1>
				<Row>
					<Col xs={6}>
						<Row style={{ marginTop: 10, marginBottom: 10 }}>
							<Col xs={6} className="text-right">
								<strong>Content:</strong>
							</Col>
							<Col xs={6}>
								<input
									className="form-control"
									type="text"
									value={this.state.content}
									onChange={evt => {
										this.setState({ content: evt.target.value });
									}}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: 10, marginBottom: 10 }}>
							<Col xs={6} className="text-right">
								<strong>Type:</strong>
							</Col>
							<Col xs={6}>
								<Select
									options={[
										{ value: "message", label: "Message" },
										{ value: "element", label: "Element" },
										{ value: "bitCase", label: "Bit Case" }
									]}
									value={this.state.type}
									onChange={value => {
										this.setState({ type: value });
									}}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: 10, marginBottom: 10 }}>
							<Col xs={6} className="text-right">
								<strong>Commenter:</strong>
							</Col>
							<Col xs={6}>
								<Select
									options={this.state.team}
									value={this.state.teamFilter}
									onChange={value => {
										this.setState({ teamFilter: value });
									}}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: 10, marginBottom: 10 }}>
							<Col xs={6} className="text-right">
								<strong>Posted Before:</strong>
							</Col>
							<Col xs={6}>
								<Flatpickr
									className="form-control"
									style={{ background: "white" }}
									disabled={false}
									value={this.state.beforeDate}
									onChange={beforeDate => {
										this.setState({ beforeDate });
									}}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: 10, marginBottom: 10 }}>
							<Col xs={6} className="text-right">
								<strong>Posted After:</strong>
							</Col>
							<Col xs={6}>
								<Flatpickr
									className="form-control"
									style={{ background: "white" }}
									disabled={false}
									value={this.state.afterDate}
									onChange={afterDate => {
										this.setState({ afterDate });
									}}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: 10, marginBottom: 10 }}>
							<Col xs={{span: 6, offset: 6}} className="text-center">
								<a
									onClick={this.search}
									className={`saveButton`}
									disabled={this.state.searching}
								>
									{this.state.searching ? "Searching..." : "Search"}
								</a>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col xs={12}>
						{this.state.searchResults.length === 0 ? (
							<Row>
								<Col>
									<p>No results</p>
								</Col>
							</Row>
						) : (
							<Table striped bordered hover>
								<thead>
									<tr>
										<th>Message</th>
										<th>Author</th>
										<th>Comment</th>
									</tr>
								</thead>
								<tbody>
									{this.state.searchResults.map(result => {
										return this.renderSearchResult(result);
									})}
								</tbody>
							</Table>
						)}
					</Col>
				</Row>
			</div>
		);
	}
}
