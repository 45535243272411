import React, { Component } from "react";
import autoBind from "react-autobind";
import { Row, Col, Container } from "react-bootstrap";
import API from "../../../API";
import camelcase from "camel-case";
import CopyToClipboard from "react-copy-to-clipboard";
import Snackbar from "../../structure/Snackbar";
import { Form, Radio, RadioGroup, Textarea } from "react-form";
import globalStore from "../../../GlobalStore";
import { observer } from "mobx-react";

export default observer(
	class Web extends Component {
		constructor(props) {
			super(props);
			autoBind(this);

			this.state = {
				snackbarMessage: "",
				variableType: null,
				template: null
			};
		}

		componentDidMount() {
			this.getData(this.props);
		}

		componentWillReceiveProps(nextProps) {
			this.getData(nextProps);
		}

		getData(props) {
			API(`/message/${props.match.params.messageID}`, "GET", {}, data => {
				this.setState({
					variableType: data.message.webVariableType,
					template: data.message.webTemplate
				});
			});
		}

		render() {
			return (
				<Container fluid id="technicalWeb">
					<Row>
						<Col>
							<Row>
								<div className="colName">Element Map</div>
							</Row>
							<Row className="messageIDRow">
								<Col xs={4}>
									<h3>Message ID:</h3>
								</Col>
								<Col xs={4}>
									<p>{this.props.match.params.messageID}</p>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row className="elementTableRow">
						<Col>
							<Row className="headers">
								<Col xs={4}>
									<h3 className="purpleColor">Element Name</h3>
								</Col>
								<Col xs={4}>
									<h3 className="purpleColor">Element ID</h3>
								</Col>
								<Col xs={4}>
									<h3 className="purpleColor">Element Slug</h3>
								</Col>
							</Row>
							{globalStore.elements.map(element => {
								return (
									<Row key={element.id} className="elementRow">
										<CopyToClipboard
											onCopy={() =>
												this.setState({ snackbarMessage: "Copied" })
											}
											text={element.name}
											className="copyRow"
										>
											<Col xs={4}>
												<h3 className="elementName">
													{element.name}
													<i className="fa fa-clipboard" />
												</h3>
											</Col>
										</CopyToClipboard>

										<CopyToClipboard
											onCopy={() =>
												this.setState({ snackbarMessage: "Copied" })
											}
											text={element.id}
											className="copyRow"
										>
											<Col xs={4}>
												<p>
													{element.id}
													<i className="fa fa-clipboard" />
												</p>
											</Col>
										</CopyToClipboard>

										<CopyToClipboard
											onCopy={() =>
												this.setState({ snackbarMessage: "Copied" })
											}
											text={camelcase(element.name)}
											className="copyRow"
										>
											<Col xs={4}>
												<p>
													{camelcase(element.name)}
													<i className="fa fa-clipboard" />
												</p>
											</Col>
										</CopyToClipboard>
									</Row>
								);
							})}
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="colName">Review Template</div>
							<Form
								values={{
									variableType: this.state.variableType,
									template: this.state.template ? this.state.template : ""
								}}
								validate={values => {
									return {
										variableType: !values.variableType
											? "A variable type is required"
											: undefined
									};
								}}
								onSubmit={values => {
									API(
										`/message/${
											this.props.match.params.messageID
										}/template/web`,
										"PUT",
										{
											templateVariableType: values.variableType,
											templateContent: values.template
										},
										data => {
											this.setState({
												variableType: data.message.templateVariableType,
												template: data.message.templateContent,
												snackbarMessage: "Saved!"
											});
										}
									);
								}}
							>
								{({ values, submitForm }) => {
									return (
										<form onSubmit={submitForm}>
											<RadioGroup field="variableType">
												<Row>
													<Col xs={2}>
														<Radio value="name" />
														&nbsp;Name
													</Col>
													<Col xs={2}>
														<Radio value="id" />
														&nbsp;ID
													</Col>
													<Col xs={2}>
														<Radio value="slug" />
														&nbsp;Slug
													</Col>
												</Row>
											</RadioGroup>
											<Row>
												<Textarea
													className="textarea"
													field="template"
													placeholder="Copy/paste or enter your review template markup here..."
													rows={10}
													value={values.template}
												/>
											</Row>
											<Row>
												<Col className="text-right">
													<a
														onClick={submitForm}
														className="saveButton"
														type="submit"
													>
														Save
													</a>
												</Col>
											</Row>
										</form>
									);
								}}
							</Form>
						</Col>
					</Row>
					<Snackbar message={this.state.snackbarMessage} />
				</Container>
			);
		}
	}
);
