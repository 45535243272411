import React, { Component } from "react";
import autoBind from "react-autobind";
import Select from "react-select-plus";
import { Row, Col } from "react-bootstrap";
import API from "../../API";
import { Link } from "react-router-dom";

export default class SearchMessages extends Component {
	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			messageStatusFilter: null,
			name: "",
			content: "",
			team: [],
			searchResults: [],
			teamFilter: null,
			searching: false,
			attributeFilterOptions: [],
			attributeFilter: null
		};
	}

	componentDidMount() {
		API("/users", "GET", {}, data => {
			this.setState({
				team: data.users.map(user => {
					return { value: user.id, label: user.displayName };
				})
			});
		});

		API(`/availableAttributesAndOptions`, "GET", {}, data => {
			this.setState({
				attributeFilterOptions: data.availableAttributes.map(group => {
					return {
						label: group.name,
						groupID: group.id,
						options: group.attributes.map(attribute => {
							return {
								label: attribute.name,
								value: attribute.attributeID
									? attribute.attributeID
									: attribute.id
							};
						})
					};
				})
			});
		});
	}

	search() {
		this.setState({ searching: true });
		API(
			"/message/search",
			"POST",
			{
				name: this.state.name,
				content: this.state.content,
				messageStatus: this.state.messageStatusFilter
					? this.state.messageStatusFilter.value
					: null,
				team: this.state.teamFilter ? this.state.teamFilter.value : null,
				attribute: this.state.attributeFilter
					? this.state.attributeFilter.value
					: null
			},
			data => {
				this.setState({ searchResults: data.searchResults, searching: false });
			}
		);
	}

	render() {
		return (
			<div>
				<h1>Search Messages</h1>
				<Row>
					<Col xs={6}>
						<Row style={{ marginTop: 10, marginBottom: 10 }}>
							<Col xs={6} className="text-right">
								<strong>Name:</strong>
							</Col>
							<Col xs={6}>
								<input
									className="form-control"
									type="text"
									value={this.state.name}
									onChange={evt => {
										this.setState({ name: evt.target.value });
									}}
									autoFocus={true}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: 10, marginBottom: 10 }}>
							<Col xs={6} className="text-right">
								<strong>Content:</strong>
							</Col>
							<Col xs={6}>
								<input
									className="form-control"
									type="text"
									value={this.state.content}
									onChange={evt => {
										this.setState({ content: evt.target.value });
									}}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: 10, marginBottom: 10 }}>
							<Col xs={6} className="text-right">
								<strong>Message Status:</strong>
							</Col>
							<Col xs={6}>
								<Select
									options={[
										"Backlog",
										"To Do",
										"In Process",
										"In Preview",
										"In Review",
										"In Testing",
										"Ready to Publish",
										"Published",
										"Roadblocked"
									].map(option => {
										return { value: option, label: option };
									})}
									value={this.state.messageStatusFilter}
									onChange={value => {
										this.setState({ messageStatusFilter: value });
									}}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: 10, marginBottom: 10 }}>
							<Col xs={6} className="text-right">
								<strong>Assigned To:</strong>
							</Col>
							<Col xs={6}>
								<Select
									options={this.state.team}
									value={this.state.teamFilter}
									onChange={value => {
										this.setState({ teamFilter: value });
									}}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: 10, marginBottom: 10 }}>
							<Col xs={6} className="text-right">
								<strong>Attribute:</strong>
							</Col>
							<Col xs={6}>
								<Select
									value={this.state.attributeFilter}
									onChange={value => {
										this.setState({ attributeFilter: value });
									}}
									options={this.state.attributeFilterOptions}
								/>
							</Col>
						</Row>
						<Row style={{ marginTop: 10, marginBottom: 10 }}>
							<Col xs={{ span: 6, offset: 6 }} className="text-center">
								<a
									onClick={this.search}
									className={`saveButton`}
									disabled={this.state.searching}
								>
									{this.state.searching ? "Searching..." : "Search"}
								</a>
							</Col>
						</Row>
					</Col>
					<Col xs={6}>
						{this.state.searchResults.length === 0 ? (
							<Row>
								<Col>
									<p>No results</p>
								</Col>
							</Row>
						) : (
							this.state.searchResults.map((result, index) => {
								return (
									<Row key={index}>
										<Col xs={12}>
											{result.elements ? (
												result.elements.map((elementResult, index) => {
													return (
														<div key={index}>
															<Link
																to={`/message/${result.id}/elements/${
																	elementResult.id
																}`}
															>
																{result.name} ({elementResult.name})
															</Link>
														</div>
													);
												})
											) : (
												<Link to={`/message/${result.id}/`}>{result.name}</Link>
											)}
										</Col>
									</Row>
								);
							})
						)}
					</Col>
				</Row>
			</div>
		);
	}
}
